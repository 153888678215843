import React from "react";
import useMediaQuery from "../../hooks/UseMediaQuery";
import { Link } from "react-router-dom";

function CampaignListing({ data, theme }) {
  const isDesktop = useMediaQuery("(min-width:640px)");

  return (
    <div className="max-w-[1440px] mt-[30px] mx-auto">
      <div
        className="fonts-for-Sathosi-campaigns "
        style={{ color: theme?.runningCampaignsMainColor }}
      >
        <div>
          <p className="ml-4 sm:ml-10 font-[700] text-[24px] uppercase">
            {" "}
            {data?.name}{" "}
          </p>
        </div>

        <div
          className={`${
            isDesktop ? "grid-cols-4 grid " : "template-CampaignsListing-scroll"
          }  `}
        >
          {data?.datas?.map((item, index) => (
            <Link
              className="hover:text-black hover:no-underline"
              to={
                data?.secId !== "UpComing" && `${item?.link + "?from=gvHome"}`
              }
              // target={item?.link && " _blank"}
            >
              <div className="justify-center mt-8 items-start grid">
                <div className="justify-center  items-center flex">
                  <div className="relative -z-20  xl:w-[283px] lg:w-[200px] w-[250px] h-[290px] sm:w-[160px] sm:h-[160px] lg:h-[200px] xl:h-[331px] ">
                    <img
                      className="w-full h-full object-cover"
                      src={item?.image}
                      alt=""
                    />
                    {data?.secId !== "UpComing" && (
                      <div className="absolute bottom-0 left-0 right-0 top-[75%] justify-center items-center flex">
                        <button className="text-[16px] font-[500] bg-slate-100 px-3 py-2  rounded-lg text-[black]">
                          {" "}
                          View Event
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="text-center xl:w-[283px] lg:w-[200px] w-[250px]  sm:w-[160px]">
                  <div className="">
                    <p className=" mt-2 text-[28px] sm:text-[18px] xl:text-[26px] font-[700] leading-[33px]">
                      {" "}
                      {item?.name}{" "}
                    </p>

                    <p
                      style={{ color: theme?.textColor }}
                      className="text-[16px] mt-2 sm:text-[10px] xl:text-[16px] font-[400]"
                    >
                      {" "}
                      {item?.text?.slice(0, 70)}...
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CampaignListing;
