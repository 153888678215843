import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./Influencer.module.css";
import axios from "axios";
import rightIcon from "../../../../images/rightIcon.png";
import Footer from "../../../../Components/footer/Footer";
import Back from "../../../../Components/back/Back";

function BioPage() {
  const { influencerId } = useParams();
  
  const [bioDetails, setBioDetails] = useState();
const location = useLocation()
const navigate = useNavigate()
  const influencerBioDetails = async () => {
    try {
      const response = await axios.get(`/${influencerId}/data.json`);

      setBioDetails(response?.data?.data?.bioDetails);
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    influencerBioDetails();
  }, []);




  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation); 
    }else{
      navigate("/")
    } 

  };
  return (
    <>
    <div className="max-w-[1440px] mx-auto">
          <div className=" mt-1 z-10 sticky top-0 bg-[white]" onClick={handleNavigateToPrevLocation}>
          <div className='sm:ml-5 ml-2'>
 <Back/>
 </div> 
</div>
      <div
        className={`grid  ${styles.paraFontStyle} lg:pb-0 pb-10 relative grid-cols-1 md:grid-cols-2`}
      >
        <div className=" md:ml-10   items-center md:order-1 order-2 justify-center mt-0 md:mt-[25%]">
          <div className="hidden md:block">
            <ul className="leading-[75px]">
              <li className={`${styles.fontStyle} text-[48px] lg:text-[68px] `}>
                {bioDetails?.name}
              </li>
              <li className={`${styles.fontStyle} text-[48px] lg:text-[68px] `}>
                {bioDetails?.lastName}
              </li>
              <li className=" font-[400]  text-[18px] -mt-5 ">
                {bioDetails?.title}
              </li>
            </ul>
          </div>

          <div className="mt-10 sm:ml-0 ml-4 mr-4 sm:mr-0 grid grid-cols-2">
            {bioDetails?.socialMedia?.map((item) => (
              <div className="w-[90%]  mt-4 p-1 pl-2 pr-2 justify-between items-center flex text-start bg-white shadow-xl ">
                <div className="mt-1 text-[16px] sm:text-[12px]  lg:text-[18px]">
                  <div className="font-bold justify-center items-center flex">
                    {" "}
                    <img
                      className="w-[40px] h-[40px]"
                      src={item?.icon}
                      alt=""
                    />{" "}
                    <Link target="_blank" to={item?.link}>
                    <p  className="ml-2">{item?.name}</p>
                    </Link>
                    
                  </div>
                
                </div>

                <div className="items-center">
                  <Link target="_blank" to={item?.link} className="">
                    <img src={rightIcon} alt="" />
                  </Link>
                </div>
              </div>
            ))}
            <div></div>
          </div>

          <div className=" lg:mt-24 mt-[4rem]  leading-[24px] ">
            <div className="sm:ml-0 ml-4 mr-4 sm:mr-0">
            <p className="font-[600] sm:text-[33px] text-[26px]">About</p>
            <p className="font-[400] mt-5 sm:mt-10 mr-5 text-[16px]  sm:text-[18px]">
              {bioDetails?.about}
            </p>
            </div>
          </div>
        </div>

        <div className="md:order-2  relative order-1   ">
          <div>
            <img
              className="w-[100%] object-cover h-auto"
              src={bioDetails?.image}
              alt=""
            />
          </div>
          <div className=" md:hidden ml-4 mt-5  leading-[40px] ">
            <p className={` ${styles.fontStyle}  text-[48px]  `}>
              {bioDetails?.name}
            </p>
            <p className={` ${styles.fontStyle}  text-[48px]  `}>
              {" "}
              {bioDetails?.lastName}{" "}
            </p>
            <p className=" font-[400] text-[18px]  ">{bioDetails?.title}</p>
          </div>
        </div>

      </div>

  
    </div>

    <div className="mt-20">
      <Footer/>
      </div>
    </>
  );
}

export default BioPage;
