import React, { useContext, useEffect, useRef } from "react";
import MinimalModelHeader from "./MinimalModelHeader";
import MinimalModelLandingPage from "./MinimalModelLandingPage";
import MinimalModelAbout from "./MinimalModelAbout";
import MinimalModelAchievements from "./MinimalModelAchievements";
import MinimalModelBrands from "./MinimalModelBrands";
import MinimalModelWorks from "./MinimalModelWorks";
import MinimalModelGetInTouch from "./MinimalModelGetInTouch";
import Footer from "../../../../Components/footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Back from "../../../../Components/back/Back";
import { imageData } from "../../../../context/dataContext";
import { Context } from "../../../../ScrollContext";
import { isEmpty } from "lodash";
import useMediaQuery from "../../../../hooks/UseMediaQuery";

function MinimalPortfolio({ influencerDetails }) {
  const { setImagesData, imagesData, setBrandsData } = useContext(imageData);
  const { scrollingPositon, setScrollingPosition } = useContext(Context);
  const isDesktop = useMediaQuery("(min-width:640px)");
  const aboutRef = useRef(null);
  const achievementsRef = useRef(null);
  const brandsRef = useRef(null);
  const worksRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (elementRef) => {
    console.log(elementRef, "element");
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (influencerDetails)
      setImagesData({
        workName: influencerDetails?.workName,
        datas: influencerDetails?.works,
      });
    if (influencerDetails?.Brands)
      return setBrandsData({ datas: influencerDetails?.Brands });
  }, [influencerDetails]);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollingPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(influencerDetails)) {
      window.scrollTo(0, scrollingPositon);
    }
  }, [influencerDetails]);

  return (
    <div className="">
      <MinimalModelHeader
        handleBack={handleNavigateToPrevLocation}
        aboutRef={aboutRef}
        achievementsRef={achievementsRef}
        brandsRef={brandsRef}
        worksRef={worksRef}
        contactRef={contactRef}
        scrollToSection={scrollToSection}
        data={influencerDetails}
      />
      <MinimalModelLandingPage
        scrollToSection={scrollToSection}
        aboutRef={aboutRef}
        achievementsRef={achievementsRef}
        brandsRef={brandsRef}
        worksRef={worksRef}
        contactRef={contactRef}
        data={influencerDetails}
      />
      <MinimalModelAbout
        aboutref={aboutRef}
        data={influencerDetails?.aboutDetail}
      />
      <MinimalModelAchievements
        achievementsref={achievementsRef}
        data={influencerDetails?.Achievements}
      />
      {influencerDetails?.Brands && (
        <MinimalModelBrands
          brandsref={brandsRef}
          data={influencerDetails?.Brands}
        />
      )}
      <MinimalModelWorks
        worksref={worksRef}
        data={influencerDetails?.works}
        Name={influencerDetails?.workName}
      />
      <MinimalModelGetInTouch
        contactref={contactRef}
        data={influencerDetails?.getInTouch}
      />
      <div className="mt-20">{isDesktop && <Footer />}</div>
    </div>
  );
}

export default MinimalPortfolio;
