import React, { useState } from "react";
import RuleLine from "../giveaways/RuleLine";
import ReactPlayer from "react-player";
import { isEmpty } from "lodash";

function AccordianSection({ data }) {
  const [selectedId, setSelectedId] = useState(1);

  const handleOpen = (id) => {
    setSelectedId(id);
  };

  const handleClose = () => {
    setSelectedId("");
  };

  const ThumbnailImage = ({ image }) => {
    return (
      <div className=" h-[360px] sm:w-[100%] w-[93%] ">
        <img className="w-full h-full object-contain " src={image} alt="" />
      </div>
    );
  };

  return (
    <div className=" w-[90%] mt-6 sm:w-[76%] mx-auto">
      {/* <div className=" mx-2 sm:mx-10 lg:mx-20">
        {data?.map((item) => (
          <>
            <div className="justify-between items-start flex py-[35px]">
              <div>
                <p className="sm:mx-0 mx-2 text-[22px] sm:text-[32px] font-[700] leading-[44px]">
                  {" "}
                  {item?.name}{" "}
                </p>

                {item?.id === selectedId && (
                  <div className="grid grid-cols-1  lg:grid-cols-2 ">
                    <div className="mt-[44px]">
                      {item?.para?.map((items, i) => (
                        <div>
                          <ol
                            className={`grid  sm:ml-0 ml-5  text-[#161616] ${
                              i > 0 ? "mt-[25px]" : "mt-0 "
                            }`}
                          >
                            <li className="text-[18px] sm:text-[24px] font-[500]  leading-[32px]">
                              {items?.id}. <span>{items?.name}</span>
                            </li>

                            <li className="ml-5 lg:mr-32 mt-[7px] text-[#787878] text-[16px] font-[400] leading-[22px]">
                              {items?.para}
                            </li>
                          </ol>
                        </div>
                      ))}
                    </div>

                    <div className="lg:justify-end  justify-center items-center flex mt-[44px]">
                      <div className=" h-[360px] w-[360px]  relative">
                        <ReactPlayer
                          style={{ objectFit: "contain" }}
                          height={"100%"}
                          width={"100%"}
                          controls={true}
                          playing={true}
                          light={
                            <ThumbnailImage image={item?.thumbnailPhoto} />
                          }
                          url={item?.video}
                        />
                       
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="justify-center items-center flex ">
                <button className="w-[32px] rounded-[50%] border-[1px] border-[#b7b5b5] h-[32px] items-center justify-center flex bg-[#F5F6F8]">
                  {item?.id === selectedId ? (
                    <svg
                      onClick={() => handleClose()}
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M11.5713 5.40012L9 7.97142L6.4287 5.40012C6.29231 5.26373 6.10733 5.18711 5.91444 5.18711C5.72156 5.18711 5.53657 5.26373 5.40018 5.40012C5.26379 5.53651 5.18717 5.7215 5.18717 5.91438C5.18717 6.10727 5.26379 6.29225 5.40018 6.42864L7.97148 8.99994L5.40018 11.5712C5.26379 11.7076 5.18717 11.8926 5.18717 12.0855C5.18717 12.2784 5.26379 12.4634 5.40018 12.5998C5.53657 12.7361 5.72156 12.8128 5.91444 12.8128C6.10733 12.8128 6.29231 12.7361 6.4287 12.5998L9 10.0285L11.5713 12.5998C11.7077 12.7361 11.8927 12.8128 12.0856 12.8128C12.2784 12.8128 12.4634 12.7361 12.5998 12.5998C12.7362 12.4634 12.8128 12.2784 12.8128 12.0855C12.8128 11.8926 12.7362 11.7076 12.5998 11.5712L10.0285 8.99994L12.5998 6.42864C12.7362 6.29225 12.8128 6.10726 12.8128 5.91438C12.8128 5.7215 12.7362 5.53651 12.5998 5.40012C12.4634 5.26373 12.2784 5.18711 12.0856 5.18711C11.8927 5.18711 11.7077 5.26373 11.5713 5.40012Z"
                        fill="#161616"
                      />
                    </svg>
                  ) : (
                    <svg
                      onClick={() => handleOpen(item?.id)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
                        fill="#161616"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <RuleLine />
          </>
        ))}
      </div> */}
      {data?.map((item) => (
        <div>
          <div
            onClick={() =>
              item?.id === selectedId ? handleClose() : handleOpen(item?.id)
            }
            className="justify-between py-[21px] sm:py-[35px] items-start flex"
          >
            <div>
              <p className="text-[22px] sm:text-[32px] font-[700] leading-[44px]">
                {" "}
                {item?.name}{" "}
              </p>
            </div>

            <div className="justify-center items-center flex ">
              <button className="w-[32px] rounded-[50%] border-[1px] border-[#b7b5b5] h-[32px] items-center justify-center flex bg-[#F5F6F8]">
                {item?.id === selectedId ? (
                  <svg
                    onClick={() => handleClose()}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M11.5713 5.40012L9 7.97142L6.4287 5.40012C6.29231 5.26373 6.10733 5.18711 5.91444 5.18711C5.72156 5.18711 5.53657 5.26373 5.40018 5.40012C5.26379 5.53651 5.18717 5.7215 5.18717 5.91438C5.18717 6.10727 5.26379 6.29225 5.40018 6.42864L7.97148 8.99994L5.40018 11.5712C5.26379 11.7076 5.18717 11.8926 5.18717 12.0855C5.18717 12.2784 5.26379 12.4634 5.40018 12.5998C5.53657 12.7361 5.72156 12.8128 5.91444 12.8128C6.10733 12.8128 6.29231 12.7361 6.4287 12.5998L9 10.0285L11.5713 12.5998C11.7077 12.7361 11.8927 12.8128 12.0856 12.8128C12.2784 12.8128 12.4634 12.7361 12.5998 12.5998C12.7362 12.4634 12.8128 12.2784 12.8128 12.0855C12.8128 11.8926 12.7362 11.7076 12.5998 11.5712L10.0285 8.99994L12.5998 6.42864C12.7362 6.29225 12.8128 6.10726 12.8128 5.91438C12.8128 5.7215 12.7362 5.53651 12.5998 5.40012C12.4634 5.26373 12.2784 5.18711 12.0856 5.18711C11.8927 5.18711 11.7077 5.26373 11.5713 5.40012Z"
                      fill="#161616"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={() => handleOpen(item?.id)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M8.27361 4.63637V8.27273H4.63725C4.44437 8.27273 4.25938 8.34935 4.12299 8.48574C3.9866 8.62213 3.90998 8.80712 3.90998 9C3.90998 9.19289 3.9866 9.37787 4.12299 9.51426C4.25938 9.65065 4.44437 9.72728 4.63725 9.72728H8.27361V13.3636C8.27361 13.5565 8.35024 13.7415 8.48663 13.8779C8.62302 14.0143 8.808 14.0909 9.00089 14.0909C9.19377 14.0909 9.37876 14.0143 9.51515 13.8779C9.65154 13.7415 9.72816 13.5565 9.72816 13.3636V9.72728H13.3645C13.5574 9.72728 13.7424 9.65065 13.8788 9.51426C14.0152 9.37787 14.0918 9.19289 14.0918 9C14.0918 8.80712 14.0152 8.62213 13.8788 8.48574C13.7424 8.34935 13.5574 8.27273 13.3645 8.27273H9.72816V4.63637C9.72816 4.44348 9.65154 4.2585 9.51515 4.12211C9.37876 3.98572 9.19377 3.90909 9.00089 3.90909C8.808 3.90909 8.62302 3.98572 8.48663 4.12211C8.35024 4.2585 8.27361 4.44348 8.27361 4.63637Z"
                      fill="#161616"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          {item?.id === selectedId && (
            <div className="grid grid-cols-1 lg:grid-cols-2 mb-10">
              <div className="mt-0 sm:mt-[0px]">
                {item?.para?.map((items, i) => (
                  <div>
                    <div
                      className={`grid  sm:ml-0   text-[#161616] ${
                        i > 0 ? "mt-[25px]" : "mt-0 "
                      }`}
                    >
                      <p className="xl:whitespace-nowrap text-[18px] sm:text-[24px] font-[500]  leading-[32px]">
                        {items?.id}. <span>{items?.name}</span>
                      </p>

                      <p className=" lg:mr-32 mt-[7px] text-[#787878] text-[16px] font-[400] leading-[22px]">
                        {items?.para}
                        <a href={items?.link}>{items?.link}</a>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              {!isEmpty(item?.video) && (
                <div className="lg:justify-end  justify-center items-center flex mt-[22px] sm:mt-[0px]">
                  <div className=" h-[360px] sm:w-[90%] w-[93%]  relative">
                    <ReactPlayer
                      style={{ objectFit: "contain" }}
                      height={"100%"}
                      width={"100%"}
                      controls={true}
                      playing={true}
                      light={<ThumbnailImage image={item?.thumbnailPhoto} />}
                      url={item?.video}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <RuleLine />
        </div>
      ))}
    </div>
  );
}

export default AccordianSection;
