import React from "react";
import JudgeDetialSection from "./JudgeDetialSection";

export default function EsteemedJudges({ campaignData }) {
  return (
    <div className="template-inner-container mt-[30px] md:mt-[70px]">
      <div className="template-esteemed-judges-container">
        <h3 className="-mb-[25px] text-[17px] md:text-[35px] font-[700] ">The Esteemed Judges</h3>
        {campaignData?.esteemedJudges?.map((obj, index) => {
          return (
            <div key={index}>
              <JudgeDetialSection data={obj} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
