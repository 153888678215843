import React from "react";
import useMediaQuery from "../../../../hooks/UseMediaQuery";

function HowToParcitipate({ data }) {
  const isDesktop = useMediaQuery("(min-width:640px)");
  return (
    <div className="bg-[#F8F8F9] mt-[32px] sm:mt-[50px] w-full h-full pb-[57px] pt-[32px] sm:pt-[57px] fonts-for-Sathosi-giveaway">
     
      <div className="justify-center items-center flex text-center">
        <p className="text-[24px]  sm:text-[32px] font-[700] text-[#161616]">
          {data?.howToParcitipate}
        </p>
      </div>

      <div className="max-w-[1440px] mx-auto  sm:mt-[80px]">
        <div className="grid grid-cols-1 sm:grid-cols-3 ">
          {data?.steps?.map((item) => (
            <div className="grid items-start sm:mt-0 mt-[60px]">
              <div className="relative flex items-center justify-center">
                {isDesktop ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="88"
                    height="74"
                    viewBox="0 0 88 74"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M63.8531 8.26637C68.1221 8.48455 71.5152 10.6377 74.9085 13.0555C85 23 88.1257 32.3946 80.5 44.5C68.6419 63.2726 43.4176 74.6702 22 69C17.2567 67.7504 11.2068 67.6025 6.5 61.5C2.12161 55.8258 1.89895 51.245 3.90571 45C5.94896 38.597 10.3622 33.2881 13.5 29.5C27.219 12.9726 53.491 -3.94271 78.3384 3.97632C78.8492 4.12891 79.3601 3.86651 79.506 3.39028C79.652 2.91405 79.3964 2.40339 78.922 2.25081C53.3449 -5.89909 24.9948 9.44463 10.8745 26.4537C6.97045 31.1772 1.86261 39.0622 0.403142 47.2204C-0.581997 52.7964 0.111106 58.4962 3.90571 63.4546C8.90438 69.9551 15.7274 71.2283 20.7626 72.5592C42.8735 78.4156 71.4423 65.7702 83.6653 46.3818C91.8748 33.3706 87.9344 20.1174 75.9668 11.5833C72.3181 8.96006 68.5963 6.69308 63.9625 6.45677C63.4517 6.43104 63.014 6.81558 63.014 7.315C62.9775 7.81443 63.3788 8.24064 63.8531 8.26637Z"
                      fill="#426BFF"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="37"
                    viewBox="0 0 44 37"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M31.9266 4.13319C34.061 4.24228 35.7576 5.31887 37.4543 6.52774C42.5 11.5 44.0629 16.1973 40.25 22.25C34.3209 31.6363 21.7088 37.3351 11 34.5C8.62837 33.8752 5.60339 33.8013 3.25 30.75C1.0608 27.9129 0.949475 25.6225 1.95286 22.5C2.97448 19.2985 5.18108 16.644 6.75 14.75C13.6095 6.4863 26.7455 -1.97135 39.1692 1.98816C39.4246 2.06445 39.68 1.93325 39.753 1.69514C39.826 1.45702 39.6982 1.20169 39.461 1.1254C26.6725 -2.94955 12.4974 4.72231 5.43726 13.2268C3.48522 15.5886 0.931303 19.5311 0.201571 23.6102C-0.290998 26.3982 0.0555532 29.2481 1.95286 31.7273C4.45219 34.9776 7.86372 35.6142 10.3813 36.2796C21.4367 39.2078 35.7211 32.8851 41.8326 23.1909C45.9374 16.6853 43.9672 10.0587 37.9834 5.79165C36.159 4.48003 34.2982 3.34654 31.9813 3.22839C31.7259 3.21552 31.507 3.40779 31.507 3.6575C31.4888 3.90721 31.6894 4.12032 31.9266 4.13319Z"
                      fill="#141EFF"
                    />
                  </svg>
                )}
                <div className="absolute text-center">
                  <p className="text-[#426BFF] text-[25px] sm:text-[55px] font-[500] homepage-campaign-name-font">
                    {item?.id}
                  </p>
                </div>
              </div>

              <div className="text-center mt-[20px] items-start">
                <p className="text-[#373737] font-[500] leading-[28px] text-[18px] sm:text-[26px] xl:ml-[70px] ml-5 mr-5 lg:ml-10 lg:mr-10  xl:mr-[70px]">
                  {" "}
                  {item?.steps}{" "}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-[60px] sm:mt-[80px] justify-center flex items-center ">
          <p className="text-[16px] leading-[20px] text-[#161616] font-[400] xl:ml-72 ml-8 mr-8 sm:ml-32 sm:mr-32  xl:mr-72">
            {" "}
            {data?.caution}{" "}
          </p>
        </div>
      </div>


    </div>
  );
}

export default HowToParcitipate;
