import React from "react";

function BannerTemp2({ data }) {
  if (!data?.bannerImage) {
    return null;
  }

  const containerStyle = {
    background: `url(${data?.bannerImage}) center center / cover no-repeat `,
  };
  const svgRender = (innerWidth) => {
    if (innerWidth <= 640) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="640"
          height="40"
          viewBox="0 0 640 40"
          fill="none"
          className="absolute bottom-0 left-0 z-10 w-full h-auto"
        >
          <path d="M0 0L640 40H0V0Z" fill={data?.backGround} />
        </svg>
      );
    } else {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1734"
          height="100"
          viewBox="0 0 1734 100"
          fill="none"
          className="absolute bottom-[-1px] left-0 z-10 w-full h-auto"
        >
          <path d="M0 0L1734 100H0V0Z" fill={data?.backGround} />
        </svg>
      );
    }
  };
  const fontOneStyle = {
    fontFamily: data?.fontOne?.fontFamily,
    color: data?.mainColor,
  };
  
  return (
    <>
      {data?.fontOne && <style>@import url({data?.fontOne?.familyUrl});</style>}
      <div className=" w-[100%] h-auto ">
        <div
          className=" relative min-h-[80vh]  py-[100px]"
          style={containerStyle}
        >
          <div className="text-center flex flex-col justify-center items-center  font-[500] font-for-Results-page relative z-10 max-w-[1440px] mx-auto">
            <p className=" uppercase text-[18px] font-[500] ">
              {data?.titleOne}
            </p>
            <p
              className=" uppercase text-[72px] font-[400] template-results-heading-font "
              style={fontOneStyle}
            >
              {data?.Heading}
            </p>

            <p
              className=" uppercase text-[40px] font-[500] "
              style={{ fontFamily: data?.fontOne?.fontFamily }}
            >
              {data?.titletwo}
            </p>

            <p className="mt-6 leading-[25px] sm:leading-[32px] text-center xl:ml-[25%] sm:ml-[20%] ml-5 mr-5 sm:mr-[20%] xl:mr-[25%] text-[16px] sm:text-[21px] font-[500] ">
              {data?.para}
            </p>
          </div>

          {svgRender(window.innerWidth)}
          {data?.bannerLeftImage && (
            <img
              src={data?.bannerLeftImage}
              alt="Bottom Left Image"
              className="hidden md:block absolute bottom-0 left-0 w-[28%] "
            />
          )}
          {data?.bannerRightImage && (
            <img
              src={data?.bannerRightImage}
              alt="Bottom Right Image"
              className="hidden md:block absolute bottom-0 right-0 w-[28%]"
            />
          )}
        </div>
        <div className="w-full px-[20px] md:px-[8%]">
          <div className="flex flex-col md:flex-row w-full justify-around items-start md:items-center  uppercase template-results-heading-font">
            <div className="">
              <div className="flex gap-[5px] items-end">
                <p
                  className="xl:text-[100px] text-[52px] sm:text-[45px] lg:text-[80px] font-[400] leading-none"
                  style={fontOneStyle}
                >
                  {data?.parcitipation}{" "}
                </p>
                <p className=" text-[28px] sm:text-[25px] lg:text-[30px]  xl:text-[40px] font-[400]">
                  participation
                </p>
              </div>

              <p className=" text-[28px] sm:text-[25px] lg:text-[30px] xl:text-[40px] font-[400]">
                campaign Runtime - {data?.campaignRuntime}
              </p>
            </div>

            <div className=" sm:mt-0 mt-7">
              <div className="flex items-end gap-[5px]">
                <p
                  className="xl:text-[100px] text-[52px] sm:text-[45px] lg:text-[80px] font-[400] leading-none"
                  style={fontOneStyle}
                >
                  {data?.views}{" "}
                </p>
                <p className=" text-[28px] sm:text-[25px] lg:text-[30px]  xl:text-[40px] font-[400]">
                  Views
                </p>
              </div>
              <p className=" text-[28px] sm:text-[25px] lg:text-[30px] xl:text-[40px] font-[400]">
                Total EngageMent - {data?.totalEngagement}
              </p>
            </div>

            <div className=" sm:mt-0 mt-7">
              <div className="flex items-end gap-[5px]">
                <p
                  className="xl:text-[100px] text-[52px] sm:text-[45px] lg:text-[80px] font-[400] leading-none"
                  style={fontOneStyle}
                >
                  {data?.winners}{" "}
                </p>
                <p className=" text-[28px] sm:text-[25px] lg:text-[30px]  xl:text-[40px] font-[400]">
                  winners
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerTemp2;
