import React, { forwardRef } from "react";
import GalleryImages from "../../dancerTemplate/minimal/GalleryImages";
import MinimalModelLine from "./MinimalModelLine";

function MinimalModelWorks({ data, worksref,Name  }) {
  return (
    <div className="mt-16 max-w-[1440px] ml-auto mr-auto" ref={worksref}>
      <div className="modelMinimalFontFamily  sm:w-full  flex items-center  ">
        {" "}
        <p className="ml-4 sm:ml-16 text-start  text-[25px] sm:text-[45px] font-semibold">
          {" "}
        {Name}
        </p>
        <div className="justify-center mt-1  items-center  mx-auto w-[50%] lg:w-[65%]">
          <MinimalModelLine />
        </div>
      </div>

      <div className="grid items-start ml-4 mr-4 sm:ml-20 sm:mr-20 gap-3 sm:gap-6   mt-5 grid-cols-2 sm:grid-cols-3">
        <GalleryImages
          prevIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="26"
              viewBox="0 0 37 26"
              fill="none"
            >
              <path
                d="M36.2978 1.40135L24.8852 13.0477L36.3962 24.5936C36.7153 24.9132 36.7169 25.4343 36.401 25.7568C36.0852 26.0792 35.5701 26.0813 35.2518 25.7617L23.2807 13.7543C23.1311 13.6041 23.0519 13.4108 23.0413 13.2146C23.038 13.1575 23.0417 13.1003 23.0498 13.044C23.0466 13.0217 23.0413 13.0004 23.0409 12.9781C23.0291 12.7544 23.1067 12.5261 23.275 12.355L35.1437 0.243547C35.4595 -0.0793228 35.9742 -0.0813789 36.2933 0.238611C36.6124 0.558599 36.6141 1.0793 36.2978 1.40135Z"
                fill="black"
              />
              <path
                d="M24.7782 1.40135L13.3657 13.0477L24.8766 24.5936C25.1957 24.9132 25.1974 25.4343 24.8815 25.7568C24.5656 26.0792 24.0506 26.0813 23.7323 25.7617L11.7612 13.7543C11.6116 13.6041 11.5323 13.4108 11.5218 13.2146C11.5185 13.1575 11.5222 13.1003 11.5303 13.044C11.527 13.0217 11.5218 13.0004 11.5213 12.9781C11.5096 12.7544 11.5872 12.5261 11.7555 12.355L23.6241 0.243547C23.94 -0.0793228 24.4547 -0.0813789 24.7738 0.238611C25.0929 0.558599 25.0945 1.0793 24.7782 1.40135Z"
                fill="#343434"
              />
              <path
                d="M13.2587 1.40135L1.84618 13.0477L13.3571 24.5936C13.6762 24.9132 13.6778 25.4343 13.362 25.7568C13.0461 26.0792 12.5311 26.0813 12.2128 25.7617L0.241656 13.7543C0.0920582 13.6041 0.0127888 13.4108 0.00222015 13.2146C-0.00103283 13.1575 0.00262451 13.1003 0.0107546 13.044C0.00750256 13.0217 0.00222015 13.0004 0.00181389 12.9781C-0.00997543 12.7544 0.067668 12.5261 0.235966 12.355L12.1046 0.243547C12.4205 -0.0793228 12.9351 -0.0813789 13.2542 0.238611C13.5734 0.558599 13.575 1.0793 13.2587 1.40135Z"
                fill="#828282"
              />
            </svg>
          }
          nextIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="26"
              viewBox="0 0 37 26"
              fill="none"
            >
              <path
                d="M0.338938 1.40135L11.7515 13.0477L0.240562 24.5936C-0.0785528 24.9132 -0.0801793 25.4343 0.235684 25.7568C0.551547 26.0792 1.0666 26.0813 1.38491 25.7617L13.356 13.7543C13.5056 13.6041 13.5849 13.4108 13.5954 13.2146C13.5987 13.1575 13.595 13.1003 13.5869 13.044C13.5902 13.0217 13.5954 13.0004 13.5958 12.9781C13.6076 12.7544 13.53 12.5261 13.3617 12.355L1.49304 0.243547C1.17718 -0.0793228 0.662526 -0.0813789 0.34341 0.238611C0.0242951 0.558599 0.0226684 1.0793 0.338938 1.40135Z"
                fill="black"
              />
              <path
                d="M11.8585 1.40135L23.271 13.0477L11.7601 24.5936C11.441 24.9132 11.4394 25.4343 11.7552 25.7568C12.0711 26.0792 12.5861 26.0813 12.9044 25.7617L24.8755 13.7543C25.0251 13.6041 25.1044 13.4108 25.115 13.2146C25.1182 13.1575 25.1146 13.1003 25.1064 13.044C25.1097 13.0217 25.115 13.0004 25.1154 12.9781C25.1272 12.7544 25.0495 12.5261 24.8812 12.355L13.0126 0.243547C12.6967 -0.0793228 12.1821 -0.0813789 11.8629 0.238611C11.5438 0.558599 11.5422 1.0793 11.8585 1.40135Z"
                fill="#343434"
              />
              <path
                d="M23.378 1.40135L34.7905 13.0477L23.2796 24.5936C22.9605 24.9132 22.9589 25.4343 23.2747 25.7568C23.5906 26.0792 24.1057 26.0813 24.424 25.7617L36.3951 13.7543C36.5447 13.6041 36.6239 13.4108 36.6345 13.2146C36.6378 13.1575 36.6341 13.1003 36.626 13.044C36.6292 13.0217 36.6345 13.0004 36.6349 12.9781C36.6467 12.7544 36.5691 12.5261 36.4008 12.355L24.5321 0.243547C24.2162 -0.0793228 23.7016 -0.0813789 23.3825 0.238611C23.0634 0.558599 23.0617 1.0793 23.378 1.40135Z"
                fill="#828282"
              />
            </svg>
          }
          bgColor={"#FFF"}
          textColor={"#111"}
          data={data}
          name={Name}
          colNo={3}
          fontFamily={"modelMinimalFontFamily"}
        />
      </div>

      {/* <div className="ml-20 mr-20 gap-10 grid grid-cols-3">
        {data?.map((item)=>(
  <div className='justify-center items-center flex'>
  <img className='w-[100%]' src={item?.image} alt=''/>
  
  </div>
))}

        <GalleryImages data={data} />
      </div> */}
    </div>
  );
}

export default forwardRef(MinimalModelWorks);
