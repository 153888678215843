import React from "react";

export default function JudgeDetialSection({ data }) {
  return (
    <div className="template-esteemed-judge mt-10 grid grid-cols-2 mb-[35px]">
      <div className="template-esteemed-judge-image-container relative">
        <div className="md:ml-5 ml-0">
          <div className="template-esteemed-judge-image border-[#CB8B4D] border-[5px]  rounded-full ">
            <img src={data?.logo} className="object-cover rounded-full h-full w-full" />
          </div>
          <div className="text-center sm:text-[20px] lg:text-[30px] text-[12px] font-[700] template-judgesDetailSection-font text-[#1B3332] ">{data?.name}</div>
          <p className="lg:text-[23px] sm:text-[20px] text-[12px]">{data?.position}</p>
        </div>
      </div>

    

      

      <div className="template-esteemed-judge-details-container ">
        {data?.works?.map((obj, index) => {
          return (
            <div key={index} className="template-esteemed-judge-details">
              <div className="template-esteemed-judge-details-image">
                <img src={obj?.image} className="w-full h-full object-cover" />
              </div>
              <div className="mt-2 template-esteemed-judge-details-image-content ">
                <p className="text-[18px] lg:text-[30px] contents" >{obj?.name}</p>
                <ul className="lg:ml-5 ml-1 lg:text-[23px] text-[15px] contentsPoints">
                  {obj?.points?.map((el, index) => {
                    return <li key={index}>  {el}</li> 
                  })}
                </ul>
              </div>
            </div>
          );
        })}

        <div className="absolute">

        </div>
      </div>
    </div>
  );
}
