import React from "react";

function GymGallery({ data }) {
 
  return (
    <div className="w-[100%] fontFamilyForGym mt-[74px] sm:mt-[120px]">
      <div className="max-w-[1440px] mx-auto">
        <div className="text-[32px] font-[400] text-[#D7FC51]">
          <p className="ml-4 sm:ml-24"> {data?.heading} </p>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-[32px] items-start gap-[16px]  sm:gap-[18px] xl:gap-[16px] ml-4 mr-4 sm:ml-24 sm:mr-24">
          {data?.datas?.map((item) => (
            <div className="xl:w-[300px] lg:h-[250px]   w-[167px] h-[167px] sm:w-[280px] sm:h-[280px]  lg:w-[250px] mx-auto xl:h-[300px]  ">
              <img
                className="w-full h-full object-cover"
                src={item?.image}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GymGallery;
