import React, { useContext, useEffect, useState } from "react";
import GymHeader from "./GymHeader";
import GymAchievements from "./GymAchievements";
import GymAbout from "./GymAbout";
import GymTraining from "./GymTraining";
import GymAssociated from "./GymAssociated";
import GymCollaboration from "./GymCollaboration";
import GymGallery from "./GymGallery";
import GymSocialMedia from "./GymSocialMedia";
import GymgetInTouch from "./GymgetInTouch";
import Footer from "../../../Components/footer/Footer";
import { imageData } from "../../../context/dataContext";
import useMediaQuery from "../../../hooks/UseMediaQuery";

function GymPortfolio({ influencerDetails }) {
const {setImagesData,imagesData} = useContext(imageData)
const isDesktop = useMediaQuery("(min-width:640px)")
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (influencerDetails)
      return setImagesData({
        workName: influencerDetails?.workName,
        datas: influencerDetails?.works,
      });
  }, [influencerDetails]);

  return (
    <div style={{ background: "#000" }} className="w-[100%] fontFamilyForGym">
      <GymHeader data={influencerDetails} />

      <GymAchievements data={influencerDetails?.achievements} />

      <GymAbout data={influencerDetails?.aboutDetails} />

      <GymTraining data={influencerDetails?.trainingsPrograms} />

      <GymAssociated data={influencerDetails?.associatedBrands} />

      <GymCollaboration data={influencerDetails?.collaborationWorks} />

      <GymGallery data={influencerDetails?.photoGallery}/>

      <GymSocialMedia data={influencerDetails?.socialMedia} />

      <GymgetInTouch data={influencerDetails?.getInTouch}/>


  {isDesktop && <Footer/>}

    </div>
  );
}

export default GymPortfolio;
