import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { imageData } from "../context/dataContext";
import useMediaQuery from "../hooks/UseMediaQuery";

function GalleryViewPage() {
  const { index } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { imagesData, state } = useContext(imageData);
  const [currentIndex, setCurrentIndex] = useState(parseInt(index, 10));
  const isDesktop = useMediaQuery("(min-width:640px)");
  

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  const nextPhoto = () => {
    const newIndex = currentIndex + 1;
    if (newIndex < imagesData?.datas?.length) {
      setCurrentIndex(newIndex);
    }
  };

  const prevPhoto = () => {
    const newIndex = currentIndex - 1;
    if (newIndex >= 0) {
      setCurrentIndex(newIndex);
    }
  };

  useEffect(() => {
    if (imagesData?.datas[currentIndex] === undefined) {
      handleNavigateToPrevLocation();
    }
  }, [imagesData?.datas[currentIndex] === undefined]);

  return (
    <div
      style={{ background: state?.bgColor }}
      className={`h-screen overflow-y-auto  ${state?.fontFamily}  mx-auto  w-[100%]`}
    >
      <div
        style={{ background: state?.bgColor ?  state?.bgColor : "#fff" }}
        className=" z-20 py-1 sticky top-0 justify-between w-[100%]  px-4 sm:px-10  items-center flex"
      >
        <div className="sm:w-[25] w-[20]"></div>
        <div>
          <p
            style={{ color: state?.textColor ? state?.textColor : "#111" }}
            className=" text-[22px] py-4 sm:text-[32px]"
          >
            {" "}
            {imagesData?.workName}
          </p>
        </div>
        <div className="  text-end" onClick={handleNavigateToPrevLocation}>
          <svg
            className=""
            xmlns="http://www.w3.org/2000/svg"
            width={isDesktop ? "25" : "20"}
            height="25"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M1 16L16 1"
              stroke={state?.textColor ? state?.textColor : "#111"}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 1L16 16"
              stroke={state?.textColor ? state?.textColor : "#111"}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <div className="relative w-[100%] h-full ">
        {/* <div
      className={`  justify-center relative backdrop:blur-[50px] mt-5 h-auto items-center flex`}
      style={{
        backgroundImage: `url(${images[selectedPhoto]}) `,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    > */}
        <div className={`relative justify-center items-center flex`}>
          <div
            //  className="lg:mx-auto mx-4  absolute  sm:mx-10  mb-10"
            className=" justify-center items-start flex"
          >
            <img
              className="sm:mt-6  max-h-[75vh] w-[100%] object-contain  sm:max-h-[82vh]  mt-3   "
              // src={images[selectedPhoto]}
              src={
                index &&
                imagesData?.datas &&
                imagesData?.datas[currentIndex]?.image
              }
              alt=""
            />
          </div>

          {currentIndex > 0 && (
            <div
              className={`cursor-pointer text-[32px] text-[#fff] sm:left-10 left-4 ${
                !isDesktop && "-bottom-10 "
              } absolute`}
              onClick={prevPhoto}
            >
              {state?.prevIcon}
            </div>
          )}

          {currentIndex < imagesData?.datas.length - 1 && (
            <div
              className={`cursor-pointer absolute ${
                !isDesktop && "-bottom-10"
              } right-4 sm:right-10 text-[32px] text-[#fff]`}
              onClick={nextPhoto}
            >
              {state?.nextIcon}
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default GalleryViewPage;
