import { useEffect } from "react";
import useMediaQuery from "../../../../hooks/UseMediaQuery";

function SelectedImage({
    images,
    selectedPhoto,
    prevPhoto,
    originalArray,
    fontFamily,
    nextPhoto,
    onClose,
    name,
    textColor,
    bgColor,
    prevIcon,
    nextIcon,
  }) {
    const isDesktop = useMediaQuery("(min-width:640px)");
    useEffect(() => {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    }, []);
  
    return (
      <div
        style={{ transform: "translate(-50%, -50%)", background: bgColor }}
        className={`h-full overflow-scroll  ${fontFamily}  w-[100%] max-w-[1440px] z-50  fixed top-[50%] bottom-0 left-[50%] shadow-xl`}
      >
        <div
          style={{ background: bgColor }}
          className=" z-20 py-1 sticky top-0 justify-between w-[100%]  px-4 sm:px-10 mt-10  items-center flex"
        >
          <div className="sm:w-[25] w-[20]"></div>
          <div>
            <p
              style={{ color: textColor }}
              className=" text-[22px] sm:text-[32px]"
            >
              {" "}
              {name}
            </p>
          </div>
          <div className="  text-end" onClick={onClose}>
            <svg
              className=""
              xmlns="http://www.w3.org/2000/svg"
              width={isDesktop ? "25" : "20"}
              height="25"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M1 16L16 1"
                stroke={textColor}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1L16 16"
                stroke={textColor}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
  
        <div className="relative w-[100%] h-full ">
          {/* <div
          className={`  justify-center relative backdrop:blur-[50px] mt-5 h-auto items-center flex`}
          style={{
            backgroundImage: `url(${images[selectedPhoto]}) `,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        > */}
          <div className={`relative justify-center items-center flex`}>
            <div
            //  className="lg:mx-auto mx-4  absolute  sm:mx-10  mb-10"
            className=" justify-center items-start flex"
            
            >
              <img
                className="sm:mt-6  max-h-[75vh] w-[100%] object-contain  sm:max-h-[82vh]  mt-3   "
                src={images[selectedPhoto]}
                alt=""
              />
            </div>
  
            {selectedPhoto > 0 && (
              <div
                className={`cursor-pointer sm:left-10 left-4 ${
                  !isDesktop && "-bottom-10"
                } absolute`}
                onClick={prevPhoto}
              >
                {prevIcon}
              </div>
            )}
  
            {selectedPhoto < originalArray.length - 1 && (
              <div
                className={`cursor-pointer absolute ${
                  !isDesktop && "-bottom-10"
                } right-4 sm:right-10`}
                onClick={nextPhoto}
              >
                {nextIcon}
              </div>
            )}
          </div>
  
        </div>
        {/* </div> */}
      </div>
    );
  }

  export default SelectedImage