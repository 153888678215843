import React from "react";

function Landing({ campaignData }) {
  
  return (
    <div className="relative  sm:pb-[10rem]">
      <div className="absolute top-[5%] md:top-0 right-0 ">
        <img
          className="  md:w-[60%] sm:w-[40%] w-[25%]   ml-auto h-[auto]"
          src={campaignData?.background2}
          alt=""
        />
      </div>

      <div className="absolute top-0 left-0 ">
        <img className="md:w-[60%] sm:w-[40%] w-[25%]  mr-auto h-[auto]" src={campaignData?.maveli} alt="" />
      </div>
      <div className="absolute sm:top-[40%] top-[30%]   right-0 ">
        <img className=" h-[auto] md:w-[60%] sm:w-[40%] w-[25%] ml-auto" src={campaignData?.boat} alt="" />
      </div>

      <div className="justify-center  sm:mt-0 items-center flex z-20">
        
        <div className="sm:leading-[50px] leading-[30px] md:leading-[95px] sm:mt-24 mt-6 justify-center items-center flex  z-10 text-center ">
         <div className="">
         <p
            style={{ color: campaignData?.textColor1 }}
            className="template-landingOnam2023-heading2  sm:-ml-[0%]   md:mr-0 sm:mr-28 mr-0 uppercase text-[43px] sm:text-[60px] md:text-[100px] lg:text-[130px]"
          >
            {campaignData?.heading1}
          </p>
          <p
            style={{ color: campaignData?.textColor2 }}
            className="template-landingOnam2023-heading2  sm:-ml-[0%]   md:mr-0 sm:mr-28 mr-0  text-[28px] sm:text-[45px] md:text-[65px] lg:text-[85px] "
          >
            {campaignData?.heading2}
          </p>
          <p
            style={{ color: campaignData?.textColorSubHeading }}
            className="lg:text-[50px] md:text-[30px] text-[15px] sm:-ml-[0%]  leading-[35px] sm:leading-[50px]  sm:text-[30px] md:mr-0 mr-0 sm:mr-28 template-landingOnam2023-subheading"
          >
            {campaignData?.subheading}{" "}
          </p>
          </div>

        </div>
        
      </div>

<div className="sm:hidden block justify-center items-center text-center">
   <p style={{color:campaignData?.textColor2 }} className="template-online-contests-font text-[15px] uppercase font-[400]">Online contests</p>

</div>

     
    </div>
  );
}

export default Landing;
