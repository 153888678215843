import React from "react";
import Feature from "./featured/Feature";

function Features({ data }) {
  return (
    <div className="w-full" style={{ backgroundColor: data?.bgColor }}>
      <div className="mt-20 pt-10 font-for-Results-page pb-20 max-w-[1440px] mx-auto">
        <div
          style={{ color: data?.headingColor }}
          className="justify-center items-center text-center"
        >
          <p className="text-[24px] ml-7 mr-7 sm:text-[32px] font-[700] xl:ml-[25%] sm:ml-[5%] sm:mr-[5%] xl:mr-[25%]">
            {" "}
            {data?.heading}{" "}
          </p>
        </div>

        <Feature
          data={data?.Datas}
          secId={data?.secId}
          featureColor={data?.featuredColor}
          headingColor={data?.headingColor}
        />
      </div>
    </div>
  );
}

export default Features;
