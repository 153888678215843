import React, { useState } from "react";
import useMediaQuery from "../../../hooks/UseMediaQuery";

function CelebrityAbout({ data }) {
  const [showFull , setShowFull] = useState(false)
  const isDesktop  = useMediaQuery("(min-width:640px)")

  return (
    <div>
      <div className="bg-transparent border-[1px] rounded-[40px] text-[#fff] ml-4 sm:ml-16  mr-4 sm:mr-9 mt-10 pt-5 sm:pt-10 pb-5 sm:pb-16">
        {/* <p className="sm:ml-20 font-[400] text-[18px] sm:text-[45px] sm:text-start text-center">
          {" "}
          {data?.name}{" "}
        </p> */}

        <div className="grid sm:flex mt-5">
          <div className="sm:justify-start justify-center items-center  flex">
            <div className="lg:w-[300px] w-[200px] sm:w-[200px] h-[200px] lg:h-[300px] sm:ml-10">
              <img
                src={data?.image}
                className="rounded-[50%] object-cover w-full h-full"
                alt=""
              />
            </div>
          </div>
         

          <div className="celebrityFontFamilyLight leading-[20px] sm:leading-[24px] justify-start items-start sm:mt-0 mt-3 grid">
          <p className="sm:ml-14 mr-4 ml-4 sm:mr-10 font-[400] text-[18px] sm:text-[45px] sm:text-start text-center">
          {" "}
          {data?.name}{" "}
        </p>
            <div className="sm:ml-14 mr-4 ml-4 sm:mr-10 sm:text-[16px] text-[14px] font-[300]">
              <p> { isDesktop ? data?.desc : showFull ? data?.desc : data?.desc?.slice(0,280)} {!showFull && !isDesktop && "..."} <span onClick={()=>setShowFull(!showFull)} className="whitespace-nowrap sm:hidden text-[14px] items-start font-[700]"> {showFull ? "Show less" : "Read more >"} </span>  </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CelebrityAbout;
