import React, { useContext, useEffect, useRef, useState } from "react";
import Odd from "./CelebrityAchievementComponent/Odd";
import Even from "./CelebrityAchievementComponent/Even";
import useMediaQuery from "../../../hooks/UseMediaQuery";
import Back from "../../../Components/back/Back";
import AchievementsPopUp from "../../../Components/achievementsPopUp/AchievementsPopUp";
import { act } from "@testing-library/react";
import { useLocation, useNavigate } from "react-router-dom";
import { imageData } from "../../../context/dataContext";

// const PopAchievement = ({ data, selectedAchievement, onClose }) => {
//   return (
//     <div
//       style={{ transform: "translate(-50%, -50%)" }}
//       className="h-[100%]  w-[100%] max-w-[1440px] z-30  opacity-[0.9px] fixed top-[50%] bg-[white] bottom-0 left-[50%] shadow-xl"
//     >
//       <div className="justify-center items-center  flex">
//         <div className="flex w-[100%] mx-5 rounded-full p-2 mt-5 items-center border-2  border-[#D9D9D9]">
//           <div className=" " onClick={onClose}>
//             <Back />
//           </div>
//           <div className="text-center">
//             <p className="ml-5 text-[black] text-[17px]">
//               {" "}
//               {data[selectedAchievement]?.event}{" "}
//             </p>
//           </div>
//         </div>
//       </div>

//       <div className="grid  mt-10 text-[black]">
//         <div className="justify-center items-center flex">
//           <div className="w-[180px] h-[202px] ">
//             <img
//               className="w-full h-full object-cover"
//               src={data[selectedAchievement]?.image}
//               alt=""
//             />
//           </div>
//         </div>
//         <div className="text-center mt-2 ">
//           <p className="text-[14px] font-[500]">
//             {" "}
//             {data[selectedAchievement]?.event}{" "}
//           </p>

//           <p className="text-[12px] font-[400]">
//             {" "}
//             {data[selectedAchievement]?.date}{" "}
//           </p>
//           <p className="text-[16px] font-[300] mt-4 mx-4">
//             {" "}
//             {data[selectedAchievement]?.desc}{" "}
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

const CarouselDot = ({ active, onClick, id }) => {
  const dotClass = `${active ? "#FFF" : "#757575"}`;

  return (
    <div onClick={onClick} id={id}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        viewBox="0 0 18 18"
        fill="none"
      >
        <circle cx="9" cy="9" r="9" fill={dotClass} />
      </svg>
    </div>
  );
};

function CelebrityAchievements({ data }) {
  const isDesktop = useMediaQuery("(min-width:640px)");
  const [open, setOpen] = useState(false);
  const [selectedAchievement, setSelectedAchievement] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const cardRefs = useRef([]);
  const { achievementsStyles, setAchievementsStyles } = useContext(imageData);
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelect = (index) => {
    // setOpen(true);
    // setSelectedAchievement(index);
    navigate(`achievementsview/${index}`, {
      state: { previousLocation: location?.pathname },
    });
  };

  useEffect(() => {
    if (data) {
      setAchievementsStyles({
        mainBgColor: "#111",
        subBgColor: "#2C2C2C80",
        textColor: "#fff",
        fontFamily: "celebrityFontFamily",
        fontFamilyText: "celebrityFontFamilyLight",
      });
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
    setSelectedAchievement(null);
  };

  const prevAch = () => {
    if (selectedAchievement > 0) {
      setSelectedAchievement(selectedAchievement - 1);
    }
  };

  const nextAch = () => {
    if (selectedAchievement < data?.datas?.length - 1) {
      setSelectedAchievement(selectedAchievement + 1);
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;
    const handleScroll = () => {
      const sliderWidth = slider.offsetWidth;
      const sliderLeft = slider.getBoundingClientRect().left;
      const activeSlide = Array.from(slider.children).find((slide) => {
        const slideLeft = slide.getBoundingClientRect().left;
        return slideLeft >= sliderLeft;
      });
      console.log(activeSlide, "sloede");
      console.log(slider, "sl");
      setActiveIndex(
        activeSlide ? Array.from(slider.children).indexOf(activeSlide) : 0
      );
      console.log(Array.from(slider.children).indexOf(activeSlide), "array");
    };
    slider?.addEventListener("scroll", handleScroll);
    return () => slider?.removeEventListener("scroll", handleScroll);
  }, []);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };

  const dots = (datas) =>
    datas.map((_, index) => (
      <CarouselDot
        key={index}
        active={index === activeIndex}
        id={`dot-${index}`}
        onClick={() => handleSlideChange(index)}
      />
    ));

  return (
    <>
      {data && (
        <div className="mt-10 sm:mt-16 ">
          <div className="flex text-[#EEE] gap-10 items-center">
            <div className="ml-4 sm:ml-10">
              <p className="font-[400] lg:text-[30px] text-[18px] sm:text-[20px] xl:text-[45px] ">
                {" "}
                {data?.name}{" "}
              </p>
            </div>

            <div className="sm:flex hidden justify-center items-center  mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="4"
                viewBox="0 0 908 4"
                fill="none"
              >
                <path
                  d="M2 2H906.035"
                  stroke="#525252"
                  stroke-opacity="0.9"
                  stroke-width="2.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>

            <div className="sm:hidden flex justify-center items-center  mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="2"
                viewBox="0 0 235 2"
                fill="none"
              >
                <path
                  d="M1 1L234 1"
                  stroke="#464646"
                  stroke-opacity="0.9"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>

          {data?.datas?.map((item, index) => (
            <>
              <div className="text-[#EEE] sm:flex hidden" key={index}>
                {index % 2 !== 0 ? <Even data={item} /> : <Odd data={item} />}
              </div>
            </>
          ))}

          <div
            ref={sliderRef}
            className={`${
              !isDesktop && "celebrity-template-achievements-scroll"
            }  ${
              data?.datas?.length === 1 && "justify-center items-center flex"
            } mt-4 text-[#EEE]`}
          >
            {data?.datas?.map((item, index) => (
              <>
                <div className="sm:hidden flex">
                  {/* {open ? (
                    <AchievementsPopUp
                      selectedOne={selectedAchievement}
                      datas={data?.datas}
                      name={data?.name}
                      prev={prevAch}
                      next={nextAch}
                      onClose={handleClose}
                      mainBgColor={"#111"}
                      subBgColor={"#2C2C2C80"}
                      textColor={"#fff"}
                      fontFamily={"celebrityFontFamily"}
                      fontFamilyText={"celebrityFontFamilyLight"}
                    />
                  ) : ( */}
                    <div
                      ref={(element) => {
                        cardRefs.current[index] = element;
                      }}
                      className="ml-4 justify-center items-center mx-auto"
                      onClick={() => handleSelect(index)}
                    >
                      <div className="w-[181px]  h-[202px]">
                        <img
                          className="w-full  h-full object-cover"
                          src={item?.image}
                          alt=""
                        />
                      </div>
                      <div className="text-center mt-[12px] font-[400]">
                        <p className="text-[13px] "> {item?.event} </p>
                        <p className="text-[12px] "> {item?.date} </p>
                      </div>
                    </div>
                  {/* )} */}
                </div>
              </>
            ))}
          </div>

          {data?.datas?.length > 1 && (
            <div className="sm:hidden mt-2 w-[100%] flex items-center justify-center gap-[6px] relative z-1 h-fit">
              {dots(data?.datas)}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CelebrityAchievements;
