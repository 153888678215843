import React from "react";
import { Link, useLocation } from "react-router-dom";

function Actor({
  profilePic,
  name,
  title,
  profileId,
  position,
  titleColor,
  positionColor,
}) {
  const location = useLocation();
 
  return (
    <div>

    <Link
    className="hover:text-black hover:no-underline"
    to={profileId}
      state= {{ previousLocation: location?.pathname }}>
      <div className="justify-center items-center  flex">
        <img
          className="object-cover lg:w-[290px] lg:h-[290px] w-[100%] h-[350px] sm:w-[150px] sm:h-[150px]"
          src={profilePic}
          alt=""
        />
      </div>

      <div className="justify-center ml-10 mr-10 items-center text-center mt-5">
        <p
          style={{ color: positionColor }}
          className="fonts-for-Sathosi-homepage font-[400] uppercase text-[16px] sm:text-[11px] lg:text-[16px]"
        >
          {position}
        </p>
        <p
          className=" lg:text-[30px] text-[32px] sm:text-[20px] font-[600] homepage-campaign-name-font"
          style={{ fontStyle: "italic" }}
        >
          {name}
        </p>
        <p
          className="fonts-for-Sathosi-homepage lg:text-[16px] sm:text-[11px] text-[16px]  font-[400] "
          style={{ color: titleColor }}
        >
          {title}
        </p>
      </div>
      <div className="justify-center items-center  flex mt-3">
        <Link
        
          to={profileId}
            state= {{ previousLocation: location?.pathname }}
         
          className="hover:no-underline hover:text-black lg:text-[16px] text-[16px] sm:text-[11px]"
        >
     { profileId &&    'View Portfolio'}
        </Link>
      </div>

    </Link>
    </div>
  );
}

export default Actor;
