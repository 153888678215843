import React from 'react'
import { Link } from 'react-router-dom'
import VideoCardComponent from './VideoCardComponent'

function JudgesVideos({data}) {
   
  return (
    <div className='mt-[57px] fonts-for-Sathosi-homepage'>
        <div className='sm:ml-20 ml-4 mr-4 sm:mr-20 justify-between flex items-center'>
          <div>
        <p className='xl:text-[32px] mr-10 text-[21px] sm:text-[20px] lg:text-[26px] font-[700]  leading-[28px] sm:leading-[40px]'> 
         {data?.heading} </p>
         </div>
         <div className='lg:mt-2 mt-1'>
          <Link to={"/judgesSayings"}  className='text-[#161616] leading-[22px] whitespace-nowrap hover:underline hover:text-[#161616] uppercase text-[16px] font-[400]'> view all </Link>
         </div>
        </div>


<VideoCardComponent data={data?.datas}/>

    </div>
  )
}

export default JudgesVideos