import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../Components/footer/Footer";
import FeatureComponent from "../Components/FeaturePageComponent/FeatureComponent";
import { useParams } from "react-router";
import Back from "../Components/back/Back";
import axios from "axios";
import SelectedFeature from "../Components/FeaturePageComponent/SelectedFeature";
import LoadingCircle from "../Components/LoadingCircle/LoadingCircle";
import useMediaQuery from "../hooks/UseMediaQuery";


function FeaturingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { secId ,folderName} = useParams();
  const selectedId = new URLSearchParams(window.location.search).get(
    "selectedId"
  );

  const [data, setData] = useState([]);
  const [selectedFeature,setSelectedFeature] = useState()
  const [scrolling,setScrolling] = useState(false)
const [filteredData,setFilteredData] = useState()
const isDesktop = useMediaQuery("(min-width:640px)")
  const featuredData = async () => {
    try {
      let config = {
        method: "get",
        // url: `/featured/${folderName}/${secId}.json`,
           url:` https://storage.googleapis.com/goviral_magazine_data/featured/${folderName}/${secId}.json`
      };

      const response = await axios(config);
      setData(response?.data?.data);
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    featuredData();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  
  };

  useEffect(()=>{
    scrollToTop()
  },[])

  useEffect(()=>{
const findSelectedFeature = data?.FeaturedList?.find((item)=>item?.id == selectedId) 
setSelectedFeature(findSelectedFeature)

  },[selectedId , data?.FeaturedList])

useEffect(()=>{
  const filterData = data?.FeaturedList?.filter((item)=>item?.id !== selectedFeature?.id)
  setFilteredData(filterData)
},[data?.FeaturedList,selectedFeature])



  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };


  const handleScroll = ()=>{
    if(window.scrollY >= 20){
      setScrolling(true)
    }else{
      setScrolling(false)
    }
  }
  window.addEventListener("scroll",handleScroll)



  return (
    <>
      <div className=" fonts-for-Sathosi-homepage min-h-[100vh]  w-[100%] mx-auto">
        {/* <div className="sticky top-0 w-[100%] bg-[white] py-[2px]  max-w-[1440px] mx-auto">
          <div
            onClick={handleNavigateToPrevLocation}
            className=" bg-[white] fonts-for-Sathosi-homepage lg:w-[50%] xl:w-[30%] items-center   flex ml-4 mr-4 xl:ml-16 sm:ml-10 sm:mr-10  xl:mr-16 mt-16"
          >
            <div>
              <Back />
            </div>

            <div className=" font-[700] text-[16px] sm:text-[21px] ml-5 uppercase">
              <p>Featured </p>
            </div>
          </div>
        </div> */}

        <div
          onClick={handleNavigateToPrevLocation}
          className={`sticky top-0 ${scrolling && "py-4" } bg-[white] fonts-for-Sathosi-homepage  items-center   flex   mt-16`}
        >
          <div className="ml-4  xl:ml-16 sm:ml-10">
            <Back />
          </div>

          <p className=" font-[700] text-[16px] sm:text-[21px] ml-5 uppercase">
            {" "}
         Featured
          </p>
        </div>
{/* 
        <div className="max-w-[1440px] mx-auto fonts-for-Sathosi-homepage">
          <div className="w-[100%] sm:w-[50%] mt-10  sm:ml-20 ">
            <div className="sm:ml-0 ml-4">
              <p className=" text-[30px] mr-5 sm:text-[28px] lg:text-[32px] lg:mr-[15rem] font-[700] leading-[40px]">
                {" "}
                {data?.heading}{" "}
              </p>
              <p className="font-[500] text-[16px] mr-5 leading-[22px] lg:mr-[15rem] mt-5">
                {" "}
                {data?.para}{" "}
              </p>
            </div>
          </div>
        </div> */}

{data?.FeaturedList ? <>
  {selectedId && selectedFeature && <SelectedFeature  data={selectedFeature}/>}

<FeatureComponent  colorTheme={data?.colorTheme} folderName={data?.folderName} data={filteredData ? filteredData : data?.FeaturedList} secId={secId} />

</> :      <div className="w-[10%] mx-auto mt-20">
          <LoadingCircle/>
          </div>

}
       
       
      </div>
  {data?.FeaturedList &&  isDesktop &&  <Footer/> }
    </>
  );
}

export default FeaturingPage;
