import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/header/Header";
import axios from "axios";
import Carousel from "../Components/Poster/Carousel";
import CampaignListing from "../Components/campaignListingComponent/CampaignListing";
import Footer from "../Components/footer/Footer";
import Back from "../Components/back/Back";
import { useLocation, useNavigate } from "react-router-dom";
import { campaignChips, filterChips } from "../common/Constants";
import useMediaQuery from "../hooks/UseMediaQuery";
import { isEmpty } from "lodash";

function CampaignsListingPage() {
  const [campaignData, setCampaignData] = useState();
  const [scrolling, setScrolling] = useState(false);
  const [selectedChip, setSelectedChip] = useState();
  const isDesktop = useMediaQuery("(min-width:640px)");
  const sectionRefs = useRef({});
  const location = useLocation();
  const navigate = useNavigate();

  //   try {
  //     let config = {
  //       method: "get",
  //       url: `/home.json`,
  //     };
  //     let result = await axios(config);
  //     setMagazineData(result?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getCampaignData = async () => {
    try {
      let config = {
        method: "get",
        url: `https://storage.googleapis.com/goviral_magazine_data/campaigns/data.json`,
      };
      let result = await axios(config);
      setCampaignData(result?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCampaignData();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  const handleScroll = () => {
    if (window.scrollY >= 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  window.addEventListener("scroll", handleScroll);

  const scrollToSection = (section) => {
    // if (sectionRefs.current[section]) {
    //   sectionRefs.current[section].scrollIntoView({ behavior: "smooth" , inline:"nearest",block:"start",offsetTop:500});
    // }

    window.scrollTo({
      top: sectionRefs.current[section].offsetTop - 150,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {/* <div className='justify-start  mt-20 sm:mt-24 p-5  bg-[white] items-start text-start'>
<p className='fonts-for-Sathosi-campaigns uppercase text-[18px] sm:text-[24px] font-[700]'> Trending Campaigns </p>
</div> */}
      <div className="w-[100%] min-h-[100vh] mx-auto fonts-for-Sathosi-homepage">
        <div
          onClick={handleNavigateToPrevLocation}
          className={`mt-16 sticky top-0 w-[100%] ${
            scrolling && "py-4"
          }    bg-[white] fonts-for-Sathosi-homepage  items-center   flex `}
        >
          <div className="ml-4   sm:ml-8">
            <Back />
          </div>

          <p className=" font-[700] text-[16px] sm:text-[21px] ml-5 uppercase">
            {" "}
            Campaigns
          </p>
        </div>

        <div className="w-[100%] sticky top-14 bg-[white]  py-5  flex items-center  mt-16">
          <div className="ml-4  sm:ml-8 ">
            <button
              className="border-[1px] border-[#575757] py-[10px] px-[20px] uppercase sm:text-[16px] text-[12px] whitespace-nowrap"
              style={{ borderRadius: "0px 32px 32px 0px" }}
            >
              {" "}
              Campaigns
            </button>
          </div>
          <ul className="ml-4 sm:ml-16 w-fit pr-[30px]   font-headerLinks  grid-flow-col grid gap-[20px] overflow-x-auto [&::-webkit-scrollbar]:hidden">
            {campaignData?.campaigns?.map((item, index) => {
              let filterData = item?.sectionData;
              if (!isEmpty(filterData?.datas)) {
                return (
                  <li>
                    <button
                      className={`whitespace-nowrap uppercase ${
                        selectedChip === filterData?.secId &&
                        "bg-black text-[#F6F6F6]"
                      }  hover:bg-black hover:text-[#F6F6F6] text-[12px] sm:text-[16px] border-[1px]  py-2 px-5 rounded-[50px] `}
                      onClick={() => {
                        scrollToSection(filterData?.secId);
                        setSelectedChip(filterData?.secId);
                      }}
                    >
                      {" "}
                      {filterData?.name}{" "}
                    </button>
                  </li>
                );
              }
            })}
          </ul>
        </div>

        {campaignData?.campaigns?.map((item) => {
          let filterData = item?.sectionData;
          if (!isEmpty(filterData?.datas)) {
            return (
              <div
                ref={(ref) => {
                  sectionRefs.current[item?.sectionData?.secId] = ref;
                }}
              >
                <div className="mt-1 ">
                  <CampaignListing
                    data={item?.sectionData}
                    theme={campaignData?.themes}
                  />
                </div>
              </div>
            );
          }
        })}
      </div>

      {isDesktop && <Footer />}
    </div>
  );
}

export default CampaignsListingPage;
