import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { imageData } from "../context/dataContext";
import useMediaQuery from "../hooks/UseMediaQuery";

function AchievementsView() {
  const { index } = useParams();
  const navigate = useNavigate()
  const location = useLocation()
  const [currentIndex, setCurrentIndex] = useState(parseInt(index, 10));
  const isDesktop = useMediaQuery("(min-width:640px)")
   const { achievementsData, achievementsStyles } = useContext(imageData);
  const next = () => {
    const newIndex = currentIndex + 1;
    if (newIndex < achievementsData?.datas?.datas?.length) {
      setCurrentIndex(newIndex);
    }
  };

  const prev = () => {
    const newIndex = currentIndex - 1;
    if (newIndex >= 0) {
      setCurrentIndex(newIndex);
    }
  };

  useEffect(() => {
    if (achievementsData?.datas?.datas[currentIndex] === undefined) {
      handleNavigateToPrevLocation();
    }
  }, [achievementsData?.datas?.datas[currentIndex] === undefined]);

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };


  return (
    <div
      style={{
        background: achievementsStyles?.mainBgColor,
        color: achievementsStyles?.textColor,
      }}
      className={` h-screen overflow-y-auto  ${achievementsStyles?.fontFamily}  mx-auto  w-[100%]`}
    >
      <div
        style={{ background: achievementsStyles?.mainBgColor }}
        className="z-20 py-1 justify-between w-[100%] sticky top-0  text-center mt-10  items-center flex"
      >
        <div></div>
        <div className="text-center ml-10 sm:ml-20 ">
          <p className="font-[400] text-[22px] sm:text-[32px]">
            {" "}
            {achievementsData?.datas?.name}{" "}
          </p>
        </div>
        <div
          className="mr-4 sm:mr-[7%]  text-end"
            onClick={handleNavigateToPrevLocation}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={isDesktop ? "25" : "20"}
            height="25"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M1 16L16 1"
              stroke={achievementsStyles?.textColor}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 1L16 16"
              stroke={achievementsStyles?.textColor}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <div
        className="w-[90%] mt-10  pt-10 pb-10 sm:w-[80%] mx-auto sm:p-10"
        style={{
          background: achievementsStyles?.subBgColor,
          color: achievementsStyles?.textColor,
        }}
      >
        <div className="grid relative ">
          <div className="justify-center  items-center flex">
            <div className="sm:w-[250px] w-[155px] h-[192px] sm:h-[250px]">
              <img
                className="object-cover  h-full w-full"
                src={achievementsData?.datas?.datas[currentIndex]?.image}
                alt=""
              />
            </div>
          </div>
          <div className={`text-center  mt-2 text-[20px] `}>
            <p> {achievementsData?.datas?.datas[currentIndex]?.event} </p>
          </div>
          <div className={` text-[white]   text-center  mt-3`}>
            <p className="ml-4  mr-4 sm:ml-10 sm:mr-10 xl:ml-32 xl:mr-32 text-[14px]">
              {" "}
              {achievementsData?.datas?.datas[currentIndex]?.desc}
            </p>
          </div>

          {currentIndex > 0 && (
          <div onClick={prev} className="sm:hidden absolute -left-2 top-48">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <g filter="url(#filter0_b_4984_1160)">
                <circle
                  cx="15"
                  cy="15"
                  r="15"
                  fill="#676767"
                  fill-opacity="0.7"
                />
              </g>
              <path
                d="M17.25 9L11.25 15L17.25 21"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <filter
                  id="filter0_b_4984_1160"
                  x="-24"
                  y="-24"
                  width="78"
                  height="78"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_4984_1160"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_4984_1160"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        )}

          {currentIndex < achievementsData?.datas?.datas?.length - 1 && (
            <div onClick={next} className="sm:hidden absolute -right-2 top-48">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <g filter="url(#filter0_b_4984_1163)">
                  <circle
                    cx="15"
                    cy="15"
                    r="15"
                    transform="matrix(-1 0 0 1 30 0)"
                    fill="#676767"
                    fill-opacity="0.7"
                  />
                </g>
                <path
                  d="M12.75 9L18.75 15L12.75 21"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <filter
                    id="filter0_b_4984_1163"
                    x="-24"
                    y="-24"
                    width="78"
                    height="78"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_4984_1163"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_4984_1163"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AchievementsView;
