import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import NavigationFooter from "../Components/NavigationFooter/NavigationFooter";
import Context from "../context/Context";

export default function Layout() {
  const { isMobile } = useContext(Context);
  return (
    <div>
      <div className={!isMobile && "mb-[90px]"}>
        <Outlet />
      </div>
      {!isMobile && <NavigationFooter />}
    </div>
  );
}
