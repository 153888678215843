import React from "react";

function Line({ ml, mr }) {
  return (
    <div
      className={`justify-center items-center opacity-20 grid  ml-4  ${
        ml ? ml : "sm:ml-20"
      } mr-4 ${mr ? mr : "sm:mr-20"} bg-[#161616]`}
    >
      <hr className={`w-[80%] h-[1px]`} />
    </div>
  );
}

export default Line;
