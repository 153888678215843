import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import styles from "./Popup.module.css";

import backButton from "../../assests/backButton.svg";
import Right from "../../assests/ryt.svg";
import Left from "../../assests/lftw.svg";
import { useNavigate } from "react-router";
import "./Popup.css";
import * as _ from "lodash";

const Popup = ({
  clickedImg,
  setClickedImg,
  handelRotationRight,
  handelRotationLeft,
  contestDetails,
  isPrevDisabled,
  isNextDisabled,
}) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismissButton")) {
      setClickedImg(null);
    }
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div
      className={` dismiss z-[100] mx-auto ${styles.popup}`}
      onClick={handleClick}
    >
      <div
        className={`h-[100%] w-[100%] max-w-[1440px] mx-auto bg-[#000000] relative no-scrollbar overflow-y-scroll text-white`}
      >
        <div className={styles.header}>
          <div className={styles.headerInnerPill}>
            <img
              src={backButton}
              alt=""
              className={` dismissButton ${styles.backButton}`}
              onClick={handleClick}
            />
            <div className="z-[1]">
              <p className="text-regular text_1Line ">
                {/* {contestDetails?.campaignName}
              {" - "} */}
                <span style={{ color: contestDetails?.buttonColor }}>
                  {" "}
                  {clickedImg?.contestName}
                </span>
              </p>
            </div>
            <div className="w-[32px] h-[32px]" />
          </div>
        </div>

        <div className="flex justify-between items-center   w-[100%] gap-[15px] py-[16px] px-[20px]">
          <div className="flex items-center justify-center gap-[10px] select-none z-[1]">
            <p className="text-regular">{clickedImg?.participantName}</p>
          </div>
        </div>
        {clickedImg?.fileUrls?.map((obj, index) => {
          return (
            <>
              <div
                className={`${
                  index === 0 ? "h-[58vh] " : "h-[auto] mb-[20px]"
                } w-[100%] flex items-center justify-center relative `}
                style={{
                  background: `url(${
                    obj.fileType?.startsWith("video")
                      ? clickedImg?.thumbnailUrl
                      : obj.fileUrl
                  }) center center/cover no-repeat`,
                }}
              >
                <div className={styles.imageBg}>
                  {obj.fileType?.startsWith("video") ? (
                    <ReactPlayer
                      config={{
                        file: { attributes: { controlsList: "nodownload" } },
                      }}
                      playing={false}
                      controls={true}
                      width="100%"
                      height="100%"
                      url={obj.fileUrl}
                    />
                  ) : (
                    <img
                      src={obj.fileUrl}
                      alt=""
                      className="h-[100%] w-[100%] mx-auto object-contain"
                    />
                  )}
                  {index == 0 && (
                    <>
                      <button
                        className="w-[32px] h-[32px] disabled:opacity-0  absolute top-[45%] left-[9px]  backdrop-blur-[27px] rounded-full z-10"
                        disabled={isPrevDisabled}
                        onClick={handelRotationLeft}
                      >
                        <img className="w-[32px] h-[32px]  " src={Left} />
                      </button>
                      <button
                        className="w-[32px] h-[32px] disabled:opacity-0  absolute  top-[45%] right-[9px] backdrop-blur-[27px] rounded-full z-10"
                        onClick={handelRotationRight}
                        disabled={isNextDisabled}
                      >
                        <img
                          src={Right}
                          className="w-[32px] h-[32px] "
                          alt=""
                        />
                      </button>
                    </>
                  )}
                </div>
              </div>
              {index == 0 && (
                <>
                  {clickedImg?.description && (
                    <div className="px-[20px]">
                      <div className="seperator" />
                    </div>
                  )}
                  <div className="p-[16px]">
                    {clickedImg?.description && (
                      <div
                        className={`text-regular mb-[20px] bg-[#0f0f0f] text-[#f6f6f6] rounded-[10px] py-[16px] px-[10px]`}
                      >
                        <p>{clickedImg?.description}</p>
                      </div>
                    )}
                    {contestDetails?.formAttributes?.map((el) => {
                      return (
                        <div className="flex flex-col gap-[30px] px-[4px] ">
                          {el?.items?.map((key) => {
                            if (clickedImg[key] && el?.display) {
                              return (
                                <div className="pb-[30px]">
                                  <p
                                    className="text-regular-medium mb-[12px]"
                                    style={{
                                      color: contestDetails?.buttonColor,
                                    }}
                                  >
                                    {el?.title}{" "}
                                  </p>
                                  <p className="text-regular-callout  text-[#606060]">
                                    {clickedImg[key]}
                                  </p>
                                </div>
                              );
                            }
                          })}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          );
        })}
        <div className="px-[20px] mt-[40px] ">
          <div className="seperator " />
        </div>
      </div>
      {/* {share && (
        <SharePopup showShare={ShareHandle} contestant={contestContent} />
      )} */}
    </div>
  );
};

export default Popup;
