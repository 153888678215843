import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Back from "../../../../Components/back/Back";
import UseMediaQuery from "../../../../hooks/UseMediaQuery";
import MinimalModelLine from "./MinimalModelLine";
import CelebrityModelPopup from "../../../../Components/celebrityModelPopup/CelebrityModelPopup";
import { imageData } from "../../../../context/dataContext";

// const PopBrands = ({ selectedBrand, data, datas, onClose }) => {
//   return (
//     <div
//       style={{ transform: "translate(-50%, -50%)" }}
//       className="h-full modelMinimalFontFamily  w-[100%] max-w-[1440px] z-50 opacity-[0.9px] fixed top-[50%] bg-[white] bottom-0 left-[50%] shadow-xl"
//     >
//  <div className="text-black text-end mt-10 mr-10 text-[20px]">
//     <p onClick={onClose}> Back </p>
//    </div>
//       <div className="grid mt-10">

//         <div className="justify-center  items-center flex">
//           <div className="w-[250px] h-[250px]">
//             <img
//               className="object-cover  h-full w-full"
//               src={datas[selectedBrand]?.logo}
//               alt=""
//             />
//           </div>
//         </div>
//         <div className="text-center modelMinimalAcheivementsFontFamily mt-5">
//           <p className="ml-4 mr-4"> {datas[selectedBrand]?.para} </p>
//         </div>
//       </div>
//     </div>
//   );
// };
const CarouselDot = ({ active, onClick, id }) => {
  const dotClass = `${active ? "#000" : "#D9D9D9"}`;


  return (
    <div onClick={onClick} id={id}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 18 18"
        fill="none"
      >
        <circle cx="9" cy="9" r="9" fill={dotClass} />
      </svg>
    </div>
  );
};

function MinimalModelBrands({ data, brandsref }) {
  const isDesktop = UseMediaQuery("(min-width:640px)");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const cardRefs = useRef([]);
  const sliderRef = useRef(null);
  const {setBrandStyles} = useContext(imageData)

  const handleSelect = (item) => {
    // setOpen(true);
    // setSelectedBrand(item);
    navigate(`workview/${item}`, {
      state: { previousLocation: location?.pathname },
    });
  };

  useEffect(() => {
    if(data){
      setBrandStyles({
     
      mainBgColor:"#FFF",
      subBgColor:"#D9D9D980",
    
      textColor:"#111",

      fontFamily:"modelMinimalAcheivementsFontFamily",
      fontFamilyText:"modelMinimalFontFamily"
    });
  }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
    setSelectedBrand(null);
  };

  const prevBrand = () => {
    if (selectedBrand > 0) {
      setSelectedBrand(selectedBrand - 1);
    }
  };

  const nextBrand = () => {
    if (selectedBrand < data?.datas?.length - 1) {
      setSelectedBrand(selectedBrand + 1);
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;
    const handleScroll = () => {
      const sliderWidth = slider.offsetWidth;
      const sliderLeft = slider.getBoundingClientRect().left;
      const activeSlide = Array.from(slider.children).find((slide) => {
        const slideLeft = slide.getBoundingClientRect().left;
        return slideLeft >= sliderLeft;
      });
      console.log(activeSlide, "sloede");
      console.log(slider, "sl");
      setActiveIndex(
        activeSlide ? Array.from(slider.children).indexOf(activeSlide) : 0
      );
      console.log(Array.from(slider.children).indexOf(activeSlide), "array");
    };
    slider?.addEventListener("scroll", handleScroll);
    return () => slider?.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNext = () => {
    let currentIndex = activeIndex;
    let nextIndex;
    if (currentIndex + 1 == data?.datas?.length) {
      nextIndex = currentIndex;
    } else {
      nextIndex = currentIndex + 2;
    }
    setActiveIndex(nextIndex);
    if (cardRefs.current[nextIndex]) {
      cardRefs.current[nextIndex].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };

  const handlePrevious = () => {
    let currentIndex = activeIndex;
    let prevIndex;
    if (currentIndex == 0) {
      prevIndex = currentIndex;
    } else {
      prevIndex = currentIndex - 1;
    }
    setActiveIndex(prevIndex);
    if (cardRefs.current[prevIndex]) {
      cardRefs.current[prevIndex].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };

  const handleSlideChange = (index) => {
    setActiveIndex(index);
    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };

  const dots = (datas) =>
    datas.map((_, index) => (
      <CarouselDot
        key={index}
        active={index === activeIndex}
        id={`dot-${index}`}
        onClick={() => handleSlideChange(index)}
      />
    ));

  return (
    <div
      className="max-w-[1440px] relative mt-16 h-auto modelMinimalAcheivementsFontFamily ml-auto mr-auto"
      ref={brandsref}
    >
      <div className="modelMinimalFontFamily  sm:w-full  flex items-center  ">
        {" "}
        <p className="ml-4 sm:ml-16 text-start  text-[25px] sm:text-[45px] font-semibold">
          {" "}
          {data?.name}
        </p>
        <div className="justify-center mt-1  items-center  mx-auto w-[50%] lg:w-[65%]">
          <MinimalModelLine />
        </div>
      </div>

      <div
        ref={sliderRef}
        className={`  template-model-scroll  ml-7  mt-7 sm:mt-10 `}
      >
        {data?.datas?.map((item, index) => (
          <>
            {/* {open ? (
              <CelebrityModelPopup
                selectedBrand={selectedBrand}
                datas={data?.datas}
                name={data?.name}
                mainBgColor={"#FFF"}
                subBgColor={"#D9D9D980"}
                prev={prevBrand}
                next={nextBrand}
                textColor={"#111"}
                onClose={handleClose}
                fontFamily={"modelMinimalAcheivementsFontFamily"}
               headFontFamily={"modelMinimalFontFamily"}
              />
            ) : ( */}
              <>
                <div
                  ref={(element) => {
                    cardRefs.current[index] = element;
                  }}
                  onClick={() => handleSelect(index)}
                  className="mb-10 "
                >
                  <div className=" rounded-2xl pb-5 sm:pb-10 shadow-right-bottom items-start bg-[#F6F6F6]  flex flex-col  h-[100%]  w-[211px]   sm:w-[250px] lg:w-[330px]  xl:w-[430px] ">
                    <div className="xl:w-[289px] w-[105px] h-[111px] sm:w-[200px] sm:h-[250px] items-start xl:h-[306px] mt-6 sm:mt-10 mx-auto">
                      <img
                        className="w-full h-full object-cover"
                        src={item.logo}
                        alt=""
                      />
                    </div>
                    <div className="grid sm:leading-[30px] leading-[16px]  items-start  text-center">
                      <p className="mt-5 lg:mr-14 ml-5 mr-5 text-[12px] sm:text-[16px] lg:ml-14">
                        {isDesktop
                          ? item?.para?.slice(0, 250)
                          : item?.para?.slice(0, 70)}
                        ...{" "}
                        <span className="cursor-pointer  whitespace-nowrap  font-bold text-[black]  items-center ">
                          {" "}
                          Read More{" > "}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            {/* )} */}
          </>
        ))}
      </div>

      <div className="sm:flex hidden absolute top-[50%] right-0">
        <svg
          onClick={handleNext}
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 30 30"
          fill="none"
        >
          <g filter="url(#filter0_b_4984_1163)">
            <circle
              cx="15"
              cy="15"
              r="15"
              transform="matrix(-1 0 0 1 30 0)"
              fill="#676767"
              fill-opacity="0.7"
            />
          </g>
          <path
            d="M12.75 9L18.75 15L12.75 21"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <filter
              id="filter0_b_4984_1163"
              x="-24"
              y="-24"
              width="78"
              height="78"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4984_1163"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4984_1163"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="sm:flex hidden absolute top-[50%] left-0">
        <svg
          onClick={handlePrevious}
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 30 30"
          fill="none"
        >
          <g filter="url(#filter0_b_4984_1160)">
            <circle cx="15" cy="15" r="15" fill="#676767" fill-opacity="0.7" />
          </g>
          <path
            d="M17.25 9L11.25 15L17.25 21"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <filter
              id="filter0_b_4984_1160"
              x="-24"
              y="-24"
              width="78"
              height="78"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4984_1160"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4984_1160"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>


      <div className="sm:hidden  w-[100%] flex items-center justify-center gap-[6px] relative z-1 h-fit">
        {dots(data?.datas)}
      </div>
      {/* <div className="flex items-center gap-5 justify-center"> 
      {data?.datas?.map((item)=>(
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <circle cx="9" cy="9" r="9" fill="black"/>
              </svg>
              ))}
              </div> */}
    </div>
  );
}

export default forwardRef(MinimalModelBrands);
