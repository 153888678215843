import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useMetaTags(title, description, canonicalUrl) {
  const location = useLocation();
  const tags = {
    "/contact": {
      title: "Get in touch",
      description: "Get in touch with us for more information",
      pageCanonicalUrl: "https://www.goviral.world/contact",
    },
    "/howitworks": {
      title: "How Goviral Works",
      description:
        "Participate in our online campaigs or contests. Share and Grab Likes. Claim you prizes and Gifts",
      pageCanonicalUrl: "https://www.goviral.world/howitworks",
    },
    "/results": {
      title: "Campaign Results",
      description:
        "Checkout results of previous campaign,contest and competitions",
      pageCanonicalUrl: "https://www.goviral.world/results",
    },
    "/campaigns": {
      title: "Online Campaigns",
      description:
        "Checkout running and previous campaign , partipate to win exiting gifts",
      pageCanonicalUrl: "https://www.goviral.world/campaigns",
    },
    "/giveaways": {
      title: "Giveaways and Events",
      description: "Exiting giveaways and events",
      pageCanonicalUrl: "https://www.goviral.world/giveaways",
    },
  };

  useEffect(() => {
    const tagData = tags[location.pathname];

    const pageTitle =
      title ||
      tagData?.title ||
      "Goviral World | Online Magazine, Portfolios, & Campaigns";
    const metaDescription =
      description ||
      tagData?.description ||
      "Participate in online campaigns efforlessly. Curated public-figure portfolios. Feature in our online magazine";
    const pageCanonicalUrl = canonicalUrl || tagData?.pageCanonicalUrl || "";

    if (pageTitle) {
      document.title = pageTitle;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute("content", metaDescription);
      }
    }

    if (pageCanonicalUrl) {
      const canonicalLink = document.querySelector('link[rel="canonical"]');
      if (canonicalLink) {
        canonicalLink.setAttribute("href", pageCanonicalUrl);
      } else {
        const newCanonicalLink = document.createElement("link");
        newCanonicalLink.setAttribute("rel", "canonical");
        newCanonicalLink.setAttribute("href", pageCanonicalUrl);
        document.head.appendChild(newCanonicalLink);
      }
    }
  }, [location.pathname, title, description, canonicalUrl]);
}

export default useMetaTags;
