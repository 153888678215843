import { createContext, useEffect, useState } from "react";
import useMetaTags from "../hooks/UseMetaTags";

const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [promptInstall, setPromptInstall] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // const mqStandAlone = "(display-mode: standalone)";
    // if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    //   setIsMobile(true);
    // }
    let ifMobile = process.env.REACT_APP_BUILD_TYPE;
    if (ifMobile == "mob") {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);
  useMetaTags();
  return (
    <Context.Provider value={{ promptInstall, setPromptInstall, isMobile }}>
      {children}
    </Context.Provider>
  );
};

export default Context;
