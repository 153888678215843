import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Back from "../Components/back/Back";
import axios from "axios";
import JudgesViewAll from "../Components/MagazineTemplate/components/judgesVideos/JudgesViewAll";
import Footer from "../Components/footer/Footer";
import LoadingCircle from "../Components/LoadingCircle/LoadingCircle";

function JudgesVideosListingPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [scrolling, setScrolling] = useState(false);
  const [judgesData, setJudgesData] = useState();

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getJudgesData();
  }, []);

  const getJudgesData = async () => {
    try {
      let config = {
        method: "get",
        // url: `/home.json`,
        url: `https://storage.googleapis.com/goviral_magazine_data/home.json`,
      };
      let result = await axios(config);
      setJudgesData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  const handleScroll = () => {
    if (window.scrollY >= 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  window.addEventListener("scroll", handleScroll);

  return (
    <div className="fonts-for-Sathosi-homepage w-[100%] min-h-[100vh]">
      <div
        onClick={handleNavigateToPrevLocation}
        className={`fonts-for-Sathosi-homepage  sticky top-0 ${
          scrolling && "py-4"
        } bg-[white]   items-center z-20  flex ml-4  xl:ml-16 sm:ml-10  mt-16`}
      >
        <div className="">
          <Back />
        </div>

        <p className=" font-[700] text-[16px] sm:text-[21px] ml-5 uppercase">
          {" "}
          From celebrities
        </p>
      </div>
      {judgesData?.judgesVideos ? (
        <>
          <JudgesViewAll data={judgesData?.judgesVideos} />
          <Footer />
        </>
      ) : (
        <div className="w-[10%] mx-auto mt-20">
          <LoadingCircle />
        </div>
      )}
    </div>
  );
}

export default JudgesVideosListingPage;
