import React from "react";
import Line from "./components/Line";
import { Link } from "react-router-dom";

function Feedbacks({ data }) {
  return (
    <div className="max-w-[1440px] mx-auto  fonts-for-Sathosi-homepage">
      <div className="text-[18px]  lg:text-[24px] font-[500] uppercase justify-between  flex  ml-4 sm:ml-20 mr-4  sm:mr-20 items-center text-start mt-[22px] sm:mt-[40px]">
        <p className="   ">
          {" "}
          Feedbacks{" "}
        </p>
        <Link
            
            className=" cursor-pointer  hover:text-[#161616] text-[#575757]"
          >
            {" "}
            view All{" "}
          </Link>
      </div>
      <div className="sm:mt-[40px] mt-[22px] ">
        <Line />
      </div>

      <div className="sm:mr-20 ml-4 mr-4 sm:ml-20 text-[#fff]">
        <div className="xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 grid gap-5 sm:gap-10  w-[100%] mt-[22px] sm:mt-[40px]">
          {data?.feedbacks?.map((item, index) => (
            <Link to={item?.link} target="_blank" className="hover:no-underline">
            <div
              className="h-[100%] flex items-center justify-between pt-[7px] pb-[24px]"
              style={{ background: index % 2 === 0 ? "#59C9A5" : "#8884ED" }}
            >
              <div className="flex items-center justify-center">
                <div className="ml-5 w-[86px] h-[86px] items-center  shrink-0">
                  <img
                    style={{ borderRadius: "120px" }}
                    className=" w-full h-full object-cover"
                    src={item?.image}
                    alt=""
                  />
                </div>
                <div className="relative">
                  <div className="mt-[35px] ">
                    <p className="text-[16px]  sm:mr-0 mr-[19px] ml-4 font-[600] leading-[24px] text-[#fff]">
                      {" "}
                      {item?.text?.slice(0,80)}...{" "}
                    </p>
                    <p className="whitespace-nowrap text-[13px] ml-4 mt-[13px] font-[500] text-[#fff]">
                      {" "}
                      {item?.name}{" "}
                    </p>

                    <div className="absolute top-2 left-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g style={{ mixBlendMode: "multiply" }}>
                          <path
                            d="M4.583 17.3212C3.553 16.2272 3 15.0002 3 13.0112C3 9.51124 5.457 6.37424 9.03 4.82324L9.923 6.20124C6.588 8.00524 5.936 10.3462 5.676 11.8222C6.213 11.5442 6.916 11.4472 7.605 11.5112C9.409 11.6782 10.831 13.1592 10.831 15.0002C10.831 15.9285 10.4623 16.8187 9.80587 17.4751C9.1495 18.1315 8.25926 18.5002 7.331 18.5002C6.81766 18.4958 6.31034 18.3892 5.83856 18.1868C5.36679 17.9844 4.93999 17.6902 4.583 17.3212ZM14.583 17.3212C13.553 16.2272 13 15.0002 13 13.0112C13 9.51124 15.457 6.37424 19.03 4.82324L19.923 6.20124C16.588 8.00524 15.936 10.3462 15.676 11.8222C16.213 11.5442 16.916 11.4472 17.605 11.5112C19.409 11.6782 20.831 13.1592 20.831 15.0002C20.831 15.9285 20.4623 16.8187 19.8059 17.4751C19.1495 18.1315 18.2593 18.5002 17.331 18.5002C16.8177 18.4958 16.3103 18.3892 15.8386 18.1868C15.3668 17.9844 14.94 17.6902 14.583 17.3212Z"
                            fill={`${index % 2 === 0 ? "#59C9A5" : "#8884ED"}`}
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="absolute bottom-0 right-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g style={{ mixBlendMode: "multiply" }}>
                          <path
                            d="M19.417 17.3212C20.447 16.2272 21 15.0002 21 13.0112C21 9.51124 18.543 6.37424 14.97 4.82324L14.077 6.20124C17.412 8.00524 18.064 10.3462 18.324 11.8222C17.787 11.5442 17.084 11.4472 16.395 11.5112C14.591 11.6782 13.169 13.1592 13.169 15.0002C13.169 15.9285 13.5377 16.8187 14.1941 17.4751C14.8505 18.1315 15.7407 18.5002 16.669 18.5002C17.1823 18.4958 17.6897 18.3892 18.1614 18.1868C18.6332 17.9844 19.06 17.6902 19.417 17.3212ZM9.417 17.3212C10.447 16.2272 11 15.0002 11 13.0112C11 9.51124 8.543 6.37424 4.97 4.82324L4.077 6.20124C7.412 8.00524 8.064 10.3462 8.324 11.8222C7.787 11.5442 7.084 11.4472 6.395 11.5112C4.591 11.6782 3.169 13.1592 3.169 15.0002C3.169 15.9285 3.53775 16.8187 4.19412 17.4751C4.8505 18.1315 5.74074 18.5002 6.669 18.5002C7.18234 18.4958 7.68966 18.3892 8.16144 18.1868C8.63321 17.9844 9.06001 17.6902 9.417 17.3212Z"
                            fill={`${index % 2 === 0 ? "#59C9A5" : "#8884ED"}`}
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Feedbacks;
