import React from "react";

function GymgetInTouch({ data }) {
  const containerStyle = {
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 20.17%, #000 75.04%), url(${data?.image}) center center / cover no-repeat `,
  };
  return (
    <div className="w-[100%]">
      <div style={containerStyle} className="max-w-[1440px] mx-auto h-[550px] lg:h-[455px] ">
        <div className="lg:flex justify-center lg:justify-between items-start  ml-4 mr-4 sm:ml-10 sm:mr-10 lg:mr-24 lg:ml-24">
          <div className="sm:text-[42px] text-[32px] lg:text-[52px] text-[#D7FC51] w-[100%] pt-[70px] font-[600] fontFeature">
            <p> {data?.heading} </p>
          </div>

          <div className="lg:pt-[70px] pt-[19px] w-[100%]">
            <form>
              <div className="w-full ">
                <input autoFocus className="text-[16px] w-full text-[#161616] px-[20px]  appearance-none block py-[20px] bg-[#F6F6F6] rounded-[2px]  leading-tight focus:outline-none focus:bg-[#F6F6F6]" type="text"  placeholder="Your Name" />
              </div>

              <div className="w-full mt-[30px]">
                <input  className="text-[16px] w-full text-[#161616] px-[20px]  appearance-none block py-[20px] bg-[#F6F6F6] rounded-[2px]  leading-tight focus:outline-none focus:bg-[#F6F6F6]" type="text" placeholder="Mobile or Email" />
              </div>

              <div className="w-full  mt-[30px]">
                <textarea rows={5} className="text-[16px] w-full text-[#161616] px-[20px]  appearance-none block py-[20px] bg-[#F6F6F6] rounded-[2px]  leading-tight focus:outline-none focus:bg-[#F6F6F6]" type="text" placeholder="Write something you want to share with" />
              </div>

<div className="sm:justify-end justify-center items-center sm:items-end flex mt-[30px]">
    <button  type="submit " className="px-12 py-2 items-center uppercase text-[16px] sm:text-[21px] bg-[#D7FC51] rounded-[6px]">
submit
    </button>

</div>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GymgetInTouch;
