import React from "react";
import ProfileComponent from "./ProfileComponent";
import useMediaQuery from "../../hooks/UseMediaQuery";

function ProfileBanner({ data, theme }) {
 
  const isDesktop = useMediaQuery("(min-width:640px)")

  

  return (
    <>
    {/* {isDesktop ? "" : 
    <div className="mt-5 ml-[10px] mr-[10px]">
      <div className="justify-between  items-center flex">
        <div className="w-[64%]">
          <div className="justify-between  relative items-center flex">
            <img
              style={{ border: `4px solid ${theme?.profile1Border}` }}
              className="h-[130px] object-cover  w-[50%]"
              src={data?.ProfilePic1}
              alt=""
            />

            <div
              className="w-[50%] h-[130px]"
              style={{
                backgroundColor: theme?.profileBg1,
                color: theme?.profile12textColor,
              }}
            >
              <p className="uppercase text-[10px] font-[400] mt-3 ml-3">
                {data?.position1}
              </p>
              <p className="text-[18px] font-[700] ml-3 mt-2 leading-[22px]">
                {data?.name1}
              </p>
              <button
                style={{
                  backgroundColor: theme?.profileButtonBg,
                  color: theme?.profileButtonTextColor,
                }}
                className="text-[10px] p-2 ml-3  rounded-full mt-3"
              >
                view Profile
              </button>
            </div>
            <div className="absolute left-0 right-0 top-0 bottom-0 grid place-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="26"
                viewBox="0 0 21 26"
                fill="none"
              >
                <path d="M0 13L21 0.875645V25.1244L0 13Z" fill="#D6FEB7" />
              </svg>
            </div>
          </div>

          <div className="justify-between  relative items-center flex">
            <div
              className="w-[50%] h-[130px]"
              style={{
                backgroundColor: theme?.profileBg2,
                color: theme?.profile12textColor,
              }}
            >
              <p className="uppercase text-[10px] font-[400] mt-3 ml-3">
                {data?.position2}
              </p>
              <p className="text-[18px] font-[700] ml-3 mt-2 leading-[22px]">
                {data?.name2}
              </p>
              <button
                style={{
                  backgroundColor: theme?.profileButtonBg,
                  color: theme?.profileButtonTextColor,
                }}
                className="text-[10px] p-2 ml-3 rounded-full mt-3"
              >
                view Profile
              </button>
            </div>

            <img
              style={{ border: `4px solid ${theme?.profile2Border}` }}
              className="h-[130px] object-cover  w-[50%]"
              src={data?.ProfilePic2}
              alt=""
            />

            <div className="absolute left-[-5px] right-0 top-0 bottom-0 grid place-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: "rotate(240deg)" }}
                width="21"
                height="26"
                viewBox="0 0 21 26"
                fill="none"
              >
                <path d="M21 13L0 0.875645V25.1244L21 13Z" fill="#F6F6F6" />
              </svg>
            </div>
          </div>
        </div>

        <ProfileComponent   portFolioBorder={theme?.profile3Border}
  profilePic={data?.ProfilePic3}
  position={data?.position3}
  textColor={theme?.profile3textColor}
  portfolioButtonBg={theme?.profileButtonBg}
  background={theme?.profileBg3}
  portfollioButtonTextColor={theme?.profileButtonTextColor}
  name={data?.name3} />
      </div>
    </div>
    } */}
    </>
  );
}

export default ProfileBanner;
