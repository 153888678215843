import React from 'react'
import DeskHeader from './DeskHeader'
import MobHeader from './MobHeader'
import useMediaQuery from "../../hooks/UseMediaQuery"

function CampaignHeader() {
  const isDesktop = useMediaQuery("(min-width: 770px)"); 
  
  return (
    <div className=''>
      {isDesktop ? <DeskHeader/> : 
<MobHeader/>  }



    </div>
  )
}

export default CampaignHeader