import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ContextProvider } from "./ScrollContext";
import * as serviceWorker from "./serviceWorker";
import { ImageDataProvider } from "./context/dataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ContextProvider>
      <ImageDataProvider>
    <App />
    </ImageDataProvider>
    </ContextProvider>
  </BrowserRouter>
);

// serviceWorker.register();
