import React from 'react'
import { Link } from 'react-router-dom'

function Contest2({contestId,positionColor, contestImage, contestAplicantName}) {
  return (
    <div>
      <Link to={contestId} target='_blank' className='hover:text-black hover:no-underline'>
      <div className='justify-center  overflow-hidden  items-center flex'>
        <img className=' object-cover sm:w-[130px] w-[100%] h-[350px] sm:h-[130px] lg:w-[250px]  lg:h-[250px]' src={contestImage} alt='' />
      </div>
      <div className='justify-center items-center text-center mt-3'>
        <p style={{color:positionColor}} className='fonts-for-Sathosi-homepage uppercase text-[16px] sm:text-[11px] lg:text-[16px]'>{contestAplicantName}</p>
      </div>
      </Link>
    </div>
  )
}

export default Contest2