import axios from "axios";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Back from "../Components/back/Back";
import bgImage from "../assests/image.jpg"

function PortfolioWorking({ image, name, position }) {
  const { id, secId } = useParams();
  console.log(id, secId, "ppp");
  const [data, setData] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [findData, setFindData] = useState();

  useEffect(() => {
    getInfluencersData();
  }, []);

  useEffect(() => {
    if (data?.sectionData?.influencers && id) {
      const finding = data?.sectionData?.influencers?.find(
        (item) => item?.id == id
      );

      setFindData(finding);
    }
  }, [id, data]);

  const getInfluencersData = async () => {
    try {
      let config = {
        method: "get",
        // url: `/influencers/${secId}.json`,
        url: `https://storage.googleapis.com/goviral_magazine_data/influencers/${secId}.json`,
      };
      let result = await axios(config);
      setData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      {findData && (
        <>
          <div className="relative  w-[100%] min-h-[100vh] mx-auto fonts-for-Sathosi-homepage">
            <div
              onClick={handleNavigateToPrevLocation}
              className="sticky top-0   py-4  z-20  fonts-for-Sathosi-homepage  items-center   flex ml-4 mr-4 xl:ml-16 sm:ml-10 sm:mr-10  xl:mr-16 "
            >
              <div className="mt-10">
                <Back />
              </div>


            </div>
            <div className="max-w-[1440px] mx-auto">

            <div className="grid grid-cols-1 sm:grid-cols-2 items-start mt-20 sm:mt-36">
              <div className="sm:mt-0 mt-[37px]  justify-center items-center grid sm:order-1 order-2">
                <div className="lg:w-[290px] sm:w-[250px] w-[300px] h-[300px]  sm:h-[250px] lg:h-[290px] ">
                  <img
                    className="w-full h-full object-cover"
                    src={findData?.image}
                    alt=""
                  />
                </div>
                <div className="text-[#161616] text-center">
                  <p className="text-[16px] mt-[22px] uppercase font-[400]">
                    {" "}
                    {findData?.position}{" "}
                  </p>
                  <p className="text-[32px] font-[600] homepage-campaign-name-font">
                    {" "}
                    {findData?.name}{" "}
                  </p>{" "}
                </div>
              </div>
              <div className="justify-center items-center grid sm:order-2 order-1">
                <div>
                  <p className="text-[32px]  sm:text-[50px] lg:text-[72px] sm:ml-0 ml-4 lg:mr-32 font-[700] leading-[40px] sm:leading-[50px] lg:leading-[72px] ">
                    {" "}
                    Portfolio in Progress{" "}
                  </p>
                  <p className="mt-[14px] sm:mt-[55px] text-[16px]  sm:text-[24px] lg:text-[32px] font-[400] leading-[22px] sm:leading-[40px] sm:ml-0 ml-4 sm:mr-10 lg:mr-48">
                    This portfolio is currently in the works, so stay tuned for
                    the updated and stunning reveal!
                  </p>
                </div>
              </div>
            </div>
            </div>

       <div className="absolute -z-30 top-0 left-0 sm:flex hidden">
            <img className="w-full h-full object-cover" src={bgImage} alt=""/>
            </div>
          </div>
          
        
         
        </>
      )}
    </>
  );
}

export default PortfolioWorking;
