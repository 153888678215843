import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/footer/Footer";
import Back from "../Components/back/Back";
import LoadingCircle from "../Components/LoadingCircle/LoadingCircle";
import useMediaQuery from "../hooks/UseMediaQuery";
import PagesListingBox from "../Components/MagazineTemplate/components/PagesListingBox/PagesListingBox";

function PagesListingPage() {
  const { secId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState();
  const isDesktop = useMediaQuery("(min-width:640px)");

  const getInfluencersData = async () => {
    try {
      let config = {
        method: "get",
        // url: `/SEC16.json`,
        url: `https://storage.googleapis.com/goviral_magazine_data/portfolio/SEC16.json`,
      };

      let result = await axios(config);
      setData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfluencersData();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div
        style={{ color: data?.sectionProps?.colorTheme?.textColor }}
        className="w-[100%] min-h-[100vh] mx-auto fonts-for-Sathosi-homepage"
      >
        <div
          onClick={handleNavigateToPrevLocation}
          className="sticky top-0  py-4  z-20 bg-[white] fonts-for-Sathosi-homepage  items-center   flex ml-4  xl:ml-16 sm:ml-10  mt-16"
        >
          <div>
            <Back />
          </div>

          <p className=" font-[700] text-[16px] sm:text-[21px] ml-5 uppercase">
            Pages
          </p>
        </div>

        {data?.sectionData?.pages ? (
          <>
            <PagesListingBox data={data?.sectionData?.pages} />
          </>
        ) : (
          <div className=" mx-auto mt-20">
            <LoadingCircle />
          </div>
        )}
      </div>
      {data?.sectionData?.pages && isDesktop && <Footer />}
    </>
  );
}

export default PagesListingPage;
