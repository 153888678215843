import React from 'react'
import { Link } from 'react-router-dom'

function GymTraining({data}) {
    console.log(data,"data")

  return (
    <div className='w-[100%] fontFamilyForGym text-[#f6f6f6]'>
        <div className='max-w-[1440px] mx-auto'>

<div className= 'mt-[74px] sm:mt-[120px]  sm:w-[50%]'>
<p className='text-[32px] font-[600] ml-4 sm:ml-24'> {data?.heading}  </p>
<p className='text-[16px] sm:text-[18px] text-[#787878] mt-[16px] font-[600] ml-4  sm:ml-24'> {data?.para}  </p>
</div>

<div className='justify-center items-center grid mt-[78px]'>
<div className='grid grid-cols-1 sm:grid-cols-3  xl:grid-cols-4 ml-4 mr-4  sm:ml-24 sm:mr-20 gap-[45px]'>
    {data?.datas?.map((item)=>(
        <div>
            <p className='text-[#D7FC51] font-[600] text-[24px]'>{item?.name}</p>
            <p className='text-[#787878] font-[400] mt-[20px] text-[16px] mr-10'>{item?.para}</p>

            <Link to={""} className='text-[13px] font-[700] text-[#f6f6f6] mt-[10px]'> Learn More</Link>
            </div>
    ))}

</div>
</div>
        </div>

    </div>
  )
}

export default GymTraining