import React, { useState } from 'react'
import closeIcon from "../../../../images/closeIcon.png"
import addIcon from  "../../../../images/addIcon.png"

function Awards({data}) {

    const [toggle,setToggle] = useState("")

    const handleOpen = (id)=>{
        setToggle(id)
    }
    const handleClose = ()=>{
setToggle("")
    }

    
  return (
    <div className=' mt-2 md:mt-16'>
        <div className='md:ml-10 ml-4 mr-4 md:mr-10'>
<h1 className='text-[26px] sm:text-[33px] font-[600] text-[black] ' > {data?.name} </h1>
<p className="text-[16px] mt-2 sm:text-[18px] font-[400]">
        {data?.text}
        </p>
            {data && data?.datas?.map((item,i)=>(
                 <>
 <div key={item?.i}  onClick={()=>item?.id === toggle ? handleClose() : handleOpen(item?.id)} className={`justify-between  pt-3 pb-3  items-start flex`}>
 <div className="">
<p className='text-[14px] sm:text-[22px] font-[600]'>{item?.awardName} <span className='font-[300] ml-2'>{item?.date}</span> </p>
{ item?.id === toggle ? (
  <div className='w-[70%]'>
    <p className='mt-2 mb-2 text-[12px] lg:text-[16px] font-[400]'>
      {item?.awardTitle}
    </p>
  </div>
) : ""}
 </div>




{item?.id === toggle  ?  <div className='cursor-pointer shrink-0' onClick={handleClose} >
<img className='w-full h-full' src={closeIcon} alt="" />
 </div> :
  <div className='cursor-pointer shrink-0' onClick={()=>handleOpen(item?.id)} >
{item?.awardTitle && <img src={addIcon} className='w-full h-full' alt="" /> }  
  </div>
 

}
 



 
 </div>
 <hr className='text-black'/>
</>
            ))}
   
    </div>
    </div>
  )
}

export default Awards