import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import LoadingCircle from "../../../LoadingCircle/LoadingCircle";

function VideoPopUp({ data, selectedIndex, setOpen }) {
  const [isPlaying, setIsPlaying] = useState(true);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <>
      <div className="fixed fonts-for-Sathosi-homepage z-50 bg-[white] min-h-[100vh] bg-opacity-80 top-0  left-0 right-0  w-[100%] ">
        <div className="mt-[25%] sm:mt-[8%] xl:mt-[1%] mb-[300px] sm:justify-end justify-center items-center sm:items-end flex  ml-1 mr-1 sm:mr-10  lg:mr-20 xl:mr-48">
          <div
            className=" overflow-hidden  items-start relative grid sm:flex h-[530px] sm:h-[530px] bg-[white] w-[93%]  lg:w-[70%] xl:w-[46%] sm:justify-between"
            style={{
              opacity: 1,
              boxShadow: " 0px 4px 60px 0px rgba(0, 0, 0, 0.16)",
            }}
          >
            <div className="w-[100%] sm:justify-between   sm:h-[530px] sm:flex sm:flex-col">
              <div className="sm:flex hidden">
                <p className="text-[#787878] ml-[20px] mt-[22px]">
                  Goviral.world
                </p>
              </div>
              <div className="w-[100%] sm:ml-[20px] ml-[16px] mb-[16px] sm:mb-[30px] text-[#161616]">
                <div className="sm:flex hidden">
                  <p className="mr-[20px] text-[21px] font-[700] leading-[26px] ">
                    {data[selectedIndex]?.text}
                  </p>
                </div>
                <p className="text-[16px] uppercase font-[400] mt-[16px] sm:mt-[20px]">
                  {data[selectedIndex]?.name}{" "}
                </p>
                <p className="text-[13px] font-[400] text-[#787878] ">
                  {data[selectedIndex]?.profession}{" "}
                </p>
              </div>
            </div>

            <div className=" w-full  h-full  relative">
              <ReactPlayer
                style={{ objectFit: "contain" }}
                height={"100%"}
                width={"100%"}
                playing={isPlaying}
                url={data[selectedIndex]?.video}
              />
              <div
                onClick={togglePlayPause}
                className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center"
              >
                {!isPlaying && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="44"
                    viewBox="0 0 36 44"
                    fill="none"
                  >
                    <path
                      d="M35.75 22C35.7513 22.5518 35.6099 23.0945 35.3394 23.5754C35.0688 24.0562 34.6785 24.4589 34.2062 24.7443L4.94 42.6477C4.44658 42.9498 3.88145 43.1148 3.30297 43.1255C2.72449 43.1362 2.15364 42.9923 1.64938 42.7086C1.14991 42.4294 0.733842 42.0221 0.443958 41.5287C0.154073 41.0354 0.000834555 40.4737 0 39.9014V4.09863C0.000834555 3.5264 0.154073 2.96471 0.443958 2.47134C0.733842 1.97796 1.14991 1.5707 1.64938 1.29144C2.15364 1.00778 2.72449 0.863868 3.30297 0.874581C3.88145 0.885293 4.44658 1.05024 4.94 1.35238L34.2062 19.2558C34.6785 19.5412 35.0688 19.9438 35.3394 20.4247C35.6099 20.9056 35.7513 21.4483 35.75 22Z"
                      fill="white"
                    />
                  </svg>
                )}
              </div>
            </div>

            <div className="absolute top-[23px] right-[20px]">
              <button
                onClick={() => setOpen(false)}
                style={{
                  border: "1px solid var(--Mid-Grey, #D9D9D9)",
                  borderRadius: "40px",
                }}
                className="bg-[#FFF] p-[4px]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M14.375 14.375L5.625 5.625M14.375 5.625L5.625 14.375"
                    stroke="#161616"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoPopUp;
