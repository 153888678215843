import React from "react";

function GymAchievements({ data }) {
  return (
    <div className="max-w-[1440px] mx-auto  relative fontFamilyForGym">
      <div className="lg:grid grid-cols-2  hidden items-start ">
        <div
          className="w-full"
          style={{
            background:
              "linear-gradient(98deg, #000 5.9%, rgba(0, 0, 0, 0.00) 73.99%)",
          }}
        >
          <img
            className="w-full lg:ml-10  h-full object-cover"
            src={data?.image}
            alt=""
          />
        </div>

        <div className=" ">
          <div className="  ml-20  mt-[73px]">
            <p className="text-[#D7FC51]  fontFeature   text-[52px] font-[700]">
              {" "}
              {data?.heading}
            </p>
            {data?.datas?.map((item) => (
              <div className="mt-[32px] font-[400]">
                <p className="text-[32px] text-[#D7FC51] "> {item?.date}</p>
                <p className="text-[21px] text-[#F6F6F6] mt-[16px]  ">
                  {" "}
                  {item?.name}
                </p>
                <p className="text-[16px] leading-[22px] text-[#787878] mt-[16px]  ">
                  {" "}
                  {item?.para}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* mobile */}

      <div className="    lg:hidden  min-h-[100vh]  ">
        <div className="w-full  ">
          <img
            className="w-full h-full object-cover"
            src={data?.image}
            alt=""
          />
        </div>

        <div
          className="bg-[#000]   "
          style={{
            background:
              "linear-gradient(360deg, #000 80%, rgba(0, 0, 0, 0.00) 100%)",
          }}
        >
          <div className=" ml-20  mr-10  ">
            <p className="text-[#D7FC51]  fontFeature   text-[32px] sm:text-[52px] font-[700]">
              {" "}
              {data?.heading}
            </p>
            {data?.datas?.map((item) => (
              <div className="mt-[32px] font-[400]">
                <div>
                  <p className="text-[24px]  sm:text-[32px] text-[#D7FC51] "> {item?.date}</p>
                  <p className="text-[18px] sm:text-[21px] text-[#F6F6F6] mt-[16px]  ">
                    {" "}
                    {item?.name}
                  </p>
                  <p className="text-[13px] sm:text-[16px] leading-[22px] text-[#787878] mt-[16px]  ">
                    {" "}
                    {item?.para}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GymAchievements;
