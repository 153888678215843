import React from "react";
import { Link } from "react-router-dom";
import useMediaQuery from "../../hooks/UseMediaQuery";

function Ads({ data }) {
  const isDesktop = useMediaQuery("(min-width:640px)")
  return (
    <div className="max-w-[1440px] mx-auto mb-[30px] mt-[30px] lg:mt-[60px] sm:mb-[40px] lg:mb-[60px]">
      <div className="  ml-4 mr-4  mx-auto xl:ml-20 xl:mr-20">
        <Link to={"/giveaways"} className={``}>
          <img
            className=" w-full h-full  object-contain"
            src={isDesktop ? data?.images?.image: data?.images?.Mobimage ? data?.images?.Mobimage :data?.images?.image  }
            alt=""
          />
        </Link>
      </div>
    </div>
  );
}

export default Ads;
