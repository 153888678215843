import React from "react";
import useMediaQuery from "../../hooks/UseMediaQuery";
import WebHighlights from "./WebHighlights";
import MoreHighlights from "./HighlightsComponents/MoreHighlights";
import { Link } from "react-router-dom";
import Line from "./components/Line";

function Highlights({ data, theme }) {
  return (
    <div>
      <WebHighlights data={data} theme={theme} />



    </div>
  );
}

export default Highlights;
