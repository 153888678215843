import React, { useState } from "react";
import { HeaderLinks } from "../../common/Constants";

function MobHeader() {
  const [open, setOpen] = useState(false);

  return (
    <div className={` justify-between items-center  flex`} >
      <div className="z-10 ml-[10%] mt-8 text-[white] font-[600] text-[15px] sm:text-[25px]">
        <p>Goviral</p>
      </div>

      <div className={`  mr-[10%] mt-8`}  onClick={() => setOpen(!open)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
        >
          <rect width="18.6667" height="3.33333" rx="1.66667" fill="white" />
          <rect
            y="5.3335"
            width="18.6667"
            height="3.33333"
            rx="1.66667"
            fill="white"
          />
          <rect
            y="10.6665"
            width="18.6667"
            height="3.33333"
            rx="1.66667"
            fill="white"
          />
        </svg>
      </div>

   
       <div
        className={`fixed right-0 top-0 z-20 bg-[#D9D9D9F2] p-3 rounded-2xl  h-auto transform transition-transform ease-in-out duration-300 ${
          open ? "translate-x-0 right-2 top-2 " : "translate-x-full"
        }`}
      >
        
        <div
          className=" gap-7   font-headerLinks text-[15px] font-[700] text-[#2B453D] cursor-pointer"
          style={{ listStyleType: "none" }}
        >
          <div className="mt-1 justify-end items-end flex">
            <p className=" mr-4 text-[15px]">Close</p>
            <svg
            onClick={()=>setOpen(false)}
              className="mr-3 mb-1"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M0.470429 0.470417C-0.15681 1.09766 -0.15681 2.11459 0.470429 2.74183L6.22859 8.49996L0.470429 14.2582C-0.15681 14.8854 -0.15681 15.9024 0.470429 16.5296C1.09765 17.1568 2.1146 17.1568 2.74182 16.5296L8.49997 10.7713L14.2582 16.5296C14.8854 17.1568 15.9024 17.1568 16.5296 16.5296C17.1568 15.9024 17.1568 14.8854 16.5296 14.2582L10.7713 8.49996L16.5296 2.74184C17.1568 2.11462 17.1568 1.09767 16.5296 0.470449C15.9023 -0.15679 14.8854 -0.15679 14.2582 0.470449L8.49997 6.22859L2.74182 0.470417C2.1146 -0.156806 1.09765 -0.156806 0.470429 0.470417Z"
                fill="#58795B"
              />
            </svg>

          </div>
          <div><hr className="h-[2px] bg-[#58795B]"/></div>
          <div className="justify-end items-end grid ">
            {HeaderLinks?.map((item) => (
              <div className="justify-end mt-4 items-end flex">
              <li className="mr-3 " key={item?.name}>{item?.name}</li>
              <li className="mr-3">{item?.logo}</li>
              </div>
            ))}
          </div>
        </div>

        
      </div>






    </div>
  );
}

export default MobHeader;
