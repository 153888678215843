import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ProfileComponent({
  portFolioBorder,
  profilePic,
  profileId,
  position,
  textColor,
  portfolioButtonBg,
  background,
  portfollioButtonTextColor,
  name,
}) {
  return (
    <div
      className={`h-[260px] w-[36%] mr-4   fonts-for-Sathosi-homepage`}
      style={{
        border: `4px solid ${portFolioBorder}`,
        backgroundColor: background,
      }}
    >
      <div className="overflow-hidden justify-center items-center  grid">
        <div className=" w-[130px] h-[130px]  mx-auto">
          <img
            style={{ borderBottom: `4px solid ${portFolioBorder}` }}
            className="w-full h-full  object-cover"
            src={profilePic}
          />
        </div>
        <div className="text-start" style={{ color: textColor }}>
          <p className=" ml-2 mt-2 text-[10px] font-[400] uppercase">
            {position}
          </p>
          <p className="ml-2 mt-2 font-[700] text-[16px] leading-[22px]">
            {name}{" "}
          </p>
        </div>
        <Link
          to={profileId}
          className="ml-2 mt-2 text-[10px]"
          style={{ color: textColor }}
        >
          {profileId && "View Profile"}
        </Link>
      </div>
    </div>
  );
}

export default ProfileComponent;
