import React, { useState } from "react";
import VideoPopUp from "./VideoPopUp";

function JudgesViewAll({ data }) {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();

  const handleSelect = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };

 
  return (
    <div className="max-w-[1440px] mx-auto">
      <div className="justify-start items-start mt-[57px] ">
        <p className="text-[32px] font-[700] leading-[40px] ml-4 sm:ml-10 xl:ml-20 ">
          {data?.heading}
        </p>
      </div>

      <div className="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-start grid xl:ml-20 sm:ml-10  sm:mr-10  xl:mr-20 gap-[27px] mt-[30px]">
        {data?.datas?.map((item,index) => (
          <div onClick={()=>handleSelect(index)} className="justify-center items-start grid mt-[30px]">
            <div className="w-[90%] sm:w-[300px] h-[276px] mx-auto relative">
              <img
                className="w-full h-full object-cover"
                src={item?.image}
                alt=""
              />
              <div className="absolute -bottom-5 right-[12px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg "
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="none"
                >
                  <circle cx="21" cy="21" r="10.5" fill="white" />
                  <path
                    d="M16.625 28.875L28.875 21L16.625 13.125V28.875ZM21 38.5C18.5792 38.5 16.3042 38.0403 14.175 37.121C12.0458 36.2017 10.1938 34.9551 8.61875 33.3813C7.04375 31.8063 5.79717 29.9542 4.879 27.825C3.96083 25.6958 3.50117 23.4208 3.5 21C3.5 18.5792 3.95967 16.3042 4.879 14.175C5.79833 12.0458 7.04492 10.1938 8.61875 8.61875C10.1938 7.04375 12.0458 5.79717 14.175 4.879C16.3042 3.96083 18.5792 3.50117 21 3.5C23.4208 3.5 25.6958 3.95967 27.825 4.879C29.9542 5.79833 31.8063 7.04492 33.3813 8.61875C34.9563 10.1938 36.2034 12.0458 37.1227 14.175C38.0421 16.3042 38.5012 18.5792 38.5 21C38.5 23.4208 38.0403 25.6958 37.121 27.825C36.2017 29.9542 34.9551 31.8063 33.3813 33.3813C31.8063 34.9563 29.9542 36.2034 27.825 37.1227C25.6958 38.0421 23.4208 38.5012 21 38.5Z"
                    fill="#161616"
                  />
                </svg>
              </div>
            </div>
            <div className="mt-[21px] justify-center items-center mx-auto grid w-[90%] sm:w-[300px] ">
              <p className="text-[21px] leading-[26px] font-[700] text-[#161616] ">
                {" "}
                {item?.text}
              </p>
              <p className="mt-[16px] uppercase text-[16px] text-[#161616] font-[400] ">
                {" "}
                {item?.name}{" "}
              </p>
              <p className="text-[13px] text-[#787878]"> {item?.profession} </p>
            </div>
          </div>
        ))}
        {open && (
          <div>
            <VideoPopUp
              setOpen={setOpen}
              data={data?.datas}
              selectedIndex={selectedIndex}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default JudgesViewAll;
