import React, { useState } from "react";
import MinimalModelLine from "../model/minimal/MinimalModelLine";
import Pop from "../Pop/Pop";
import { isEmpty } from "lodash";

function CelebrityGetInTouch({ data }) {
  const [popUp, setPopUp] = useState(false);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  
  const handleSubmit = (e) => {
    e.preventDefault();
 
   
      setPopUp(true);
   
  };

  return (
    <>
      <div className="max-w-[1440px] ml-auto mr-auto text-[white]">
        <div className=" mt-10  sm:mt-20 pb-[50px]">
          <div className="  sm:w-full  flex items-center  ">
            {" "}
            <p className="ml-4 sm:ml-10 xl:ml-16 text-start  font-[400] lg:text-[30px] text-[18px] sm:text-[20px] xl:text-[45px] ">
              {" "}
              Get In Touch
            </p>
            <div className="sm:flex  hidden justify-center items-center sm:mx-10  xl:mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="4"
                viewBox="0 0 880 4"
                fill="none"
              >
                <path
                  d="M2 2L878 2"
                  stroke="#464646"
                  stroke-opacity="0.9"
                  stroke-width="2.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="sm:hidden flex justify-center items-center  mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="90%"
                height="2"
                viewBox="0 0 238 2"
                fill="none"
              >
                <path
                  d="M1 1L237 1"
                  stroke="#464646"
                  stroke-opacity="0.9"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>

          <div className="  items-start -mt-5  grid  grid-cols-1 lg:grid-cols-2  sm:mt-10 ">
            <div className="justify-center items-center hidden sm:flex">
              <img
                className="ml-16 xl:w-[590px] object-cover  w-[400px] h-[400px] xl:h-[604px]"
                src={data?.image}
                alt=" "
              />
            </div>
            <div className="lg:mt-0 mt-5 sm:mt-10">
              <div className="justify-center sm:mx-0 mx-4 items-center grid">
                <form
                  class="w-full  sm:mx-0  mt-5 sm:mt-16"
                  onSubmit={handleSubmit}
                >
                  <label className="text-[14px] sm:text-[16px] font-[400]">
                    Name
                  </label>
                  <div className="flex mb-3 items-start mt-2">
                    <input
                      name="firstName"
                      value={values.firstName}
                      onChange={(e) => handleChange(e)}
                      class="appearance-none placeholder-[#5F5F5F] block w-full bg-[#232323] focus:outline-double focus:outline-[white]  text-[#fff]  py-3 px-4 mb-3 leading-tight "
                      type="text"
                      placeholder="First Name"
                    />

                    <input
                      name="lastName"
                      value={values.lastName}
                      onChange={(e) => handleChange(e)}
                      class="ml-4 appearance-none block w-full bg-[#232323] focus:outline-double focus:outline-[white] placeholder-[#5F5F5F]   text-[#fff] py-3 px-4 mb-3 leading-tight "
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                  <label className="text-[14px] sm:text-[16px] font-[400]">
                    Email
                  </label>

                  <div className="flex mb-3 items-start mt-2">
                    <input
                      name="email"
                      value={values.email}
                      onChange={(e) => handleChange(e)}
                      class="appearance-none block w-full bg-[#232323] focus:outline-double focus:outline-[white]  placeholder-[#5F5F5F]  text-[#fff]  py-3 px-4 mb-3 leading-tight "
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                  <label className=" text-[14px] sm:text-[16px] font-[400]sm">
                    Message
                  </label>
                  <div className="flex mb-5 items-start mt-2">
                    <textarea
                      name="message"
                      value={values.message}
                      onChange={(e) => handleChange(e)}
                      rows={8}
                      class="appearance-none focus:outline-double focus:outline-[white]  placeholder-[#5F5F5F] block w-full bg-[#232323]    text-[#fff]  py-3 px-4 mb-3 leading-tight "
                      type="message"
                      placeholder="message"
                    />
                  </div>
                  <div className="justify-end items-end flex">
                    <button
                      type="submit"
                      className=" bg-[#232323] hover:bg-[#C4C4C4]  border hover:text-[#232323] font-[300] p-2  pl-7 pr-7 rounded-lg "
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {popUp && <Pop setPopUp={setPopUp} />}
    </>
  );
}

export default CelebrityGetInTouch;
