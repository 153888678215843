import React from "react";

export default function Dignitaries({ campaignData }) {
  return (
    <div >
      <div className="template-inner-container mt-[20px] md:mt-[70px]">
        <div className="template-dignitaries-container ">
          <h3 className="mb-[10px] text-[#58795B] md:text-[20px] text-[18px] lg:text-[35px]">The Dignitaries</h3>
          <div className="grid grid-cols-4 place-items-center  h-auto">
            {campaignData?.dignitaries?.map((obj, index) => {
              return (
                <div className="cursor-pointer h-full w-[90%] sm:w-[80%] flex flex-col ">
                  <div
                    key={index}
                    className="w-full h-full overflow-hidden  max-h-[300px] rounded-[8px]"
                  >
                    <img
                      src={obj.logo}
                      className="object-cover h-full w-full"
                    />
                  </div>
                  <h4 className=" mt-[10px] md:mt-[20px] text-[#1B3332] text-[10px] sm:text-[20px] lg:text-[30px]">{obj?.name}</h4>
                  <p className="text-[#58795B] text-[8px]  lg:text-[23px]">{obj?.position}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
