import React from "react";
import { Link, useLocation } from "react-router-dom";
import useMediaQuery from "../../../../hooks/UseMediaQuery";

function ResultListingComponent({ data }) {
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width:1024px)");

  return (
    <div className="max-w-[1440px] mx-auto">
      <div className="lg:grid-cols-2  items-start grid-cols-1 mt-12 lg:mt-14 grid mx-auto pb-[100px]">
        {data?.map((item, index) => (
          <Link
            className="hover:no-underline hover:text-black"
            to={`/results${item?.link}`}
            state={{ previousLocation: location?.pathname }}
          >
            <div
              className={`${index > 1 && "lg:mt-8 "} ${
                !isDesktop && index > 0 && "mt-5"
              }  h-[172px]  sm:h-[150px]  lg:h-[200px] xl:h-[331px] shadowForResultsPage    ml-4 sm:ml-10 xl:ml-20 mr-4 sm:mr-10  items-start flex`}
            >
              <div className="xl:w-[283px] lg:w-[180px] w-[120px] h-[162px] sm:w-[150px] sm:h-[150px]  lg:h-[200px] xl:h-[331px] shrink-0">
                <img
                  src={item?.image}
                  className="w-full h-full object-cover "
                  alt=""
                />
              </div>
              <div className="ml-5  flex flex-col h-[100%] sm:h-[150px]  lg:h-[200px] xl:h-[331px] justify-between  mt-2 mb-2">
                <div>
                  <p className="uppercase sm:text-[14px] lg:text-[16px]  leading-[22px] font-[400] ">
                    {item?.type}
                  </p>
                  <p className="text-[21px] leading-[25px]  mt-[2px] sm:mt-[10px] sm:text-[18px] lg:text-[24px]   font-[600]   ">
                    {item?.name}
                  </p>

                  <p className="text-[13px]  mt-[2px] sm:mt-[10px] lg:text-[16px] sm:text-[12px]  leading-[20px] xl:mr-10 font-[400]   ">
                    {item?.text?.slice(0, 50)}...
                  </p>
                </div>
                <div className="mb-[10px] sm:mb-[20px]">
                  <Link
                    state={{ previousLocation: location?.pathname }}
                    to={`/results${item?.link}`}
                    className="uppercase text-[13px] hover:underline font-[500]  sm:text-[12px] lg:text-[16px]   sm:font-[400] leading-[20px]"
                  >
                    View Results
                  </Link>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ResultListingComponent;
