import React, { useEffect, useRef, useState } from "react";
import VideoPopUp from "./VideoPopUp";

function VideoCardComponent({ data }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const cardRefs = useRef([]);
  const sliderRef = useRef(null);
  useEffect(() => {
    const slider = sliderRef.current;
    const handleScroll = () => {
      const sliderWidth = slider.offsetWidth;
      const sliderLeft = slider.getBoundingClientRect().left;
      const activeSlide = Array.from(slider.children).find((slide) => {
        const slideLeft = slide.getBoundingClientRect().left;
        return slideLeft >= sliderLeft;
      });
    
      setActiveIndex(
        activeSlide ? Array.from(slider.children).indexOf(activeSlide) : 0
      );
      console.log(Array.from(slider.children).indexOf(activeSlide), "array");
    };
    slider?.addEventListener("scroll", handleScroll);
    return () => slider?.removeEventListener("scroll", handleScroll);
  }, []);
  const handleNext = () => {
    let currentIndex = activeIndex;
    let nextIndex;
    if (currentIndex + 1 === data?.length) {
      nextIndex = 0;
    } else {
      nextIndex = currentIndex + 2;
    }
    setActiveIndex(nextIndex);
    if (cardRefs.current[nextIndex]) {
      cardRefs.current[nextIndex].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };
  const handlePrevious = () => {
    let currentIndex = activeIndex;
    let prevIndex;
    if (currentIndex === 0) {
      prevIndex = data?.length - 1;
    } else {
      prevIndex = currentIndex - 1;
    }
    setActiveIndex(prevIndex);
    if (cardRefs.current[prevIndex]) {
      cardRefs.current[prevIndex].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };

  const handleSelect = (index) => {
    
    setSelectedIndex(index);
    setOpen(true)
   
  };
   
  return (
    <>
      <div
        ref={sliderRef}
        className="sm:ml-20 ml-4 mr-4 sm:mr-20  relative grid  mt-[40px]   items-start grid-flow-col gap-[20px] sm:gap-[27px] overflow-x-auto [&::-webkit-scrollbar]:hidden "
      
      >
        {data?.map((item, index) => (
          <div
            ref={(element) => {
              cardRefs.current[index] = element;
            }}
            className="grid items-center "
            onClick={() => handleSelect(index)}
          >
            <div className="w-[300px] h-[276px] relative">
              <img
                className={`w-full h-full object-cover`}
                src={item?.image}
                alt=" "
              />
              <div className="absolute -bottom-5 right-[12px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg "
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="none"
                >
                  <circle cx="21" cy="21" r="10.5" fill="white" />
                  <path
                    d="M16.625 28.875L28.875 21L16.625 13.125V28.875ZM21 38.5C18.5792 38.5 16.3042 38.0403 14.175 37.121C12.0458 36.2017 10.1938 34.9551 8.61875 33.3813C7.04375 31.8063 5.79717 29.9542 4.879 27.825C3.96083 25.6958 3.50117 23.4208 3.5 21C3.5 18.5792 3.95967 16.3042 4.879 14.175C5.79833 12.0458 7.04492 10.1938 8.61875 8.61875C10.1938 7.04375 12.0458 5.79717 14.175 4.879C16.3042 3.96083 18.5792 3.50117 21 3.5C23.4208 3.5 25.6958 3.95967 27.825 4.879C29.9542 5.79833 31.8063 7.04492 33.3813 8.61875C34.9563 10.1938 36.2034 12.0458 37.1227 14.175C38.0421 16.3042 38.5012 18.5792 38.5 21C38.5 23.4208 38.0403 25.6958 37.121 27.825C36.2017 29.9542 34.9551 31.8063 33.3813 33.3813C31.8063 34.9563 29.9542 36.2034 27.825 37.1227C25.6958 38.0421 23.4208 38.5012 21 38.5Z"
                    fill="#161616"
                  />
                </svg>
              </div>
            </div>
            <div className="mt-[21px]">
              <p className="text-[21px] leading-[26px] font-[700] text-[#161616] ">
                {" "}
                {item?.text?.slice(0,45)}...
              </p>
              <p className="mt-[16px] uppercase text-[16px] text-[#161616] font-[400] ">
                {" "}
                {item?.name}{" "}
              </p>
              <p className="text-[13px] text-[#787878]"> {item?.profession} </p>
            </div>
          </div>
        ))}
        {open && (
          <div>
            <VideoPopUp setOpen={setOpen} data={data} selectedIndex={selectedIndex} />
          </div>
        )}
      </div>



      <div className="sm:flex hidden mt-[24px] justify-end mr-20 gap-[32px] items-end ">
        <button
          onClick={handlePrevious}
          style={{
            border: "1px solid var(--Mid-Grey, #D9D9D9)",
            borderRadius: "40px",
          }}
          className="bg-[#FFF] p-[4px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.2426 5.59173C12.3199 5.66882 12.3812 5.76039 12.423 5.86121C12.4648 5.96202 12.4863 6.07009 12.4863 6.17923C12.4863 6.28837 12.4648 6.39643 12.423 6.49725C12.3812 6.59806 12.3199 6.68963 12.2426 6.76673L9.00929 10.0001L12.2426 13.2334C12.3984 13.3892 12.486 13.6005 12.486 13.8209C12.486 14.0412 12.3984 14.2526 12.2426 14.4084C12.0868 14.5642 11.8755 14.6517 11.6551 14.6517C11.4348 14.6517 11.2234 14.5642 11.0676 14.4084L7.24262 10.5834C7.16537 10.5063 7.10408 10.4147 7.06226 10.3139C7.02045 10.2131 6.99892 10.105 6.99892 9.99589C6.99892 9.88675 7.02045 9.77868 7.06226 9.67787C7.10408 9.57706 7.16537 9.48549 7.24262 9.40839L11.0676 5.58339C11.3843 5.26673 11.9176 5.26673 12.2426 5.59173Z"
              fill="#161616"
            />
          </svg>
        </button>

        <button
          onClick={handleNext}
          style={{
            border: "1px solid var(--Mid-Grey, #D9D9D9)",
            borderRadius: "40px",
          }}
          className="bg-[#FFF] p-[4px] "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M7.75738 5.59173C7.68012 5.66882 7.61883 5.76039 7.57701 5.86121C7.5352 5.96202 7.51367 6.07009 7.51367 6.17923C7.51367 6.28837 7.5352 6.39643 7.57701 6.49725C7.61883 6.59806 7.68012 6.68963 7.75738 6.76673L10.9907 10.0001L7.75738 13.2334C7.60156 13.3892 7.51402 13.6005 7.51402 13.8209C7.51402 14.0412 7.60156 14.2526 7.75738 14.4084C7.91319 14.5642 8.12452 14.6517 8.34488 14.6517C8.56523 14.6517 8.77656 14.5642 8.93238 14.4084L12.7574 10.5834C12.8346 10.5063 12.8959 10.4147 12.9377 10.3139C12.9796 10.2131 13.0011 10.105 13.0011 9.99589C13.0011 9.88675 12.9796 9.77868 12.9377 9.67787C12.8959 9.57706 12.8346 9.48549 12.7574 9.40839L8.93238 5.58339C8.61571 5.26673 8.08238 5.26673 7.75738 5.59173Z"
              fill="#161616"
            />
          </svg>
        </button>
      </div>

    </>
  );
}

export default VideoCardComponent;
