import React from 'react'

function GymSocialMedia({data}) {
  return (
    <div className='w-[100%] mt-[54px] sm:mt-[70px] bg-[#D7FC51] text-[#000]'>
        <div className='gap-[30px] py-[20px] sm:py-[36px] justify-start items-start grid grid-flow-col mt-[32px]   [&::-webkit-scrollbar]:hidden overflow-x-auto'>

{data?.datas?.map((item)=>(
    <div className='flex items-center gap-[19px] whitespace-nowrap'>
        <p className='text-[24px] sm:text-[32px] font-[600] uppercase'> {item?.name} </p>
        <p className='text-[16px] sm:text-[21px]  font-[600] uppercase'> {item?.followers} </p>
   </div>     
))}

        </div>

    </div>
  )
}

export default GymSocialMedia