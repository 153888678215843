import React from "react";

function Banner({ data }) {
  if (!data?.bannerImage) {
    return null;
  }

  const containerStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.9) 23%, rgba(0, 0, 0, 0) 50%), url(${data?.bannerImage}) center center / cover no-repeat `,
  };

  return (
    <div className=" w-[100%] h-auto relative " style={containerStyle}>
      {/* <img className="w-full h-full" src={""} alt="" /> */}

      <div className="text-center min-h-[80vh] flex flex-col justify-center items-center text-[white]  font-[500] font-for-Results-page max-w-[1440px] mx-auto">
        <p className=" uppercase text-[18px] font-[500]">{data?.titleOne}</p>
        <p
          className=" uppercase text-[72px] font-[400] template-results-heading-font "
          style={{ color: data?.mainColor }}
        >
          {data?.Heading}
        </p>

        <p className=" uppercase text-[18px] font-[500]">{data?.titletwo}</p>

        <p className="mt-6 leading-[25px] sm:leading-[32px] text-center xl:ml-[25%] sm:ml-[20%] ml-5 mr-5 sm:mr-[20%] xl:mr-[25%]    text-[16px] sm:text-[21px] font-[500]">
          {data?.para}
        </p>
      </div>
      <div className="w-full flex justify-start md:justify-center px-[20px]">
        <div
          className=" grid grid-cols-1  justify-center items-center  uppercase template-results-heading-font"
          style={{ color: data?.mainColor }}
        >
          <div className="grid  sm:flex">
            <div className="xl:leading-[65px] leading-[35px]">
              <p className="xl:text-[100px] text-[52px] sm:text-[45px] lg:text-[80px] font-[400]">
                {data?.parcitipation}{" "}
                <span className="text-[white] text-[28px] sm:text-[25px] lg:text-[30px]  xl:text-[40px] font-[400]">
                  participation
                </span>
              </p>
              <p className="text-[white] text-[28px] sm:text-[25px] lg:text-[30px] xl:text-[40px] font-[400]">
                campaign Runtime - {data?.campaignRuntime}
              </p>
            </div>

            <div className="sm:ml-20 xl:leading-[65px]  leading-[35px] sm:mt-0 mt-7">
              <p className="xl:text-[100px] text-[52px] sm:text-[45px] lg:text-[80px] font-[400] ">
                {data?.views}{" "}
                <span className="text-[white] text-[28px] sm:text-[25px] lg:text-[30px] xl:text-[40px] font-[400]">
                  Views
                </span>
              </p>
              <p className="text-[white] text-[28px] sm:text-[25px] lg:text-[30px] xl:text-[40px] font-[400]">
                Total EngageMent - {data?.totalEngagement}
              </p>
            </div>

            <div className="sm:ml-20 xl:leading-[65px]  leading-[35px] sm:mt-0 mt-7">
              <p className="xl:text-[100px] text-[52px] sm:text-[45px] lg:text-[80px] font-[400]">
                {data?.winners}{" "}
                <span className="text-[white] text-[28px] sm:text-[25px] lg:text-[30px] xl:text-[40px] font-[400]">
                  winners
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
