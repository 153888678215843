import React from 'react'

function Odd({data}) {
  return (
    <div>




<div>
 <div className=" mt-10">
        
        <div className="ml-16  mt-5 items-center flex">
          <img
            className="mr-16  shrink-0 rounded-[77px] w-[280px] h-[280px] lg:w-[400px] lg:h-[400px] object-cover"
            src={data?.image}
            alt=""
          />

          <div className="  mr-10 lg:mr-20">
            <p className="text-[34px] font-[400] "> {data?.date} </p>
            <p className="text-[35px] font-[500] lg:mt-4">{data?.event}  </p>
            <p className="text-[16px] font-[300] mt-2 lg:mt-9"> {data?.desc}  </p>
          </div>
        </div>
      </div>




    </div>
    </div>
  )
}

export default Odd