import React, { forwardRef, useState } from "react";
import MinimalModelLine from "./MinimalModelLine";

function MinimalModelGetInTouch({ data,contactref }) {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(values, "values");
  };

  return (
    <div className="max-w-[1440px] ml-auto mr-auto " ref={contactref}>
    <div className=" mt-7 modelMinimalFontFamily sm:mt-20 pb-[50px]">


      <p className="text-[25px] sm:text-[45px] font-semibold ml-4 sm:ml-16 "></p>

      <div className="modelMinimalFontFamily  sm:w-full  flex items-center  ">
        {" "}
        <p className="ml-4 sm:ml-16 text-start  text-[25px] sm:text-[45px] font-semibold">
          {" "}
          Get In Touch
        </p>
        <div className="justify-center mt-1  items-center  mx-auto w-[50%] lg:w-[65%]">
          <MinimalModelLine />
        </div>
      </div>

      <div   className="  items-start  mt-4   grid  grid-cols-1 lg:grid-cols-2  sm:mt-10 ">
<div className="justify-center items-center hidden sm:flex">
        <img className="ml-16 xl:w-[590px] object-cover  w-[400px] h-[400px] xl:h-[604px]" src={data?.image} alt=" " />
        </div>
  
          <div className="justify-center  sm:mx-0 mx-4 items-center modelMinimalAcheivementsFontFamily grid">
            <form class="w-full  sm:mx-0 sm:mt-16" onSubmit={handleSubmit}>
              <label className="text-[14px] sm:text-[16px] font-[400]">Name</label>
              <div className="flex mb-3 items-start sm:mt-2">
                <input
                  name="firstName"
                  value={values.firstName}
                  onChange={(e) => handleChange(e)}
                  class="appearance-none block w-full bg-[#D9D9D9] focus:outline-double border text-[black]  py-3 px-4 mb-3 leading-tight "
                  type="text"
                  placeholder="First Name"
                />

                <input
                  name="lastName"
                  value={values.lastName}
                  onChange={(e) => handleChange(e)}
                  class="ml-4 appearance-none block w-full focus:outline-double bg-[#D9D9D9]  border  text-[black] py-3 px-4 mb-3 leading-tight "
                  type="text"
                  placeholder="Last Name"
                />
              </div>
              <label className="text-[14px] sm:text-[16px] font-[400]">Email</label>

              <div className="flex mb-3 items-start sm:mt-2">
                <input
                  name="email"
                  value={values.email}
                  onChange={(e) => handleChange(e)}
                  class="appearance-none block w-full  focus:outline-double  bg-[#D9D9D9]  border text-[black]  py-3 px-4 mb-3 leading-tight "
                  type="email"
                  placeholder="Email"
                 
                />
              </div>
              <label className=" text-[14px] sm:text-[16px] font-[400]sm">Message</label>
              <div className="flex mb-5 items-start sm:mt-2">
                <textarea
                  name="message"
                  value={values.message}
                  onChange={(e) => handleChange(e)}
                  rows={8}
                  class="appearance-none focus:outline-double  block w-full bg-[#D9D9D9]  border text-[black]  py-3 px-4 mb-3 leading-tight "
                  type="message"
                  placeholder="Message"
                />
              </div>
              <div className="justify-end items-end flex">
                <button
                  type="submit"
                  className="bg-[#D9D9D9] p-2  pl-7 pr-7 rounded-lg "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>

          
       
      </div>
    </div>
    </div>
  );
}

export default forwardRef( MinimalModelGetInTouch);
