import React, { useState } from "react";
import styles from "./styles/CampaignAndPortfolio.module.css";
import ProfileBanner from "./ProfileBanner";

import ProfileComponent from "./ProfileComponent";
import WebCompaignAndPortfolio from "./WebCompaignAndPortfolio";
import useMediaQuery from "../../hooks/UseMediaQuery";
import { useNavigate } from "react-router-dom";
import Campaign from "./components/Campaign";
import Actor from "./components/Actor";
import Line from "./components/Line";
import { judgesBg } from "../../common/Constants";

export default function CampaignAndPortfolio({ data, theme }) {
  const isDesktop = useMediaQuery("(min-width:640px)");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentBg,setCurrentBg] = useState(0)
  const [showFull , setShowFull] = useState(false)

  const navigate = useNavigate();

  const prevCom = () => {
    const firstIndex = currentIndex === 0;
    const newIndex = firstIndex
      ? data?.judgesComments?.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
    const firstBg = currentBg === 0 
    const newBg = firstBg ? judgesBg?.length - 1 : currentBg - 1
    setCurrentBg(newBg)
  };

  const nextCom = () => {
    const lastIndex = currentIndex === data?.judgesComments?.length - 1;
    const newIndex = lastIndex ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    const lastBg  = currentBg === judgesBg?.length -1 
    const newBg = lastBg ? 0 : currentBg + 1
    setCurrentBg(newBg)
  };

  return (
    <div className="">
      <div className="max-w-[1440px] mx-auto">
        <Line />
      </div>
      <div className="max-w-[1440px] mx-auto justify-between sm:flex items-center p-5 fonts-for-Sathosi-homepage  hidden">
        <div className="justify-center ml-4 sm:ml-20 items-center flex">
          <p className="lg:text-[18px] text-[14px] xl:text-[24px] font-[700] fonts-for-Sathosi-homepage uppercase">
            {data?.mainText}
          </p>

          <p className="ml-10 font-[500] text-[10px] lg:text-[13px] xl:text-[18px] uppercase">
            {" "}
            {data?.views  && <>  {data?.views} Views</> } {" "}
            <span className="ml-3"> {data?.likes && <>  {data?.likes} Likes </>} </span>{" "}
          </p>
        </div>

        <div
          className=" sm:mr-20 items-center flex"
          onClick={() => navigate("/campaigns")}
        >
          <p className="uppercase font-[700] text-[10px] lg:text-[12px] xl:text-[16px]">
            campaigns
          </p>

          <div className="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16.172 11.0001H4V13.0001H16.172L10.808 18.3641L12.222 19.7781L20 12.0001L12.222 4.22208L10.808 5.63608L16.172 11.0001Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="  items-center p-5 fonts-for-Sathosi-homepage grid sm:hidden">
        <div className="">
          <p className=" text-[18px] font-[700] fonts-for-Sathosi-homepage uppercase">
            {data?.mainText}
          </p>
        </div>

        <div className="justify-between items-start flex text-start mt-3">
          <p className=" text-[16px] font-[600]  uppercase">
            {" "}
            {data?.views  && <>  {data?.views} Views</> } {" "}
            <span className="ml-2"> {data?.likes && <>  {data?.likes} Likes </>} </span>{" "}
          </p>

          <div
            className=" sm:mr-20 items-center flex"
            onClick={() => navigate("/campaigns")}
          >
            <p className="uppercase font-[700] text-[13px]">campaigns</p>

            <div className="ml-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.172 11.0001H4V13.0001H16.172L10.808 18.3641L12.222 19.7781L20 12.0001L12.222 4.22208L10.808 5.63608L16.172 11.0001Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1440px] mx-auto">
        <Line />
      </div>

      {isDesktop ? (
        <WebCompaignAndPortfolio data={data} theme={theme} />
      ) : (
        <>
          <div
            className=" w-[100%] pt-8  mt-6"
            style={{ backgroundColor: theme?.backGroundColor }}
          >
            <div className="ml-4 mr-4 grid">
              <div className="h-[400px]  w-[100%] relative">
                <img
                  onClick={() => navigate(`/details/campaigns/${data?.secId}`)}
                  className="w-full h-full object-cover "
                  src={data?.campaignPoster}
                />
                <div className="absolute  bottom-[10px] right-[10px] ">
                  <button
                    onClick={() =>
                      navigate(`/details/campaigns/${data?.secId}`)
                    }
                    className="p-2 rounded-full text-[10px]"
                    style={{ backgroundColor: theme?.campaignButtonBg }}
                  >
                    Open Campaign
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[100%]  flex mt-3">
            <div className=" w-[64%]">
              <img
                className="ml-4 w-full h-[260px] object-cover"
                src={data?.contestPic1}
                alt=""
              />
            </div>

            <ProfileComponent
              border={theme?.portfolioBorder}
              textColor={theme?.textColorMob}
              background={theme?.portfolioBg}
              portFolioBorder={theme?.portfolioBorder}
              portfolioButtonBg={theme?.potfollioButtonBg}
              portfollioButtonTextColor={theme?.potfollioButtonTextColor}
              profilePic={data?.profilePic}
              position={data?.position}
              name={data?.name}
              profileId={data?.portfolioId}
            />
          </div>
          {data?.judgesComments && (
            <div className="mt-[33px] fonts-for-Sathosi-homepage" onClick={()=>showFull && setShowFull(false)}>
              <div className="justify-center mt-10 items-center flex">
                <p className="text-[18px] font-[700] uppercase">
                  {" "}
                  What our Judges say{" "}
                </p>
              </div>
              <div
                className="relative mt-[28px] w-[90%] mx-auto py-5  h-[100%] justify-center items-center flex"
                style={{
                  background: judgesBg[currentBg]?.color,
                }}
              >
                <div className="flex ">
                  <div className="ml-4 shrink-0 w-[60px] my-auto h-[60px] items-center ">
                    <img
                      className="w-full h-full rounded-[50%] object-cover"
                      src={data?.judgesComments[currentIndex]?.image}
                      alt=""
                    />
                  </div>

                  <div className=" text-[#FFF]">
                    <p className=" text-[13px] ml-[18px] mr-[22px]  font-[600] ">
                      {" "}
                      {showFull ? data?.judgesComments[currentIndex]?.text :  data?.judgesComments[currentIndex]?.text?.slice(0,120)} <span onClick={()=>setShowFull(!showFull)} className="whitespace-nowrap  text-[15px] items-start font-[500] text-[#ddd9d9]"> {showFull ? "...Show less" : "...Read more "} </span>  {" "}
                    </p>
                    <p className="mt-[13px] ml-[18px] text-[13px] uppercase font-[400]">
                      {" "}
                      {data?.judgesComments[currentIndex]?.name}{" "}
                    </p>
                  </div>
                </div>

                

                <div onClick={prevCom} className=" absolute -left-2  ">
                  <div className="w-[22px] cursor-pointer h-[22px] border-[1px] border-[#6b6767] items-center bg-[#fff] rounded-[50%]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M12.2416 5.59148C12.3189 5.66858 12.3802 5.76015 12.422 5.86096C12.4638 5.96177 12.4854 6.06984 12.4854 6.17898C12.4854 6.28812 12.4638 6.39619 12.422 6.497C12.3802 6.59781 12.3189 6.68939 12.2416 6.76648L9.00832 9.99981L12.2416 13.2331C12.3975 13.389 12.485 13.6003 12.485 13.8206C12.485 14.041 12.3975 14.2523 12.2416 14.4081C12.0858 14.564 11.8745 14.6515 11.6541 14.6515C11.4338 14.6515 11.2225 14.564 11.0666 14.4081L7.24165 10.5831C7.1644 10.5061 7.10311 10.4145 7.06129 10.3137C7.01947 10.2129 6.99795 10.1048 6.99795 9.99565C6.99795 9.88651 7.01947 9.77844 7.06129 9.67763C7.10311 9.57682 7.1644 9.48524 7.24165 9.40815L11.0666 5.58315C11.3833 5.26648 11.9166 5.26648 12.2416 5.59148Z"
                        fill="#161616"
                      />
                    </svg>
                  </div>
                </div>

                <div onClick={nextCom} className="absolute -right-2  ">
                  <div className="w-[22px] cursor-pointer h-[22px] border-[1px] border-[#6b6767] items-center bg-[#fff] rounded-[50%]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.75832 5.59148C7.68107 5.66858 7.61978 5.76015 7.57796 5.86096C7.53614 5.96177 7.51462 6.06984 7.51462 6.17898C7.51462 6.28812 7.53614 6.39619 7.57796 6.497C7.61978 6.59781 7.68107 6.68939 7.75832 6.76648L10.9917 9.99981L7.75832 13.2331C7.60251 13.389 7.51497 13.6003 7.51497 13.8206C7.51497 14.041 7.60251 14.2523 7.75832 14.4081C7.91414 14.564 8.12547 14.6515 8.34582 14.6515C8.56618 14.6515 8.77751 14.564 8.93332 14.4081L12.7583 10.5831C12.8356 10.5061 12.8969 10.4145 12.9387 10.3137C12.9805 10.2129 13.002 10.1048 13.002 9.99565C13.002 9.88651 12.9805 9.77844 12.9387 9.67763C12.8969 9.57682 12.8356 9.48524 12.7583 9.40815L8.93332 5.58315C8.61665 5.26648 8.08332 5.26648 7.75832 5.59148Z"
                        fill="#161616"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
