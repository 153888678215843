import React from "react";
import { Link } from "react-router-dom";

function Campaign({
  campaignPoster,
  titleColor,
  positionColor,
  campaignLink,
  campaignName,
  campaignTitle,
}) {
  return (
    <div>
      <div className="justify-center items-center sm:flex ">
        <Link to={campaignLink} className="">
          <img
            src={campaignPoster}
            className="lg:w-[580px] lg:h-[580px] w-[100%] h-[400px] sm:w-[350px] sm:h-[350px] object-cover"
            alt=""
          />
        </Link>
      </div>
      <Link to={campaignLink} className="hover:text-black hover:no-underline">
        <div className="justify-center ml-10 mr-10 items-center fonts-for-Sathosi-homepage text-center mt-5">
          <p
            style={{ color: positionColor }}
            className="uppercase text-[16px] font-[400] sm:text-[11px] lg:text-[16px]"
          >
            Campaign
          </p>
          <p
            style={{ fontStyle: "italic" }}
            className="font-[600] text-[32px] sm:text-[20px] lg:text-[32px] homepage-campaign-name-font"
          >
            {campaignName}
          </p>
          <p
            style={{ color: titleColor }}
            className=" text-[16px] sm:text-[11px] font-[400] lg:text-[16px]"
          >
            {campaignTitle}
          </p>
        </div>
      </Link>
    </div>
  );
}

export default Campaign;
