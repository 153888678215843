import React, { useContext, useEffect, useState } from "react";
import DanceMinPortfolio from "../../Pages/Influencer/dancerTemplate/minimal/DanceMinPortfolio";
import axios from "axios";
import MinimalPortfolio from "./model/minimal/MinimalPortfolio";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CelebrityPortfolio from "./celebrity/CelebrityPortfolio";
import GymPortfolio from "./gymPortfolio/GymPortfolio";
import BandPortfolioPage from "./Band/BandPortfolioPage";

function InfluencerPortfolio() {
  const { influencerId } = useParams();

  const [influencerDetails, setInfluencerDetails] = useState();

  const influencerDetailsData = async () => {
    try {
      let config = {
        method: "get",
        // url: `/${influencerId}/data.json`,
        url: `https://storage.googleapis.com/goviral_magazine_data/portfolio/${influencerId}/data.json`,
      };
      const response = await axios(config);
      setInfluencerDetails(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    influencerDetailsData();
  }, []);

  let templateComponent;

  switch (influencerDetails?.templateId) {
    case "dance_minimal":
      templateComponent = (
        <DanceMinPortfolio
          influencerDetails={influencerDetails}
          id={influencerId}
        />
      );
      break;
    case "model_minimal":
      templateComponent = (
        <MinimalPortfolio influencerDetails={influencerDetails} />
      );
      break;
    case "celebrity":
      templateComponent = (
        <CelebrityPortfolio influencerDetails={influencerDetails} />
      );
      break;
    case "gym":
      templateComponent = (
        <GymPortfolio influencerDetails={influencerDetails} />
      );
      break;
    case "band":
      templateComponent = <BandPortfolioPage orgDetials={influencerDetails} />;
  }

  return <div>{templateComponent}</div>;
}

export default InfluencerPortfolio;
