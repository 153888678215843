import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default function ContestCardCarousel({ contestArray }) {
  console.log("contestArray", contestArray);
  const [activeIndex, setActiveIndex] = useState(0);
  const cardRefs = useRef([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const handleScroll = () => {
      const sliderWidth = slider.offsetWidth;
      const sliderLeft = slider.getBoundingClientRect().left;
      const activeSlide = Array.from(slider.children).find((slide) => {
        const slideLeft = slide.getBoundingClientRect().left;
        return slideLeft >= sliderLeft;
      });
      setActiveIndex(
        activeSlide ? Array.from(slider.children).indexOf(activeSlide) : 0
      );
    };
    slider?.addEventListener("scroll", handleScroll);
    return () => slider?.removeEventListener("scroll", handleScroll);
  }, []);
  const handleNext = () => {
    let currentIndex = activeIndex;
    let nextIndex;
    if (currentIndex + 1 == contestArray?.length) {
      nextIndex = currentIndex;
    } else {
      nextIndex = currentIndex + 1;
    }
    setActiveIndex(nextIndex);
    if (cardRefs.current[nextIndex]) {
      cardRefs.current[nextIndex].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };

  const handlePrevious = () => {
    let currentIndex = activeIndex;
    let prevIndex;
    if (currentIndex == 0) {
      prevIndex = currentIndex;
    } else {
      prevIndex = currentIndex - 1;
    }
    setActiveIndex(prevIndex);
    if (cardRefs.current[prevIndex]) {
      cardRefs.current[prevIndex].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };

  const contestLink = (obj) => {
    const { orgDomain, campaignUniqueText, contestUniqueText } = obj;
    let contestUrl = `${process.env.REACT_APP_CONTEST_LINK}/${orgDomain}/${campaignUniqueText}/${contestUniqueText}`;
    return contestUrl;
  };
  return (
    <div className="relative">
      <div
        className=" flex box  gap-[16px] overflow-y-hidden overflow-x-auto  no-scrollbar py-[20px]"
        ref={sliderRef}
      >
        {contestArray?.map((contest, index) => {
          return (
            <div
              key={contest?.contestId}
              ref={(element) => {
                cardRefs.current[index] = element;
              }}
              className={`z-[1] cursor-pointer  h-auto rounded-[16px]  sm:w-[25%] w-[45%]    flex shrink-0 flex-grow relative overflow-hidden`}
            >
              <Link to={contestLink(contest)}>
                <img className="" src={contest?.contestPoster} />
              </Link>
            </div>
          );
        })}
      </div>
      {contestArray?.length > 1 && (
        <>
          <button
            onClick={handlePrevious}
            className="absolute top-[45%] z-10 sm:left-[-25px] left-[-18px] cursor-pointer flex w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] rounded-full  items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="71"
              height="70"
              viewBox="0 0 71 70"
              fill="none"
            >
              <g filter="url(#filter0_b_3522_5904)">
                <circle
                  cx="35.6299"
                  cy="35"
                  r="35"
                  fill="#58795B"
                  fill-opacity="0.63"
                />
              </g>
              <path
                d="M40.8799 21L26.8799 35L40.8799 49"
                stroke="#1B3332"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            onClick={handleNext}
            className="absolute top-[45%] z-10  sm:right-[-25px] right-[-18px] cursor-pointer flex w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] rounded-full  items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="71"
              height="70"
              viewBox="0 0 71 70"
              fill="none"
            >
              <g filter="url(#filter0_b_3522_5907)">
                <circle
                  cx="35.6299"
                  cy="35"
                  r="35"
                  transform="rotate(-180 35.6299 35)"
                  fill="#58795B"
                  fill-opacity="0.63"
                />
              </g>
              <path
                d="M30.3799 49L44.3799 35L30.3799 21"
                stroke="#1B3332"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </>
      )}
    </div>
  );
}
