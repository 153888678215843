import React, { useContext, useEffect, useRef } from "react";
import CelebrityHeader from "./CelebrityHeader";
import CelebrityLanding from "./CelebrityLanding";
import CelebrityAchievements from "./CelebrityAchievements";
import CelebrityWorks from "./CelebrityWorks";
import Footer from "../../../Components/footer/Footer";
import CelebrityAbout from "./CelebrityAbout";
import CelebrityGetInTouch from "./CelebrityGetInTouch";
import CelebrityMajorWorks from "./CelebrityMajorWorks";
import { useLocation, useNavigate } from "react-router-dom";
import { imageData } from "../../../context/dataContext";
import { Context } from "../../../ScrollContext";
import { isEmpty } from "lodash";
import useMediaQuery from "../../../hooks/UseMediaQuery";

function CelebrityPortfolio({ influencerDetails }) {
  const {
    imagesData,
    setImagesData,
    brandsData,
    setBrandsData,
    setAchievementsData,
  } = useContext(imageData);
  const location = useLocation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:640px)");
  const { scrollingPositon, setScrollingPosition } = useContext(Context);

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (influencerDetails)
      setImagesData({
        workName: influencerDetails?.workName,
        datas: influencerDetails?.works,
      });
    if (influencerDetails?.Brands)
      setBrandsData({ datas: influencerDetails?.Brands });
    if (influencerDetails?.Achievements)
      setAchievementsData({ datas: influencerDetails?.Achievements });
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollingPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(influencerDetails)) {
      window.scrollTo(0, scrollingPositon);
    }
  }, [influencerDetails]);

  return (
    <>
      <div className="celebrityFontFamily  bg-[#141414]">
        <CelebrityHeader
          back={handleNavigateToPrevLocation}
          data={influencerDetails}
          links={influencerDetails?.socialMediaLinks}
        />
        <div className="max-w-[1440px] mx-auto ">
          <CelebrityLanding data={influencerDetails} />

          <CelebrityAbout data={influencerDetails?.about} />

          <CelebrityAchievements data={influencerDetails?.Achievements} />

          <CelebrityMajorWorks data={influencerDetails?.Brands} />

          <CelebrityWorks
            name={influencerDetails?.workName}
            data={influencerDetails?.works}
          />

          <CelebrityGetInTouch data={influencerDetails?.getInTouch} />
        </div>

        {isDesktop && <Footer />}
      </div>
    </>
  );
}

export default CelebrityPortfolio;
