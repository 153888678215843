import React, { useEffect, useState } from "react";
import WebFeaturing from "./WebFeaturing";
import useMediaQuery from "../../hooks/UseMediaQuery";
import axios from "axios";
import Line from "./components/Line";
import MoreHighlights from "./HighlightsComponents/MoreHighlights";
import { Link, useLocation } from "react-router-dom";

function Featuring({ data, theme }) {
  const location = useLocation();
  const [featuredData, setfeaturedData] = useState();
  const [filteredData,setFilteredData] = useState()
const isDesktop = useMediaQuery("(min-width:640px)")
  const getfeaturedData = async () => {
    try {
      let config = {
        method: "get",
        // url: `/featured/${data?.folderName}/${data?.secId}.json`,
       url:` https://storage.googleapis.com/goviral_magazine_data/featured/${data?.folderName}/${data?.secId}.json`
      };

      const response = await axios(config);
      setfeaturedData(response?.data?.data);
    } catch (err) {
      console.log(err, "error");
    }
  };
 
  useEffect(() => {
    getfeaturedData();
  }, []);


useEffect(()=>{
  const filterData = featuredData?.FeaturedList?.filter((item)=>item?.id !== featuredData?.id)
  setFilteredData(filterData)
},[featuredData])



  return (
    <div>
      <div className="sm:flex hidden">
        {" "}
        <WebFeaturing data={featuredData} theme={theme} />{" "}
      </div>

      <div className="max-w-[1440px] mx-auto">
        <div className="mt-12">
          <Line />
        </div>
        <div className="fonts-for-Sathosi-homepage justify-between ml-4 sm:ml-20 mt-5 text-[18px] font-[500] sm:mr-20 mr-4 uppercase items-center flex">
          <p> {featuredData?.secHeading} </p>
          <Link
            state={{ previousLocation: location?.pathname }}
            to={`/featured/${data?.folderName}/${featuredData?.secId}`}
            className=" cursor-pointer hover:text-[#161616] text-[#575757]"
          >
            {" "}
            view All{" "}
          </Link>
        </div>

        <MoreHighlights
          url={`/featured/${data?.folderName}/`}
          secId={featuredData?.secId}
          data={ isDesktop ? (filteredData ? filteredData : featuredData?.FeaturedList) : featuredData?.FeaturedList}

          theme={theme}
        />

        <div className="mt-12">
          <Line />
        </div>
      </div>
    </div>
  );
}

export default Featuring;
