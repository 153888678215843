import React, { useEffect, useRef, useState } from "react";
import FeaturedMagazine from "../Components/FeaturedMagazine/FeaturedMagazine";
import axios from "axios";
import Header from "../Components/header/Header";

import Carousel from "../Components/Poster/Carousel";
import Footer from "../Components/footer/Footer";
import { Context } from "../ScrollContext";
import { useContext } from "react";
import { isEmpty } from "lodash";
import Line from "../Components/MagazineTemplate/components/Line";
import useMediaQuery from "../hooks/UseMediaQuery";

function Homepage() {
  const [magazineData, setMagazineData] = useState({});
  const { scrollingPositon, setScrollingPosition } = useContext(Context);
  const [scroll, setScroll] = useState(false);
  const isDesktop = useMediaQuery("(min-width:640px)")
  const getMagazineData = async () => {
    try {
      let config = {
        method: "get",
        // url: `/home.json`,
        url: `https://storage.googleapis.com/goviral_magazine_data/home.json`,
      };
      let result = await axios(config);
      setMagazineData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMagazineData();
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollingPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(magazineData)) {
      window.scrollTo(0, scrollingPositon);
    }
  }, [magazineData]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const scrollBehaviour = () => {
    if (window.scrollY >= 300) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", scrollBehaviour);

  return (
    <>
      <div className="w-[100%]  min-h-[100vh] bg-[#fff] justify-center items-center ">
        {/* <div style={{transform:"translate(-50%, -50%)"}} className="border-2 border-black z-10 opacity-[0.9] fixed bg-[white] bottom-0  rounded-[50px] sm:top-[48%] top-[40%] left-[50%]  w-[70%] h-[300px] shadow-xl   ">
      <div className="justify-center items-center text-center mt-28">
      <p className="text-[19px] lg:text-[32px] ml-10 mr-10 font-semibold text-[black]">Coming Soon, please Stay tuned!</p>
     
      </div>
  </div> */}

        <Header
          bgColor={"#fff"}
          textColor={"#111"}
          hambergerBg={"#fff"}
          hambergerText={"#111"}
        />

        {scroll && (
          <div
            onClick={() => scrollToTop()}
            className="sm:flex hidden mainClass fixed p-3 z-30 hover:bg-[#161616] bg-[#FFF] rounded-[60px]  bottom-10 right-5"
            style={{ boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.16)" }}
          >
            <button className=" w-[48px] h-[48px] justify-center items-center flex   rounded-[60px]">
              <svg
                className="firstSvg"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.625 4.375V16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V4.375C9.375 4.20924 9.44085 4.05027 9.55806 3.93306C9.67527 3.81585 9.83424 3.75 10 3.75C10.1658 3.75 10.3247 3.81585 10.4419 3.93306C10.5592 4.05027 10.625 4.20924 10.625 4.375Z"
                  fill=" #161616"
                />
                <path
                  d="M10 4.63365L4.8175 9.8174C4.70014 9.93475 4.54097 10.0007 4.375 10.0007C4.20903 10.0007 4.04986 9.93475 3.9325 9.8174C3.81514 9.70004 3.74921 9.54087 3.74921 9.3749C3.74921 9.20893 3.81514 9.04975 3.9325 8.9324L9.5575 3.3074C9.61556 3.24919 9.68453 3.20301 9.76046 3.17151C9.83639 3.14 9.91779 3.12378 10 3.12378C10.0822 3.12378 10.1636 3.14 10.2395 3.17151C10.3155 3.20301 10.3844 3.24919 10.4425 3.3074L16.0675 8.9324C16.1849 9.04975 16.2508 9.20893 16.2508 9.3749C16.2508 9.54087 16.1849 9.70004 16.0675 9.8174C15.9501 9.93475 15.791 10.0007 15.625 10.0007C15.459 10.0007 15.2999 9.93475 15.1825 9.8174L10 4.63365Z"
                  fill="#161616"
                />
              </svg>

              <svg
                className="secondSvg hidden"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.625 4.375V16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V4.375C9.375 4.20924 9.44085 4.05027 9.55806 3.93306C9.67527 3.81585 9.83424 3.75 10 3.75C10.1658 3.75 10.3247 3.81585 10.4419 3.93306C10.5592 4.05027 10.625 4.20924 10.625 4.375Z"
                  fill=" #F6F6F6"
                />
                <path
                  d="M10 4.63365L4.8175 9.8174C4.70014 9.93475 4.54097 10.0007 4.375 10.0007C4.20903 10.0007 4.04986 9.93475 3.9325 9.8174C3.81514 9.70004 3.74921 9.54087 3.74921 9.3749C3.74921 9.20893 3.81514 9.04975 3.9325 8.9324L9.5575 3.3074C9.61556 3.24919 9.68453 3.20301 9.76046 3.17151C9.83639 3.14 9.91779 3.12378 10 3.12378C10.0822 3.12378 10.1636 3.14 10.2395 3.17151C10.3155 3.20301 10.3844 3.24919 10.4425 3.3074L16.0675 8.9324C16.1849 9.04975 16.2508 9.20893 16.2508 9.3749C16.2508 9.54087 16.1849 9.70004 16.0675 9.8174C15.9501 9.93475 15.791 10.0007 15.625 10.0007C15.459 10.0007 15.2999 9.93475 15.1825 9.8174L10 4.63365Z"
                  fill="#F6F6F6"
                />
              </svg>
            </button>
          </div>
        )}

        <>
          <Carousel sectionData={magazineData?.carousel} />
          <div className="mt-20">
            <Line />
            {/* <div className="justify-center mx-auto   sm:mt-[34px] mt-[20px] mb-[20px] sm:mb-[34px] items-center flex max-w-[1440px]">
              <div className="justify-center items-center flex text-center leading-[18px] sm:leading-[22px]  ">
                <p className="text-[13px] sm:text-16px uppercase text-[#161616]">
                  {" "}
                  This site is work in progress.
                </p>
              </div>
            </div>
            <Line /> */}
          </div>

          {/* <Poster sectionData={magazineData?.posters} /> */}

          <FeaturedMagazine
            data={magazineData}
            sectionData={magazineData?.sections}
          />
        </>

        {magazineData?.sections && isDesktop && <Footer />}
      </div>
    </>
  );
}

export default Homepage;
