import React from "react";
import useMediaQuery from "../../hooks/UseMediaQuery";

function BannerTemp3({ data }) {
  const isDesktop = useMediaQuery("(min-width:1025px)");

  const isMobile = useMediaQuery("(min-width:640px)");

  if (!data?.bannerImage) {
    return null;
  }

  const containerStyle = {
    background: `${
      isMobile
        ? `url(${data?.bannerImage}) center center / ${
            isDesktop ? "cover" : "contain"
          } no-repeat`
        : `url(${data?.mobImage}) center center / cover no-repeat  `
    }`,
  };
  return (
    <>
    

      <div className="w-full h-full">
        <img className="w-full h-full" src={isMobile ? data?.bannerImage: data?.mobImage} alt=""/>

      </div>

      <div className=" mt-[40px] sm:mt-[78px] fonts-for-Sathosi-resultPage justify-center items-center flex">
        <p className="text-[18px] sm:text-[28px] font-[500]  leading-[25px] sm:leading-[39.2px] text-center mx-[5%] xl:mx-[25%]">
          {data?.para}
        </p>
      </div>


      <div className={`${data?.parcitipation && "justify-center w-[100%] gap-[72px] mt-[44px] sm:mt-[62px] fonts-for-Sathosi-resultPage items-center sm:flex" }`}>
        {data?.parcitipation && (
          <div className="flex gap-[15px] sm:ml-0 ml-[20px]">
            <div>
              <p className="font-[700] text-[52px]  sm:text-[60px] xl:text-[80px] ">
                {data?.parcitipation}{" "}
              </p>
            </div>
            <div className="items-center uppercase   justify-center flex template-results-heading-font">
              <button
                style={{ background: "rgba(242, 87, 87, 0.80)" }}
                className="px-[16px] py-[10px] font-[400] text-[28px] sm:text-[30px] xl:text-[40px]  "
              >
                Participations
              </button>
            </div>
          </div>
        )}

        {data?.views && (

          <div className="flex gap-[15px] sm:ml-0 ml-[20px] sm:mt-0 mt-[20px]">
            <div>
              <p className="font-[700] text-[52px]  sm:text-[60px] xl:text-[80px] ">
                {data?.views}{" "}
              </p>
            </div>
            <div className="items-center uppercase   justify-center flex template-results-heading-font">
              <button
                style={{ background: "rgba(242, 87, 87, 0.80)" }}
                className="px-[16px] py-[10px] font-[400] text-[28px] sm:text-[30px] xl:text-[40px]  "
              >
                views{" "}
              </button>
            </div>
          </div>

     
      )}

      


      </div>

    </>
  );
}

export default BannerTemp3;
