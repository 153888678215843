import React from "react";
import useMediaQuery from "../../../../hooks/UseMediaQuery";
import { Link } from "react-router-dom";

function GiveAwayListing({ data }) {
  const isDesktop = useMediaQuery("(min-width:640px)");

  return (
    <>
      <div className="fonts-for-Sathosi-campaigns mt-[40px] mb-[30px]">
        <div>
          {!data.hide && (
            <p className="ml-4 sm:ml-10 font-[700] text-[24px] uppercase">
              {data?.name}
            </p>
          )}
        </div>
      </div>
      <div className=" flex justify-center xl:ml-28 lg:ml-5 lg:mr-10 sm:ml-10 ml-5  mr-5 sm:mr-10   xl:mr-28 ">
        <div className="grid grid-cols-2  lg:grid-cols-3 items-start gap-[20px] sm:gap-[61px]">
          {data?.data?.map((item, index) => (
            <div className="justify-center items-center flex">
              <Link className="hover:no-underline" to={item?.link}>
                <div
                  className={` ${
                    !isDesktop && index > 1 ? "mt-[32px]" : "mt-0"
                  } ${data?.secId == "expired" && "opacity-50 "}`}
                >
                  <div className="xl:w-[359px] sm:w-[300px] mx-auto h-[206px] w-[168px] sm:h-[340px] xl:h-[441px] ">
                    <img
                      className="w-full h-full object-cover"
                      src={item?.image}
                      alt=""
                    />
                  </div>
                  <div className="text-[#161616] mt-[16px]">
                    <p className="text-[15px] sm:text-[21px] font-[700] ">
                      {" "}
                      {item?.text}{" "}
                      <span className="text-[12px] sm:text-[16px] font-[500] leading-[26px]">
                        {" "}
                        {item?.spanText}{" "}
                      </span>{" "}
                    </p>
                    <p className="flex text-[13px] sm:text-[16px] font-[500] text-[#161616] mt-[16px]">
                      {" "}
                      <p className="line-through   decoration-1">
                        {item?.worth}{" "}
                      </p>
                      <span className=" ml-[13px] text-[#FF6464] text-[13px] sm:text-[16px] font-[700]">
                        {" "}
                        {item?.likes}{" "}
                      </span>
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default GiveAwayListing;
