import React, { useEffect, useState } from "react";
import Back from "../Components/back/Back";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HighlightsListingComponent from "../Components/MagazineTemplate/HighlightsComponents/HighlightsListingComponent";
import axios from "axios";
import Footer from "../Components/footer/Footer";
import LoadingCircle from "../Components/LoadingCircle/LoadingCircle"
import SelectedHighlightComponent from "../Components/MagazineTemplate/HighlightsComponents/SelectedHighlightComponent";
import useMediaQuery from "../hooks/UseMediaQuery";

function HighlightsListingPage() {
  const { secId } = useParams();
  const location = useLocation();
  const selectedId = new URLSearchParams(window.location.search).get(
    "selectedId"
  );
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [selectedHighlight, setSelectedHighlight] = useState();
  const [filteredData, setFilteredData] = useState();
  const [scrolling, setScrolling] = useState(false);
  const isDesktop = useMediaQuery("(min-width:640px)")

  const getHighlightsData = async () => {
    try {
      let config = {
        method: "get",
        // url: `/highlights/${secId}.json`,
        url: `https://storage.googleapis.com/goviral_magazine_data/highlights/${secId}.json`,
      };
      let result = await axios(config);
      setData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHighlightsData();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (selectedId && data?.sectionData?.highLights) {
      const findSelectedHighlight = data?.sectionData?.highLights?.find(
        (item) => item?.id == selectedId
      );
      setSelectedHighlight(findSelectedHighlight);
    }
  }, [selectedId, data?.sectionData?.highLights]);

  useEffect(() => {
    const filterData = data?.sectionData?.highLights?.filter(
      (item) => item?.id !== selectedHighlight?.id
    );
    setFilteredData(filterData);
  }, [data?.sectionData?.highLights, selectedHighlight]);

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  const handleScroll = () => {
    if (window.scrollY >= 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <div
        style={{ color: data?.sectionProps?.colorTheme?.textColor }}
        className="max-w-[100%] mx-auto fonts-for-Sathosi-homepage"
      >
        <div
          onClick={handleNavigateToPrevLocation}
          className={`sticky top-0 ${
            scrolling && "py-4"
          }  bg-[white] fonts-for-Sathosi-homepage  items-center   flex ml-4 xl:ml-16 sm:ml-10  mt-16`}
        >
          <div>
            <Back />
          </div>

          <p className=" font-[700] text-[16px] sm:text-[21px] ml-5 uppercase">
            {" "}
            Highlights of Goviral{" "}
          </p>
        </div>

        {data?.sectionData?.highLights ? (
          <>
            {selectedId && selectedHighlight && (
              <SelectedHighlightComponent
                theme={data?.sectionProps?.colorTheme}
                data={selectedHighlight}
              />
            )}

            <HighlightsListingComponent
              theme={data?.sectionProps?.colorTheme}
              data={filteredData ? filteredData : data?.sectionData?.highLights}
              secId={secId}
            />
          </>
        ) : (
          <div className="w-[10%] mx-auto mt-20">
          <LoadingCircle/>
          </div>
        )}
      </div>
      {data?.sectionData?.highLights && isDesktop && <Footer />}
    </div>
  );
}

export default HighlightsListingPage;
