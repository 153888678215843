import React, { useEffect, useState } from "react";
import Line from "./components/Line";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

function Influencers({ data, theme }) {
  const [viewAll, setViewAll] = useState(false);
  const location = useLocation()
  
  const previousLocation = location.state?.previousLocation;

  const [influencerData, setInfluencerData] = useState();

  const getInfluencerData = async () => {
    try {
      let config = {
        method: "get",
        // url: `/influencers/${data?.secId}.json`,
        url:` https://storage.googleapis.com/goviral_magazine_data/influencers/${data?.secId}.json`
      };
      let result = await axios(config);
      setInfluencerData(result?.data?.sectionData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfluencerData();
  }, []);
  useEffect(() => {
    if (previousLocation) {
      // Restore scroll position to the saved position
      window.scrollTo(0, previousLocation.scrollY);
    }
  }, [previousLocation]);

  const toggleViewAll = () => {
    setViewAll(!viewAll);
  };

  const numItemsToShow = viewAll ? influencerData?.influencers?.length : 4;
  const itemsToDisplay = influencerData?.influencers?.slice(0, numItemsToShow);

  return (
    <>
    <div style={{ backgroundColor: theme?.backGroundColor }} className="">
    <div
      className="max-w-[1440px] pb-10  mx-auto"
      
    >
      <div className="sm:p-7 pt-6 pb-6 fonts-for-Sathosi-homepage justify-between ml-4 sm:ml-16 mt-5  text-[18px]  lg:text-[24px] font-[500] sm:mr-10 mr-4 uppercase items-center flex">
        <p> Influencers </p>
        <Link className="hover:underline hover:text-black" state= {{ previousLocation: location?.pathname }}  to={`/goviral/influencers/${data?.secId}`}><p  className="hover:text-[#161616] text-[#575757]  cursor-pointer">
          {" "}
          view All{" "}
        </p>
        </Link> 
      </div>
      <Line />

      <div className="fonts-for-Sathosi-homepage grid xl:gap-20 lg:gap-10 grid-cols-1 sm:grid-cols-2 items-center justify-center  lg:grid-cols-4 mt-7 sm:ml-20 mr-4 ml-4 sm:mr-20">
        {itemsToDisplay?.map((item) => (
          <Link className="hover:text-black hover:no-underline" state= {{ previousLocation: location?.pathname }} to={item?.link && item?.link}>
          <div
            className="lg:mt-0 mt-3  items-center flex"
            style={{ color: theme?.textColor }}
          >
            <div className="xl:w-[120px] shrink-0  w-[90px] h-[90px] xl:h-[120px]">
              <img
                src={item?.image}
                className="h-full w-full  object-cover"
                alt=""
              />
            </div>
            <div className="ml-3 grid items-center mt-2 mb-2">
              <p className="uppercase text-[16px] sm:text-[11px] xl:text-[16px] font-[400] ">
                {item?.position}
              </p>
              <p
                style={{ fontStyle: "italic" }}
                className=" homepage-campaign-name-font xl:text-[24px] text-[24px]  sm:text-[15px] font-[600] xl:mt-2 leading-[27px] "
              >
                {item?.name}
              </p>
              <Link    state= {{ previousLocation: location?.pathname }} to={item?.link && item?.link} className="hover:underline hover:text-black xl:text-[16px] text-[16px] sm:text-[15px] xl:mt-2 font-[400] leading-[27px]">
                View Portfolio
              </Link>
            </div>
          </div>
          </Link>
        ))}
      </div>

     
    </div>
    
   
    </div>
    <div className="mt-[60px] mx-auto max-w-[1440px]">
    <Line/>
    </div>
    </>
  );
}

export default Influencers;
