import React, { useContext, useEffect, useState } from "react";
import Footer from "../Components/footer/Footer";
import Back from "../Components/back/Back";
import { useLocation, useNavigate } from "react-router-dom";
import ResultListingComponent from "../Components/MagazineTemplate/components/resultListing/ResultListingComponent";
import axios from "axios";
import LoadingCircle from "../Components/LoadingCircle/LoadingCircle";
import Context from "../context/Context";
import useMediaQuery from "../hooks/UseMediaQuery";

function ResultListingPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [resultList, setResultListing] = useState();
  const [scrolling, setScrolling] = useState(false);
  const { isMobile } = useContext(Context);  const isDesktop = useMediaQuery("(min-width:640px)");

  const getResultsList = async () => {
    try {
      let config = {
        method: "get",
        // url: `/resultListing/data.json`,
        url: `https://storage.googleapis.com/goviral_magazine_data/resultListing/data.json`,
      };
      const response = await axios(config);

      setResultListing(response?.data?.data);
    } catch (err) {
      console.log(err, "error");
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    getResultsList();
  }, []);

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  const handleScroll = () => {
    if (window.scrollY >= 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <div className="max-w-[100%] min-h-[80vh] mx-auto fonts-for-Sathosi-homepage">
        <div
          onClick={handleNavigateToPrevLocation}
          className={`fonts-for-Sathosi-homepage sticky top-0 ${
            scrolling && "py-4"
          } bg-[white]   items-center   flex ml-4  xl:ml-16 sm:ml-10  mt-12`}
        >
          <div className="">{!isMobile && <Back />}</div>

          <p className=" font-[700] text-[16px] sm:text-[21px] ml-5 uppercase">
            {" "}
            Results
          </p>
        </div>
        {resultList?.resultsList ? (
          <ResultListingComponent data={resultList?.resultsList} />
        ) : (
          <div className=" mx-auto mt-20">
            <LoadingCircle />
          </div>
        )}
      </div>
      {resultList?.resultsList && isDesktop && <Footer />}
    </div>
  );
}

export default ResultListingPage;
