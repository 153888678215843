import React from "react";

function GymAssociated({ data }) {
  return (
    <div className="w-[100%] mt-[74px] sm:mt-[120px] fontFamilyForGym">
      <div className="max-w-[1440px] mx-auto">
        <div className="text-[#f6f6f6] text-[32px]  w-full sm:w-[50%]">
            <div className="ml-4 sm:ml-24 mr-4 sm:mr-0">
          <p className="font-[600] "> {data?.heading} </p>

          <p className="font-[600] text-[#787878] text-[18px] mt-[16px]">
            {" "}
            {data?.para}{" "}
          </p>
          </div>
        </div>

        <div className="sm:ml-24 ml-4 mr-4 sm:mr-24 justify-start items-start grid grid-flow-col mt-[42px]   [&::-webkit-scrollbar]:hidden overflow-x-auto ">
{data?.datas?.map((item)=>(
    <div className="w-[180px] h-[194px] ">
        <img className="w-full h-full object-cover" src={item?.image} alt=""/>
        </div>
))}

        </div>
      </div>
    </div>
  );
}

export default GymAssociated;
