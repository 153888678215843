import React from 'react'
import GalleryImages from "./GalleryImages"

function Gallery({data,details}) {
  return (
    <>
    <div className={`${details?.latestVideos && "mt-10"} md:ml-10 md:mr-10 ml-4 mr-4`}>
<h1 className='text-[26px] sm:text-[33px] font-[600]'> {data?.name}</h1>

<div className=''>

</div>








    </div>

    <div className='grid gap-3 sm:gap-6 items-start justify-start   grid-cols-2 md:grid-cols-4'>

<GalleryImages  bgColor={"#fff"}
       
          textColor={"#111"} data={data?.images} colNo={4} name={data?.name}/>


 


</div>

    </>
  )
}

export default Gallery