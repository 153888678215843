import React from "react";
import { Link, useLocation } from "react-router-dom";

function JudgesPanelTemp2({ data }) {
  const location = useLocation()
  return (
    <div className="w-[100%] mt-[60px] sm:mt-[108px] fonts-for-Sathosi-homepage">
      <div className="justify-center items-center flex">
        <p className="text-[32px] font-[700] "> {data?.heading} </p>
      </div>
      <div
        className={`justify-center    items-center mt-[40px] rounded-[12px]  ${
          data?.judges?.length > 1 && "sm:grid-cols-2 lg:grid-cols-3"
        }  grid `}
      >
        {data?.judges?.map((item) => (
          <div className="justify-center items-center shadow pb-[16px] grid">
            <div className="w-[316px] h-[210px] mx-auto ">
              <img
                className="w-full h-full object-cover rounded-t-[12px]"
                src={item?.image}
                alt=""
              />
            </div>
            <div className="w-[316px]  mx-auto text-center ">
              <p className="text-[16px] font-[400] uppercase mt-[22px]">
                {" "}
                {item?.position}{" "}
              </p>
              <p className="text-[24px] font-[700] "> {item?.name} </p>
              <p className="text-[16px] font-[400]  leading-[20px] mt-4">
                {" "}
                {item?.about}{" "}
              </p>
              <p className="mt-[16px]">
                <Link to={item?.link} state={{ previousLocation: location?.pathname }} className="text-[16px] font-[400]  hover:underline hover:text-[#161616] ">
                  {" "}
                  View profile{" "}
                </Link>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default JudgesPanelTemp2;
