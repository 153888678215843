import { Route, Routes } from "react-router-dom";
import "./App.css";
import Homepage from "./Pages/Homepage";
import CampaignPage from "./Pages/CampaignPage";
import InfluencerPortfolio from "./Pages/Influencer/InfluencerPortfolio";
import BioPage from "./Pages/Influencer/dancerTemplate/minimal/BioPage";
import FeaturingPage from "./Pages/FeaturingPage";
import ResultPage from "./Pages/ResultPage";
import DetailsPage from "./Pages/DetailsPage";
import CampaignsListingPage from "./Pages/CampaignsListingPage";
import HighlightsListingPage from "./Pages/HighlightsListingPage";
import PortfolioListingPage from "./Pages/PortfolioListingPage";
import ResultListingPage from "./Pages/ResultListingPage";
import GiveawayPage from "./Pages/GiveawayPage";
import PortfolioWorking from "./Pages/PortfolioWorking";
import HowItWorks from "./Pages/HowItWorks";
import JudgesVideosListingPage from "./Pages/JudgesVideosListingPage";
import InstallPage from "./Pages/InstallPage";
import InstallButton from "./Components/InstallButton/InstallButton";
import { ContextProvider } from "./context/Context";
import Layout from "./Layout/Layout";
import GalleryViewPage from "./Pages/GalleryViewPage";
import WorksViewPage from "./Pages/WorksViewPage";
import AchievementsView from "./Pages/AchievementsView";
import PagesListingPage from "./Pages/PagesListingPage";
import Contact from "./Pages/Contact/Contact";

function App() {
  return (
    <div className="App">
      <ContextProvider>
        {/* <InstallButton /> */}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/featured/:folderName/:secId"
              element={<FeaturingPage />}
            />
            <Route
              path="/goviral/highlights/:secId"
              element={<HighlightsListingPage />}
            />
            <Route
              path="/goviral/influencers/:secId"
              element={<PortfolioListingPage />}
            />
            <Route path="/pages" element={<PagesListingPage />} />
            <Route path="/howitworks" element={<HowItWorks />} />
            <Route path="/install" element={<InstallPage />} />
            <Route
              path="/goviral/influencers/:secId/:id"
              element={<PortfolioWorking />}
            />
            <Route path="/results" element={<ResultListingPage />} />
            <Route path="/details/campaigns/:secId" element={<DetailsPage />} />
            <Route path="/giveaways" element={<GiveawayPage />} />
            <Route
              path="/judgesSayings"
              element={<JudgesVideosListingPage />}
            />

            <Route
              path="/:organizationUniqueText/:campaignUniqueText"
              element={<CampaignPage />}
            />
            <Route path="/:influencerId" element={<InfluencerPortfolio />} />

            <Route path="/:influencerId/bio" element={<BioPage />} />

            <Route
              path="/results/:campaignUniqueText"
              element={<ResultPage />}
            />
            <Route path="/campaigns" element={<CampaignsListingPage />} />
          </Route>
          <Route
            path="/:influencerId/galleryview/:index"
            element={<GalleryViewPage />}
          />
          <Route
            path="/:influencerId/workview/:index"
            element={<WorksViewPage />}
          />
          <Route
            path="/:influencerId/Achievementsview/:index"
            element={<AchievementsView />}
          />
        </Routes>
      </ContextProvider>
    </div>
  );
}

export default App;
