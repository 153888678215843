import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContestCarousel from "../Components/ContestCarousel/ContestCarousel";
import "./Styles/template.css";
import Dignitaries from "../Components/Dignitaries/Dignitaries";
import EsteemedJudges from "../Components/EsteemedJudges/EsteemedJudges";
import Judges from "../Components/ContestCardCarousel/Judges/Judges";
import CampaignHeader from "../Components/CampaignHeader/CampaignHeader";
import Landing from "../Components/Landing/Landing";
import Prizes from "../Components/Prices/Prizes"
import GetINTouch from "../Components/GetINTouch/GetINTouch";

export default function CampaignPage() {
  const { organizationUniqueText, campaignUniqueText } = useParams();
  const [campaignData, setCampaignData] = useState({});

  useEffect(() => {
    getCampaignDetails();
  }, []);
  const getCampaignDetails = async () => {
    try {
      let config = {
        method: "get",
        url: `/${organizationUniqueText}/${campaignUniqueText}/campaign.json`,
      };
      let result = await axios(config);

      setCampaignData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="template-container  relative pb-[50px]">
  <div className='absolute top-0 left-0 '>
<img className=' md:w-[60%] sm:w-[40%] w-[25%]  h-[auto]' src={campaignData?.landing?.background1} alt='' />

</div>
<CampaignHeader/>
<Landing campaignData={campaignData?.landing}/>
      <ContestCarousel campaignData={campaignData} />
      <Dignitaries campaignData={campaignData} />
      <EsteemedJudges campaignData={campaignData} />
      <Judges campaignData={campaignData} />
      <Prizes campaignData={campaignData?.prizes} />
      <GetINTouch/>
    </div>
  );
}
