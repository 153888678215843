import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Components/header/Header";
import Campaign from "../Components/MagazineTemplate/components/Campaign";
import Actor from "../Components/MagazineTemplate/components/Actor";
import Contest1 from "../Components/MagazineTemplate/components/Contest1";
import Contest2 from "../Components/MagazineTemplate/components/Contest2";
import Back from "../Components/back/Back";

function DetailsPage() {
  const { secId } = useParams();

  const [campaignDetails, setCampaignDetails] = useState();
  const [magazineData, setMagazineData] = useState();
  const navigate = useNavigate();
  const getMagazineData = async () => {
    try {
      let config = {
        method: "get",
        // url: `/home.json`,
        url: `https://storage.googleapis.com/goviral_magazine_data/home.json`,
      };
      let result = await axios(config);
      setMagazineData(result?.data?.sections);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getMagazineData();
  }, []);

  useEffect(() => {
    if (magazineData && secId) {
      const item = magazineData?.find(
        (item) => item?.sectionData?.secId === secId
      );
      setCampaignDetails(item);
    }
  }, [magazineData && secId]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div onClick={() => navigate("/")} className="ml-4 sm:ml-16 mt-10">
        <Back />
      </div>

      <div
        className=" w-[100%] pt-10 pb-5   mt-6"
        style={{
          backgroundColor:
            campaignDetails?.sectionProps?.colorTheme?.backGroundColor,
        }}
      >
        <div className="ml-4 mr-4 mb-5">
          <Campaign
            campaignPoster={campaignDetails?.sectionData?.campaignPoster}
            campaignLink={campaignDetails?.sectionData?.campaignLink1}
            titleColor={
              campaignDetails?.sectionProps?.colorTheme?.titleTextColor
            }
            campaignName={campaignDetails?.sectionData?.campaignName}
            positionColor={
              campaignDetails?.sectionProps?.colorTheme?.namePositonTextColor
            }
            campaignTitle={campaignDetails?.sectionData?.campaignTitle}
          />
        </div>

        <div className="ml-4 mr-4 mb-5">
          <Actor
            profileId={campaignDetails?.sectionData?.portfolioId}
            profilePic={campaignDetails?.sectionData?.profilePic}
            titleColor={
              campaignDetails?.sectionProps?.colorTheme?.titleTextColor
            }
            positionColor={
              campaignDetails?.sectionProps?.colorTheme?.namePositonTextColor
            }
            name={campaignDetails?.sectionData?.name}
            title={campaignDetails?.sectionData?.title}
            position={campaignDetails?.sectionData?.position}
          />
        </div>

        <div className="ml-4 mr-4 mb-5">
          <Contest1
            contestId={campaignDetails?.sectionData?.contestId1}
            contestAplicantName={
              campaignDetails?.sectionData?.contestAplicantName1
            }
            titleColor={
              campaignDetails?.sectionProps?.colorTheme?.titleTextColor
            }
            positionColor={
              campaignDetails?.sectionProps?.colorTheme?.namePositonTextColor
            }
            contestImage={campaignDetails?.sectionData?.contestPic1}
            contestTitle={campaignDetails?.sectionData?.contestTitle1}
            contestName={campaignDetails?.sectionData?.contestName1}
          />
        </div>

        <div className="ml-4 mr-4 mb-5">
          <Contest1
            contestId={campaignDetails?.sectionData?.contestId2}
            contestAplicantName={
              campaignDetails?.sectionData?.contestAplicantName2
            }
            titleColor={
              campaignDetails?.sectionProps?.colorTheme?.titleTextColor
            }
            positionColor={
              campaignDetails?.sectionProps?.colorTheme?.namePositonTextColor
            }
            contestImage={campaignDetails?.sectionData?.contestPic2}
            contestTitle={campaignDetails?.sectionData?.contestTitle2}
            contestName={campaignDetails?.sectionData?.contestName2}
          />
        </div>

        <div className="ml-4 mr-4 mb-5">
          <Contest2
            contestId={campaignDetails?.sectionData?.contestId3}
            contestImage={campaignDetails?.sectionData?.contestPic3}
            contestAplicantName={
              campaignDetails?.sectionData?.contestApplicantName3
            }
            positionColor={
              campaignDetails?.sectionProps?.colorTheme?.namePositonTextColor
            }
          />
        </div>

        <div className="ml-4 mr-4 mb-5">
          <Contest2
            contestId={campaignDetails?.sectionData?.contestId4}
            contestImage={campaignDetails?.sectionData?.contestPic4}
            contestAplicantName={
              campaignDetails?.sectionData?.contestApplicantName4
            }
            positionColor={
              campaignDetails?.sectionProps?.colorTheme?.namePositonTextColor
            }
          />
        </div>
      </div>
    </div>
  );
}

export default DetailsPage;
