import React, { useContext, useEffect, useRef, useState } from "react";
import CelebrityModelPopup from "../../../Components/celebrityModelPopup/CelebrityModelPopup";
import useMediaQuery from "../../../hooks/UseMediaQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { imageData } from "../../../context/dataContext";

const CarouselDot = ({ active, onClick, id }) => {
  const dotClass = `${active ? "#FFF" : "#757575"}`;

  return (
    <div onClick={onClick} id={id}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        viewBox="0 0 18 18"
        fill="none"
      >
        <circle cx="9" cy="9" r="9" fill={dotClass} />
      </svg>
    </div>
  );
};

function CelebrityMajorWorks({ data }) {
  const isDesktop = useMediaQuery("(min-width:640px)");
  const [open, setOpen] = useState(false);
  const { brandStyles,setBrandStyles } = useContext(imageData);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const cardRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const sliderRef = useRef(null);

  const handleSelect = (item) => {
    // setOpen(true);
    // setSelectedBrand(item);
    navigate(`workview/${item}`, {
      state: { previousLocation: location?.pathname },
    });
  };

  useEffect(() => {
    if(data){
      setBrandStyles({
      mainBgColor:"#141414",
      subBgColor: "#2C2C2C80",
      textColor: "#FFF",
      fontFamily: "celebrityFontFamily",
      fontFamilyText: "celebrityFontFamilyLight",
    });
  }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
    setSelectedBrand(null);
  };

  const prevBrand = () => {
    if (selectedBrand > 0) {
      setSelectedBrand(selectedBrand - 1);
    }
  };

  const nextBrand = () => {
    if (selectedBrand < data?.datas?.length - 1) {
      setSelectedBrand(selectedBrand + 1);
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;
    const handleScroll = () => {
      const sliderWidth = slider.offsetWidth;
      const sliderLeft = slider.getBoundingClientRect().left;
      const activeSlide = Array.from(slider.children).find((slide) => {
        const slideLeft = slide.getBoundingClientRect().left;
        return slideLeft >= sliderLeft;
      });
      console.log(activeSlide, "sloede");
      console.log(slider, "sl");
      setActiveIndex(
        activeSlide ? Array.from(slider.children).indexOf(activeSlide) : 0
      );
      console.log(Array.from(slider.children).indexOf(activeSlide), "array");
    };
    slider?.addEventListener("scroll", handleScroll);
    return () => slider?.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNext = () => {
    let currentIndex = activeIndex;
    let nextIndex;
    if (currentIndex + 1 == data?.datas?.length) {
      nextIndex = currentIndex;
    } else {
      nextIndex = currentIndex + 2;
    }
    setActiveIndex(nextIndex);
    if (cardRefs.current[nextIndex]) {
      cardRefs.current[nextIndex].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };

  const handlePrevious = () => {
    let currentIndex = activeIndex;
    let prevIndex;
    if (currentIndex == 0) {
      prevIndex = currentIndex;
    } else {
      prevIndex = currentIndex - 1;
    }
    setActiveIndex(prevIndex);
    if (cardRefs.current[prevIndex]) {
      cardRefs.current[prevIndex].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };

  const handleSlideChange = (index) => {
    setActiveIndex(index);
    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };

  const dots = (datas) =>
    datas.map((_, index) => (
      <CarouselDot
        key={index}
        active={index === activeIndex}
        id={`dot-${index}`}
        onClick={() => handleSlideChange(index)}
      />
    ));

  return (
    <>
      {data && (
        <div className="text-[white] relative max-w-[1440px] mt-5 sm:mt-16 h-auto  ml-auto mr-auto">
          <div className="  sm:w-full  flex items-center  ">
            {" "}
            <p className="ml-4 sm:ml-16 text-start whitespace-nowrap text-[18px] sm:text-[45px] ">
              {" "}
              {data?.name}
            </p>
            <div className="sm:flex hidden justify-center items-center  mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="4"
                viewBox="0 0 1063 4"
                fill="none"
              >
                <path
                  d="M2 2L1061 2"
                  stroke="#464646"
                  stroke-opacity="0.9"
                  stroke-width="2.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="sm:hidden flex justify-center items-center  mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="90%"
                height="2"
                viewBox="0 0 257 2"
                fill="none"
              >
                <path
                  d="M1 1L256 1"
                  stroke="#464646"
                  stroke-opacity="0.9"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>

          <div
            ref={sliderRef}
            className={`  template-model-scroll   ml-4 sm:ml-16  mt-5 sm:mt-10 `}
          >
            {data?.datas?.map((item, index) => (
              <>
                {/* {open ? (
                  <CelebrityModelPopup
                    selectedBrand={selectedBrand}
                    datas={data?.datas}
                    name={data?.name}
                    mainBgColor={"#141414"}
                    subBgColor={"#2C2C2C80"}
                    prev={prevBrand}
                    next={nextBrand}
                    textColor={"#FFF"}
                    fontFamily={"celebrityFontFamily"}
                    fontFamilyText={"celebrityFontFamilyLight"}
                    onClose={handleClose}
                  />
                ) : ( */}
                  <>
                    <div
                      ref={(element) => {
                        cardRefs.current[index] = element;
                      }}
                      onClick={() => handleSelect(index)}
                      className="mb-10 "
                    >
                      <div className=" text-[white] rounded-2xl pb-5 sm:pb-10 shadow-model-right-bottom items-start bg-[#1B1B1B]  flex flex-col  h-[100%]  w-[211px]   sm:w-[250px] lg:w-[330px]  xl:w-[430px] ">
                        <div className="xl:w-[289px] shrink-0 w-[105px] h-[111px] sm:w-[200px] sm:h-[250px] items-start xl:h-[306px] mt-6 sm:mt-10 mx-auto">
                          <img
                            className="w-full h-full object-contain"
                            src={item.logo}
                            alt=""
                          />
                        </div>
                        <div className="grid sm:leading-[30px] leading-[16px]  items-start  text-center">
                          <p className="mt-5 lg:mr-14 ml-5 mr-5 text-[13px] sm:text-[16px] lg:ml-14">
                            {isDesktop
                              ? item?.para?.slice(0, 250)
                              : item?.para?.slice(0, 70)}
                            ...{" "}
                            <span className="whitespace-nowrap cursor-pointer font-[600] text-[14px] sm:text-[18px]  items-center ">
                              {" "}
                              Read More{" > "}
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                {/* )} */}
              </>
            ))}
          </div>

          {data?.datas?.length > 3 && (
            <>
              <div className="sm:flex hidden absolute top-[50%] right-0">
                <svg
                  onClick={handleNext}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <g filter="url(#filter0_b_4984_1163)">
                    <circle
                      cx="15"
                      cy="15"
                      r="15"
                      transform="matrix(-1 0 0 1 30 0)"
                      fill="#676767"
                      fill-opacity="0.7"
                    />
                  </g>
                  <path
                    d="M12.75 9L18.75 15L12.75 21"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_b_4984_1163"
                      x="-24"
                      y="-24"
                      width="78"
                      height="78"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="12"
                      />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_4984_1163"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_4984_1163"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div className="sm:flex hidden absolute top-[50%] left-0">
                <svg
                  onClick={handlePrevious}
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <g filter="url(#filter0_b_4984_1160)">
                    <circle
                      cx="15"
                      cy="15"
                      r="15"
                      fill="#676767"
                      fill-opacity="0.7"
                    />
                  </g>
                  <path
                    d="M17.25 9L11.25 15L17.25 21"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_b_4984_1160"
                      x="-24"
                      y="-24"
                      width="78"
                      height="78"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="12"
                      />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_4984_1160"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_4984_1160"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </>
          )}

          {data?.datas?.length > 1 && (
            <div className="sm:hidden  w-[100%] flex items-center justify-center gap-[6px] relative z-1 h-fit">
              {dots(data?.datas)}
            </div>
          )}
          {/* <div className="flex items-center gap-5 justify-center"> 
      {data?.datas?.map((item)=>(
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <circle cx="9" cy="9" r="9" fill="black"/>
              </svg>
              ))}
              </div> */}
        </div>
      )}
    </>
  );
}

export default CelebrityMajorWorks;
