import React from "react";
import { Link, useLocation } from "react-router-dom";
import PortfolioWorking from "../../../../Pages/PortfolioWorking";

function InfluencerListingComponent({ data, secId,theme }) {
  const location = useLocation();
  
  return (
    <>
    <div className="max-w-[1440px] mx-auto">
      <div className="sm:grid-cols-2   xl:grid-cols-4 lg:grid-cols-3 items-start mr-20 gap-10  ml-20  hidden sm:grid">
        {data?.map((item) => (
          
          <Link className="hover:text-black hover:no-underline"
            state={{ previousLocation: location?.pathname }}
            to={item?.link ? item?.link : `/goviral/influencers/${secId}/${item?.id}` }
          >
            <div className=" mt-14 justify-start items-start grid">
              <div className="relative justify-start items-start grid">
                <div className="lg:w-[250px] lg:h-[330px] w-[300px] h-[392px] xl:w-[300px] xl:h-[392px]">
                  <img
                    className="w-full h-full object-cover"
                    src={item?.image}
                    alt=""
                  />
                </div>
                <div
                  style={{ background: "rgba(255, 255, 255, 0.98)" }}
                  className="p-3 border-none absolute left-3 right-3 bottom-0 text-center  h-20"
                >
                  <p className="text-[16px] font-[400] uppercase  leading-[22px]">
                    {" "}
                    {item?.position}
                  </p>
                  <p className="homepage-campaign-name-font text-[24px] font-[700]">
                    {" "}
                    {item?.name}{" "}
                  </p>
                  <div className="text-center text-[16px] font-[400] mt-5 leading-[20px]">
                  {item?.link &&  <Link className="hover:text-black hover:underline"
                      state={{ previousLocation: location?.pathname }}
                      to={item?.link && item?.link}
                    >
                      View Portfolio
                    </Link>
}
                  </div>
                </div>
              </div>
            </div>
           
          </Link>
          
        ))}
      </div>

      {/* mob */}

      <div className="fonts-for-Sathosi-homepage grid   grid-cols-1 sm:hidden items-center justify-center  mt-7  mr-4 ml-4 ">
        {data?.map((item) => (
          <Link className="hover:text-black hover:no-underline"
            state={{ previousLocation: location?.pathname }}
            to={item?.link ? item?.link : `/goviral/influencers/${secId}/${item?.id}` }
          >
            <div className="lg:mt-0 mt-5 items-center flex">
              <div className="w-[120px] h-[120px] shrink-0">
                <img
                  src={item?.image}
                  className="w-full h-full object-cover "
                  alt=""
                />
              </div>
              <div className="ml-3 grid items-center mt-2 mb-2">
                <p className="uppercase text-[16px]  leading-[22px] font-[400] ">
                  {item?.position}
                </p>
                <p
                  style={{ fontStyle: "italic" }}
                  className=" homepage-campaign-name-font  text-[24px]   font-[600]   "
                >
                  {item?.name}
                </p>
                {item?.link &&     <Link
                  state={{ previousLocation: location?.pathname }}
                  to={item?.link && item?.link}
                  className="mt-3 text-[16px] hover:text-black hover:no-underline  font-[400] leading-[20px]"
                >
                  View Portfolio
                </Link>
}
              </div>
            </div>
          </Link>
        ))}
      </div>




    </div>



    </>
  );
}

export default InfluencerListingComponent;
