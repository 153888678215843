import React from 'react'
import useMediaQuery from '../../../hooks/UseMediaQuery'

function CelebrityLanding({data}) {
const isDesktop = useMediaQuery("(min-width:640px)")

  return (
  
    <div className=' relative z-10 mt-8 sm:mt-10 w-full' >
    <div style={{backgroundImage: `url(${data?.bgImage}) ` , backgroundRepeat:"no-repeat" ,backgroundSize:"cover"  }} className='w-full h-[448px] sm:h-[900px] lg:h-[1200px] xl:h-[1755px]'>
  
    </div>
{/* <div className='absolute  top-32 sm:top-[5rem] xl:top-[27rem] right-5 w-[181px] sm:w-[400px]'>
<p className=' text-[12px] sm:text-[20px] lg:text-[34px] font-[400] text-[#EEE]'> {data?.bgText} </p>
</div> */}

    <div className='absolute sm:left-0 left-7 grid top-[12rem] sm:top-[15rem] lg:top-[19rem] xl:top-[45rem] grid-cols-2'>
        <div className='flex ml-1 sm:ml-10 mr-10'>
            <div className='sm:w-[500px] w-[400px] h-[220px]   sm:h-[650px] xl:w-[650px]  z-10 xl:h-[950px]'>
<img className='w-full h-full object-cover rounded-[20px] sm:rounded-[77px] ' src={data?.profileImage} alt=''/>
</div>




        </div>

        <div className='mt-0 xl:mt-24 absolute  right-7'>
        <div className=' xl:w-[750px] xl:h-[769px] sm:w-[600px] sm:h-[600px]    '>
    <img className='w-full h-full object-cover' src={isDesktop ? data?.shapeImage1 : data?.shapeImage2} alt=''/>
    </div>
    <div className='font-[300] absolute z-10 top-[4rem] left-14 sm:left-[15rem]  lg:left-32 sm:top-[12rem]  lg:top-[15rem] xl:top-[19rem]  text-[12px] sm:text-[20px] xl:text-[34px]'>
<p className='mr-[30%]'> {data?.bgText} </p>
{/* <p> {data?.measurements?.height} </p>
<p> {data?.measurements?.waist} </p>
<p> {data?.measurements?.shoeSize} </p>
<p> {data?.measurements?.eyeColor} </p>
<p> {data?.measurements?.hairColor} </p> */}

    </div>
        </div>



    </div>

{/* <div className='absolute  w-[485px] top-10 lg:top-80 right-5'>
<p className='text-[28px] lg:text-[34px]  text-[#EEE] celebrityFontFamily font-light'> {data?.bgText }</p>
</div>

<div className='absolute top-[20rem] lg:top-[37rem] right-5 lg:right-16 w-[450px] h-[450px] lg:w-[500px] lg:h-[500px] xl:w-[802px] xl:h-[769px]' >

<img className='w-full h-full object-cover' src={data?.shapeImage} alt=''/>

</div>
<div className='absolute top-[20rem] lg:top-[35rem] xl:top-[32rem]  left-10 lg:left-20 w-[350px] h-[450px]  lg:w-[400px] lg:h-[500px] xl:w-[600px] xl:h-[950px]'>
<img className='w-full h-full object-cover rounded-[77px]' src={data?.profileImage} alt=''/>
</div>

<div className='absolute top-[25rem] lg:top-[50rem] right-40 lg:right-60 xl:right-96'>
<p className='text-[24px] lg:text-[34px] font-bold'> Measurements  </p>
</div> */}


    </div>
  
  )
}

export default CelebrityLanding