import React, { useState } from "react";
import ReactPlayer from "react-player";
import UseMediaQuery from "../../../../hooks/UseMediaQuery";
import Back from "../../../../Components/back/Back";

function LatestVideos({ data, styles }) {
  const isDesktop = UseMediaQuery("(min-width:1000px)");
  const [open, setOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState();

  const handleClick = (id) => {
    setSelectedVideo(id);
    setOpen(true);
  };

  return (
    <div>
      <div className="md:ml-10 ml-4 mt-10 ">
        <h1 className="text-[26px] sm:text-[33px] font-[600]">{data?.name}</h1>
        <p className="text-[16px] mt-2 sm:text-[18px] font-[400]">
          {data?.text}
        </p>
      </div>

      <div className={`${styles.mediaScroll}  mt-10`}>
        {data &&
          data?.datas?.map((item) => (
            <div class="md:ml-10 ml-4 w-[100%]  flex items-center   ">
              {open ? (
                <>
                  <div
                    style={{ transform: "translate(-50%, -50%)" }}
                    className="h-[100%]  w-[100%] max-w-[1440px] z-10 opacity-[0.9px] fixed top-[50%] bg-[white] bottom-0 left-[50%] shadow-xl"
                  >
                    <div className="justify-center items-center flex">
                      <div className=" w-[100%] mx-5 rounded-full p-2 mt-5 items-center border-2  border-[#D9D9D9]">
                        <div className=" " onClick={() => setOpen(false)}>
                          <Back />
                        </div>
                      </div>
                    </div>
                    <div className="justify-center items-center flex mt-20">
                      <ReactPlayer
                        height={isDesktop ? "450px" : "300px"}
                        width={isDesktop ? "500px" : "300px"}
                        controls={true}
                        url={`https://youtu.be/${selectedVideo}`}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div onClick={() => handleClick(item?.videoId)}>
                  <img
                    className="w-[300px] h-[200px]"
                    src={`https://img.youtube.com/vi/${item?.videoId}/hqdefault.jpg`}
                    alt=""
                  />
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default LatestVideos;
