import { createContext, useState } from "react";

export const imageData = createContext();

export const ImageDataProvider = ({ children }) => {
  const [state, setState] = useState({
    bgColor: "",
    textColor: "",
    fontFamily: "",
    prevIcon: "",
    nextIcon: "",
  });
  const [imagesData, setImagesData] = useState({
    workName: "",
    datas: [],
  });
  const [brandStyles, setBrandStyles] = useState({
    mainBgColor: "",
    subBgColor: "",
    textColor: "",
    fontFamily: "",
    fontFamilyText: "",
  });
  const [brandsData, setBrandsData] = useState({});
  const [achievementsStyles, setAchievementsStyles] = useState({
    mainBgColor: "",
    subBgColor: "",
    textColor: "",
    fontFamily: "",
    fontFamilyText: "",
  });
  const [achievementsData, setAchievementsData] = useState({});

  return (
    <imageData.Provider
      value={{
        imagesData,
        brandStyles,
        setBrandStyles,
        setImagesData,
        state,
        brandsData,
        setBrandsData,
        achievementsStyles,
        achievementsData,
        setAchievementsData,
        setAchievementsStyles,
        setState,
      }}
    >
      {children}
    </imageData.Provider>
  );
};
