import React, { useContext, useEffect, useState } from "react";
import useMediaQuery from "../../hooks/UseMediaQuery";
import { magHeader } from "../../common/Constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Context from "../../context/Context";

function Header({ bgColor, textColor, hambergerBg, hambergerText }) {
  const location = useLocation();
  const { promptInstall, isMobile } = useContext(Context);
  const isDesktop = useMediaQuery("(min-width:770px)");
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const [scrollingBehaviour, setScrollingBehaviour] = useState(false);

  const [scrollValue, setScrollValue] = useState(90);
  useEffect(() => {
    if (location?.pathname.startsWith("/campaigns")) {
      setScrollValue(null);
    }
  }, [location]);

  const scrollBehaviour = () => {
    if (window.scrollY >= scrollValue) {
      setScrollingBehaviour(true);
    } else {
      setScrollingBehaviour(false);
    }
  };

  window.addEventListener("scroll", scrollBehaviour);

   const handleInstall = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      navigate("/install");
    }
    promptInstall.prompt();
  };
  window.addEventListener("scroll", scrollBehaviour);

  return (
    <>
      {isDesktop ? (
        <div
          className={`w-[100%] ${location?.pathname !== "/" && "fixed"}  z-20 top-0`}
          style={{ background: bgColor }}
        >
          <div className={`justify-between  p-2  items-center flex`}>
            <div
              onClick={() => navigate("/")}
              className="xl:ml-10 ml-2 mt-5"
              style={{ color: textColor }}
            >
              {location?.pathname?.startsWith("/results") ? (
                <div className="w-[150px] h-[31px] items-center cursor-pointer">
                  <img
                    className="w-full h-full object-cover"
                    src="https://storage.googleapis.com/goviral_magazine_data/medias/ResultsPageLogo.png"
                    alt=""
                  />
                </div>
              ) : (
                <>
                  {location?.pathname?.startsWith("/giveaways") ? (
                    <p className="cursor-pointer homepage-campaign-name-font text-[32px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="157"
                        height="33"
                        viewBox="0 0 157 33"
                        fill="none"
                      >
                        <path
                          d="M17.9011 17.4879L18.2266 15.9393L17.0704 17.0197C15.5426 18.4472 13.4497 19.2922 10.7252 19.2922C4.74232 19.2922 0.5 15.6973 0.5 10.5915C0.5 5.21663 5.17589 0.5 12.1695 0.5C17.0522 0.5 20.7067 2.65163 21.8018 5.76185L19.0803 6.92247C18.0745 4.3202 15.2477 2.95026 11.9288 2.95026C9.44433 2.95026 7.35338 3.72603 5.87732 5.05448C4.39854 6.38538 3.56542 8.24683 3.56542 10.3508C3.56542 12.4158 4.36017 14.0658 5.727 15.1902C7.08227 16.305 8.95659 16.8687 11.0729 16.8687C14.607 16.8687 17.7419 15.2897 18.6374 12.4538L18.8429 11.8032H18.1606H10.0949L10.4124 9.48671H21.6989L20.378 18.2847H17.7336L17.9011 17.4879Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M23.9694 17.454L24.1641 16.0561L23.1328 17.0197C21.6051 18.4472 19.5122 19.2922 16.7877 19.2922C10.8048 19.2922 6.56244 15.6973 6.56244 10.5915C6.56244 5.21663 11.2383 0.5 18.2319 0.5C23.1147 0.5 26.7691 2.65163 27.8642 5.76185L25.1428 6.92247C24.137 4.3202 21.3101 2.95026 17.9912 2.95026C15.5068 2.95026 13.4158 3.72603 11.9398 5.05448C10.461 6.38538 9.62786 8.24683 9.62786 10.3508C9.62786 12.4158 10.4226 14.0658 11.7894 15.1902C13.1447 16.305 15.019 16.8687 17.1354 16.8687C20.6694 16.8687 23.8043 15.2897 24.6999 12.4538L24.9053 11.8032H24.2231H16.1573L16.4749 9.48671H27.7672L26.4361 18.998H23.7544L23.9694 17.454Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M15.2008 0C7.97935 0 3.03131 4.89455 3.03131 10.5915C3.03131 16.0477 7.57816 19.7922 13.7565 19.7922C16.5916 19.7922 18.8116 18.9095 20.4431 17.385L20.1489 19.498H23.8398L25.3109 8.98671H13.0076L12.5529 12.3032H21.192C20.3896 14.8441 17.5277 16.3687 14.1042 16.3687C10.0388 16.3687 7.09673 14.2022 7.09673 10.3508C7.09673 6.41908 10.1993 3.45026 14.9601 3.45026C18.3836 3.45026 21.085 4.94804 21.8071 7.59591L25.4446 6.04463C24.455 2.40715 20.4163 0 15.2008 0Z"
                          fill="black"
                        />
                        <path
                          d="M45.539 9.22743C45.539 14.5126 41.085 19.2922 34.2172 19.2922C27.975 19.2922 23.8582 15.5171 23.8582 10.5647C23.8582 5.2803 28.3114 0.5 35.2068 0.5C41.4472 0.5 45.539 4.2736 45.539 9.22743ZM42.4735 9.4414C42.4735 7.51253 41.7279 5.87404 40.4022 4.72413C39.0828 3.5797 37.2264 2.95026 35.0463 2.95026C30.2288 2.95026 26.9236 6.21872 26.9236 10.3508C26.9236 12.2811 27.6771 13.9194 29.0055 15.0685C30.3276 16.2121 32.1835 16.8419 34.3509 16.8419C39.1963 16.8419 42.4735 13.5723 42.4735 9.4414Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M51.6016 9.22743C51.6016 14.5126 47.1477 19.2922 40.2798 19.2922C34.0377 19.2922 29.9209 15.5171 29.9209 10.5647C29.9209 5.2803 34.3741 0.5 41.2695 0.5C47.5099 0.5 51.6016 4.2736 51.6016 9.22743ZM48.5362 9.4414C48.5362 7.51253 47.7906 5.87404 46.4649 4.72413C45.1455 3.5797 43.2891 2.95026 41.109 2.95026C36.2915 2.95026 32.9863 6.21872 32.9863 10.3508C32.9863 12.2811 33.7398 13.9194 35.0682 15.0685C36.3903 16.2121 38.2462 16.8419 40.4136 16.8419C45.259 16.8419 48.5362 13.5723 48.5362 9.4414Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M37.2485 19.7922C44.363 19.7922 49.0703 14.8174 49.0703 9.22743C49.0703 3.93169 44.684 0 38.2381 0C31.0969 0 26.3896 4.97479 26.3896 10.5647C26.3896 15.8605 30.8027 19.7922 37.2485 19.7922ZM38.0777 3.45026C42.2501 3.45026 45.0049 5.85741 45.0049 9.4414C45.0049 13.2661 41.9826 16.3419 37.3823 16.3419C33.2366 16.3419 30.455 13.9348 30.455 10.3508C30.455 6.52607 33.5041 3.45026 38.0777 3.45026Z"
                          fill="black"
                        />
                        <path
                          d="M52.7724 0.794601H55.856L61.0619 15.7842L61.395 16.7436L61.9522 15.8945L71.8607 0.794601H75.049L63.0568 18.9984H59.1868L52.7724 0.794601Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M58.8348 0.794601H61.9185L67.1243 15.7842L67.4575 16.7436L68.0147 15.8945L77.9231 0.794601H81.1114L69.1193 18.9984H65.2492L58.8348 0.794601Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M66.3575 19.4984L79.0084 0.294601H74.6221L64.5655 15.6202L59.243 0.294601H55.0974L61.8641 19.4984H66.3575Z"
                          fill="black"
                        />
                        <path
                          d="M88.191 11.8571H87.7555L87.6957 12.2885L86.7659 18.9984H83.845L86.4057 0.794601H97.6859C99.6763 0.794601 101.245 1.31474 102.306 2.18841C103.357 3.0536 103.953 4.29908 103.953 5.8578C103.953 8.61999 102.188 10.8208 99.0144 11.5765L98.3846 11.7265L98.6888 12.2979L102.256 18.9984H99.0314L95.4274 12.1249L95.2869 11.8571H94.9845H88.191ZM88.1771 8.99903L88.0987 9.56735H88.6724H96.5893C97.9318 9.56735 99.0458 9.21016 99.8356 8.58296C100.632 7.95076 101.074 7.06014 101.074 6.07177C101.074 5.19196 100.723 4.43199 100.036 3.90607C99.3648 3.39271 98.4215 3.13787 97.2847 3.13787H89.4213H88.9856L88.926 3.56955L88.1771 8.99903Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M94.2534 11.8571H93.818L93.7582 12.2885L92.8284 18.9984H89.9074L92.4681 0.794601H103.748C105.739 0.794601 107.307 1.31474 108.369 2.18841C109.419 3.0536 110.015 4.29908 110.015 5.8578C110.015 8.61999 108.251 10.8208 105.077 11.5765L104.447 11.7265L104.751 12.2979L108.318 18.9984H105.094L101.49 12.1249L101.349 11.8571H101.047H94.2534ZM94.2396 8.99903L94.1612 9.56735H94.7349H102.652C103.994 9.56735 105.108 9.21016 105.898 8.58296C106.694 7.95076 107.137 7.06014 107.137 6.07177C107.137 5.19196 106.786 4.43199 106.098 3.90607C105.427 3.39271 104.484 3.13787 103.347 3.13787H95.4838H95.048L94.9885 3.56955L94.2396 8.99903Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M91.2223 12.3571H98.0158L101.76 19.4984H106.12L102.162 12.0629C105.532 11.2605 107.484 8.88012 107.484 5.8578C107.484 2.46104 104.863 0.294601 100.717 0.294601H89.0024L86.301 19.4984H90.2327L91.2223 12.3571ZM91.7038 9.06735L92.4526 3.63787H100.316C102.456 3.63787 103.606 4.60074 103.606 6.07177C103.606 7.73004 102.135 9.06735 99.6206 9.06735H91.7038Z"
                          fill="black"
                        />
                        <path
                          d="M113.52 14.5317H113.249L113.101 14.7585L110.334 18.9984H107.196L119.112 0.794601H122.902L129.341 18.9984H126.312L124.878 14.8677L124.761 14.5317H124.405H113.52ZM121.079 3.92798L120.743 2.96331L120.188 3.82069L115.266 11.4166L114.766 12.1885H115.686H123.255H123.959L123.727 11.5239L121.079 3.92798Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M119.582 14.5317H119.311L119.163 14.7585L116.396 18.9984H113.259L125.175 0.794601H128.964L135.404 18.9984H132.375L130.94 14.8677L130.823 14.5317H130.468H119.582ZM127.142 3.92798L126.806 2.96331L126.25 3.82069L121.329 11.4166L120.829 12.1885H121.748H129.318H130.021L129.79 11.5239L127.142 3.92798Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M121.873 0.294601L109.302 19.4984H113.635L116.551 15.0317H127.436L128.988 19.4984H133.08L126.286 0.294601H121.873ZM123.638 4.09256L126.286 11.6885H118.717L123.638 4.09256Z"
                          fill="black"
                        />
                        <path
                          d="M137.431 16.6016H150.362L150.022 18.9984H133.593L136.154 0.794601H139.076L136.936 16.0321L136.856 16.6016H137.431Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M143.493 16.6016H156.424L156.085 18.9984H139.655L142.216 0.794601H145.138L142.998 16.0321L142.918 16.6016H143.493Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M136.049 19.4984H153.488L153.969 16.1016H140.462L142.682 0.294601H138.75L136.049 19.4984Z"
                          fill="black"
                        />
                        <path
                          d="M76.8911 18.9984L79.4517 0.794601H82.3736L79.8129 18.9984H76.8911Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M82.9535 18.9984L85.5142 0.794601H88.436L85.8753 18.9984H82.9535Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M85.9802 0.294601H82.0485L79.3471 19.4984H83.2788L85.9802 0.294601Z"
                          fill="black"
                        />
                        <path
                          d="M90.8794 24.9018L87.8311 31.1464L86.7902 24.9018H85.4094L86.7796 32.5141H88.4046L91.4317 26.3649L92.3982 32.5141H94.0445L97.9957 24.9018H96.5724L93.386 31.1464L92.3876 24.9018H90.8794Z"
                          fill="black"
                        />
                        <path
                          d="M101.735 32.6307C104.539 32.6307 106.387 30.6481 106.387 28.4323C106.387 26.3437 104.656 24.7852 102.107 24.7852C99.303 24.7852 97.4443 26.7678 97.4443 28.9836C97.4443 31.0722 99.1862 32.6307 101.735 32.6307ZM102.065 25.9832C103.838 25.9832 104.985 27.001 104.985 28.5065C104.985 30.1286 103.732 31.4327 101.778 31.4327C100.004 31.4327 98.8463 30.4149 98.8463 28.9094C98.8463 27.2873 100.11 25.9832 102.065 25.9832Z"
                          fill="black"
                        />
                        <path
                          d="M108.614 29.5667H111.545L113.096 32.5141H114.593L112.99 29.4819C114.328 29.1957 115.135 28.2627 115.135 27.054C115.135 25.7288 114.126 24.9018 112.565 24.9018H107.913L106.84 32.5141H108.199L108.614 29.5667ZM108.784 28.4005L109.102 26.068H112.405C113.308 26.068 113.807 26.4709 113.807 27.107C113.807 27.8174 113.16 28.4005 112.087 28.4005H108.784Z"
                          fill="black"
                        />
                        <path
                          d="M115.356 32.5141H122.281L122.451 31.3267H116.885L117.788 24.9018H116.428L115.356 32.5141Z"
                          fill="black"
                        />
                        <path
                          d="M123.249 32.5141H127.274C130.227 32.5141 131.948 30.5739 131.948 28.4323C131.948 26.4709 130.44 24.9018 127.954 24.9018H124.322L123.249 32.5141ZM124.747 31.3479L125.49 26.068H127.805C129.558 26.068 130.556 27.1707 130.556 28.5065C130.556 30.0226 129.324 31.3479 127.328 31.3479H124.747Z"
                          fill="black"
                        />
                        <circle
                          cx="80.2385"
                          cy="27.1025"
                          r="1.96139"
                          fill="black"
                        />
                      </svg>
                    </p>
                  ) : (
                    <p className="cursor-pointer homepage-campaign-name-font text-[32px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="157"
                        height="33"
                        viewBox="0 0 157 33"
                        fill="none"
                      >
                        <path
                          d="M17.9011 17.4879L18.2266 15.9393L17.0704 17.0197C15.5426 18.4472 13.4497 19.2922 10.7252 19.2922C4.74232 19.2922 0.5 15.6973 0.5 10.5915C0.5 5.21663 5.17589 0.5 12.1695 0.5C17.0522 0.5 20.7067 2.65163 21.8018 5.76185L19.0803 6.92247C18.0745 4.3202 15.2477 2.95026 11.9288 2.95026C9.44433 2.95026 7.35338 3.72603 5.87732 5.05448C4.39854 6.38538 3.56542 8.24683 3.56542 10.3508C3.56542 12.4158 4.36017 14.0658 5.727 15.1902C7.08227 16.305 8.95659 16.8687 11.0729 16.8687C14.607 16.8687 17.7419 15.2897 18.6374 12.4538L18.8429 11.8032H18.1606H10.0949L10.4124 9.48671H21.6989L20.378 18.2847H17.7336L17.9011 17.4879Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M23.9694 17.454L24.1641 16.0561L23.1328 17.0197C21.6051 18.4472 19.5122 19.2922 16.7877 19.2922C10.8048 19.2922 6.56244 15.6973 6.56244 10.5915C6.56244 5.21663 11.2383 0.5 18.2319 0.5C23.1147 0.5 26.7691 2.65163 27.8642 5.76185L25.1428 6.92247C24.137 4.3202 21.3101 2.95026 17.9912 2.95026C15.5068 2.95026 13.4158 3.72603 11.9398 5.05448C10.461 6.38538 9.62786 8.24683 9.62786 10.3508C9.62786 12.4158 10.4226 14.0658 11.7894 15.1902C13.1447 16.305 15.019 16.8687 17.1354 16.8687C20.6694 16.8687 23.8043 15.2897 24.6999 12.4538L24.9053 11.8032H24.2231H16.1573L16.4749 9.48671H27.7672L26.4361 18.998H23.7544L23.9694 17.454Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M15.2008 0C7.97935 0 3.03131 4.89455 3.03131 10.5915C3.03131 16.0477 7.57816 19.7922 13.7565 19.7922C16.5916 19.7922 18.8116 18.9095 20.4431 17.385L20.1489 19.498H23.8398L25.3109 8.98671H13.0076L12.5529 12.3032H21.192C20.3896 14.8441 17.5277 16.3687 14.1042 16.3687C10.0388 16.3687 7.09673 14.2022 7.09673 10.3508C7.09673 6.41908 10.1993 3.45026 14.9601 3.45026C18.3836 3.45026 21.085 4.94804 21.8071 7.59591L25.4446 6.04463C24.455 2.40715 20.4163 0 15.2008 0Z"
                          fill="black"
                        />
                        <path
                          d="M45.539 9.22743C45.539 14.5126 41.085 19.2922 34.2172 19.2922C27.975 19.2922 23.8582 15.5171 23.8582 10.5647C23.8582 5.2803 28.3114 0.5 35.2068 0.5C41.4472 0.5 45.539 4.2736 45.539 9.22743ZM42.4735 9.4414C42.4735 7.51253 41.7279 5.87404 40.4022 4.72413C39.0828 3.5797 37.2264 2.95026 35.0463 2.95026C30.2288 2.95026 26.9236 6.21872 26.9236 10.3508C26.9236 12.2811 27.6771 13.9194 29.0055 15.0685C30.3276 16.2121 32.1835 16.8419 34.3509 16.8419C39.1963 16.8419 42.4735 13.5723 42.4735 9.4414Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M51.6016 9.22743C51.6016 14.5126 47.1477 19.2922 40.2798 19.2922C34.0377 19.2922 29.9209 15.5171 29.9209 10.5647C29.9209 5.2803 34.3741 0.5 41.2695 0.5C47.5099 0.5 51.6016 4.2736 51.6016 9.22743ZM48.5362 9.4414C48.5362 7.51253 47.7906 5.87404 46.4649 4.72413C45.1455 3.5797 43.2891 2.95026 41.109 2.95026C36.2915 2.95026 32.9863 6.21872 32.9863 10.3508C32.9863 12.2811 33.7398 13.9194 35.0682 15.0685C36.3903 16.2121 38.2462 16.8419 40.4136 16.8419C45.259 16.8419 48.5362 13.5723 48.5362 9.4414Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M37.2485 19.7922C44.363 19.7922 49.0703 14.8174 49.0703 9.22743C49.0703 3.93169 44.684 0 38.2381 0C31.0969 0 26.3896 4.97479 26.3896 10.5647C26.3896 15.8605 30.8027 19.7922 37.2485 19.7922ZM38.0777 3.45026C42.2501 3.45026 45.0049 5.85741 45.0049 9.4414C45.0049 13.2661 41.9826 16.3419 37.3823 16.3419C33.2366 16.3419 30.455 13.9348 30.455 10.3508C30.455 6.52607 33.5041 3.45026 38.0777 3.45026Z"
                          fill="black"
                        />
                        <path
                          d="M52.7724 0.794601H55.856L61.0619 15.7842L61.395 16.7436L61.9522 15.8945L71.8607 0.794601H75.049L63.0568 18.9984H59.1868L52.7724 0.794601Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M58.8348 0.794601H61.9185L67.1243 15.7842L67.4575 16.7436L68.0147 15.8945L77.9231 0.794601H81.1114L69.1193 18.9984H65.2492L58.8348 0.794601Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M66.3575 19.4984L79.0084 0.294601H74.6221L64.5655 15.6202L59.243 0.294601H55.0974L61.8641 19.4984H66.3575Z"
                          fill="black"
                        />
                        <path
                          d="M88.191 11.8571H87.7555L87.6957 12.2885L86.7659 18.9984H83.845L86.4057 0.794601H97.6859C99.6763 0.794601 101.245 1.31474 102.306 2.18841C103.357 3.0536 103.953 4.29908 103.953 5.8578C103.953 8.61999 102.188 10.8208 99.0144 11.5765L98.3846 11.7265L98.6888 12.2979L102.256 18.9984H99.0314L95.4274 12.1249L95.2869 11.8571H94.9845H88.191ZM88.1771 8.99903L88.0987 9.56735H88.6724H96.5893C97.9318 9.56735 99.0458 9.21016 99.8356 8.58296C100.632 7.95076 101.074 7.06014 101.074 6.07177C101.074 5.19196 100.723 4.43199 100.036 3.90607C99.3648 3.39271 98.4215 3.13787 97.2847 3.13787H89.4213H88.9856L88.926 3.56955L88.1771 8.99903Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M94.2534 11.8571H93.818L93.7582 12.2885L92.8284 18.9984H89.9074L92.4681 0.794601H103.748C105.739 0.794601 107.307 1.31474 108.369 2.18841C109.419 3.0536 110.015 4.29908 110.015 5.8578C110.015 8.61999 108.251 10.8208 105.077 11.5765L104.447 11.7265L104.751 12.2979L108.318 18.9984H105.094L101.49 12.1249L101.349 11.8571H101.047H94.2534ZM94.2396 8.99903L94.1612 9.56735H94.7349H102.652C103.994 9.56735 105.108 9.21016 105.898 8.58296C106.694 7.95076 107.137 7.06014 107.137 6.07177C107.137 5.19196 106.786 4.43199 106.098 3.90607C105.427 3.39271 104.484 3.13787 103.347 3.13787H95.4838H95.048L94.9885 3.56955L94.2396 8.99903Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M91.2223 12.3571H98.0158L101.76 19.4984H106.12L102.162 12.0629C105.532 11.2605 107.484 8.88012 107.484 5.8578C107.484 2.46104 104.863 0.294601 100.717 0.294601H89.0024L86.301 19.4984H90.2327L91.2223 12.3571ZM91.7038 9.06735L92.4526 3.63787H100.316C102.456 3.63787 103.606 4.60074 103.606 6.07177C103.606 7.73004 102.135 9.06735 99.6206 9.06735H91.7038Z"
                          fill="black"
                        />
                        <path
                          d="M113.52 14.5317H113.249L113.101 14.7585L110.334 18.9984H107.196L119.112 0.794601H122.902L129.341 18.9984H126.312L124.878 14.8677L124.761 14.5317H124.405H113.52ZM121.079 3.92798L120.743 2.96331L120.188 3.82069L115.266 11.4166L114.766 12.1885H115.686H123.255H123.959L123.727 11.5239L121.079 3.92798Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M119.582 14.5317H119.311L119.163 14.7585L116.396 18.9984H113.259L125.175 0.794601H128.964L135.404 18.9984H132.375L130.94 14.8677L130.823 14.5317H130.468H119.582ZM127.142 3.92798L126.806 2.96331L126.25 3.82069L121.329 11.4166L120.829 12.1885H121.748H129.318H130.021L129.79 11.5239L127.142 3.92798Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M121.873 0.294601L109.302 19.4984H113.635L116.551 15.0317H127.436L128.988 19.4984H133.08L126.286 0.294601H121.873ZM123.638 4.09256L126.286 11.6885H118.717L123.638 4.09256Z"
                          fill="black"
                        />
                        <path
                          d="M137.431 16.6016H150.362L150.022 18.9984H133.593L136.154 0.794601H139.076L136.936 16.0321L136.856 16.6016H137.431Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M143.493 16.6016H156.424L156.085 18.9984H139.655L142.216 0.794601H145.138L142.998 16.0321L142.918 16.6016H143.493Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M136.049 19.4984H153.488L153.969 16.1016H140.462L142.682 0.294601H138.75L136.049 19.4984Z"
                          fill="black"
                        />
                        <path
                          d="M76.8911 18.9984L79.4517 0.794601H82.3736L79.8129 18.9984H76.8911Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M82.9535 18.9984L85.5142 0.794601H88.436L85.8753 18.9984H82.9535Z"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M85.9802 0.294601H82.0485L79.3471 19.4984H83.2788L85.9802 0.294601Z"
                          fill="black"
                        />
                        <path
                          d="M90.8794 24.9018L87.8311 31.1464L86.7902 24.9018H85.4094L86.7796 32.5141H88.4046L91.4317 26.3649L92.3982 32.5141H94.0445L97.9957 24.9018H96.5724L93.386 31.1464L92.3876 24.9018H90.8794Z"
                          fill="black"
                        />
                        <path
                          d="M101.735 32.6307C104.539 32.6307 106.387 30.6481 106.387 28.4323C106.387 26.3437 104.656 24.7852 102.107 24.7852C99.303 24.7852 97.4443 26.7678 97.4443 28.9836C97.4443 31.0722 99.1862 32.6307 101.735 32.6307ZM102.065 25.9832C103.838 25.9832 104.985 27.001 104.985 28.5065C104.985 30.1286 103.732 31.4327 101.778 31.4327C100.004 31.4327 98.8463 30.4149 98.8463 28.9094C98.8463 27.2873 100.11 25.9832 102.065 25.9832Z"
                          fill="black"
                        />
                        <path
                          d="M108.614 29.5667H111.545L113.096 32.5141H114.593L112.99 29.4819C114.328 29.1957 115.135 28.2627 115.135 27.054C115.135 25.7288 114.126 24.9018 112.565 24.9018H107.913L106.84 32.5141H108.199L108.614 29.5667ZM108.784 28.4005L109.102 26.068H112.405C113.308 26.068 113.807 26.4709 113.807 27.107C113.807 27.8174 113.16 28.4005 112.087 28.4005H108.784Z"
                          fill="black"
                        />
                        <path
                          d="M115.356 32.5141H122.281L122.451 31.3267H116.885L117.788 24.9018H116.428L115.356 32.5141Z"
                          fill="black"
                        />
                        <path
                          d="M123.249 32.5141H127.274C130.227 32.5141 131.948 30.5739 131.948 28.4323C131.948 26.4709 130.44 24.9018 127.954 24.9018H124.322L123.249 32.5141ZM124.747 31.3479L125.49 26.068H127.805C129.558 26.068 130.556 27.1707 130.556 28.5065C130.556 30.0226 129.324 31.3479 127.328 31.3479H124.747Z"
                          fill="black"
                        />
                        <circle
                          cx="80.2385"
                          cy="27.1025"
                          r="1.96139"
                          fill="black"
                        />
                      </svg>
                    </p>
                  )}
                </>
              )}
            </div>

            <div
              className="flex gap-7 xl:mr-5 mt-5 text-[18px] uppercase font-[500]  cursor-pointer"
              style={{
                listStyleType: "none",
                color:
                  location?.pathname?.startsWith("/results/") ||
                  location?.pathname?.startsWith("/giveaways")
                    ? textColor
                    : textColor,
              }}
            >
              <>
                {magHeader?.map((item) => (
                  <Link
                    to={item?.link}
                    target={item?.target}
                    state={{ previousLocation: location?.pathname }}
                    className={`${
                      location?.pathname?.startsWith("/results/Chandrayaan-3")
                        ? "hover:text-white"
                        : "hover:text-black"
                    } fonts-for-Sathosi-homepage     hover:no-underline`}
                    key={item?.name}
                  >
                    {item?.name}
                  </Link>
                ))}
                {/* {!isMobile && (
                  <button
                    onClick={handleInstall}
                    className={`${
                      location?.pathname?.startsWith("/results/Chandrayaan-3")
                        ? "hover:text-white"
                        : "hover:text-black"
                    } fonts-for-Sathosi-homepage     hover:no-underline`}
                  >
                    INSTALL
                  </button>
                )} */}
              </>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ background: bgColor }}
          className={`justify-between  p-3  ${location?.pathname !== "/" && "fixed"}  z-20 top-0 w-[100%] items-center  flex`}
        >
          <div
            onClick={() => navigate("/")}
            className="ml-4"
            style={{ color: textColor }}
          >
            {location?.pathname?.startsWith("/results") ? (
              <div className="w-[130px] h-[25px] items-center ">
                <img
                  className="w-full h-full object-cover"
                  src="https://storage.googleapis.com/goviral_magazine_data/medias/ResultsPageLogo.png"
                  alt=""
                />
              </div>
            ) : (
              <p className="text-[24px]  font-[600] mt-5 homepage-campaign-name-font">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="157"
                  height="33"
                  viewBox="0 0 157 33"
                  fill="none"
                >
                  <path
                    d="M17.9011 17.4879L18.2266 15.9393L17.0704 17.0197C15.5426 18.4472 13.4497 19.2922 10.7252 19.2922C4.74232 19.2922 0.5 15.6973 0.5 10.5915C0.5 5.21663 5.17589 0.5 12.1695 0.5C17.0522 0.5 20.7067 2.65163 21.8018 5.76185L19.0803 6.92247C18.0745 4.3202 15.2477 2.95026 11.9288 2.95026C9.44433 2.95026 7.35338 3.72603 5.87732 5.05448C4.39854 6.38538 3.56542 8.24683 3.56542 10.3508C3.56542 12.4158 4.36017 14.0658 5.727 15.1902C7.08227 16.305 8.95659 16.8687 11.0729 16.8687C14.607 16.8687 17.7419 15.2897 18.6374 12.4538L18.8429 11.8032H18.1606H10.0949L10.4124 9.48671H21.6989L20.378 18.2847H17.7336L17.9011 17.4879Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M23.9694 17.454L24.1641 16.0561L23.1328 17.0197C21.6051 18.4472 19.5122 19.2922 16.7877 19.2922C10.8048 19.2922 6.56244 15.6973 6.56244 10.5915C6.56244 5.21663 11.2383 0.5 18.2319 0.5C23.1147 0.5 26.7691 2.65163 27.8642 5.76185L25.1428 6.92247C24.137 4.3202 21.3101 2.95026 17.9912 2.95026C15.5068 2.95026 13.4158 3.72603 11.9398 5.05448C10.461 6.38538 9.62786 8.24683 9.62786 10.3508C9.62786 12.4158 10.4226 14.0658 11.7894 15.1902C13.1447 16.305 15.019 16.8687 17.1354 16.8687C20.6694 16.8687 23.8043 15.2897 24.6999 12.4538L24.9053 11.8032H24.2231H16.1573L16.4749 9.48671H27.7672L26.4361 18.998H23.7544L23.9694 17.454Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M15.2008 0C7.97935 0 3.03131 4.89455 3.03131 10.5915C3.03131 16.0477 7.57816 19.7922 13.7565 19.7922C16.5916 19.7922 18.8116 18.9095 20.4431 17.385L20.1489 19.498H23.8398L25.3109 8.98671H13.0076L12.5529 12.3032H21.192C20.3896 14.8441 17.5277 16.3687 14.1042 16.3687C10.0388 16.3687 7.09673 14.2022 7.09673 10.3508C7.09673 6.41908 10.1993 3.45026 14.9601 3.45026C18.3836 3.45026 21.085 4.94804 21.8071 7.59591L25.4446 6.04463C24.455 2.40715 20.4163 0 15.2008 0Z"
                    fill="black"
                  />
                  <path
                    d="M45.539 9.22743C45.539 14.5126 41.085 19.2922 34.2172 19.2922C27.975 19.2922 23.8582 15.5171 23.8582 10.5647C23.8582 5.2803 28.3114 0.5 35.2068 0.5C41.4472 0.5 45.539 4.2736 45.539 9.22743ZM42.4735 9.4414C42.4735 7.51253 41.7279 5.87404 40.4022 4.72413C39.0828 3.5797 37.2264 2.95026 35.0463 2.95026C30.2288 2.95026 26.9236 6.21872 26.9236 10.3508C26.9236 12.2811 27.6771 13.9194 29.0055 15.0685C30.3276 16.2121 32.1835 16.8419 34.3509 16.8419C39.1963 16.8419 42.4735 13.5723 42.4735 9.4414Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M51.6016 9.22743C51.6016 14.5126 47.1477 19.2922 40.2798 19.2922C34.0377 19.2922 29.9209 15.5171 29.9209 10.5647C29.9209 5.2803 34.3741 0.5 41.2695 0.5C47.5099 0.5 51.6016 4.2736 51.6016 9.22743ZM48.5362 9.4414C48.5362 7.51253 47.7906 5.87404 46.4649 4.72413C45.1455 3.5797 43.2891 2.95026 41.109 2.95026C36.2915 2.95026 32.9863 6.21872 32.9863 10.3508C32.9863 12.2811 33.7398 13.9194 35.0682 15.0685C36.3903 16.2121 38.2462 16.8419 40.4136 16.8419C45.259 16.8419 48.5362 13.5723 48.5362 9.4414Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M37.2485 19.7922C44.363 19.7922 49.0703 14.8174 49.0703 9.22743C49.0703 3.93169 44.684 0 38.2381 0C31.0969 0 26.3896 4.97479 26.3896 10.5647C26.3896 15.8605 30.8027 19.7922 37.2485 19.7922ZM38.0777 3.45026C42.2501 3.45026 45.0049 5.85741 45.0049 9.4414C45.0049 13.2661 41.9826 16.3419 37.3823 16.3419C33.2366 16.3419 30.455 13.9348 30.455 10.3508C30.455 6.52607 33.5041 3.45026 38.0777 3.45026Z"
                    fill="black"
                  />
                  <path
                    d="M52.7724 0.794601H55.856L61.0619 15.7842L61.395 16.7436L61.9522 15.8945L71.8607 0.794601H75.049L63.0568 18.9984H59.1868L52.7724 0.794601Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M58.8348 0.794601H61.9185L67.1243 15.7842L67.4575 16.7436L68.0147 15.8945L77.9231 0.794601H81.1114L69.1193 18.9984H65.2492L58.8348 0.794601Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M66.3575 19.4984L79.0084 0.294601H74.6221L64.5655 15.6202L59.243 0.294601H55.0974L61.8641 19.4984H66.3575Z"
                    fill="black"
                  />
                  <path
                    d="M88.191 11.8571H87.7555L87.6957 12.2885L86.7659 18.9984H83.845L86.4057 0.794601H97.6859C99.6763 0.794601 101.245 1.31474 102.306 2.18841C103.357 3.0536 103.953 4.29908 103.953 5.8578C103.953 8.61999 102.188 10.8208 99.0144 11.5765L98.3846 11.7265L98.6888 12.2979L102.256 18.9984H99.0314L95.4274 12.1249L95.2869 11.8571H94.9845H88.191ZM88.1771 8.99903L88.0987 9.56735H88.6724H96.5893C97.9318 9.56735 99.0458 9.21016 99.8356 8.58296C100.632 7.95076 101.074 7.06014 101.074 6.07177C101.074 5.19196 100.723 4.43199 100.036 3.90607C99.3648 3.39271 98.4215 3.13787 97.2847 3.13787H89.4213H88.9856L88.926 3.56955L88.1771 8.99903Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M94.2534 11.8571H93.818L93.7582 12.2885L92.8284 18.9984H89.9074L92.4681 0.794601H103.748C105.739 0.794601 107.307 1.31474 108.369 2.18841C109.419 3.0536 110.015 4.29908 110.015 5.8578C110.015 8.61999 108.251 10.8208 105.077 11.5765L104.447 11.7265L104.751 12.2979L108.318 18.9984H105.094L101.49 12.1249L101.349 11.8571H101.047H94.2534ZM94.2396 8.99903L94.1612 9.56735H94.7349H102.652C103.994 9.56735 105.108 9.21016 105.898 8.58296C106.694 7.95076 107.137 7.06014 107.137 6.07177C107.137 5.19196 106.786 4.43199 106.098 3.90607C105.427 3.39271 104.484 3.13787 103.347 3.13787H95.4838H95.048L94.9885 3.56955L94.2396 8.99903Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M91.2223 12.3571H98.0158L101.76 19.4984H106.12L102.162 12.0629C105.532 11.2605 107.484 8.88012 107.484 5.8578C107.484 2.46104 104.863 0.294601 100.717 0.294601H89.0024L86.301 19.4984H90.2327L91.2223 12.3571ZM91.7038 9.06735L92.4526 3.63787H100.316C102.456 3.63787 103.606 4.60074 103.606 6.07177C103.606 7.73004 102.135 9.06735 99.6206 9.06735H91.7038Z"
                    fill="black"
                  />
                  <path
                    d="M113.52 14.5317H113.249L113.101 14.7585L110.334 18.9984H107.196L119.112 0.794601H122.902L129.341 18.9984H126.312L124.878 14.8677L124.761 14.5317H124.405H113.52ZM121.079 3.92798L120.743 2.96331L120.188 3.82069L115.266 11.4166L114.766 12.1885H115.686H123.255H123.959L123.727 11.5239L121.079 3.92798Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M119.582 14.5317H119.311L119.163 14.7585L116.396 18.9984H113.259L125.175 0.794601H128.964L135.404 18.9984H132.375L130.94 14.8677L130.823 14.5317H130.468H119.582ZM127.142 3.92798L126.806 2.96331L126.25 3.82069L121.329 11.4166L120.829 12.1885H121.748H129.318H130.021L129.79 11.5239L127.142 3.92798Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M121.873 0.294601L109.302 19.4984H113.635L116.551 15.0317H127.436L128.988 19.4984H133.08L126.286 0.294601H121.873ZM123.638 4.09256L126.286 11.6885H118.717L123.638 4.09256Z"
                    fill="black"
                  />
                  <path
                    d="M137.431 16.6016H150.362L150.022 18.9984H133.593L136.154 0.794601H139.076L136.936 16.0321L136.856 16.6016H137.431Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M143.493 16.6016H156.424L156.085 18.9984H139.655L142.216 0.794601H145.138L142.998 16.0321L142.918 16.6016H143.493Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M136.049 19.4984H153.488L153.969 16.1016H140.462L142.682 0.294601H138.75L136.049 19.4984Z"
                    fill="black"
                  />
                  <path
                    d="M76.8911 18.9984L79.4517 0.794601H82.3736L79.8129 18.9984H76.8911Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M82.9535 18.9984L85.5142 0.794601H88.436L85.8753 18.9984H82.9535Z"
                    fill="white"
                    stroke="black"
                  />
                  <path
                    d="M85.9802 0.294601H82.0485L79.3471 19.4984H83.2788L85.9802 0.294601Z"
                    fill="black"
                  />
                  <path
                    d="M90.8794 24.9018L87.8311 31.1464L86.7902 24.9018H85.4094L86.7796 32.5141H88.4046L91.4317 26.3649L92.3982 32.5141H94.0445L97.9957 24.9018H96.5724L93.386 31.1464L92.3876 24.9018H90.8794Z"
                    fill="black"
                  />
                  <path
                    d="M101.735 32.6307C104.539 32.6307 106.387 30.6481 106.387 28.4323C106.387 26.3437 104.656 24.7852 102.107 24.7852C99.303 24.7852 97.4443 26.7678 97.4443 28.9836C97.4443 31.0722 99.1862 32.6307 101.735 32.6307ZM102.065 25.9832C103.838 25.9832 104.985 27.001 104.985 28.5065C104.985 30.1286 103.732 31.4327 101.778 31.4327C100.004 31.4327 98.8463 30.4149 98.8463 28.9094C98.8463 27.2873 100.11 25.9832 102.065 25.9832Z"
                    fill="black"
                  />
                  <path
                    d="M108.614 29.5667H111.545L113.096 32.5141H114.593L112.99 29.4819C114.328 29.1957 115.135 28.2627 115.135 27.054C115.135 25.7288 114.126 24.9018 112.565 24.9018H107.913L106.84 32.5141H108.199L108.614 29.5667ZM108.784 28.4005L109.102 26.068H112.405C113.308 26.068 113.807 26.4709 113.807 27.107C113.807 27.8174 113.16 28.4005 112.087 28.4005H108.784Z"
                    fill="black"
                  />
                  <path
                    d="M115.356 32.5141H122.281L122.451 31.3267H116.885L117.788 24.9018H116.428L115.356 32.5141Z"
                    fill="black"
                  />
                  <path
                    d="M123.249 32.5141H127.274C130.227 32.5141 131.948 30.5739 131.948 28.4323C131.948 26.4709 130.44 24.9018 127.954 24.9018H124.322L123.249 32.5141ZM124.747 31.3479L125.49 26.068H127.805C129.558 26.068 130.556 27.1707 130.556 28.5065C130.556 30.0226 129.324 31.3479 127.328 31.3479H124.747Z"
                    fill="black"
                  />
                  <circle cx="80.2385" cy="27.1025" r="1.96139" fill="black" />
                </svg>
              </p>
            )}
          </div>

          {/* <div
            className={`mr-4 ${
              location?.pathname?.startsWith("/results") ? "mt-0" : "mt-5"
            }`}
          >
            {toggle ? (
              <svg
                onClick={() => setToggle(!toggle)}
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 18 18"
              >
                <path
                  d="M17.4001 0.613143C17.2767 0.489539 17.1302 0.391475 16.9689 0.324567C16.8076 0.257658 16.6347 0.223218 16.4601 0.223218C16.2854 0.223218 16.1125 0.257658 15.9512 0.324567C15.7899 0.391475 15.6434 0.489539 15.5201 0.613143L9.00005 7.11981L2.48005 0.59981C2.35661 0.476367 2.21006 0.378447 2.04878 0.31164C1.88749 0.244834 1.71463 0.210449 1.54005 0.210449C1.36548 0.210449 1.19261 0.244834 1.03133 0.31164C0.870044 0.378447 0.723496 0.476367 0.600054 0.59981C0.476611 0.723252 0.378691 0.8698 0.311885 1.03109C0.245078 1.19237 0.210693 1.36524 0.210693 1.53981C0.210693 1.71438 0.245078 1.88725 0.311885 2.04853C0.378691 2.20982 0.476611 2.35637 0.600054 2.47981L7.12005 8.99981L0.600054 15.5198C0.476611 15.6433 0.378691 15.7898 0.311885 15.9511C0.245078 16.1124 0.210693 16.2852 0.210693 16.4598C0.210693 16.6344 0.245078 16.8072 0.311885 16.9685C0.378691 17.1298 0.476611 17.2764 0.600054 17.3998C0.723496 17.5233 0.870044 17.6212 1.03133 17.688C1.19261 17.7548 1.36548 17.7892 1.54005 17.7892C1.71463 17.7892 1.88749 17.7548 2.04878 17.688C2.21006 17.6212 2.35661 17.5233 2.48005 17.3998L9.00005 10.8798L15.5201 17.3998C15.6435 17.5233 15.79 17.6212 15.9513 17.688C16.1126 17.7548 16.2855 17.7892 16.4601 17.7892C16.6346 17.7892 16.8075 17.7548 16.9688 17.688C17.1301 17.6212 17.2766 17.5233 17.4001 17.3998C17.5235 17.2764 17.6214 17.1298 17.6882 16.9685C17.755 16.8072 17.7894 16.6344 17.7894 16.4598C17.7894 16.2852 17.755 16.1124 17.6882 15.9511C17.6214 15.7898 17.5235 15.6433 17.4001 15.5198L10.8801 8.99981L17.4001 2.47981C17.9067 1.97314 17.9067 1.11981 17.4001 0.613143Z"
                  fill={textColor}
                />
              </svg>
            ) : (
              <svg
                onClick={() => setToggle(!toggle)}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
              >
                <g clip-path="url(#clip0_188_275)">
                  <path
                    d="M26.6668 23.3334C27.1805 23.3336 27.6743 23.5315 28.046 23.8859C28.4177 24.2403 28.6388 24.7242 28.6635 25.2372C28.6882 25.7503 28.5146 26.2531 28.1786 26.6416C27.8426 27.0301 27.3701 27.2745 26.8588 27.324L26.6668 27.3334H5.3335C4.81987 27.3331 4.32604 27.1353 3.95431 26.7808C3.58259 26.4264 3.36148 25.9425 3.3368 25.4295C3.31211 24.9164 3.48575 24.4136 3.82173 24.0251C4.15771 23.6366 4.63027 23.3922 5.1415 23.3427L5.3335 23.3334H26.6668ZM26.6668 14C27.1973 14 27.706 14.2107 28.081 14.5858C28.4561 14.9609 28.6668 15.4696 28.6668 16C28.6668 16.5305 28.4561 17.0392 28.081 17.4142C27.706 17.7893 27.1973 18 26.6668 18H5.3335C4.80306 18 4.29436 17.7893 3.91928 17.4142C3.54421 17.0392 3.3335 16.5305 3.3335 16C3.3335 15.4696 3.54421 14.9609 3.91928 14.5858C4.29436 14.2107 4.80306 14 5.3335 14H26.6668ZM26.6668 4.66669C27.1973 4.66669 27.706 4.8774 28.081 5.25247C28.4561 5.62755 28.6668 6.13625 28.6668 6.66669C28.6668 7.19712 28.4561 7.70583 28.081 8.0809C27.706 8.45597 27.1973 8.66669 26.6668 8.66669H5.3335C4.80306 8.66669 4.29436 8.45597 3.91928 8.0809C3.54421 7.70583 3.3335 7.19712 3.3335 6.66669C3.3335 6.13625 3.54421 5.62755 3.91928 5.25247C4.29436 4.8774 4.80306 4.66669 5.3335 4.66669H26.6668Z"
                    fill={textColor}
                  />
                </g>
              </svg>
            )}

            <div
              className={`fixed right-0  w-full h-full top-[70px] z-20  p-3   transform transition-transform ease-in-out duration-300 ${
                toggle ? "translate-x-0 right- top-0 " : "translate-x-full"
              }`}
              style={{ listStyleType: "none", background: hambergerBg }}
            >
              {magHeader?.map((item) => (
                <Link
                  target={item?.target}
                  style={{ color: hambergerText }}
                  to={item?.link}
                  className="fonts-for-Sathosi-homepage hover:text-black hover:no-underline w-full uppercase font-[500] text-[18px] justify-start mt-4 items-start grid"
                >
                  <Link
                    target={item?.target}
                    to={item?.link}
                    className="hover:text-black hover:no-underline mt-2"
                    key={item?.name}
                  >
                    {item?.name}
                  </Link>
                </Link>
              ))}
              {!isMobile && (
                <button
                  onClick={handleInstall}
                  className="hover:text-black hover:no-underline mt-2 fonts-for-Sathosi-homepage hover:text-black hover:no-underline w-full uppercase font-[500] text-[18px] justify-start mt-4 items-start grid"
                >
                  INSTALL
                </button>
              )}
            </div>
          </div> */}
        </div>
      )}
    </>
  );
}

export default Header;
