import React, { useRef, useState } from "react";
import Contest1 from "./components/Contest1";
import Campaign from "./components/Campaign";
import Actor from "./components/Actor";
import Contest2 from "./components/Contest2";
import Profiles from "./components/Profiles";
import { judgesBg } from "../../common/Constants";

function WebCompaignAndPortfolio({ data, theme }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentBg, setCurrentBg] = useState(0);
  const [showFull, setShowFull] = useState(false);

  const prevCom = () => {
    const firstIndex = currentIndex === 0;
    const newIndex = firstIndex
      ? data?.judgesComments?.length - 1
      : currentIndex - 1;
    scrollToIndex(newIndex);
    const firstBg = currentBg === 0;
    const newBg = firstBg ? judgesBg?.length - 1 : currentBg - 1;
    setCurrentBg(newBg);
  };

  const nextCom = () => {
    const lastIndex = currentIndex === data?.judgesComments?.length - 1;
    const newIndex = lastIndex ? 0 : currentIndex + 1;
    scrollToIndex(newIndex);
    const lastBg = currentBg === judgesBg?.length - 1;
    const newBg = lastBg ? 0 : currentBg + 1;
    setCurrentBg(newBg);
  };

  // const refs = data?.judgesComments?.reduce((acc, val, i) => {
  //   acc[i] = React.createRef();
  //   return acc;
  // }, {}) ;

  const refs = useRef(data?.judgesComments?.map(() => React.createRef()));

  const scrollToIndex = (i) => {
    setCurrentIndex(i);

    refs[i]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });

    // refs[i]?.current?.scrollIntoView({

    //   behavior: 'smooth',

    //   block: 'nearest',

    //   inline: 'start',
    // });
  };
  // console.log( data?.judgesComments[currentIndex],"popooooo")
  return (
    <div style={{ backgroundColor: theme?.backGroundColor }}>
      <div className="pt-16 mx-auto  fonts-for-Sathosi-homepage pb-16 max-w-[1440px]">
        <div className="grid grid-cols-3   items-start">
          <div className="justify-center items-center  flex">
            <Actor
              profileId={data?.portfolioId}
              profilePic={data?.profilePic}
              titleColor={theme?.titleTextColor}
              positionColor={theme?.namePositonTextColor}
              name={data?.name}
              title={data?.title}
              position={data?.position}
            />
          </div>

          <div className="justify-center items-center  flex">
            <Campaign
              campaignPoster={data?.campaignPoster}
              campaignLink={data?.campaignLink1}
              titleColor={theme?.titleTextColor}
              campaignName={data?.campaignName}
              positionColor={theme?.namePositonTextColor}
              campaignTitle={data?.campaignTitle}
            />
          </div>
          <div className="justify-center items-center  flex">
            <Contest1
              contestId={data?.contestId1}
              contestAplicantName={data?.contestAplicantName1}
              titleColor={theme?.titleTextColor}
              positionColor={theme?.namePositonTextColor}
              contestImage={data?.contestPic1}
              contestTitle={data?.contestTitle1}
              contestName={data?.contestName1}
            />
          </div>
        </div>

        <div className="grid grid-cols-3 items-start">
          <div className="justify-center items-center  mt-[-100px] flex">
            <Contest1
              contestId={data?.contestId2}
              contestAplicantName={data?.contestAplicantName2}
              titleColor={theme?.titleTextColor}
              positionColor={theme?.namePositonTextColor}
              contestImage={data?.contestPic2}
              contestTitle={data?.contestTitle2}
              contestName={data?.contestName2}
            />
          </div>

          <div className="justify-center items-center  gap-10 mt-10 flex">
            <Contest2
              contestId={data?.contestId3}
              contestImage={data?.contestPic3}
              contestAplicantName={data?.contestApplicantName3}
              positionColor={theme?.namePositonTextColor}
            />

            <Contest2
              contestId={data?.contestId4}
              contestImage={data?.contestPic4}
              contestAplicantName={data?.contestApplicantName4}
              positionColor={theme?.namePositonTextColor}
            />
          </div>

          <div className="justify-center items-center  mt-[-100px] flex">
            {data?.name2 ? (
              <Actor
                profileId={data?.portfolioId2}
                profilePic={data?.profilePic2}
                titleColor={theme?.titleTextColor}
                positionColor={theme?.namePositonTextColor}
                name={data?.name2}
                title={data?.title2}
                position={data?.position2}
              />
            ) : (
              <Contest1
                contestId={data?.contestId5}
                contestAplicantName={data?.contestAplicantName5}
                titleColor={theme?.titleTextColor}
                positionColor={theme?.namePositonTextColor}
                contestImage={data?.contestPic5}
                contestTitle={data?.contestTitle5}
                contestName={data?.contestName5}
              />
            )}
          </div>
        </div>
        <div className="justify-center items-center mt-5 xl:ml-16  lg:ml-10 flex">
          {data?.profiles && (
            <Profiles
              data={data?.profiles}
              titleColor={theme?.titleTextColor}
              positionColor={theme?.namePositonTextColor}
            />
          )}
        </div>
        {data?.judgesComments && (
          <div className="" onClick={() => showFull && setShowFull(false)}>
            <div className="justify-center items-center flex">
              <p className="text-[24px] font-[700] uppercase">
                {" "}
                What our Judges say{" "}
              </p>
            </div>
            <div
              className=" relative mt-[32px] w-[70%] xl:w-[50%] py-7 mx-auto h-[100%] justify-center items-center flex"
              style={{
                background: judgesBg[currentBg]?.color,
              }}
            >
              <div className="flex ">
                <div className="ml-[70px] shrink-0 w-[68px] my-auto h-[68px] ">
                  <img
                    className="w-full h-full rounded-[50%] object-cover"
                    src={data?.judgesComments[currentIndex]?.image}
                    alt=""
                  />
                </div>
                <div className="ml-[24px] mr-[50px] text-[#FFF]">
                  <p className="ml text-[18px]  font-[600] ">
                    {" "}
                    {showFull
                      ? data?.judgesComments[currentIndex]?.text
                      : data?.judgesComments[currentIndex]?.text?.slice(
                          0,
                          120
                        )}{" "}
                    <span
                      onClick={() => setShowFull(!showFull)}
                      className="whitespace-nowrap  text-[20px] items-start font-[500] text-[#ddd9d9]"
                    >
                      {" "}
                      {showFull ? "...Show less" : "...Read more "}{" "}
                    </span>{" "}
                  </p>
                  <p className="mt-[13px] text-[13px] uppercase font-[400]">
                    {" "}
                    {data?.judgesComments[currentIndex]?.name}{" "}
                  </p>
                </div>
              </div>

              <div onClick={prevCom} className="absolute -left-3  ">
                <div className=" w-[32px] cursor-pointer h-[32px] border-[1px] border-[#6b6767] items-center bg-[#fff] rounded-[50%]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M12.2416 5.59148C12.3189 5.66858 12.3802 5.76015 12.422 5.86096C12.4638 5.96177 12.4854 6.06984 12.4854 6.17898C12.4854 6.28812 12.4638 6.39619 12.422 6.497C12.3802 6.59781 12.3189 6.68939 12.2416 6.76648L9.00832 9.99981L12.2416 13.2331C12.3975 13.389 12.485 13.6003 12.485 13.8206C12.485 14.041 12.3975 14.2523 12.2416 14.4081C12.0858 14.564 11.8745 14.6515 11.6541 14.6515C11.4338 14.6515 11.2225 14.564 11.0666 14.4081L7.24165 10.5831C7.1644 10.5061 7.10311 10.4145 7.06129 10.3137C7.01947 10.2129 6.99795 10.1048 6.99795 9.99565C6.99795 9.88651 7.01947 9.77844 7.06129 9.67763C7.10311 9.57682 7.1644 9.48524 7.24165 9.40815L11.0666 5.58315C11.3833 5.26648 11.9166 5.26648 12.2416 5.59148Z"
                      fill="#161616"
                    />
                  </svg>
                </div>
              </div>

              <div onClick={nextCom} className="absolute -right-3  ">
                <div className="w-[32px] cursor-pointer h-[32px] border-[1px] border-[#6b6767] items-center bg-[#fff] rounded-[50%]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M7.75832 5.59148C7.68107 5.66858 7.61978 5.76015 7.57796 5.86096C7.53614 5.96177 7.51462 6.06984 7.51462 6.17898C7.51462 6.28812 7.53614 6.39619 7.57796 6.497C7.61978 6.59781 7.68107 6.68939 7.75832 6.76648L10.9917 9.99981L7.75832 13.2331C7.60251 13.389 7.51497 13.6003 7.51497 13.8206C7.51497 14.041 7.60251 14.2523 7.75832 14.4081C7.91414 14.564 8.12547 14.6515 8.34582 14.6515C8.56618 14.6515 8.77751 14.564 8.93332 14.4081L12.7583 10.5831C12.8356 10.5061 12.8969 10.4145 12.9387 10.3137C12.9805 10.2129 13.002 10.1048 13.002 9.99565C13.002 9.88651 12.9805 9.77844 12.9387 9.67763C12.8969 9.57682 12.8356 9.48524 12.7583 9.40815L8.93332 5.58315C8.61665 5.26648 8.08332 5.26648 7.75832 5.59148Z"
                      fill="#161616"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {/* <div className={` relative  flex justify-center overflow-hidden`}>
 <div className=" flex justify-center   items-center">
      <div className="relative w-full">
        <div className="carousel">
{data?.judgesComments?.map((item,i)=>(
  <div
  ref={refs[i]}
  className="relative mt-[32px] w-[70%] xl:w-[50%] mx-auto h-[155px] justify-center items-center flex"
  style={{
    background: item?.bgColor,
  }}
>
  <div className="flex ">
    <div className="ml-[70px] shrink-0 w-[68px] h-[68px] ">
      <img
        className="w-full h-full rounded-[50%] object-cover"
        src={item?.image}
        alt=""
      />
    </div>
    <div className="ml-[24px] mr-[127px] text-[#FFF]">
      <p className="ml text-[18px]  font-[600] ">
        {" "}
        {item?.text}{" "}
      </p>
      <p className="mt-[13px] text-[13px] uppercase font-[400]">
        {" "}
        {item?.name}{" "}
      </p>
    </div>
  </div>

  <div onClick={prevCom}  className="absolute -left-3  ">
    <div  className=" w-[32px] cursor-pointer h-[32px] border-[1px] border-[#6b6767] items-center bg-[#fff] rounded-[50%]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M12.2416 5.59148C12.3189 5.66858 12.3802 5.76015 12.422 5.86096C12.4638 5.96177 12.4854 6.06984 12.4854 6.17898C12.4854 6.28812 12.4638 6.39619 12.422 6.497C12.3802 6.59781 12.3189 6.68939 12.2416 6.76648L9.00832 9.99981L12.2416 13.2331C12.3975 13.389 12.485 13.6003 12.485 13.8206C12.485 14.041 12.3975 14.2523 12.2416 14.4081C12.0858 14.564 11.8745 14.6515 11.6541 14.6515C11.4338 14.6515 11.2225 14.564 11.0666 14.4081L7.24165 10.5831C7.1644 10.5061 7.10311 10.4145 7.06129 10.3137C7.01947 10.2129 6.99795 10.1048 6.99795 9.99565C6.99795 9.88651 7.01947 9.77844 7.06129 9.67763C7.10311 9.57682 7.1644 9.48524 7.24165 9.40815L11.0666 5.58315C11.3833 5.26648 11.9166 5.26648 12.2416 5.59148Z"
          fill="#161616"
        />
      </svg>
    </div>
  </div> 


 <div onClick={nextCom} className="absolute -right-3  ">
    <div className="w-[32px] cursor-pointer h-[32px] border-[1px] border-[#6b6767] items-center bg-[#fff] rounded-[50%]">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20" fill="none">
<path d="M7.75832 5.59148C7.68107 5.66858 7.61978 5.76015 7.57796 5.86096C7.53614 5.96177 7.51462 6.06984 7.51462 6.17898C7.51462 6.28812 7.53614 6.39619 7.57796 6.497C7.61978 6.59781 7.68107 6.68939 7.75832 6.76648L10.9917 9.99981L7.75832 13.2331C7.60251 13.389 7.51497 13.6003 7.51497 13.8206C7.51497 14.041 7.60251 14.2523 7.75832 14.4081C7.91414 14.564 8.12547 14.6515 8.34582 14.6515C8.56618 14.6515 8.77751 14.564 8.93332 14.4081L12.7583 10.5831C12.8356 10.5061 12.8969 10.4145 12.9387 10.3137C12.9805 10.2129 13.002 10.1048 13.002 9.99565C13.002 9.88651 12.9805 9.77844 12.9387 9.67763C12.8969 9.57682 12.8356 9.48524 12.7583 9.40815L8.93332 5.58315C8.61665 5.26648 8.08332 5.26648 7.75832 5.59148Z" fill="#161616"/>
</svg>
    </div>
  </div>
</div>
))}

</div>
</div>
</div>


</div>     */}
          </div>
        )}
      </div>
    </div>
  );
}

export default WebCompaignAndPortfolio;
