import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import ImageGallery from "../Components/ImageGallery/ImageGallery";
import { isEmpty } from "lodash";
import GalleryLanding from "../Components/GalleryLanding/GalleryLanding";
import Banner from "../Components/results/Banner";
import Header from "../Components/header/Header";
import Webinar from "../Components/results/Webinar";
import Certificate from "../Components/results/Certificate";
import JudgesPanel from "../Components/results/JudgesPanel";
import Winners from "../Components/results/Winners";
import Features from "../Components/results/Features";
import Footer from "../Components/footer/Footer";
import Line from "../Components/results/line/Line";
import BannerTemp2 from "../Components/results/BannerTemp2";
import SpecialGuest from "../Components/results/SpecialGuest";
import LoadingCircle from "../Components/LoadingCircle/LoadingCircle";
import BannerTemp3 from "../Components/results/BannerTemp3";
import SpecialApperance from "../Components/results/SpecialApperance";
import JudgesPanelTemp2 from "../Components/results/JudgesPanelTemp2";
import useMediaQuery from "../hooks/UseMediaQuery";

export default function ResultPage() {
  const { campaignUniqueText } = useParams();
  const [entries, setEntries] = useState([]);
  const [paginatedData, setPagenatedData] = useState([]);
  const [itemsCount, setItemsCount] = useState(8);
  const [hasMore, setHasMore] = useState(true);
  const [dataloading, setDataLoading] = useState(false);
  const [resultsDatas, setResultsDatas] = useState();
  const isDesktop = useMediaQuery("(min-width:640px)");

  const getEntries = async () => {
    setDataLoading(true);
    try {
      let config = {
        method: "get",
        url: `https://storage.googleapis.com/goviral_magazine_data/results/${campaignUniqueText}/data.json`,
        // url: `/results/${campaignUniqueText}/data.json`,
      };
      const response = await axios(config);
      let entriesFromJson = response?.data?.data;
      let campaignData = response?.data?.otherData;
      setResultsDatas(campaignData);
      setEntries(entriesFromJson);
      setPagenatedData(entriesFromJson?.slice(0, itemsCount));
      setDataLoading(false);
    } catch (err) {
      console.log(err, "error");
    }
  };
  const observer = useRef();

  const lastElementRef = useCallback((node) => {
    if (dataloading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setItemsCount((prev) => prev + 8);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    getEntries();
  }, []);

  useEffect(() => {
    if (!isEmpty(entries)) {
      setPagenatedData(entries?.slice(0, itemsCount));
      if (itemsCount >= entries?.length) {
        setHasMore(false);
      }
    }
  }, [itemsCount]);

  let banner;
  let headerBgColor;
  let headColor;

  switch (resultsDatas?.templateId) {
    case "TEMP_1":
      banner = <Banner data={resultsDatas} />;
      headerBgColor = resultsDatas?.headerBg;
      headColor = resultsDatas?.headTextColor;
      break;
    case "TEMP_2":
      banner = <BannerTemp2 data={resultsDatas} />;
      headerBgColor = resultsDatas?.headerBg;
      headColor = resultsDatas?.headTextColor;
      break;
    case "TEMP_3":
      banner = <BannerTemp3 data={resultsDatas} />;
      headerBgColor = resultsDatas?.headerBg;
      headColor = resultsDatas?.headTextColor;
      break;
    default:
      headerBgColor = "#fff";
      headColor = "#111";
  }

  let judgesPanel;

  switch (resultsDatas?.judingPanel?.tempId) {
    case "TEMP_1":
      judgesPanel = <JudgesPanel data={resultsDatas?.judingPanel} />;
      break;
    case "TEMP_2":
      judgesPanel = <JudgesPanelTemp2 data={resultsDatas?.judingPanel} />;
  }

  return (
    <div
      className="w-full min-h-[100vh]   "
      style={{
        backgroundColor: resultsDatas?.backGround,
        color: resultsDatas?.textColor && resultsDatas?.textColor,
      }}
    >
      <Header
        bgColor={headerBgColor}
        textColor={headColor}
        svgColor={"#FFFFFF"}
        hambergerBg={"#fff"}
        hambergerText={"#111"}
      />
      {resultsDatas ? (
        <>
          <div className="w-full">
            {banner}
            {resultsDatas?.sepcialGuests && (
              <SpecialGuest data={resultsDatas?.sepcialGuests} />
            )}
            {resultsDatas?.webinarDetails && (
              <Webinar data={resultsDatas?.webinarDetails} />
            )}
            {resultsDatas?.certificate && (
              <Certificate data={resultsDatas?.certificate} />
            )}

            {resultsDatas?.SpecialAppearance && (
              <SpecialApperance data={resultsDatas?.SpecialAppearance} />
            )}

            {resultsDatas?.judingPanel && <div> {judgesPanel} </div>}
            {resultsDatas?.lineColor && (
              <Line lineColor={resultsDatas?.lineColor} />
            )}

            {resultsDatas?.campaignWinners && (
              <Winners
                lineColor={resultsDatas?.lineColor}
                data={resultsDatas?.campaignWinners}
              />
            )}
            {resultsDatas?.features && (
              <Features data={resultsDatas?.features} />
            )}
            {!resultsDatas?.features && resultsDatas?.lineColor && (
              <Line lineColor={resultsDatas?.lineColor} />
            )}

            <div className="justify-center mt-[108px] items-center text-center font-for-Results-page max-w-[1440px] mx-auto">
              <p
                className="text-[24px] sm:text-[32px] font-[700] ml-4 mr-4 sm:ml-[5%] sm:mr-[5%] xl:ml-[10%] xl:mr-[10%]"
                style={{ color: resultsDatas?.imageGallery?.headingColor }}
              >
                {" "}
                {resultsDatas?.imageGallery?.heading}{" "}
              </p>
              {resultsDatas?.imageGallery?.text && (
                <p
                  className="text-[32px]  font-[700] mt-28 uppercase"
                  style={{ color: resultsDatas?.imageGallery?.textColor }}
                >
                  {" "}
                  {resultsDatas?.imageGallery?.text}{" "}
                </p>
              )}

              {resultsDatas?.imageGallery?.para && (
                <p
                  className="text-[24px]  font-[700] mt-10  sm:mr-10 sm:ml-10 ml-4 mr-4 "
                  style={{ color: resultsDatas?.imageGallery?.textColor }}
                >
                  {" "}
                  {resultsDatas?.imageGallery?.para}{" "}
                </p>
              )}
            </div>
            <div className="max-w-[1440px] mx-auto mt-[21px] sm:mt-[97px] ">
              <ImageGallery
                data={paginatedData}
                reference={lastElementRef}
                hasMore={hasMore}
                setItemsCount={setItemsCount}
              />
            </div>
          </div>

          {isDesktop && <Footer />}
        </>
      ) : (
        <div className="w-[10%] mx-auto mt-48">
          <LoadingCircle />
        </div>
      )}
    </div>
  );
}
