import React from 'react'

function MinimalModelLine() {
  return (
   
    <div  className='justify-center items-center grid   bg-[#8B8B8B] sm:bg-[#161616]'>
    <hr className='w-[70%] h-[1px]' />
  </div>

  )
}

export default MinimalModelLine