import React from "react";

function Certificate({ data }) {
  return (
    <div className="w-full " style={{ backgroundColor: data?.bgColor }}>
      <div className="p-4 sm:p-10  mt-24  justify-center items-center grid sm:flex max-w-[1440px] mx-auto">
        <div className=" lg:ml-10 justify-center items-center sm:justify-start sm:items-start flex">
          <img
            className="object-cover sm:w-[218px] w-[99px] h-[77px] sm:h-[168px]"
            src={data?.image}
            alt=""
          />
        </div>

        <div
          style={{ color: data?.textColor }}
          className="text-start sm:mt-0 mt-4   text-[21px] sm:text-[32px] font-[700] font-for-Results-page"
        >
          <div className="sm:text-start text-center">
            <p className=" sm:ml-[10%] sm:mr-[20%]"> {data?.text} </p>
          </div>
          <div className="mt-5 sm:text-start text-center">
            <p
              style={{ color: data?.certificateByColor }}
              className="sm:ml-[10%] text-[16px]  sm:text-[24px] "
            >
              {data?.certificateBy}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certificate;
