import React from "react";

function GymAbout({ data }) {
  
  return (
    <div className="w-[100%] mt-[108px] bg-[#fff] pb-10 fontFamilyForGym ">
      <div className="max-w-[1440px] mx-auto relative lg:grid hidden">
        <div
          style={{ color: "rgba(120, 120, 120, 0.30)" }}
          className="text-center z-20 py-[50px]"
        >
          <p className="text-[21px] font-[400]"> {data?.position} </p>
          <p className="text-[100px] font-[700] fontFeature "> {data?.name} </p>
        </div>

        <div className="w-[80%] z-20 mx-auto">
          <div className="text-[#787878]">
            <p className="text-[#1865FA] fontFeature text-[52px] font-[900] ">
              {" "}
              {data?.heading}{" "}
            </p>
            <p className="  text-[21px] font-[400] mr-72"> {data?.aboutme} </p>
          </div>
          <div>
            <div className="text-[21px] flex items-center gap-[42px] mt-[24px] font-[600] text-[#1865FA]">
              <p>Height: {data?.height}</p>
              <p>Skills: {data?.skills}</p>
            </div>
            <div className="text-[21px] flex items-center gap-[42px] mt-[10px] font-[600] text-[#1865FA]">
              <p>Weight: {data?.weight}</p>
              <p>WeightLift: {data?.weightLift}</p>
            </div>
          </div>

          <div className="mt-[60px] ">
            <p className="text-[#1865FA] text-[32px] font-[600]">
              {" "}
              {data?.fitnessLevel?.heading}{" "}
            </p>
            <p className="text-[18px] text-[#787878] mr-72">
              {" "}
              {data?.fitnessLevel?.para}{" "}
            </p>
          </div>

          <div className="mt-[46px] ">
            <p className="text-[#1865FA] text-[32px] font-[600]">
              {" "}
              {data?.lifestylegoals?.heading}{" "}
            </p>
            <p className="text-[18px] text-[#787878] mr-72">
              {" "}
              {data?.lifestylegoals?.para}{" "}
            </p>
          </div>
        </div>


        <div className="absolute right-10 top-[20%]">
<img className="w-full h-[700px] object-cover   " src={data?.image} alt="" />
        </div>
      </div>

{/* Mobile */}


<div className="max-w-[1440px] mx-auto relative grid  pb-44  lg:hidden">
       

        <div className="w-[100%]  mx-auto">
          <div className="text-[#787878]">
            <p className="text-[#1865FA] fontFeature text-[32px] mt-[24px] ml-4 font-[900] ">
              {" "}
              {data?.heading}{" "}
            </p>
            <p className="  text-[16px] leading-[22px] font-[400] mr-10 ml-4"> {data?.aboutme} </p>
          </div>
          <div>
            <div className="ml-4 mr-4 text-[18px] flex items-center gap-[42px] mt-[20px] font-[600] text-[#1865FA]">
              <p>Height: {data?.height}</p>
              <p>Skills: {data?.skills}</p>
            </div>
            <div className="text-[21px] flex items-center ml-4 mr-4 gap-[42px] mt-[10px] font-[600] text-[#1865FA]">
              <p>Weight: {data?.weight}</p>
              <p>WeightLift: {data?.weightLift}</p>
            </div>
          </div>

          <div className="mt-[60px] ml-4 mr-4">
            <p className="text-[#1865FA]  text-[24px] font-[600]">
              {" "}
              {data?.fitnessLevel?.heading}{" "}
            </p>
            <p className="text-[18px] text-[#787878] ">
              {" "}
              {data?.fitnessLevel?.para}{" "}
            </p>
          </div>

          <div className="mt-[46px] ml-4 mr-4">
            <p className="text-[#1865FA] text-[24px] font-[600]">
              {" "}
              {data?.lifestylegoals?.heading}{" "}
            </p>
            <p className="text-[18px] text-[#787878] ">
              {" "}
              {data?.lifestylegoals?.para}{" "}
            </p>
          </div>
        </div>

        <div
          style={{ color: "rgba(120, 120, 120, 0.30)" }}
          className="text-center mt-[27px]"
        >
          <p className="text-[18px] font-[400]"> {data?.position} </p>
          <p className="text-[40px] font-[700] fontFeature "> {data?.name} </p>
        </div>
        <div className="absolute bottom-0 right-0 ">
<img className="w-full h-[300px] object-cover   " src={data?.image} alt="" />
        </div>
      </div>
      
    </div>
  );
}

export default GymAbout;
