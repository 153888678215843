import React, { useEffect, useRef, useState } from "react";
import styles from "./FeaturedMagazine.module.css";
import SingleBanner from "../MagazineTemplate/SingleBanner";
import CampaignAndPortfolio from "../MagazineTemplate/CampaignAndPortfolio";
import ProfileBanner from "../MagazineTemplate/ProfileBanner";

import Highlights from "../MagazineTemplate/Highlights";
import Featuring from "../MagazineTemplate/Featuring";
import Influencers from "../MagazineTemplate/Influencers";
import { filterChips } from "../../common/Constants";
import Ads from "../MagazineTemplate/Ads";
import Feedbacks from "../MagazineTemplate/Feedbacks";
import JudgesVideos from "../MagazineTemplate/components/judgesVideos/JudgesVideos";

export default function FeaturedMagazine({ data, sectionData }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedChip, setSelectedChip] = useState();
  const [scroll, setScroll] = useState(false);
  const cardRefs = useRef([]);
  const sliderRef = useRef(null);
  const sectionRefs = useRef({});

  const scrollBehaviour = () => {
    if (window.scrollY >= 800) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", scrollBehaviour);

  useEffect(() => {
    const slider = sliderRef.current;
    const handleScroll = () => {
      const sliderWidth = slider.offsetWidth;
      const sliderLeft = slider.getBoundingClientRect().left;
      const activeSlide = Array.from(slider.children).find((slide) => {
        const slideLeft = slide.getBoundingClientRect().left;
        return slideLeft >= sliderLeft;
      });

      setActiveIndex(
        activeSlide ? Array.from(slider.children).indexOf(activeSlide) : 0
      );
      console.log(Array.from(slider.children).indexOf(activeSlide), "array");
    };
    slider?.addEventListener("scroll", handleScroll);
    return () => slider?.removeEventListener("scroll", handleScroll);
  }, []);

  // const handleNext = () => {
  //   let currentIndex = activeIndex;
  //   let nextIndex;
  //   if (currentIndex + 1 == filterChips?.length) {
  //     nextIndex = currentIndex;
  //   } else {
  //     nextIndex = currentIndex + 1;
  //   }
  //   setActiveIndex(nextIndex);
  //   if (cardRefs.current[nextIndex]) {
  //     cardRefs.current[nextIndex].scrollIntoView({
  //       behavior: "smooth",
  //       inline: "center",
  //       block: "nearest",
  //     });
  //   }
  // };

  // const handlePrevious = () => {
  //   let currentIndex = activeIndex;
  //   let prevIndex;
  //   if (currentIndex == 0) {
  //     prevIndex = currentIndex;
  //   } else {
  //     prevIndex = currentIndex - 1;
  //   }
  //   setActiveIndex(prevIndex);
  //   if (cardRefs.current[prevIndex]) {
  //     cardRefs.current[prevIndex].scrollIntoView({
  //       behavior: "smooth",
  //       inline: "center",
  //       block: "nearest",
  //     });
  //   }
  // };

  const scrollToSection = (section) => {
    window.scrollTo({
      top: sectionRefs.current[section].offsetTop - 140,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={`${styles.featuredMagazine}  `}>
      <div className="text-[#575757] justify-center  items-center mt-3 flex max-w-[1440px] mx-auto   ">
        {/* <div className="justify-start xl:ml-10 ml-4 items-start flex  ">
          {activeIndex > 0 && (
            <button
              onClick={handlePrevious}
              className="border-[1px] py-2 px-2  rounded-[50px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M12.2416 5.59173C12.3189 5.66882 12.3802 5.76039 12.422 5.86121C12.4638 5.96202 12.4854 6.07009 12.4854 6.17923C12.4854 6.28837 12.4638 6.39643 12.422 6.49725C12.3802 6.59806 12.3189 6.68963 12.2416 6.76673L9.00832 10.0001L12.2416 13.2334C12.3975 13.3892 12.485 13.6005 12.485 13.8209C12.485 14.0412 12.3975 14.2526 12.2416 14.4084C12.0858 14.5642 11.8745 14.6517 11.6541 14.6517C11.4338 14.6517 11.2225 14.5642 11.0666 14.4084L7.24165 10.5834C7.1644 10.5063 7.10311 10.4147 7.06129 10.3139C7.01947 10.2131 6.99795 10.105 6.99795 9.99589C6.99795 9.88675 7.01947 9.77868 7.06129 9.67787C7.10311 9.57706 7.1644 9.48549 7.24165 9.40839L11.0666 5.58339C11.3833 5.26673 11.9166 5.26673 12.2416 5.59173Z"
                  fill="#161616"
                />
              </svg>
            </button>
          )}
        </div> */}

        <div
          className={`w-[100%]  ${
            scroll && " fixed top-0  z-50"
          } bg-[#fff]  py-5  flex items-center  `}
        >
          <div className="ml-4  sm:ml-20 ">
            <button
              className="border-[1px] border-[#575757] py-[10px] px-[20px] uppercase sm:text-[16px] text-[14px] whitespace-nowrap"
              style={{ borderRadius: "0px 32px 32px 0px" }}
            >
              {" "}
              Stories
            </button>
          </div>
          <ul className="mx-4 sm:mx-10  font-headerLinks  justify-start items-start grid-flow-col grid gap-[20px] overflow-x-auto [&::-webkit-scrollbar]:hidden">
            {filterChips?.map((item, index) => (
              <li
                ref={(element) => {
                  cardRefs.current[index] = element;
                }}
              >
                <button
                  className={`whitespace-nowrap  ${
                    selectedChip === item?.id && "bg-black text-[#F6F6F6]"
                  }  hover:bg-black hover:text-[#F6F6F6] text-[14px] sm:text-[16px] border-[1px]  py-2 px-5 rounded-[50px] `}
                  onClick={() => {
                    scrollToSection(item?.id);
                    setSelectedChip(item?.id);
                  }}
                >
                  {" "}
                  {item?.name}{" "}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="max-w-[1440px] mx-auto">
        <JudgesVideos data={data?.judgesVideos} />
      </div>

      {sectionData?.map((obj, index) => {
        return (
          <div
            ref={(ref) => {
              sectionRefs.current[obj?.sectionData?.secId] = ref;
            }}
          >
            {obj?.sectionProps?.sectionType == "SINGLEBANNER" && (
              <SingleBanner
                data={obj?.sectionData}
                theme={obj?.sectionProps?.colorTheme}
              />
            )}
            {obj?.sectionProps?.sectionType == "CAMPAIGN_AND_PORTIFOLIO" && (
              <CampaignAndPortfolio
                data={obj?.sectionData}
                theme={obj?.sectionProps?.colorTheme}
              />
            )}

            {obj?.sectionProps?.sectionType == "ADS" && (
              <Ads data={obj?.sectionData} theme={obj?.sectionProps} />
            )}

            {obj?.sectionProps?.sectionType == "PORTIFOLIO" && (
              <ProfileBanner
                data={obj?.sectionData}
                theme={obj?.sectionProps?.colorTheme}
              />
            )}

            {obj?.sectionProps?.sectionType == "HIGHLIGHTS" && (
              <Highlights
                data={obj?.sectionData}
                theme={obj?.sectionProps?.colorTheme}
              />
            )}

            {obj?.sectionProps?.sectionType == "FEATURING" && (
              <Featuring
                data={obj?.sectionData}
                theme={obj?.sectionProps?.colorTheme}
              />
            )}

            {obj?.sectionProps?.sectionType == "INFLUENCERS" && (
              <Influencers
                data={obj?.sectionData}
                theme={obj?.sectionProps?.colorTheme}
              />
            )}

            {obj?.sectionProps?.sectionType == "FEEDBACKS" && (
              <Feedbacks data={obj?.sectionData} theme={obj?.sectionProps} />
            )}
          </div>
        );
      })}
    </div>
  );
}
