import React, { useContext, useEffect } from "react";

import { Link } from "react-router-dom";
import SupporTeam from "./SupporTeam";

function Support() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  const data = {
    contactUs: [
      {
        id: 1,
        image: "https://eguidancelabs.com/assets/about/arun.jpg",
        name: "Arun Unnikrishnan",
        job: "Founder/CEO",
      },
      {
        id: 2,
        image: "https://eguidancelabs.com/assets/about/athira.jpeg",
        name: "Athira A Nair ",
        job: "Product Owner",
      },
      {
        id: 3,
        image: "https://eguidancelabs.com/assets/about/gishnu.jpeg",
        name: "Gishnu Murali",
        job: "Product Designer",
      },
      {
        id: 4,
        image: "https://eguidancelabs.com/assets/about/nelvin.jpeg",
        name: "Muhammed Nelvin",
        job: "Backend Developer",
      },
      {
        id: 5,
        image: "https://eguidancelabs.com/assets/about/akashkg.jpg",
        name: "Akash K G",
        job: "Backend Developer",
      },
      {
        id: 5,
        image: "https://eguidancelabs.com/assets/about/roopesh.jpg",
        name: "Roopesh K P",
        job: "Backend Developer",
      },
      {
        id: 6,
        image: "https://eguidancelabs.com/assets/about/ajay.jpeg",
        name: "Ajay Krishna R K",
        job: "Frontend Developer",
      },
      {
        id: 7,
        image: "https://eguidancelabs.com/assets/about/indrajith.jpeg",
        name: "Indrajith A C",
        job: "Frontend Developer",
      },
      {
        id: 8,
        image: "https://eguidancelabs.com/assets/about/blesson.jpeg",
        name: "Blesson T Kurian",
        job: "Frontend Developer",
      },

      {
        id: 9,
        image: "https://eguidancelabs.com/assets/about/nishitha.jpeg",
        name: "Nishitha M A",
        job: "Flutter Developer",
      },
      {
        id: 10,
        image: "https://eguidancelabs.com/assets/about/megha.jpeg",
        name: "Megha Manoj",
        job: "Marketing Assosiate",
      },
    ],
  };

  const contactDetails = [
    {
      id: 1,
      name: "Contact",
      number: "+91 8089551777 ",
      link: "tel:+918089551777 ",
    },

    {
      id: 2,
      name: "Email",
      number: "support@goviral.world",
      link: "mailto:support@goviral.world",
    },
  ];

  return (
    <div className="lg:px-[10%] px-[20px] text-[#161616] ">
      <div className="mt-[80px] bg-[#F9F9F9] w-full h-full  p-[20px] lg:p-[60px] rounded-[16px]">
        <div className="lg:mr-[40%]">
          <p className="text-[28px] sm:text-[40px] lg:text-[52px] leading-[33px] sm:leading-[45px] lg:leading-[62px] font-[600]  ">
            Get in touch with us for more information
          </p>
          <p className="text-[16px] sm:text-[18px] mt-[14px] sm:mr-[30%] leading-[22px] sm:leading-[25px] font-[400] ">
            If you need help, have a questions, have any doubts, we’re here for
            you
          </p>
        </div>
        <div className="mt-[40px] lg:mt-[80px] grid lg:flex  items-center gap-[30px] lg:gap-[60px] border-t-[#3171F6] border-t-[1px] border-opacity-20 pt-[30px]">
          {contactDetails?.map((item, index) => (
            <div className="">
              <p className="text-[#3171F6] text-[16px]"> {item?.name} </p>
              <p className="text-[#161616] text-[16px] mt-[15px] lg:mt-[23px]">
                {" "}
                {item?.number}{" "}
              </p>
            </div>
          ))}
          <div>
            <p className="text-[#3171F6] text-[16px]"> WhatsApp </p>
            <div className="flex">
              <Link
                to={`https://wa.me/919567183183`}
                className="mt-[15px] lg:mt-[23px] "
              >
                {" "}
                9567183183 ,
              </Link>
              <Link
                to={`https://wa.me/918089551777`}
                className="mt-[15px] lg:mt-[23px]"
              >
                {" "}
                8089551777
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* about Company */}
      <div className="border-t-[1px] border-t-[#D9D9D9] mt-[30px]  lg:mt-[60px] pt-[30px] lg:pt-[60px] border-opacity-50">
        <p className="text-[#F96149] uppercase">About company</p>
        <p className="text-[32px] sm:text-[40px] lg:text-[52px] font-[600] ">
          Goviral Magazine
        </p>
        <p className="text-[16px] font-[400] lg:mr-[50%]  leading-[22px]">
          GoViral World, the ultimate platform for unleashing the power of
          online contests and entertainment! 🚀 We simplify your life by
          eliminating the hassle of offline auditions with our seamless digital
          solution.
        </p>
      </div>
      <SupporTeam data={data?.contactUs} />
    </div>
  );
}

export default Support;
