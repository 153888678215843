import React from "react";
import { HeaderLinks } from "../../common/Constants";

function DeskHeader() {
  return (
    <div className="justify-between ml-20  mr-10 items-center flex">
      <div className="z-10 ml-[7%] mt-8  text-[white] font-[600] text-[32px]">
        <p>Goviral</p>
      </div>

      <div
        className="flex gap-7 mt-8 font-headerLinks text-[19px] font-[700] text-[white] cursor-pointer"
        style={{ listStyleType: "none" }}
      >
        {HeaderLinks?.map((item) => (
          <li key={item?.name}>{item?.name}</li>
        ))}
      </div>
    </div>
  );
}

export default DeskHeader;
