import React from "react";

export default function Judges({ campaignData }) {
  return (
    <div className="template-inner-container mt-[30px]">
      <div className="template-judges-container ">
        <div className="template-judges  ">
          {campaignData?.judges?.map((obj, index) => {
            return (
              <div className="template-judge " key={index}>
                <div className="justify-center items-center grid">
                <div className="   w-[80px] h-[80px] sm:w-[100px] sm:h-[100px]  border-[#CB8B4D] rounded-full">
                  <img  className="border-[5px] border-[#CB8B4D]  w-[80px] h-[80px] sm:w-[100px] sm:h-[100px] rounded-full" src={obj?.logo} />
                  
                </div>

                </div>
                <div className="justify-center items-center text-center ">
                  <p className="text-[#1B3332]  template-judge-name text-[10px]  lg:text-[30px] ">{obj?.name}</p>
                  <p className="text-[#58795B]  template-judge-name text-[10px] lg:text-[23px] ">{obj?.position}</p>
                  </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
