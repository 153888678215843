import React, { useEffect, useState } from "react";
import { modelLinks } from "../../../../common/Constants";
import UseMediaQuery from "../../../../hooks/UseMediaQuery";

function MinimalModelLandingPage({
  data,
  aboutRef,
  achievementsRef,
  brandsRef,
  worksRef,
  contactRef,
  scrollToSection,
}) {
  const isDesktop = UseMediaQuery("(min-width:1000px)");

  const isDesktopLarge = UseMediaQuery("(min-width:1100px)");

  const [scrollingBehaviour, setScrollingBehaviour] = useState(false);
  const [scrollValue, setScrollValue] = useState();

  const scrollBehaviour = () => {
    if (window.scrollY >= scrollValue) {
      setScrollingBehaviour(true);
    } else {
      setScrollingBehaviour(false);
    }
  };

  window.addEventListener("scroll", scrollBehaviour);

  useEffect(() => {
    if (isDesktopLarge) {
      setScrollValue(900);
    } else if (isDesktop) {
      setScrollValue(700);
    } else {
      setScrollValue(550);
    }
  }, [scrollBehaviour]);

  return (
    <div className="max-w-[1440px]  ml-auto mr-auto">
      <div className="modelMinimalFontFamily  items-start   grid grid-cols-2 sm:grid-cols-3">
        <div
          className={` ${
            scrollingBehaviour ? "hidden" : "sticky"
          } xl:top-[16%] top-[18%]  text-[black] bg-white    sm:block hidden  ml-16 sm:mt-[35%] lg:mt-[27%]  sm:text-[20px] xl:text-[34px] font-[400] `}
        >
          <p className={`mt-3 cursor-pointer`} onClick={() => scrollToSection(aboutRef)}>
          {data?.headLinks?.about}
          </p>

          <p
            className={`mt-3 cursor-pointer`}
            onClick={() => scrollToSection(achievementsRef)}
          >
             {data?.headLinks?.achievements}
          </p>

          <p className={`mt-3 cursor-pointer`} onClick={() => scrollToSection(brandsRef)}>
          {data?.headLinks?.majorWorks}
          </p>

          <p className={`mt-3 cursor-pointer`} onClick={() => scrollToSection(worksRef)}>
          {data?.headLinks?.otherWorks}
          </p>

          <p className={`mt-3 cursor-pointer`} onClick={() => scrollToSection(contactRef)}>
          {data?.headLinks?.contact}
          </p>
        </div>

        <div className="sm:ml-0 ml-4 mt-3 sm:mt-[26%]">
          <div className="mt-[4.5rem] sm:mt-12 w-[90%]">
            <img
              className="h-[262px] sm:h-[660px] w-full object-cover"
              src={data?.image1}
              alt=""
            />
          </div>

          <div className="mt-5 sm:mt-10 w-[90%]">
            <img
              className="h-[262px] sm:h-[660px] w-full object-cover"
              src={data?.image2}
              alt=""
            />
          </div>
        </div>

        <div className="sm:mt-[30%] mt-7">
          <div className="sm:leading-[30px]  xl:leading-[47px] leading-[20px] mt-[5rem] sm:text-[20px] xl:text-[34px] font-[400] text-[black]">
            <p> {data?.about} </p>
          </div>

          <div className="mt-5 sm:mt-12 w-[90%]">
            <img
              className="h-[262px] sm:h-[660px] w-full object-cover"
              src={data?.image3}
              alt=""
            />
          </div>

          <div className="mt-5 sm:mt-10 w-[90%]">
            <img
              className="h-[262px] sm:h-[660px] w-full object-cover"
              src={data?.image4}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MinimalModelLandingPage;
