import React, {  useContext, useEffect, useState } from "react";

import SelectedImage from "./SelectedImage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { imageData } from "../../../../context/dataContext";



function GalleryImages({
  data,
  colNo,
  textColor,
  name,
  prevIcon,
  nextIcon,
  fontFamily,
  bgColor,
}) {
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [open, setOpen] = useState(false);
  const {setState} = useContext(imageData)
  const navigate = useNavigate()
  const location = useLocation()
  const originalArray = data;

useEffect(()=>{
  if(textColor && bgColor && fontFamily) return setState({
    bgColor: bgColor,
    textColor:textColor,
    fontFamily: fontFamily,
    prevIcon:prevIcon,
    nextIcon:nextIcon
  })

},[textColor,bgColor,fontFamily,prevIcon,nextIcon])


  const calculateNumArrays = () => {
    return window.innerWidth < 768 ? 2 : colNo;
  };
  const numArrays = calculateNumArrays();
  const splitArrays = Array.from({ length: numArrays }, () => []);

  for (let i = 0; i < originalArray.length; i++) {
    const arrayIndex = i % numArrays;
    splitArrays[arrayIndex].push(originalArray[i]);
  }
  const handleClick = (files) => {
    // setOpen(true);
    let index = data?.indexOf(files);
    // setSelectedPhoto(index);
    navigate(`galleryview/${index}`,{state:{previousLocation: location?.pathname }})
  };

 

  const handleClose = () => {
    setOpen(false);
    setSelectedPhoto(null);
  };

  const nextPhoto = () => {
    if (selectedPhoto < originalArray.length - 1)
      return setSelectedPhoto(selectedPhoto + 1);
  };

  const prevPhoto = () => {
    if (selectedPhoto > 0) return setSelectedPhoto(selectedPhoto - 1);
  };

  return (
    <>
      {splitArrays?.map((splitArray, index) => (
        <div>
          {splitArray?.map((item) => (
            <div key={item.id}>
              {/* {open && (
                <>
                  (
                  <SelectedImage
                    images={originalArray.map((item) => item.image)}
                    selectedPhoto={selectedPhoto}
                    onClose={handleClose}
                    nextPhoto={nextPhoto}
                    prevPhoto={prevPhoto}
                    name={name}
                    originalArray={originalArray}
                    bgColor={bgColor}
                    prevIcon={prevIcon}
                    nextIcon={nextIcon}
                    textColor={textColor}
                    fontFamily={fontFamily}
                  />
                  ){" "}
                </>
              )} */}

              <div onClick={() => handleClick(item)}>
           <img
                  className="cursor-pointer w-[100%] sm:mt-6 mt-3"
                  src={item.image}
                  alt=""
                />
             
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
}

export default GalleryImages;
