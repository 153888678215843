import React from "react";
import useMediaQuery from "../../../hooks/UseMediaQuery";
import Line from "../line/Line";
import { Link } from "react-router-dom";

function DrawingWinners({
  heading,
  paraText,
  crowdWinnersPara,
  headingColor,
  cardBgColor,
  paraColor,
  bgColor,
  textColor,
  results,
  lineColor
}) {
  const isDesktop = useMediaQuery("(min-width:640px)");

  return (
    <>
      <div className=" font-for-Results-page">
        <div className="justify-evenly items-start grid xl:flex">
          <div className="items-center  sm:mx-0 mx-4 md:w-[40%] mt-7 sm:mt-20">
            <p
              style={{ color: textColor }}
              className="uppercase font-[700] sm:ml-14 text-[21px] sm:text-[24px]"
            >
              {heading}
            </p>
            <p
              style={{ color: paraColor }}
              className="mt-2 sm:mt-5 sm:ml-14 sm:mr-10 text-[13px] sm:text-[17px] leading-[22px]"
            >
              {" "}
              {paraText}{" "}
            </p>
          </div>

          <div
            className={`${
              !isDesktop && "result-page-winners-scroll"
            } flex sm:grid sm:grid-cols-2 md:grid-cols-3 lg:gap-10 mt-7 sm:mt-20 xl:ml-0 ml-4 sm:ml-10  xl:mr-20 h-full`}
          >
            {results?.map((item) => (
              <Link className="hover:text-black hover:no-underline" to={item?.link && item?.link} target={item?.link && "_blank"}>
              <div
                style={{ background: item?.cardGradiant }}
                className="w-[250px]  h-[351px] flex-shrink-0"
              >
                <div
                  className="relative"
                  // style={{ background: item?.cardGradiant }}
                >
                  <div className="justify-end items-end flex">
                    <div className="w-[223px]  h-[243px] ">
                      <img
                        className="w-full h-full  shrink-0 object-cover rounded-tl-[50%]"
                        src={item?.image}
                      />
                    </div>
                  </div>
                  <div className="absolute top-2 left-2 font-headerLinks">
                    {item?.prizeTag ? (
                      <p className="font-[700] text-[18px] text-[white]">
                        {" "}
                        {item?.prizeTag}{" "}
                      </p>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M5 16L3 5L8.5 10L12 4L15.5 10L21 5L19 16H5ZM19 19C19 19.6 18.6 20 18 20H6C5.4 20 5 19.6 5 19V18H19V19Z"
                          fill="#F6F6F6"
                        />
                      </svg>
                    )}
                  </div>
                </div>

                <div
                  style={{ color: paraColor, background: bgColor }}
                  className="text-start py-3 "
                >
                  <p className="uppercase text-[13px] font-[400] ml-2 ">
                    {" "}
                    {item?.prize}{" "}
                  </p>
                  <p
                    style={{ color: textColor }}
                    className=" text-[24px] font-[700] ml-2  "
                  >
                    {" "}
                    {item?.name}{" "}
                  </p>
                  <p className="text-[13px] ml-2 ">{item?.competition}</p>
                </div>
              </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    
      
       
    </> 
  );
}

export default DrawingWinners;
