import React from "react";

import useMediaQuery from "../../../../hooks/UseMediaQuery";

function Banner({ data }) {
  const isDesktop = useMediaQuery("(min-width:640px)");
  return (
    <div className="w-full h-full ">
      <img
        className="w-full h-full object-cover "
        src={isDesktop ? data?.bannerImage : data?.mobBannerImage}
        alt=""
      />
    </div>
  );
}

export default Banner;
