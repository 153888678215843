import React, { useEffect, useState } from "react";
import HighlightOneSection from "./HighlightsComponents/HighlightOneSection";
import MoreHighlights from "./HighlightsComponents/MoreHighlights";
import Line from "./components/Line";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import useMediaQuery from "../../hooks/UseMediaQuery";

function WebHighlights({ data, theme }) {
  const isDesktop = useMediaQuery("(min-width:640px)")
  const [highlightsData, setHighLightsData] = useState();
  const [filteredData, setFilteredData] = useState();
  const location = useLocation();

  const getHighLightsData = async () => {
    try {
      let config = {
        method: "get",
        // url: `/highlights/${data?.secId}.json`,
        url:`https://storage.googleapis.com/goviral_magazine_data/highlights/${data?.secId}.json`

      };
      let result = await axios(config);
      setHighLightsData(result?.data?.sectionData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHighLightsData();
  }, []);

  useEffect(() => {
    const filterData = highlightsData?.highLights?.filter(
      (item) => item?.id !== highlightsData?.id
    );
    setFilteredData(filterData);
  }, [highlightsData]);

  return (
    <div className="max-w-[1440px] mx-auto">
      <div className="justify-start mt-7 items-start text-start">
        <p className="uppercase fonts-for-Sathosi-homepage font-[700] ml-4 sm:ml-20 text-[18px] sm:text-[24px]">
          HIGHLIGHTS of goviral world
        </p>
      </div>
      <div className="sm:flex hidden">
        <HighlightOneSection data={highlightsData} theme={theme} />
      </div>
      <div className="sm:mt-10 mt-7">
        <Line />
      </div>

      <div>
        <div className="fonts-for-Sathosi-homepage justify-between ml-4 sm:ml-20 mt-5 text-[18px] font-[500] mr-4 sm:mr-20 uppercase items-center flex">
          <p className="">{highlightsData?.secHeading} </p>
          <Link
            state={{ previousLocation: location?.pathname }}
            to={`/goviral/highlights/${highlightsData?.secId}`}
            className="hover:text-[#161616] text-[#575757] cursor-pointer"
          >
            {" "}
            view All{" "}
          </Link>
        </div>
        <MoreHighlights
          url={"/goviral/highlights/"}
          secId={highlightsData?.secId}
          data={isDesktop ? (filteredData ? filteredData : highlightsData?.highLights) : highlightsData?.highLights}
          theme={theme}
        />
      </div>
      <div className="mt-12"> 
        <Line />
      </div>
    </div>
  );
}

export default WebHighlights;
