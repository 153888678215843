import React, { useCallback, useEffect, useRef, useState } from "react";

import { isEmpty } from "lodash";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CampaignCard from "../../../Components/CampaignCard/CampaignCard";
import Back from "../../../Components/back/Back";
import ImageGallery from "../../../Components/ImageGallery/ImageGallery";
import useMediaQuery from "../../../hooks/UseMediaQuery";
import Footer from "../../../Components/footer/Footer";

export default function BandPortfolioPage({ orgDetials }) {
  const [dataloading, setDataLoading] = useState(false);
  const [itemsCount, setItemsCount] = useState(8);
  const [hasMore, setHasMore] = useState(true);
  const [paginatedData, setPagenatedData] = useState([]);
  const isDesktop = useMediaQuery("(min-width:640px)");

  const navigate = useNavigate();
  const location = useLocation();
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (!isEmpty(orgDetials?.gallery)) {
      setPagenatedData(orgDetials?.gallery?.slice(0, itemsCount));
    }
  }, [orgDetials]);

  useEffect(() => {
    if (!isEmpty(orgDetials?.gallery)) {
      setPagenatedData(orgDetials?.gallery?.slice(0, itemsCount));
      if (itemsCount >= orgDetials?.gallery?.length) {
        setHasMore(false);
      }
    }
  }, [itemsCount]);

  const observer = useRef();
  const lastElementRef = useCallback((node) => {
    if (dataloading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setItemsCount((prev) => prev + 8);
      }
    });
    if (node) observer.current.observe(node);
  }, []);
  return (
    <div>
      <div
        className="w-full mt-1 sticky top-0 z-10  bg-[#faebd7] py-[20px] flex justify-center"
        onClick={handleNavigateToPrevLocation}
      >
        <div className="sm:ml-5 ml-2 max-w-[1220px] w-full">
          <Back />
        </div>
      </div>
      <div className="mx-[auto] ">
        <div className="bg-[#faebd7] p-[20px]">
          <div className="max-w-[1220px] mx-auto flex flex-col md:flex-row gap-0 md:gap-[64px] justify-start items-start">
            <div className="flex gap-[8px]">
              <div className="w-[52px] md:w-[122px] h-[52px] md:h-[122px] shrink-0">
                <img
                  className="w-[100%]  h-[100%] object-cover"
                  src={orgDetials?.logo}
                  alt=""
                />
              </div>
              <div className="md:hidden block">
                <p className="text-[black] font-semibold text-[18px] md:text-[28px] leading-[26px] md:leading-[39px] ">
                  {orgDetials?.name}
                </p>
                <p className="mt-[5px] text-[#000000] font-semibold text-[15px] md:text-[17px] leading-[20px] md:leading-[23px] max-w-[399px]">
                  {orgDetials?.tagLine}
                </p>
              </div>
            </div>
            <div>
              <p className="text-[black] font-semibold text-[18px] md:text-[28px] leading-[26px] md:leading-[39px] hidden md:block">
                {orgDetials?.name}
              </p>
              <p className="mt-[22px] md:mt-[32px] text-[#000000] font-semibold text-[15px] md:text-[17px] leading-[20px] md:leading-[23px] max-w-[399px]  hidden md:block">
                {orgDetials?.tagLine}
              </p>
              <p className="mt-[12px] md:mt-[16px] text-[#787878] font-normal text-[12px] md:text-[12px] leading-[18px] md:leading-[18px] max-w-[464px]">
                {orgDetials?.about}
              </p>
              <div className="flex items-start justify-start gap-[10px] mt-[20px] md:mt-[24px] ">
                {orgDetials?.socialMediaLinks?.facebook && (
                  <Link
                    target={orgDetials?.socialMediaLinks?.facebook && "_blank"}
                    to={orgDetials?.socialMediaLinks?.facebook}
                    className=""
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={"18"}
                      height={"23"}
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 3.8C0 1.70132 1.70132 0 3.8 0H15.2C17.2986 0 19 1.70132 19 3.8V15.2C19 17.2986 17.2986 19 15.2 19H3.8C1.70132 19 0 17.2986 0 15.2V3.8ZM3.8 1.9C2.75066 1.9 1.9 2.75066 1.9 3.8V15.2C1.9 16.2494 2.75066 17.1 3.8 17.1H9.5V10.45H8.55C8.02532 10.45 7.6 10.0247 7.6 9.5C7.6 8.97532 8.02532 8.55 8.55 8.55H9.5V7.125C9.5 5.28865 10.9887 3.8 12.825 3.8H13.395C13.9197 3.8 14.345 4.22533 14.345 4.75C14.345 5.27467 13.9197 5.7 13.395 5.7H12.825C12.038 5.7 11.4 6.33799 11.4 7.125V8.55H13.395C13.9197 8.55 14.345 8.97532 14.345 9.5C14.345 10.0247 13.9197 10.45 13.395 10.45H11.4V17.1H15.2C16.2494 17.1 17.1 16.2494 17.1 15.2V3.8C17.1 2.75066 16.2494 1.9 15.2 1.9H3.8Z"
                        fill="#000000"
                      />
                    </svg>
                  </Link>
                )}
                {orgDetials?.socialMediaLinks?.instagram && (
                  <Link
                    target={orgDetials?.socialMediaLinks?.instagram && "_blank"}
                    to={orgDetials?.socialMediaLinks?.instagram}
                    className=""
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={"18"}
                      height={"23"}
                      viewBox="0 0 20 19"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.99996 14.682C13.0124 14.682 15.4545 12.362 15.4545 9.50018C15.4545 6.63834 13.0124 4.31836 9.99996 4.31836C6.98749 4.31836 4.54541 6.63834 4.54541 9.50018C4.54541 12.362 6.98749 14.682 9.99996 14.682ZM9.99996 12.9547C12.0082 12.9547 13.6363 11.408 13.6363 9.50018C13.6363 7.59228 12.0082 6.04563 9.99996 6.04563C7.99165 6.04563 6.36359 7.59228 6.36359 9.50018C6.36359 11.408 7.99165 12.9547 9.99996 12.9547Z"
                        fill="#000000"
                      />
                      <path
                        d="M15.4545 3.45459C14.9524 3.45459 14.5454 3.84126 14.5454 4.31823C14.5454 4.79519 14.9524 5.18186 15.4545 5.18186C15.9566 5.18186 16.3636 4.79519 16.3636 4.31823C16.3636 3.84126 15.9566 3.45459 15.4545 3.45459Z"
                        fill="#000000"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.594509 2.82932C0 3.93778 0 5.38882 0 8.29091V10.7091C0 13.6112 0 15.0622 0.594509 16.1706C1.11745 17.1457 1.95189 17.9384 2.97824 18.4352C4.14503 19 5.67245 19 8.72727 19H11.2727C14.3275 19 15.855 19 17.0217 18.4352C18.0481 17.9384 18.8825 17.1457 19.4055 16.1706C20 15.0622 20 13.6112 20 10.7091V8.29091C20 5.38882 20 3.93778 19.4055 2.82932C18.8825 1.8543 18.0481 1.06158 17.0217 0.564784C15.855 0 14.3275 0 11.2727 0H8.72727C5.67245 0 4.14503 0 2.97824 0.564784C1.95189 1.06158 1.11745 1.8543 0.594509 2.82932ZM11.2727 1.72727H8.72727C7.16985 1.72727 6.11114 1.72862 5.2928 1.79213C4.49567 1.854 4.08804 1.96615 3.80367 2.10379C3.11945 2.435 2.56315 2.96347 2.21452 3.61349C2.06963 3.88363 1.95158 4.27089 1.88645 5.02816C1.8196 5.80558 1.81818 6.81136 1.81818 8.29091V10.7091C1.81818 12.1887 1.8196 13.1944 1.88645 13.9718C1.95158 14.7291 2.06963 15.1164 2.21452 15.3865C2.56315 16.0365 3.11945 16.565 3.80367 16.8962C4.08804 17.0338 4.49567 17.146 5.2928 17.2079C6.11114 17.2713 7.16985 17.2727 8.72727 17.2727H11.2727C12.8302 17.2727 13.8888 17.2713 14.7072 17.2079C15.5044 17.146 15.912 17.0338 16.1964 16.8962C16.8805 16.565 17.4368 16.0365 17.7855 15.3865C17.9304 15.1164 18.0485 14.7291 18.1135 13.9718C18.1804 13.1944 18.1818 12.1887 18.1818 10.7091V8.29091C18.1818 6.81136 18.1804 5.80558 18.1135 5.02816C18.0485 4.27089 17.9304 3.88363 17.7855 3.61349C17.4368 2.96347 16.8805 2.435 16.1964 2.10379C15.912 1.96615 15.5044 1.854 14.7072 1.79213C13.8888 1.72862 12.8302 1.72727 11.2727 1.72727Z"
                        fill="#000000"
                      />
                    </svg>
                  </Link>
                )}
                {orgDetials?.socialMediaLinks?.youtube && (
                  <Link
                    target={orgDetials?.socialMediaLinks?.youtube && "_blank"}
                    to={orgDetials?.socialMediaLinks?.youtube}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={"23"}
                      height={"23"}
                      viewBox="0 0 28 20"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21.3684 18.2935C24.3641 18.3971 26.883 16.1781 27 13.3325V5.96448C26.883 3.11888 24.3641 0.899881 21.3684 1.00349H6.6316C3.63592 0.899881 1.11697 3.11888 1 5.96448V13.3325C1.11697 16.1781 3.63592 18.3971 6.6316 18.2935H21.3684Z"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.3603 6.15968L17.4696 8.81241C17.7358 8.96011 17.8995 9.23095 17.8995 9.52377C17.8995 9.81671 17.7358 10.0875 17.4696 10.2351L13.3603 13.1374C12.5803 13.64 11.3999 13.2115 11.3999 12.426V6.86857C11.3999 6.08681 12.5816 5.65703 13.3603 6.15968Z"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isEmpty(orgDetials?.campaigns) && (
          <div className="max-w-[1220px] mx-auto px-[20px] pt-[20px] md:pt-[50px] pb-[20px]">
            <p className="clashFont mb-[10px] text-[black] font-semibold text-[21px] md:text-[28px] leading-[24px] md:leading-[39px]">
              Campaigns
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-[10px] mt-8">
              {orgDetials?.campaigns?.map((obj, index) => {
                return <CampaignCard data={obj} />;
              })}
            </div>
          </div>
        )}

        {!isEmpty(orgDetials?.promotions) && (
          <div className="max-w-[1220px] mx-auto px-[20px] pt-[20px] md:pt-[50px] pb-[20px]">
            <p className="clashFont mb-[10px] text-[black] font-semibold text-[21px] md:text-[28px] leading-[24px] md:leading-[39px]">
              Promotions
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-[10px] mt-8">
              {orgDetials?.promotions?.map((obj) => {
                return <CampaignCard data={obj} />;
              })}
            </div>
          </div>
        )}
        {!isEmpty(orgDetials?.gallery) && (
          <div className="bg-[#f2f2f2] pt-[20px] px-[20px] md:pt-[50px] pb-[20px]">
            <div className="max-w-[1220px] mx-auto">
              <p className="clashFont mb-[10px]  text-[black] font-semibold text-[21px] md:text-[28px] leading-[24px] md:leading-[39px]">
                Gallery
              </p>
              <div className="w-full mx-auto mt-[20px]  ">
                <ImageGallery
                  data={paginatedData}
                  reference={lastElementRef}
                  hasMore={hasMore}
                  setItemsCount={setItemsCount}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div>{isDesktop && <Footer />}</div>
    </div>
  );
}
