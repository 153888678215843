function CampaignCard({ data }) {
  return (
    <div
      className="flex items-start flex-col justify-between w-[100%] bg-[#fff] cursor-pointer drop-shadow-lg"
      onClick={() => {
        window.location.href = data?.link;
      }}
    >
      <div className="w-[100%] h-[210px] object-cover relative">
        <img
          className="w-full h-full object-cover"
          src={data?.fileUrl}
          alt=""
        />
        <div className="absolute bottom-[10px] left-0 right-0  justify-center items-center flex">
          <button className="text-[16px] font-[500] bg-slate-100 px-3 py-2  rounded-lg text-[black]">
            Show Details
          </button>
        </div>
      </div>
      <div className="p-[20px] boxColcard">
        <p className="text-[#000000] font-medium text-[16px] md:text-[17px] leading-[20px] md:leading-[20px]">
          {data?.name}
        </p>
        <p className="mt-[10px] text-[#787878] font-nomal text-[12px] md:text-[12px] leading-[14px] md:leading-[14px] max-w-[100%] text-four-line">
          {data?.description}
        </p>
      </div>
    </div>
  );
}

export default CampaignCard;
