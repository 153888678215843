import { useState } from "react";
import { createContext } from "react";



export const  Context = createContext()


export const ContextProvider = ({children})=>{
const [scrollingPositon,setScrollingPosition] = useState()

    return(
        <Context.Provider value={{scrollingPositon,setScrollingPosition}}>
            {children}
        </Context.Provider>
    )
}