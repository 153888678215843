import React from "react";

import {  useLocation, useNavigate } from "react-router-dom";
import Back from "../../../../Components/back/Back";

function LandingPage({ data,styles , id,handleBack}) {
const navigate = useNavigate()
const location = useLocation()


  return (
    <div className="">
   
      <div className={` grid  lg:pb-0 pb-10 relative grid-cols-1 md:grid-cols-2`}>
        <div className=" md:ml-10 ml-4  items-center md:order-1 order-2 justify-center mt-0 md:mt-[25%]">
          <div className="hidden md:block">
            <ul className="leading-[75px]">
                <li className={`${styles.fontStyle} text-[48px] lg:text-[68px] `}>
                {data?.name}
                </li>
                <li className={`${styles.fontStyle} text-[48px] lg:text-[68px] `}>
               {data?.lastName}
                </li>
                <li className={` font-[400]  text-[14px] sm:text-[18px] -mt-5 `}>
               {data?.title}
                </li>
            </ul>
          
          </div>
       

          <div className=" md:mt-10 mt-[2rem]   leading-[20px] ">
            <p className="font-[600] sm:text-[33px] text-[26px]">About</p>
            <p className="font-[400] leading-[26px]  mt-3 text-[16px] sm:text-[18px]">
            {data?.about}
            </p>
            <div className='  '>
    <button onClick={()=>navigate(`/${id}/bio`,{state:{previousLocation:location?.pathname}})}  className='font-[600]    text-[16px] text-white bg-[#2A44A0] p-3 mt-6 rounded-full pl-6 pr-6'>Explore More</button>
  </div>
          </div>
        </div>

        <div className="md:order-2   order-1   ">
          <div className="justify-end items-end flex">
          <img className="w-[95%] " src={data?.profileImage} alt="" />
          </div>
          <div className=" md:hidden ml-4  bottom-[1px] mt-5 leading-[40px] ">
          <p className={` ${styles.fontStyle}  text-[48px]  `}>{data?.name}</p>
          <p className={` ${styles.fontStyle}  text-[48px]  `}>       {data?.lastName} </p>
          <p className=" font-[400] text-[18px]  ">
          {data?.title}
          </p>
          </div>
        </div>

      </div>
    </div>
  );
}

export default LandingPage;
