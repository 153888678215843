import React from "react";
import { Link, useLocation } from "react-router-dom";

function SpecialGuest({ data }) {
  const location = useLocation();
  return (
    <div className="max-w-[1440px] mx-auto md:px-[5%]">
      <div
        className="justify-center items-center text-center mt-[120px] font-for-Results-page "
        style={{ color: data?.textColor }}
      >
        <p className="text-[24px] font-[700] uppercase">{data?.heading}</p>
      </div>

      <div className=" justify-center items-center mx-auto">
        <div
          className={`grid  grid-cols-2 sm:grid-cols-3 ${
            data?.judges?.length < 4
              ? "md:grid-cols-none md:flex md:justify-center"
              : "md:grid-cols-4"
          } mt-[60px] font-for-Results-page`}
          style={{ color: data?.textColor }}
        >
          {data?.judges?.map((item) => (
            <Link
            className="hover:text-black hover:no-underline"
              to={item?.link}
              state={{ previousLocation: location?.pathname }}
            >
              <div
                key={item?.id}
                style={{ color: data?.textColor }}
                className={`justify-center items-center mx-auto mt-10 sm:mt-5 ${
                  data?.judges?.length < 4 && "xl:w-[400px] md:w-[300px]"
                }`}
              >
                <div className="justify-center items-center flex ">
                  <img
                    className="xl:w-[210px] w-[120px] h-[120px] sm:w-[150px] sm:h-[150px] lg:w-[180px]  lg:h-[180px] xl:h-[210px] object-cover"
                    src={item?.image}
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <p className="xl:text-[16px] text-[12px]  font-[400] mt-5 uppercase ">
                    {" "}
                    {item?.position}{" "}
                  </p>
                  <p className=" text-[18px] sm:text-[20px] lg:text-[24px] xl:text-[32px] font-[700] ">
                    {" "}
                    {item?.name}{" "}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SpecialGuest;
