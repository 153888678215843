import Support from "../../Components/Contact/Support";
import Footer from "../../Components/footer/Footer";
import Header from "../../Components/header/Header";

function Contact() {
  return (
    <div>
      <Header
        bgColor={"#fff"}
        textColor={"#111"}
        hambergerBg={"#fff"}
        hambergerText={"#111"}
      />
      <Support />
      <Footer />
    </div>
  );
}

export default Contact;
