import React, { useEffect, useState } from "react";
import { modelLinks } from "../../../../common/Constants";
import UseMediaQuery from "../../../../hooks/UseMediaQuery";
import { Link } from "react-router-dom";
import Back from "../../../../Components/back/Back";

function MinimalModelHeader({
  data,
  aboutRef,
  handleBack,
  achievementsRef,
  brandsRef,
  worksRef,
  contactRef,
  scrollToSection,
}) {
  const [open, setOpen] = useState(false);

  const isDesktop = UseMediaQuery("(min-width:1000px)");

  const isDesktopLarge = UseMediaQuery("(min-width:1100px)");

  const [scrollingBehaviour, setScrollingBehaviour] = useState(false);
  const [scrollValue, setScrollValue] = useState();

  const scrollBehaviour = () => {
    if (window.scrollY >= scrollValue) {
      setScrollingBehaviour(true);
    } else {
      setScrollingBehaviour(false);
    }
  };

  window.addEventListener("scroll", scrollBehaviour);

  useEffect(() => {
    if (isDesktopLarge) {
      setScrollValue(900);
    } else if (isDesktop) {
      setScrollValue(700);
    } else {
      setScrollValue(550);
    }
  }, [scrollBehaviour]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    }
  }, [open]);

  return (
    <>
      <div className="bg-[#D9D9D9] z-40 fixed w-[100%] p-2 lg:p-4   sm:flex  justify-between items-center hidden">
        <div className="flex  ml-[1%] text-[18px] items-center xl:text-[45px] font-[400] modelMinimalFontFamily">
          
          {data?.modelName}
        </div>

        {/* <div className="flex"></div> */}

        <div
          className={`${
            scrollingBehaviour ? "flex justify-between " : "hidden"
          } xl:gap-10 gap-5 modelMinimalFontFamily`}
        >
          {data?.headLinks?.about && (
            <p
              className="cursor-pointer font-[400] text-[13px] lg:text-[18px]"
              onClick={() => scrollToSection(aboutRef)}
            >
              {data?.headLinks?.about}
            </p>
          )}
          {data?.headLinks?.achievements && (
            <p
              className="cursor-pointer font-[400] text-[13px] lg:text-[18px]"
              onClick={() => scrollToSection(achievementsRef)}
            >
              {data?.headLinks?.achievements}
            </p>
          )}
          {data?.headLinks?.majorWorks && (
            <p
              className="cursor-pointer font-[400] text-[13px] lg:text-[18px]"
              onClick={() => scrollToSection(brandsRef)}
            >
              {data?.headLinks?.majorWorks}
            </p>
          )}

          {data?.headLinks?.otherWorks && (
            <p
              className="cursor-pointer font-[400] text-[13px] lg:text-[18px]"
              onClick={() => scrollToSection(worksRef)}
            >
              {data?.headLinks?.otherWorks}
            </p>
          )}

          {data?.headLinks?.contact && (
            <p
              className="cursor-pointer font-[400] text-[13px] lg:text-[18px]"
              onClick={() => scrollToSection(contactRef)}
            >
              {data?.headLinks?.contact}
            </p>
          )}
        </div>

        <div className=" flex  gap-7 mr-[3%]">
          <Link
            target={data?.socialMediaLinks?.instagram && "_blank"}
            to={data?.socialMediaLinks?.instagram}
            className=""
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="19"
              viewBox="0 0 20 19"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.99996 14.682C13.0124 14.682 15.4545 12.362 15.4545 9.50018C15.4545 6.63834 13.0124 4.31836 9.99996 4.31836C6.98749 4.31836 4.54541 6.63834 4.54541 9.50018C4.54541 12.362 6.98749 14.682 9.99996 14.682ZM9.99996 12.9547C12.0082 12.9547 13.6363 11.408 13.6363 9.50018C13.6363 7.59228 12.0082 6.04563 9.99996 6.04563C7.99165 6.04563 6.36359 7.59228 6.36359 9.50018C6.36359 11.408 7.99165 12.9547 9.99996 12.9547Z"
                fill="black"
              />
              <path
                d="M15.4545 3.45459C14.9524 3.45459 14.5454 3.84126 14.5454 4.31823C14.5454 4.79519 14.9524 5.18186 15.4545 5.18186C15.9566 5.18186 16.3636 4.79519 16.3636 4.31823C16.3636 3.84126 15.9566 3.45459 15.4545 3.45459Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.594509 2.82932C0 3.93778 0 5.38882 0 8.29091V10.7091C0 13.6112 0 15.0622 0.594509 16.1706C1.11745 17.1457 1.95189 17.9384 2.97824 18.4352C4.14503 19 5.67245 19 8.72727 19H11.2727C14.3275 19 15.855 19 17.0217 18.4352C18.0481 17.9384 18.8825 17.1457 19.4055 16.1706C20 15.0622 20 13.6112 20 10.7091V8.29091C20 5.38882 20 3.93778 19.4055 2.82932C18.8825 1.8543 18.0481 1.06158 17.0217 0.564784C15.855 0 14.3275 0 11.2727 0H8.72727C5.67245 0 4.14503 0 2.97824 0.564784C1.95189 1.06158 1.11745 1.8543 0.594509 2.82932ZM11.2727 1.72727H8.72727C7.16985 1.72727 6.11114 1.72862 5.2928 1.79213C4.49567 1.854 4.08804 1.96615 3.80367 2.10379C3.11945 2.435 2.56315 2.96347 2.21452 3.61349C2.06963 3.88363 1.95158 4.27089 1.88645 5.02816C1.8196 5.80558 1.81818 6.81136 1.81818 8.29091V10.7091C1.81818 12.1887 1.8196 13.1944 1.88645 13.9718C1.95158 14.7291 2.06963 15.1164 2.21452 15.3865C2.56315 16.0365 3.11945 16.565 3.80367 16.8962C4.08804 17.0338 4.49567 17.146 5.2928 17.2079C6.11114 17.2713 7.16985 17.2727 8.72727 17.2727H11.2727C12.8302 17.2727 13.8888 17.2713 14.7072 17.2079C15.5044 17.146 15.912 17.0338 16.1964 16.8962C16.8805 16.565 17.4368 16.0365 17.7855 15.3865C17.9304 15.1164 18.0485 14.7291 18.1135 13.9718C18.1804 13.1944 18.1818 12.1887 18.1818 10.7091V8.29091C18.1818 6.81136 18.1804 5.80558 18.1135 5.02816C18.0485 4.27089 17.9304 3.88363 17.7855 3.61349C17.4368 2.96347 16.8805 2.435 16.1964 2.10379C15.912 1.96615 15.5044 1.854 14.7072 1.79213C13.8888 1.72862 12.8302 1.72727 11.2727 1.72727Z"
                fill="black"
              />
            </svg>
          </Link>
          <Link
            target={data?.socialMediaLinks?.facebook && "_blank"}
            to={data?.socialMediaLinks?.facebook}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4ZM4 2C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H10V11H9C8.4477 11 8 10.5523 8 10C8 9.4477 8.4477 9 9 9H10V7.5C10 5.567 11.567 4 13.5 4H14.1C14.6523 4 15.1 4.44772 15.1 5C15.1 5.55228 14.6523 6 14.1 6H13.5C12.6716 6 12 6.67157 12 7.5V9H14.1C14.6523 9 15.1 9.4477 15.1 10C15.1 10.5523 14.6523 11 14.1 11H12V18H16C17.1046 18 18 17.1046 18 16V4C18 2.89543 17.1046 2 16 2H4Z"
                fill="black"
              />
            </svg>
          </Link>

          <Link
            target={data?.socialMediaLinks?.youtube && "_blank"}
            to={data?.socialMediaLinks?.youtube}
            className=""
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="20"
              viewBox="0 0 28 20"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.3684 18.2935C24.3641 18.3971 26.883 16.1781 27 13.3325V5.96448C26.883 3.11888 24.3641 0.899881 21.3684 1.00349H6.6316C3.63592 0.899881 1.11697 3.11888 1 5.96448V13.3325C1.11697 16.1781 3.63592 18.3971 6.6316 18.2935H21.3684Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.3604 6.15968L17.4697 8.81241C17.736 8.96011 17.8996 9.23095 17.8996 9.52377C17.8996 9.81671 17.736 10.0875 17.4697 10.2351L13.3604 13.1374C12.5804 13.64 11.4 13.2115 11.4 12.426V6.86857C11.4 6.08681 12.5817 5.65703 13.3604 6.15968Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>

          <div className="  text-end" onClick={handleBack}>
            <svg
              className="mr-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M1.00025 1.00025C0.373009 1.62749 0.842944 2.11483 1.47018 2.74207L7.22835 8.50021L1.47018 14.2585C0.842944 14.8857 0.372591 15.3731 0.999829 16.0002C1.87049 16.8708 2.37253 16.1274 2.99975 15.5002L8.7579 9.74197L15 16.0002C15.6272 16.6274 15.8728 16.6274 16.5 16.0002C17.1272 15.3731 17.1272 15.1274 16.5 14.5002L10.2284 8.24201L16.5 2.00021C17.1272 1.37298 17.1273 1.12744 16.5 0.500212C15.8726 -0.127026 15.6272 -0.127025 15 0.500214L8.7579 6.75841L3 1.00024C2.37278 0.373022 1.92544 0.0750566 1.00025 1.00025Z"
                fill="#4C4C4C"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="bg-[#D9D9D9] z-40 fixed w-[100%] p-4 sm:hidden flex  justify-between items-center">
        <div className=" text-[16px] items-center flex font-[400] modelMinimalFontFamily">
          <div className="bg-transparent mr-2  " onClick={handleBack}>
            {" "}
            <Back />{" "}
          </div>{" "}
          {data?.modelName}
        </div>

        <div onClick={() => setOpen(!open)} className="mr-[2%]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="12"
            viewBox="0 0 24 12"
            fill="none"
          >
            <rect width="23.1669" height="1.33037" rx="0.665185" fill="black" />
            <rect
              y="5.33496"
              width="23.1669"
              height="1.33037"
              rx="0.665185"
              fill="black"
            />
            <rect
              y="10.6694"
              width="23.1669"
              height="1.33037"
              rx="0.665185"
              fill="black"
            />
          </svg>
        </div>
      </div>

      <div
        onClick={() => setOpen(false)}
        className={`  fixed right-0 top-0  z-50 bg-opacity-40  bg-[#95a5ad]   h-full w-full  ${
          open ? "translate-x-0  top-0 ] " : "translate-x-full"
        } `}
      >
        <div
          className={` ${
            open ? "translate-x-0  top-0 " : "translate-x-full"
          }  bg-[#FFF] p-3 w-[63%] fixed  right-2 top-2  h-full rounded-lg  transform transition-transform ease-in-out duration-300`}
        >
          <div
            className=" gap-7 modelMinimalFontFamily   text-[14px] font-[400] text-[#4C4C4C] cursor-pointer"
            style={{ listStyleType: "none" }}
          >
            <div
              onClick={() => setOpen(false)}
              className=" justify-end items-end flex"
            >
              <p className=" mr-4 text-[15px] ">Close</p>

              <svg
                className="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <path
                  d="M1.00025 1.00025C0.373009 1.62749 0.842944 2.11483 1.47018 2.74207L7.22835 8.50021L1.47018 14.2585C0.842944 14.8857 0.372591 15.3731 0.999829 16.0002C1.87049 16.8708 2.37253 16.1274 2.99975 15.5002L8.7579 9.74197L15 16.0002C15.6272 16.6274 15.8728 16.6274 16.5 16.0002C17.1272 15.3731 17.1272 15.1274 16.5 14.5002L10.2284 8.24201L16.5 2.00021C17.1272 1.37298 17.1273 1.12744 16.5 0.500212C15.8726 -0.127026 15.6272 -0.127025 15 0.500214L8.7579 6.75841L3 1.00024C2.37278 0.373022 1.92544 0.0750566 1.00025 1.00025Z"
                  fill="#4C4C4C"
                />
              </svg>
            </div>
            <div className="mt-5">
              <hr className="h-[2px] bg-[#4C4C4C]" />
            </div>
            <div className="justify-end items-end grid ">
              {data?.headLinks?.about && (
                <div
                  className="justify-end mt-4 items-end flex"
                  onClick={() => scrollToSection(aboutRef)}
                >
                  <li className="mr-3 " onClick={() => setOpen(false)}>
                    {" "}
                    {data?.headLinks?.about}
                  </li>
                </div>
              )}
              {data?.headLinks?.achievements && (
                <div
                  onClick={() => scrollToSection(achievementsRef)}
                  className="justify-end mt-4 items-end flex"
                >
                  <li className="mr-3 " onClick={() => setOpen(false)}>
                    {" "}
                    {data?.headLinks?.achievements}
                  </li>
                </div>
              )}
              {data?.headLinks?.majorWorks && (
                <div
                  onClick={() => scrollToSection(brandsRef)}
                  className="justify-end mt-4 items-end flex"
                >
                  <li className="mr-3 " onClick={() => setOpen(false)}>
                    {" "}
                    {data?.headLinks?.majorWorks}{" "}
                  </li>
                </div>
              )}
              {data?.headLinks?.otherWorks && (
                <div
                  onClick={() => scrollToSection(worksRef)}
                  className="justify-end mt-4 items-end flex"
                >
                  <li className="mr-3 " onClick={() => setOpen(false)}>
                    {" "}
                    {data?.headLinks?.otherWorks}
                  </li>
                </div>
              )}
              {data?.headLinks?.contact && (
                <div
                  onClick={() => scrollToSection(contactRef)}
                  className="justify-end mt-4 items-end flex"
                >
                  <li className="mr-3 " onClick={() => setOpen(false)}>
                    {" "}
                    {data?.headLinks?.contact}
                  </li>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MinimalModelHeader;
