import React from 'react'
import { Link } from 'react-router-dom'

function Profiles({data,titleColor,positionColor}) {

if(!data) return null


  return (
   

<div className={`grid  sm:gap-[30px] xl:gap-[60px] grid-cols-4`}>
{data.map((item)=>(
    <div className=''>
    <>
    <div className='justify-center  items-center flex'>
        <img src={item?.image} className='lg:w-[230px] object-cover w-[140px] h-[140px] lg:h-[230px]' alt='' />
      
        </div>
      <div className='justify-center items-center mt-5 text-center '> 

      
        <p style={{color:positionColor}} className='text-[11px] lg:text-[16px] uppercase '>{item?.position}</p>
        <p style={{color:positionColor,fontStyle:"italic"}} className='text-[20px] lg:text-[30px] font-[600] homepage-campaign-name-font '>{item?.name}</p>
        <p style={{color:titleColor}} className='lg:text-[16px] text-[11px]  '>{item?.title}</p>
       
       
        </div>
        <div className='text-center mt-3'>
        <Link style={{color:titleColor}} target='_blank' className=' mt-5 lg:text-[16px] text-[11px]' to={item?.link} >View {item?.type} </Link>
       
        </div>
       

        <div>
          
        </div>
        
    
    </>
    </div>
))}
</div>



   
  )
}

export default Profiles