import React, { useContext, useEffect, useState } from "react";
import { imageData } from "../context/dataContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useMediaQuery from "../hooks/UseMediaQuery";

function WorksViewPage() {
  const { index } = useParams();
  const { brandsData, brandStyles } = useContext(imageData);
  const {isDesktop } = useMediaQuery("min-width:640px")
  const location = useLocation()
  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(parseInt(index, 10));
  const next = () => {
    const newIndex = currentIndex + 1;
    if (newIndex < brandsData?.datas?.datas?.length) {
      setCurrentIndex(newIndex);
    }
  };

  const prev = () => {
    const newIndex = currentIndex - 1;
    if (newIndex >= 0) {
      setCurrentIndex(newIndex);
    }
  };

  useEffect(() => {
    if (brandsData?.datas?.datas[currentIndex] === undefined) {
      handleNavigateToPrevLocation();
    }
  }, [brandsData?.datas?.datas[currentIndex] === undefined]);

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };


  return (
    <div>
      <div
        style={{
          background: brandStyles?.mainBgColor,
          color: brandStyles?.textColor,
        }}
        className={`h-screen overflow-y-auto  ${brandStyles?.fontFamily}  mx-auto  w-[100%]`}
      >
        <div
          style={{ background: brandStyles?.mainBgColor }}
          className="justify-between py-1 sticky top-0 z-20 px-4 sm:px-10 w-[100%] text-center mt-10  items-center flex"
        >
          <div className="sm:w-[25] w-[20]"></div>
          <div className="text-center ">
            <p
              className={`${brandStyles?.headFontFamily}  font-[400] text-[22px] sm:text-[32px]`}
            >
              {brandsData?.datas?.name}
            </p>
          </div>
          <div
            className="  text-end"
          onClick={handleNavigateToPrevLocation}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
               width={isDesktop ? "25" : "20"}
              height="25"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M1 16L16 1"
                stroke={brandStyles?.textColor}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1L16 16"
                stroke={brandStyles?.textColor}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div
          className="w-[90%] mt-10  pt-10 pb-10 sm:w-[80%] mx-auto sm:p-10"
          style={{ background: brandStyles?.subBgColor }}
        >
          <div className="grid relative ">
            <div className="justify-center  items-center flex">
              <div className="sm:w-[250px] w-[155px] h-[192px] sm:h-[250px]">
                <img
                  className="object-contain  h-full w-full"
                  src={brandsData?.datas?.datas[currentIndex]?.logo}
                  alt=""
                />
              </div>
            </div>
            <div
              className={` 
         
            text-center  mt-5 `}
            >
              <p className="ml-4 mr-4 sm:ml-10 sm:mr-10 xl:ml-32 xl:mr-32 text-[14px]  sm:text-[16px]">
                {" "}
                {brandsData?.datas?.datas[currentIndex]?.para}
              </p>
            </div>

            {currentIndex > 0 && (
              <div
                onClick={prev}
                className="hidden sm:flex absolute -left-16 top-52"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <g filter="url(#filter0_b_4984_1160)">
                    <circle
                      cx="15"
                      cy="15"
                      r="15"
                      fill="#676767"
                      fill-opacity="0.7"
                    />
                  </g>
                  <path
                    d="M17.25 9L11.25 15L17.25 21"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_b_4984_1160"
                      x="-24"
                      y="-24"
                      width="78"
                      height="78"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="12"
                      />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_4984_1160"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_4984_1160"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            )}

            {currentIndex < brandsData?.datas?.datas?.length - 1 && (
              <div
                onClick={next}
                className="hidden sm:flex absolute -right-16 top-52"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <g filter="url(#filter0_b_4984_1163)">
                    <circle
                      cx="15"
                      cy="15"
                      r="15"
                      transform="matrix(-1 0 0 1 30 0)"
                      fill="#676767"
                      fill-opacity="0.7"
                    />
                  </g>
                  <path
                    d="M12.75 9L18.75 15L12.75 21"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_b_4984_1163"
                      x="-24"
                      y="-24"
                      width="78"
                      height="78"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="12"
                      />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_4984_1163"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_4984_1163"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            )}

            {currentIndex > 0 &&
            <div onClick={prev} className="sm:hidden absolute -left-2 top-48">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <g filter="url(#filter0_b_4984_1160)">
                  <circle
                    cx="15"
                    cy="15"
                    r="15"
                    fill="#676767"
                    fill-opacity="0.7"
                  />
                </g>
                <path
                  d="M17.25 9L11.25 15L17.25 21"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <filter
                    id="filter0_b_4984_1160"
                    x="-24"
                    y="-24"
                    width="78"
                    height="78"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_4984_1160"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_4984_1160"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
} 

            {currentIndex < brandsData?.datas?.datas?.length - 1 &&
            <div onClick={next} className="sm:hidden absolute -right-2 top-48">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <g filter="url(#filter0_b_4984_1163)">
                  <circle
                    cx="15"
                    cy="15"
                    r="15"
                    transform="matrix(-1 0 0 1 30 0)"
                    fill="#676767"
                    fill-opacity="0.7"
                  />
                </g>
                <path
                  d="M12.75 9L18.75 15L12.75 21"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <filter
                    id="filter0_b_4984_1163"
                    x="-24"
                    y="-24"
                    width="78"
                    height="78"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_4984_1163"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_4984_1163"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorksViewPage;
