import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../Components/header/Header";
import axios from "axios";
import Banner from "../Components/MagazineTemplate/components/giveaways/Banner";
import GiveAwayListing from "../Components/MagazineTemplate/components/giveaways/GiveAwayListing";
import HowToParcitipate from "../Components/MagazineTemplate/components/giveaways/HowToParcitipate";
import Rules from "../Components/MagazineTemplate/components/giveaways/Rules";
import Footer from "../Components/footer/Footer";
import LoadingCircle from "../Components/LoadingCircle/LoadingCircle";
import Back from "../Components/back/Back";
import { useLocation, useNavigate } from "react-router-dom";
import Context from "../context/Context";
import useMediaQuery from "../hooks/UseMediaQuery";
import { isEmpty } from "lodash";
import CampaignListing from "../Components/campaignListingComponent/CampaignListing";

function GiveawayPage() {
  const [giveaway, setGiveAway] = useState();
  const [scrolling, setScrolling] = useState(false);
  const [selectedChip, setSelectedChip] = useState();
  const isDesktop = useMediaQuery("(min-width:640px)");
  const navigate = useNavigate();
  const sectionRefs = useRef({});
  const howToParcitipateRef = useRef();
  const location = useLocation();
  const { isMobile } = useContext(Context);
  useEffect(() => {
    getGiveAwayData();
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);
  const getGiveAwayData = async () => {
    try {
      let config = {
        method: "get",
        url: `https://storage.googleapis.com/goviral_magazine_data/giveaways/data.json`,
      };
      let result = await axios(config);
      setGiveAway(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  const handleScroll = () => {
    if (window.scrollY >= 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  window.addEventListener("scroll", handleScroll);
  const scrollToSection = (section) => {
    window.scrollTo({
      top: sectionRefs.current[section].offsetTop - 150,
      left: 0,
      behavior: "smooth",
    });
  };
  const scrollToHowToParticipate = () => {
    window.scrollTo({
      top: howToParcitipateRef.current.offsetTop - 150,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="fonts-for-Sathosi-giveaway">
      {!isMobile && (
        <div
          onClick={handleNavigateToPrevLocation}
          className={`fonts-for-Sathosi-homepage py-4 sticky top-0  w-[100%] ${
            scrolling && "py-4"
          } bg-[white]   items-center z-20   `}
        >
          <div className="ml-4">
            <Back />
          </div>
        </div>
      )}

      {giveaway?.data ? (
        <>
          {/* <Banner data={giveaway?.data} /> */}
          <div className="max-w-[1440px] mx-auto">
            {/* <div className="justify-center items-center flex text-center mt-[60px]">
              <p className="homepage-campaign-name-font text-[21px] font-[600] text-[#161616]">
                {" "}
                {giveaway?.data?.giveawayHeading}{" "}
              </p>
            </div>

            <div className="justify-center items-center mt-[23px] flex text-center ">
              <p className="text-[32px] font-[700] ">
                {" "}
                {giveaway?.data?.giveawayMainHeading}{" "}
              </p>
            </div> */}
            <div
              className={`w-[100%] sticky z-10  bg-[white]  py-5  flex items-center  ${
                isMobile ? "top-0" : "top-14"
              }`}
            >
              <div className="ml-4  sm:ml-8 ">
                <button
                  className="border-[1px] border-[#575757] py-[10px] px-[20px] uppercase sm:text-[16px] text-[12px] whitespace-nowrap"
                  style={{ borderRadius: "0px 32px 32px 0px" }}
                >
                  {" "}
                  Giveaways and Events
                </button>
              </div>
              <ul className="ml-4 sm:ml-16 w-fit pr-[30px]   font-headerLinks  grid-flow-col grid gap-[20px] overflow-x-auto [&::-webkit-scrollbar]:hidden">
                {giveaway?.data?.events?.map((item, index) => {
                  if (!isEmpty(item?.datas) && !item.hide) {
                    return (
                      <li>
                        <button
                          className={`whitespace-nowrap uppercase ${
                            selectedChip === item?.secId &&
                            "bg-black text-[#F6F6F6]"
                          }  hover:bg-black hover:text-[#F6F6F6] text-[12px] sm:text-[16px] border-[1px]  py-2 px-5 rounded-[50px] `}
                          onClick={() => {
                            scrollToSection(item?.secId);
                            setSelectedChip(item?.secId);
                          }}
                        >
                          {" "}
                          {item?.name}{" "}
                        </button>
                      </li>
                    );
                  }
                })}
                {giveaway?.data?.giveaways?.map((item, index) => {
                  if (!isEmpty(item?.data) && !item.hide) {
                    return (
                      <li>
                        <button
                          className={`whitespace-nowrap uppercase ${
                            selectedChip === item?.secId &&
                            "bg-black text-[#F6F6F6]"
                          }  hover:bg-black hover:text-[#F6F6F6] text-[12px] sm:text-[16px] border-[1px]  py-2 px-5 rounded-[50px] `}
                          onClick={() => {
                            scrollToSection(item?.secId);
                            setSelectedChip(item?.secId);
                          }}
                        >
                          {" "}
                          {item?.name}{" "}
                        </button>
                      </li>
                    );
                  }
                })}

                {/* {isMobile && (
                  <li>
                    <button
                      className={`whitespace-nowrap uppercase ${
                        selectedChip === "howToParticipate" &&
                        "bg-black text-[#F6F6F6]"
                      }  hover:bg-black hover:text-[#F6F6F6] text-[12px] sm:text-[16px] border-[1px]  py-2 px-5 rounded-[50px] `}
                      onClick={() => {
                        setSelectedChip("howToParticipate");
                        scrollToHowToParticipate();
                      }}
                    >
                      How To Particiapte
                    </button>
                  </li>
                )} */}
              </ul>
            </div>
            {giveaway?.data?.events?.map((item) => {
              if (!isEmpty(item?.datas)) {
                return (
                  <div
                    ref={(ref) => {
                      sectionRefs.current[item?.secId] = ref;
                    }}
                  >
                    <div className="mt-1 ">
                      <CampaignListing data={item} />
                    </div>
                  </div>
                );
              }
            })}
            <div className="py-[10px]" />
            {giveaway?.data?.giveaways?.map((obj) => {
              if (!isEmpty(obj?.data)) {
                return (
                  <div
                    ref={(ref) => {
                      sectionRefs.current[obj?.secId] = ref;
                    }}
                  >
                    <GiveAwayListing data={obj} />
                  </div>
                );
              }
            })}

            <div className="text-[#141EFF] gap-[15px] justify-center items-center flex mt-[50px]">
              {isDesktop ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1838_1278)">
                    <g opacity="0.2">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.8996 1.80005C19.0927 1.79974 19.2807 1.86152 19.4359 1.97626C19.5912 2.091 19.7054 2.25261 19.7618 2.43725L23.2322 13.8366L33.6092 17.6293C33.7803 17.6919 33.9284 17.8051 34.0338 17.9538C34.1392 18.1024 34.197 18.2796 34.1995 18.4618C34.2021 18.644 34.1492 18.8227 34.048 18.9743C33.9468 19.1258 33.8019 19.243 33.6326 19.3105L23.324 23.4055L19.7618 35.3574C19.7063 35.543 19.5924 35.7056 19.4371 35.8213C19.2817 35.9369 19.0933 35.9994 18.8996 35.9994C18.706 35.9994 18.5175 35.9369 18.3622 35.8213C18.2068 35.7056 18.0929 35.543 18.0374 35.3574L14.4752 23.4055L4.16661 19.3105C3.9973 19.243 3.85246 19.1258 3.75123 18.9743C3.65 18.8227 3.59716 18.644 3.5997 18.4618C3.60223 18.2796 3.66003 18.1024 3.76543 17.9538C3.87084 17.8051 4.01889 17.6919 4.19001 17.6293L14.567 13.8366L18.0392 2.43725C18.0955 2.25291 18.2095 2.0915 18.3644 1.97679C18.5192 1.86209 18.7069 1.80013 18.8996 1.80005Z"
                        fill="#141EFF"
                      />
                      <path
                        d="M31.193 35.91C30.995 35.91 30.833 35.766 30.815 35.586C30.455 31.986 30.185 30.834 26.801 30.528C26.7068 30.5199 26.6192 30.4763 26.5558 30.4062C26.4923 30.3361 26.4578 30.2446 26.459 30.15C26.459 29.952 26.603 29.79 26.801 29.772C30.221 29.448 30.455 28.512 30.815 25.002C30.833 24.804 30.995 24.66 31.193 24.66C31.391 24.66 31.553 24.804 31.571 24.984C31.931 28.512 32.165 29.448 35.585 29.754C35.783 29.772 35.927 29.934 35.927 30.132C35.927 30.33 35.783 30.492 35.585 30.51C32.219 30.834 31.931 31.986 31.589 35.568C31.571 35.766 31.409 35.91 31.211 35.91H31.193Z"
                        fill="#141EFF"
                      />
                    </g>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.2004 0.900026C16.3934 0.89972 16.5815 0.961497 16.7367 1.07623C16.892 1.19097 17.0062 1.35259 17.0626 1.53723L20.533 12.9366L30.91 16.7292C31.0811 16.7919 31.2292 16.9051 31.3346 17.0537C31.44 17.2024 31.4978 17.3796 31.5003 17.5618C31.5028 17.744 31.45 17.9227 31.3488 18.0742C31.2475 18.2258 31.1027 18.343 30.9334 18.4104L20.6248 22.5054L17.0626 34.4574C17.0071 34.6429 16.8932 34.8056 16.7378 34.9212C16.5825 35.0369 16.394 35.0993 16.2004 35.0993C16.0067 35.0993 15.8183 35.0369 15.6629 34.9212C15.5076 34.8056 15.3937 34.6429 15.3382 34.4574L11.776 22.5054L1.46739 18.4104C1.29808 18.343 1.15324 18.2258 1.05201 18.0742C0.950782 17.9227 0.897942 17.744 0.900478 17.5618C0.903014 17.3796 0.960808 17.2024 1.06621 17.0537C1.17162 16.9051 1.31967 16.7919 1.49079 16.7292L11.8678 12.9366L15.34 1.53723C15.3963 1.35288 15.5102 1.19148 15.6651 1.07677C15.82 0.962061 16.0076 0.900106 16.2004 0.900026ZM16.2004 4.88883L13.459 13.8888C13.4183 14.0222 13.3473 14.1443 13.2514 14.2455C13.1555 14.3467 13.0374 14.4243 12.9064 14.472L4.32579 17.6112L12.847 20.9952C12.9732 21.0456 13.0864 21.1239 13.1781 21.2243C13.2698 21.3247 13.3375 21.4445 13.3762 21.5748L16.2022 31.05L19.0246 21.5748C19.0633 21.4445 19.131 21.3247 19.2227 21.2243C19.3143 21.1239 19.4275 21.0456 19.5538 20.9952L28.075 17.6112L19.4944 14.4756C19.3634 14.4279 19.2453 14.3503 19.1494 14.2491C19.0535 14.1479 18.9824 14.0258 18.9418 13.8924L16.2004 4.88883Z"
                      fill="#141EFF"
                    />
                    <path
                      d="M30.1508 34.416C29.9708 34.416 29.8088 34.272 29.7908 34.092C29.4668 30.816 29.2148 29.772 26.2088 29.484C26.0288 29.466 25.8848 29.304 25.8848 29.124C25.8848 28.944 26.0288 28.782 26.2088 28.764C29.2868 28.476 29.4668 27.684 29.7908 24.408C29.7908 24.228 29.9708 24.084 30.1508 24.084C30.3308 24.084 30.4928 24.228 30.5108 24.408C30.8348 27.684 31.0328 28.476 34.0928 28.764C34.2728 28.782 34.4168 28.944 34.4168 29.124C34.4168 29.304 34.2728 29.466 34.0928 29.484C31.0688 29.772 30.8348 30.816 30.5108 34.11C30.5108 34.29 30.3488 34.434 30.1508 34.434V34.416Z"
                      fill="#141EFF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1838_1278">
                      <rect width="36" height="36" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1870_761)">
                    <g opacity="0.2">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.5984 1.19995C12.7271 1.19975 12.8525 1.24093 12.956 1.31742C13.0595 1.39392 13.1357 1.50166 13.1732 1.62475L15.4868 9.22435L22.4048 11.7528C22.5189 11.7945 22.6176 11.87 22.6879 11.9691C22.7582 12.0682 22.7967 12.1863 22.7984 12.3078C22.8001 12.4293 22.7648 12.5484 22.6974 12.6494C22.6299 12.7504 22.5333 12.8286 22.4204 12.8736L15.548 15.6036L13.1732 23.5716C13.1362 23.6952 13.0603 23.8037 12.9567 23.8808C12.8532 23.9579 12.7275 23.9995 12.5984 23.9995C12.4693 23.9995 12.3437 23.9579 12.2401 23.8808C12.1366 23.8037 12.0607 23.6952 12.0236 23.5716L9.64884 15.6036L2.77644 12.8736C2.66357 12.8286 2.567 12.7504 2.49952 12.6494C2.43203 12.5484 2.3968 12.4293 2.3985 12.3078C2.40019 12.1863 2.43872 12.0682 2.50899 11.9691C2.57926 11.87 2.67796 11.7945 2.79204 11.7528L9.71004 9.22435L12.0248 1.62475C12.0624 1.50186 12.1383 1.39425 12.2416 1.31778C12.3449 1.24131 12.4699 1.20001 12.5984 1.19995Z"
                        fill="#141EFF"
                      />
                      <path
                        d="M20.7947 23.9399C20.6627 23.9399 20.5547 23.8439 20.5427 23.7239C20.3027 21.3239 20.1227 20.5559 17.8667 20.3519C17.8039 20.3465 17.7455 20.3175 17.7032 20.2707C17.6609 20.224 17.6379 20.163 17.6387 20.0999C17.6387 19.9679 17.7347 19.8599 17.8667 19.8479C20.1467 19.6319 20.3027 19.0079 20.5427 16.6679C20.5547 16.5359 20.6627 16.4399 20.7947 16.4399C20.9267 16.4399 21.0347 16.5359 21.0467 16.6559C21.2867 19.0079 21.4427 19.6319 23.7227 19.8359C23.8547 19.8479 23.9507 19.9559 23.9507 20.0879C23.9507 20.2199 23.8547 20.3279 23.7227 20.3399C21.4787 20.5559 21.2867 21.3239 21.0587 23.7119C21.0467 23.8439 20.9387 23.9399 20.8067 23.9399H20.7947Z"
                        fill="#141EFF"
                      />
                    </g>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.7996 0.599976C10.9283 0.599773 11.0537 0.640957 11.1572 0.717449C11.2607 0.793941 11.3368 0.901686 11.3744 1.02478L13.688 8.62438L20.606 11.1528C20.7201 11.1946 20.8188 11.27 20.8891 11.3691C20.9593 11.4682 20.9979 11.5863 20.9995 11.7078C21.0012 11.8293 20.966 11.9484 20.8985 12.0494C20.831 12.1505 20.7345 12.2286 20.6216 12.2736L13.7492 15.0036L11.3744 22.9716C11.3374 23.0952 11.2615 23.2037 11.1579 23.2808C11.0544 23.3579 10.9287 23.3995 10.7996 23.3995C10.6705 23.3995 10.5449 23.3579 10.4413 23.2808C10.3378 23.2037 10.2618 23.0952 10.2248 22.9716L7.85001 15.0036L0.977608 12.2736C0.864739 12.2286 0.768175 12.1505 0.700689 12.0494C0.633204 11.9484 0.597977 11.8293 0.599668 11.7078C0.601358 11.5863 0.639888 11.4682 0.710159 11.3691C0.78043 11.27 0.879131 11.1946 0.993208 11.1528L7.91121 8.62438L10.226 1.02478C10.2635 0.901882 10.3395 0.794279 10.4428 0.717806C10.546 0.641333 10.6711 0.60003 10.7996 0.599976ZM10.7996 3.25918L8.97201 9.25918C8.94491 9.34809 8.89753 9.42949 8.8336 9.49697C8.76967 9.56444 8.69093 9.61613 8.60361 9.64798L2.88321 11.7408L8.56401 13.9968C8.64818 14.0304 8.72364 14.0826 8.78475 14.1495C8.84585 14.2164 8.891 14.2963 8.91681 14.3832L10.8008 20.7L12.6824 14.3832C12.7082 14.2963 12.7534 14.2164 12.8145 14.1495C12.8756 14.0826 12.951 14.0304 13.0352 13.9968L18.716 11.7408L12.9956 9.65038C12.9083 9.61853 12.8295 9.56684 12.7656 9.49936C12.7017 9.43189 12.6543 9.35049 12.6272 9.26158L10.7996 3.25918Z"
                      fill="#141EFF"
                    />
                    <path
                      d="M20.0999 22.944C19.9799 22.944 19.8719 22.848 19.8599 22.728C19.6439 20.544 19.4759 19.848 17.4719 19.656C17.3519 19.644 17.2559 19.536 17.2559 19.416C17.2559 19.296 17.3519 19.188 17.4719 19.176C19.5239 18.984 19.6439 18.456 19.8599 16.272C19.8599 16.152 19.9799 16.056 20.0999 16.056C20.2199 16.056 20.3279 16.152 20.3399 16.272C20.5559 18.456 20.6879 18.984 22.7279 19.176C22.8479 19.188 22.9439 19.296 22.9439 19.416C22.9439 19.536 22.8479 19.644 22.7279 19.656C20.7119 19.848 20.5559 20.544 20.3399 22.74C20.3399 22.86 20.2319 22.956 20.0999 22.956V22.944Z"
                      fill="#141EFF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1870_761">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
              <p className="text-[18px] sm:text-[21px] font-[700]">
                {" "}
                and many more coming...
              </p>
            </div>
          </div>
          <div ref={howToParcitipateRef}>
            <HowToParcitipate data={giveaway?.data} />
          </div>

          <Rules data={giveaway?.data?.rules} />

          {isDesktop && <Footer />}
        </>
      ) : (
        <div className="w-[10%] mx-auto mt-36">
          <LoadingCircle />
        </div>
      )}
    </div>
  );
}

export default GiveawayPage;
