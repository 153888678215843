import React from 'react'


function Prizes({campaignData}) {
    const bannerStyle = {
        background: `url("${campaignData?.backgroundImage}") center center / cover no-repeat`,
      };
  return (
    <div className='mt-5'>

<p style={{color:campaignData?.titleTextColor}} className='sm:text-[40px] text-[21px] lg:text-[70px] uppercase template-Prices-title text-center'>{campaignData?.title}</p>
<div className='justify-center items-center flex'>
<div style={bannerStyle} className='justify-center w-[83%] sm:w-[80%] pt-10 pb-10 items-center flex'>

<div className='grid  items-center grid-cols-1 lg:grid-cols-2'>

<div className=' justify-center items-center flex order-2 lg:order-1 lg:mt-0 mt-10'>
<div className=' lg:ml-[20%] w-[90%] p-7 rounded-2xl prize-template-para-font text-[25px] font-[600]  text-center ' style={{textDecoration:"underline",backgroundColor:campaignData?.paraBg}}>
<p className='lg:text-[25px] sm:text-[18px] text-[12px]'>{campaignData?.para1}</p>
<p className='mt-5 lg:text-[25px] sm:text-[18px] text-[12px]'>{campaignData?.para2}</p>
<button className='lg:text-[23px] sm:text-[16px] text-[11px] rounded-lg  bg-[#e5e2e2] pl-2 pr-2 mt-5'>
Join Now !
</button>
</div>
</div>

<div className='order-1 lg:order-2 justify-center items-center flex'>
<img className='w-[50%] ' src={campaignData?.giftImage} alt='' />
</div>
</div>



</div>

</div>
    

    </div>
  )
}

export default Prizes