import React from "react";

function Webinar({ data }) {
  return (
    <div className="mt-28 sm:mt-20 max-w-[1440px] mx-auto">
      <div className="justify-center items-center flex text-center">
        <p
          className="font-for-Results-page text-[21px] lg:text-[32px] font-[700] mr-4 ml-4 sm:ml-[10%] sm:mr-[10%] "
          style={{ color: data?.headingColor }}
        >
          {data?.heading}
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 mt-16 sm:mt-20">
        <div className="ml-4 sm:ml-14 font-for-Results-page">
          <div>
            <button
              style={{ backgroundColor: data?.webinarDetails?.buttonBg }}
              className="rounded p-2 text-[24px] sm:text-[20px] xl:text-[24px] font-[500]"
            >
              {data?.webinarDetails?.webinarType}
            </button>
          </div>

          <div
            style={{ color: data?.webinarDetails?.headingColor }}
            className="xl:leading-[50px] leading-[32px] sm:leading-[40px] mt-5  template-results-heading-font "
          >
            <p className="text-[32px] sm:text-[36px] xl:text-[48px] font-[400] ">
              {data?.webinarDetails?.heading}
            </p>
          </div>

          <div className="flex items-start mt-10 ">
            <div className="w-[40%]">
              <img
                className="w-[126px] h-[142px] object-cover"
                src={data?.webinarDetails?.profile}
                alt=""
              />
            </div>
            <div
              style={{ color: data?.webinarDetails?.textColor }}
              className="w-[100%] items-center ml-3 xl:-ml-14"
            >
              <div className="uppercase text-[18px] sm:text-[13px] xl:text-[21px] font-[700]">
                <p>{data?.webinarDetails?.name}</p>
              </div>
              <div>
                <p className="mt-5 mr-4 sm:mr-20 text-[16px] sm:text-[12px] xl:text-[16px] font-[400] leading-[20px]">
                  {data?.webinarDetails?.desc}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative sm:mr-10 sm:mt-0 mt-10">
          <div className="justify-center items-center flex">
            <div className="h-[161px] w-[350px] sm:w-[711px] sm:h-[350px] "></div>
            <img
              className="object-cover "
              src={data?.webinarDetails?.image}
              alt=""
            />
          </div>

          <div className="absolute grid left-14 sm:left-5 -bottom-7 sm:bottom-7 lg:-bottom-12 grid-cols-2 template-results-heading-font">
            <div style={{ color: data?.webinarDetails?.headingColor }}>
              <p className="text-[32px] sm:text-[35px] lg:text-[50px] xl:text-[72px] font-[400] uppercase whitespace-nowrap">
                {data?.webinarDetails?.listners}
              </p>
            </div>

            <div
              style={{ color: data?.webinarDetails?.buttonBg }}
              className="items-end text-end "
            >
              <p className="text-[32px] sm:text-[35px] lg:text-[50px] xl:text-[72px] font-[400] uppercase ">
                {data?.webinarDetails?.time}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Webinar;
