import React, { useEffect } from "react";

function Pop({setPopUp}) {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
          document.body.style.overflow = "unset";
        };
      }, []);
  return (
    <div className="fixed fonts-for-Sathosi-homepage bg-[#141414] min-h-[100vh] bg-opacity-80 top-0  left-0 right-0  w-[100%] ">
      <div className="justify-center items-center flex">
        <div
          className=" grid   h-[476px] bg-[#141414] w-[90%] rounded-[17px]  sm:w-[50%] mt-32 "
          style={{
            opacity: 1,
            boxShadow: " 0px 4px 60px 0px rgba(0, 0, 0, 0.16)",
          }}
        >
          <div >
            <div className="justify-center items-center flex mt-[105px]">
                <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="110"
                height="110"
                viewBox="0 0 110 110"
                fill="none"
              >
                <path
                  d="M55 0C44.122 0 33.4884 3.22569 24.4437 9.26917C15.399 15.3126 8.34947 23.9025 4.18665 33.9524C0.0238306 44.0023 -1.06535 55.061 1.05683 65.7299C3.17902 76.3989 8.41726 86.199 16.1091 93.8908C23.801 101.583 33.6011 106.821 44.2701 108.943C54.939 111.065 65.9977 109.976 76.0476 105.813C86.0975 101.651 94.6874 94.601 100.731 85.5563C106.774 76.5116 110 65.878 110 55C110 47.7773 108.577 40.6253 105.813 33.9524C103.049 27.2795 98.9981 21.2163 93.8909 16.1091C88.7837 11.0019 82.7205 6.95063 76.0476 4.18663C69.3747 1.42262 62.2227 0 55 0ZM86.218 45.3035L53.218 75.5535C52.1732 76.5115 50.7989 77.0292 49.3816 76.9984C47.9644 76.9677 46.6138 76.3909 45.6115 75.3885L29.1115 58.8885C28.5862 58.3811 28.1672 57.7742 27.879 57.1032C27.5907 56.4322 27.439 55.7105 27.4326 54.9802C27.4263 54.2499 27.5655 53.5257 27.842 52.8497C28.1185 52.1738 28.5269 51.5597 29.0433 51.0433C29.5598 50.5269 30.1738 50.1185 30.8498 49.842C31.5257 49.5654 32.2499 49.4263 32.9802 49.4326C33.7105 49.439 34.4322 49.5907 35.1032 49.8789C35.7743 50.1672 36.3812 50.5862 36.8885 51.1115L49.665 63.888L78.782 37.1965C79.8571 36.2104 81.2798 35.6918 82.7372 35.7547C84.1947 35.8176 85.5674 36.4569 86.5535 37.532C87.5396 38.607 88.0582 40.0298 87.9953 41.4872C87.9324 42.9447 87.2931 44.3174 86.218 45.3035Z"
                  fill="#E0E0E0"
                />
              </svg>
              </div>
            </div>
            <div className="justify-center text-center items-center grid text-[#fff] mt-[44px]">
                <p className="text-[18px] leading-[20px] font-[400] FooterFontFamily ">Your request is successfully submitted</p>
            <p className="text-[16px] font-[300] celebrityFontFamilyLight mt-[7px]"> Thankyou for filling it out! We’ll get back to you soon!  </p>
            </div>
<div className="justify-center items-center flex celebrityFontFamilyLight mt-[44px]">
    <button onClick={()=>setPopUp(false)} className="bg-[#E0E0E0] py-1 px-10 rounded-[8px]">
OK
    </button>

</div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Pop;
