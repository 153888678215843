import React, { forwardRef, useState } from "react";
import useMediaQuery from "../../../../hooks/UseMediaQuery";

function MinimalModelAbout({ data, aboutref }) {

const [showFull , setShowFull] = useState(false)
const isDesktop  = useMediaQuery("(min-width:640px)")

  return (
    <div className="max-w-[1440px] ml-auto mr-auto" ref={aboutref}>
      <div className="bg-[#D9D9D9] modelMinimalFontFamily ml-4 sm:ml-16  mr-4 sm:mr-9 mt-10 pt-5 sm:pt-10 pb-5 sm:pb-16">
        <p className="sm:ml-20 font-semibold  text-[25px] sm:text-[45px] sm:text-start text-center">
          {" "}
          {data?.heading}{" "} 
        </p>

        <div className="grid sm:flex mt-5">
        <div className="sm:justify-start justify-center items-center  flex">
            <div className="lg:w-[300px]  w-[200px] sm:w-[200px] h-[200px] lg:h-[300px] sm:ml-10">
              <img
                src={data?.image}
                className=" rounded-[50%] object-cover w-full h-full"
                alt="" 
              />
            </div>
          </div>

          {/* <div className="justify-start items-center sm:mt-0 mt-3 flex">
            <div className="sm:ml-14 mr-4 ml-4 sm:mr-10 text-[16px] font-[300]">
              <p> {isDesktop ? data?.para : showFull ? data?.para : data?.para?.slice(0,250)} {!showFull && !isDesktop && "..."} <span onClick={()=>setShowFull(!showFull)} className="sm:hidden items-center font-[700]"> {showFull ? "Show less" : "Read more >"} </span> </p>
            </div>
          </div> */}

          <div className="justify-start items-center sm:mt-0 mt-3 flex">
            <div className="sm:ml-14 mr-4 ml-4 sm:mr-10 text-[16px] font-[300]">
              <p> { isDesktop ? data?.para : showFull ? data?.para : data?.para?.slice(0,250)} {!showFull && !isDesktop && "..."}  <span onClick={()=>setShowFull(!showFull)} className="whitespace-nowrap cursor-pointer sm:hidden items-start font-[700]"> {showFull ? "Show Less" : "Read More >"} </span>  </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(MinimalModelAbout);
