import React, { useState } from "react";
import styles from "./ImageGallery.module.css";
import Popup from "../Popup/Popup";

const ImageGallery = ({ data, reference, hasMore, setItemsCount }) => {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const originalArray = data;

  const calculateNumArrays = () => {
    return window.innerWidth < 768 ? 2 : 4;
  };
  const numArrays = calculateNumArrays();
  const splitArrays = Array.from({ length: numArrays }, () => []);

  for (let i = 0; i < originalArray.length; i++) {
    const arrayIndex = i % numArrays;
    splitArrays[arrayIndex].push(originalArray[i]);
  }
  const handleShowPopup = (files) => {
    let index = data?.indexOf(files);
    setCurrentIndex(index);
    setClickedImg(files);
  };

  const handelRotationRight = () => {
    const totalLength = data?.length;

    if (currentIndex + 3 >= totalLength) {
      if (hasMore) {
        setItemsCount((prev) => prev + 8);
      }
    }
    let newIndex = currentIndex + 1;
    if (newIndex === totalLength) {
      newIndex = 0;
    }
    const newUrl = data?.filter((item) => {
      return data?.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0];

    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = data?.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = data && data[totalLength - 1];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = data?.filter((item) => {
      return data?.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0];

    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };
  const isPrevDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex === data?.length - 1 && !hasMore;

  return (
    <>
      {clickedImg && (
        <Popup
          clickedImg={clickedImg}
          setClickedImg={setClickedImg}
          handelRotationLeft={handelRotationLeft}
          handelRotationRight={handelRotationRight}
          isNextDisabled={isNextDisabled}
          isPrevDisabled={isPrevDisabled}
        />
      )}
      <div className={`${styles.imageGallery} mt-8`}>
        {splitArrays?.map((splitArray, index) => (
          <div key={index} className={styles.image}>
            {splitArray?.map((obj, itemIndex) => (
              <div
                onClick={() => handleShowPopup(obj)}
                ref={index === splitArrays?.length - 1 ? reference : null}
              >
                <div className="w-full my-[4px] relative cursor-pointer">
                  <img src={obj?.thumbnailUrl} className="w-full" />

                  <div className="text-white absolute top-0 left-0 w-[100%] h-[100%] flex flex-col justify-between  p-[8px] text-smallest top-and-bottom-gradient">
                    <div className=" flex items-center justify-between">
                      {obj?.likesCount && (
                        <div className="flex gap-[5px] items-center">
                          <p className="text-small">{obj?.likesCount}</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="18"
                            viewBox="0 0 24 21"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 3.34243C9.80074 0.847315 6.12572 0.0762153 3.37017 2.36099C0.614608 4.64577 0.226662 8.46578 2.39062 11.168C4.18981 13.4147 9.63476 18.1532 11.4193 19.6869C11.6189 19.8585 11.7188 19.9443 11.8353 19.978C11.9368 20.0073 12.048 20.0073 12.1497 19.978C12.2662 19.9443 12.3659 19.8585 12.5657 19.6869C14.3502 18.1532 19.7951 13.4147 21.5943 11.168C23.7583 8.46578 23.4177 4.62174 20.6147 2.36099C17.8118 0.10025 14.1993 0.847315 12 3.34243Z"
                              fill="#FFFEFE"
                              stroke=""
                              stroke-width="1.67"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                    <div className=" flex items-center justify-between">
                      <p className="truncate max-w-[80%]">
                        {obj?.participantName}
                      </p>
                      {obj.fileType === "video" && (
                        <div className={styles.playbtnBg}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="32"
                            viewBox="0 0 26 32"
                            fill="none"
                          >
                            <path
                              d="M1.99339 30.8984C2.30915 31.0344 2.66445 31.125 3.01974 31.125C3.57238 31.125 4.12503 30.8984 4.59866 30.5358L24.1382 18.3425L24.3355 18.1159C24.8487 17.5266 25.125 16.8013 25.125 15.9855C25.125 15.1696 24.8487 14.399 24.3355 13.8551L24.1382 13.6284L4.59866 1.34452C3.88814 0.709954 2.8618 0.573977 1.99339 0.981912C1.00652 1.43517 0.375 2.5684 0.375 3.79228V28.0881C0.374935 29.312 1.00652 30.4451 1.99339 30.8984Z"
                              fill="#FFFEFE"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {obj?.description && (
                  <div
                    style={{
                      padding: "16px 10px 3px 16px",
                      boxShadow:
                        "5px 0 10px rgba(0, 0, 0, 0.1), -5px 0 10px rgba(0, 0, 0, 0.1)",
                    }}
                    className="w-full h-auto  "
                  >
                    <p className="text-[13px] font-[400] text-six-line">
                      {" "}
                      {obj?.description}{" "}
                    </p>
                    <p className="my-[16px] flex items-center text-[13px] text-[#787878]">
                      View Entry
                      <svg
                        className=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <path
                          d="M2.5 7.91667L7.91667 2.5M7.91667 2.5V7.7M7.91667 2.5H2.71667"
                          stroke="#787878"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default ImageGallery;
