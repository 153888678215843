import React, { useEffect } from "react";
import useMediaQuery from "../../hooks/UseMediaQuery";

function AchievementsPopUp({
  mainBgColor,
  fontFamilyText,
  selectedOne,
  textColor,
  fontFamily,
  datas,
  subBgColor,
  name,
  onClose,
  prev,
  next,
}) {
  const isDesktop = useMediaQuery("(min-width:640px)");

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div
      style={{ transform: "translate(-50%, -50%)", background: mainBgColor }}
      className={`h-full pb-[100px]  overflow-scroll ${fontFamily}  w-[100%] max-w-[1440px] z-50 opacity-[0.9px] fixed top-[50%]  bottom-0 left-[50%] shadow-xl`}
    >
      <div
        style={{ background: mainBgColor }}
        className="z-20 py-1 justify-between w-[100%] sticky top-0  text-center mt-10  items-center flex"
      >
        <div></div>
        <div className="text-center ml-10 sm:ml-20 ">
          <p className="font-[400] text-[22px] sm:text-[32px]"> {name} </p>
        </div>
        <div className="mr-4 sm:mr-[7%]  text-end" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={isDesktop ? "25" : "20"}
            height="25"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M1 16L16 1"
              stroke={textColor}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 1L16 16"
              stroke={textColor}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <div
        className="w-[90%] mt-10  pt-10 pb-10 sm:w-[80%] mx-auto sm:p-10"
        style={{ background: subBgColor, color: textColor }}
      >
        <div className="grid relative ">
          <div className="justify-center  items-center flex">
            <div className="sm:w-[250px] w-[155px] h-[192px] sm:h-[250px]">
              <img
                className="object-cover  h-full w-full"
                src={datas[selectedOne]?.image}
                alt=""
              />
            </div>
          </div>
          <div className={`text-center  mt-2 text-[20px] ${fontFamily}`}>
            <p> {datas[selectedOne]?.event} </p>
          </div>
          <div className={`${fontFamilyText} text-[white]   text-center  mt-3`}>
            <p className="ml-4  mr-4 sm:ml-10 sm:mr-10 xl:ml-32 xl:mr-32 text-[14px]">
              {" "}
              {datas[selectedOne]?.desc}{" "}
            </p>
          </div>

          {selectedOne > 0 && (
            <div onClick={prev} className="sm:hidden absolute -left-2 top-48">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <g filter="url(#filter0_b_4984_1160)">
                  <circle
                    cx="15"
                    cy="15"
                    r="15"
                    fill="#676767"
                    fill-opacity="0.7"
                  />
                </g>
                <path
                  d="M17.25 9L11.25 15L17.25 21"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <filter
                    id="filter0_b_4984_1160"
                    x="-24"
                    y="-24"
                    width="78"
                    height="78"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_4984_1160"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_4984_1160"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          )}

          {selectedOne < datas?.length - 1 && (
            <div onClick={next} className="sm:hidden absolute -right-2 top-48">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <g filter="url(#filter0_b_4984_1163)">
                  <circle
                    cx="15"
                    cy="15"
                    r="15"
                    transform="matrix(-1 0 0 1 30 0)"
                    fill="#676767"
                    fill-opacity="0.7"
                  />
                </g>
                <path
                  d="M12.75 9L18.75 15L12.75 21"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <filter
                    id="filter0_b_4984_1163"
                    x="-24"
                    y="-24"
                    width="78"
                    height="78"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_4984_1163"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_4984_1163"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AchievementsPopUp;
