import React from 'react'
import { Link } from 'react-router-dom'

function Contest1({contestId,contestImage,positionColor,titleColor, contestAplicantName, contestTitle,  contestName}) {
  return (
    <div>
<Link to={contestId} target='_blank' className='hover:text-black hover:no-underline'>
<div className='justify-center items-center flex'>

  <img src={contestImage} alt='' className='object-cover w-[100%] h-[350px] sm:w-[150px] sm:h-[150px] lg:w-[290px] lg:h-[290px]'/>
</div>


<div className='justify-center items-center fonts-for-Sathosi-homepage text-center font-[400] mt-5 ml-10 mr-10'>
  <p style={{color:positionColor}} className='uppercase text-[16px] sm:text-[11px]  lg:text-[16px]'>Contest : {contestName}</p>
  <p style={{fontStyle:"italic"}} className='homepage-campaign-name-font font-[600] text-[32px] sm:text-[20px] lg:text-[30px]'>{contestAplicantName}</p>
  <p style={{color:titleColor}} className=' text-[16px] sm:text-[11px] lg:text-[16px] ml-10 mr-10'>{contestTitle}</p>

</div>
</Link>
    </div>
  )
}

export default Contest1