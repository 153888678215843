import React from "react";
import DrawingWinners from "./competitionWinners/DrawingWinners";
import Line from "./line/Line";

function Winners({ data ,lineColor}) {
  return (
    <div className="max-w-[1440px] mx-auto">
      <div
        className="mt-[60px] justify-start items-center text-center font-for-Results-page  "
        style={{ color: data?.textColor }}
      >
        <p className="text-[32px] font-[700] uppercase"> {data?.name} </p>

        <p className="text-[17px] font-[400] leading-[22px] lg:ml-[20%] mt-5 ml-5 mr-5 sm:ml-[18%] sm:mr-[18%] lg:mr-[20%]">
          {" "}
          {data?.campaignWinnersPara}{" "}
        </p>
      </div>

      {data?.judgeWinners?.map((obj) => {
        return (
          <DrawingWinners
            headingColor={data?.headingColor}
            cardBgColor={data?.cardBgColor}
            textColor={data?.textColor}
            paraColor={data?.paraColor}
            bgColor={data?.bgColor}
            heading={obj?.category}
            paraText={obj?.description}
            lineColor={lineColor}
            results={obj?.results}
          />
        );
      
      })}
     
      {/* {data?.crowdWinners && <Line lineColor={data?.lineColor} />} */}
      {data?.crowdWinners?.map((obj) => {
        return (
          <DrawingWinners
            headingColor={data?.headingColor}
            textColor={data?.textColor}
            bgColor={data?.bgColor}
            paraColor={data?.paraColor}
            cardBgColor={data?.cardBgColor}
            heading={obj?.category}
            paraText={obj?.description}
            results={obj?.results}
          />
        );
      })}
    </div>
  );
}

export default Winners;
