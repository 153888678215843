import React, { forwardRef, useState } from "react";

import Odd from "./acheivementComponents/Odd";
import Even from "./acheivementComponents/Even";
import Line from "../../../../Components/MagazineTemplate/components/Line";
import Back from "../../../../Components/back/Back";
import useMediaQuery from "../../../../hooks/UseMediaQuery"
import MinimalModelLine from "./MinimalModelLine";

const PopAchievement = ({ onClose, datas, selectedAchievement }) => {
  return (
    <div
      style={{ transform: "translate(-50%, -50%)" }}
      className="h-[100%]  w-[100%] max-w-[1440px] z-10 opacity-[0.9px] fixed top-[50%] bg-[white] bottom-0 left-[50%] shadow-xl"
    >
      <div className="justify-center items-center flex">
        <div className="flex w-[100%] mx-5 rounded-full p-2 mt-5 items-center border-2  border-[#D9D9D9]">
          <div className=" " onClick={onClose}>
            <Back />
          </div>
          <div className="text-center">
            <p className="ml-5"> {datas[selectedAchievement]?.name} </p>
          </div>
        </div>
      </div>

      <div className="grid  mt-10">
        <div className="justify-center items-center flex">
          <div className="w-[180px] h-[202px] ">
            <img
              className="w-full h-full object-cover"
              src={datas[selectedAchievement]?.image}
              alt=""
            />
          </div>
        </div>
        <div className="text-center mt-2 modelMinimalAcheivementsFontFamily">
          <p className="text-[14px] font-[500]">
            {" "}
            {datas[selectedAchievement]?.event}{" "}
          </p>

          <p className="text-[12px] font-[400]">
            {" "}
            {datas[selectedAchievement]?.date}{" "}
          </p>
          <p className="text-[16px] font-[300] mt-4 mx-4">
            {" "}
            {datas[selectedAchievement]?.para}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

function MinimalModelAchievements({ data, achievementsref }) {
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState();
  const [toggleOpen,setToggleOpen] = useState(false)
  const [selectedAchievement, setSelectedAchievement] = useState(null);
  const isDesktop = useMediaQuery("(min-width:640px)")

  const handleClick = (index) => {
    setOpen(true);
    setSelectedAchievement(index);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedAchievement(null);
  };
  const handleOpenToggle = (id) => {
    setToggle(id);
 
  };
  const handleCloseToggle = () => {
    setToggle("");

  };

  

  return (
    <>
      {data && (
        <div
          className="max-w-[1440px] mt-16 ml-auto mr-auto modelMinimalAcheivementsFontFamily "
          ref={achievementsref}
        >
       
       <div className="modelMinimalFontFamily  sm:w-full  flex items-center  ">
        {" "}
        <p className="ml-4 sm:ml-16 text-start  text-[25px] sm:text-[45px] font-semibold">
          {" "}
       {data?.heading}
        </p>
        <div className="justify-center mt-[3px]  items-center  mx-auto w-[50%] lg:w-[65%]">
          <MinimalModelLine />
        </div>
      </div>
            <div className="sm:mt-10
             mt-5">
            {data?.datas?.map((item) => (
              <>
                <div onClick={() =>{item?.id ===  toggle ? handleCloseToggle() : handleOpenToggle(item.id)}} className=" justify-between mt-10 items-center my-3 flex">
                  <div  className="ml-4 sm:ml-20"  >
                    <p  className="text-[16px] sm:text-[28px] lg:text-[34px] font-[500]">
                      {" "}
                      {item?.id} . {item?.event}{" "}
                    </p>
                  </div>

                  {item?.id === toggle  ? (
                    <div
                      className="mr-4 cursor-pointer"
                      onClick={handleCloseToggle}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={isDesktop ? "24" : "14"}
                        height={isDesktop ? "24" : "14"}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g opacity="0.7">
                          <path
                            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                            fill="#120A08"
                          />
                        </g>
                      </svg>
                    </div>
                  ) : (
                    <div
                      className="mr-4 cursor-pointer"
                      onClick={() => handleOpenToggle(item.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={isDesktop ? "24" : "14"}
                        height={isDesktop ? "24" : "14"}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g opacity="0.7">
                          <path
                            d="M11 19V13H5V11H11V5H13V11H19V13H13V19H11Z"
                            fill="#120A08"
                          />
                        </g>
                      </svg>
                    </div>
                  )}
                </div>
                {item.id === toggle &&  (
                 
                  <div className="justify-start items-start   pb-[25px]">
                    <div className="grid lg:flex lg:ml-20  w-[100%] lg:w-[70%]  ">
                      <div className="max-w-[181px] shrink-0 lg:mr-16 h-[181px] mx-auto  sm:max-w-[242px] sm:h-[271px] lg:float-left ">
                        <img
                          src={item?.image}
                          className=" object-cover w-full h-full"
                          alt=""
                        />
                      </div>
                      <div className="flex w-[100%] lg:text-start justify-center items-center lg:mt-0 mt-5  text-center ">
                    
                       <p className="font-[400] lg:ml-0 lg:mr-0 ml-10 mr-10  leading-[17px]  sm:leading-[26px] text-[12px] sm:text-[16px]"> {item?.para} </p>
               
                   </div>
                    </div>
                  </div>
                )}
                <div className="w-[90%] mx-auto  opacity-20">
                <MinimalModelLine/>
                </div>
              </>
            ))}
</div>
     

  
        </div>
      )}
    </>
  );
}

export default forwardRef(MinimalModelAchievements);
