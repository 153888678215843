import React from "react";
import ReactPlayer from "react-player";

function GymCollaboration({ data }) {
  console.log(data);
  return (
    <div className="w-[100%] mt-[74px] sm:mt-[120px] fontFamilyForGym">
      <div className="max-w-[1440px] mx-auto">
        <div className="text-[#f6f6f6] text-[32px]  w-full sm:w-[50%]">
          <div className="ml-4 sm:ml-24 mr-4 sm:mr-0">
            <p className="font-[600] "> {data?.heading} </p>

            <p className="font-[600] text-[#787878] text-[18px] mt-[16px]">
              {" "}
              {data?.para}{" "}
            </p>
          </div>
        </div>

        <div className="sm:ml-24 ml-4 mr-4 sm:mr-24 gap-[30px] justify-start items-start grid grid-flow-col mt-[32px]   [&::-webkit-scrollbar]:hidden overflow-x-auto ">
        {data?.datas?.map((item)=>(
           <div className=" sm:w-[340px]  w-[225px] h-[225px]  sm:h-[340px]  relative">
           <ReactPlayer
             style={{ objectFit: "contain" }}
             height={"100%"}
             width={"100%"}
             controls={true}
             url={item?.video}
           />
           {/* <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center"></div> */}
         </div>
        ))}
          
        </div>
      </div>
    </div>
  );
}

export default GymCollaboration;
