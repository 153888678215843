import React, { useEffect, useState } from "react";

import Awards from "./Awards";
import LandingPage from "./LandingPage";
import LatestVideos from "./LatestVideos";
import Gallery from "./Gallery";
import styles from "./Influencer.module.css";
import Footer from "../../../../Components/footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Back from "../../../../Components/back/Back";
import { useContext } from "react";
import { imageData } from "../../../../context/dataContext";
import { Context } from "../../../../ScrollContext";
import { isEmpty } from "lodash";
import useMediaQuery from "../../../../hooks/UseMediaQuery";

function Influencer({ influencerDetails, id }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { scrollingPositon, setScrollingPosition } = useContext(Context);
  const { setImagesData } = useContext(imageData);
  const isDesktop = useMediaQuery("(min-width:640px)");

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (influencerDetails)
      return setImagesData({
        workName: influencerDetails?.gallery?.name,
        datas: influencerDetails?.gallery?.images,
      });
  }, [influencerDetails]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollingPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(influencerDetails)) {
      window.scrollTo(0, scrollingPositon);
    }
  }, [influencerDetails]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="w-[100%]">
      <div
        className=" mt-1 sticky top-0 z-10  bg-[white]"
        onClick={handleNavigateToPrevLocation}
      >
        <div className="sm:ml-5 ml-2">
          <Back />
        </div>
      </div>
      <div className={`${styles?.paraFontStyle}  max-w-[1440px]  mx-[auto] `}>
        {/* <div className='md:bg-[#F7DEF9] bg-[#FCF0BB] md:pb-[5%] pb-[20%]  md:h-auto relative' >
<div className='grid grid-cols-1 md:grid-cols-2  '>
  <div className='justify-center items-center  order-2 md:order-1 absolute top-[31%] md:top-0 md:relative'>
   <p className='ml-4 md:ml-10 font-style text-[48px] mt-[250px]  md:text-[68px]'>{influencerDetails?.name}</p>
   <p className='ml-4 md:ml-10 font-style text-[48px] -mt-5 md:text-[68px]'>Chandrakumar Pillai</p>
   <p className='ml-4 md:ml-10 font-[400] text-[18px] -mt-3 '>Indian actress and Bharatanatyam dancer</p>
  <div className='md:bg-[transparent] bg-[#F7DEF9] md:p-0 p-1 md:mt-10 mt-1 md:pb-0 '>
    <p className='md:ml-10 ml-4 font-[600] text-black text-[16px] mt-6  md:text-[18px]' >About</p>
<p className='md:ml-10 ml-4 mt-3 font-[400] text-black text-[16px]  md:text-[18px]'>{influencerDetails?.about}</p>
  </div>
  <div className=' md:bg-[transparent] bg-[#F7DEF9] md:p-0 p-1 md:mt-2  md:pb-0 '>
    <button className='font-[600] md:ml-10 ml-4   text-[16px] text-white bg-[#2A44A0] p-3 mt-6 rounded-full pl-6 pr-6'>Explore More</button>
  </div>
    </div> 
    <div className='justify-end items-end  flex order-1 md:order-2 '>
<img className='' src={bannerImage} alt=''/>
    </div>

</div>
</div> */}

        <LandingPage
          styles={styles}
          handleBack={handleNavigateToPrevLocation}
          data={influencerDetails}
          id={id}
        />

        <Awards data={influencerDetails?.awards} />

        <LatestVideos styles={styles} data={influencerDetails?.latestVideos} />

        <Gallery
          data={influencerDetails?.gallery}
          details={influencerDetails}
        />
        <div className="mt-20">{isDesktop && <Footer />}</div>
      </div>
    </div>
  );
}

export default Influencer;
