import React, { useEffect, useState } from "react";
import UseMediaQuery from "../../hooks/UseMediaQuery";
import { Link, useLocation } from "react-router-dom";


function Carousel({ sectionData }) {
  
  const [marginStyle, setMarginStyle] = useState(false);

  const location = useLocation();
  const isDesktop = UseMediaQuery("(min-width:640px)");
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    if (location?.pathname.startsWith("/campaigns")) {
      setMarginStyle(true);
    }
  }, [location]);

  if (!sectionData || sectionData.length === 0) {
    return null;
  }

  const prevSlide = () => {
    const firstIndex = currentImage === 0;
    const newIndex = firstIndex ? sectionData?.length - 1 : currentImage - 1;
    scrollToImage(newIndex);

  };
  

   

  const nextSlide = () => {
    const lastIndex = currentImage === sectionData?.length - 1;
    const newIndex = lastIndex ? 0 : currentImage + 1;
  
    scrollToImage(newIndex);
  
  };






 
  const refs = sectionData?.reduce((acc, val, i) => {
    acc[i] = React.createRef();
    return acc;
  }, {});

  const scrollToImage = i => {

    setCurrentImage(i);

    refs[i]?.current?.scrollIntoView({
      //     Defines the transition animation.
      behavior: 'smooth',
      //      Defines vertical alignment.
      block: 'nearest',
      //      Defines horizontal alignment.
      inline: 'start',
    });

  };




  

  return (
    <>
 
 <div className={`w-full relative  flex justify-center `}>
 <div className=" flex justify-center   items-center">
      <div className="relative w-full">
        <div className="carousel">
         
          {sectionData.map((img, i) => (
            <div className="w-full flex-shrink-0 h-full " key={img} ref={refs[i]}>
              <img src={isDesktop ? img?.image : img?.mobImage} className="w-full h-full object-cover" />
            </div>
          ))}
     
        </div>
      </div>
    </div>
      
      
    <div className="absolute  -bottom-14 gap-7 left-4  sm:left-14 items-center hidden sm:flex">
       <div className="flex items-center gap-4" onClick={prevSlide}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
         <path
              d="M38.8542 18.75H6.25723L13.7568 11.2504L11.9891 9.48267L1.47168 20.0001L11.9891 30.5175L13.7568 28.7498L6.25707 21.25H38.8542V18.75Z"
              fill="#161616"
            />
          </svg>
          <div className="text-[18px] font-[500] text-[#111]">Prev</div>
        </div>

        <div className="flex  items-center gap-4" onClick={nextSlide}>
          <div className="text-[18px] font-[500] text-[#111]">Next</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M1.14582 18.75H33.7428L26.2432 11.2504L28.0109 9.48267L38.5283 20.0001L28.0109 30.5175L26.2432 28.7498L33.7429 21.25H1.14582V18.75Z"
              fill="#161616"
            />
          </svg>
        </div>
      </div>


      {/* mob */}

         <div
        onClick={nextSlide}
        className="right-5 absolute  -bottom-14 items-center flex sm:hidden"
      >
        <div className="text-[16px] font-[500] mr-3 text-[#111]">Next</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M0.916754 15.0001H26.9943L20.9946 9.00037L22.4088 7.58618L30.8228 16.0001L22.4088 24.4141L20.9946 22.9999L26.9944 17.0001H0.916754V15.0001Z"
            fill="#161616"
          />
        </svg>
      </div>

      <div
        onClick={prevSlide}
        className="left-5 absolute  -bottom-14 items-center flex sm:hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M31.0832 15.0001H5.00568L11.0054 9.00037L9.59118 7.58618L1.17725 16.0001L9.59118 24.4141L11.0054 22.9999L5.00556 17.0001H31.0832V15.0001Z"
            fill="#161616"
          />
        </svg>
        <div className="text-[16px] font-[500] ml-3 text-[#111]">Prev</div>
      </div>

      <div className="absolute sm:text-[16px] text-[13px] bottom-5 sm:-bottom-11 text-[#FFF] sm:text-[#161616] right-5 sm:right-14  flex">
        {currentImage + 1} / {sectionData?.length}
      </div>
      
      
       </div>
      
    </>
    // <div
    //   className={`w-[100%]   sm:mt-0  ${
    //     marginStyle ? "" : "mt-20"
    //   } relative fonts-for-Sathosi-homepage  w-full m-auto   group`}
    // >
    //   <div className="relative ">

    //     <Link className="" to={sectionData[currentIndex]?.link} target="_blank">
    //       {" "}
    //       <div
    //         style={{
    //           backgroundImage: isDesktop
    //             ? `url(${sectionData[currentIndex]?.image})`
    //             : `url(${sectionData[currentIndex]?.mobImage})`,
    //         }}
    //         className={` w-full h-[630px] sm:h-[560px] xl:h-[648px] 2xl:h-[750px] bg-center bg-cover    transform transition-transform ease-in-out  top-0  duration-500`}
    //       ></div>{" "}
    //     </Link>

    //   </div>

    //   {/* mob */}
    //   <div
    //     onClick={nextSlide}
    //     className="right-5 absolute  -bottom-14 items-center flex sm:hidden"
    //   >
    //     <div className="text-[16px] font-[500] mr-3 text-[#111]">Next</div>
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="32"
    //       height="32"
    //       viewBox="0 0 32 32"
    //       fill="none"
    //     >
    //       <path
    //         d="M0.916754 15.0001H26.9943L20.9946 9.00037L22.4088 7.58618L30.8228 16.0001L22.4088 24.4141L20.9946 22.9999L26.9944 17.0001H0.916754V15.0001Z"
    //         fill="#161616"
    //       />
    //     </svg>
    //   </div>
    //   <div
    //     onClick={prevSlide}
    //     className="left-5 absolute  -bottom-14 items-center flex sm:hidden"
    //   >
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="32"
    //       height="32"
    //       viewBox="0 0 32 32"
    //       fill="none"
    //     >
    //       <path
    //         d="M31.0832 15.0001H5.00568L11.0054 9.00037L9.59118 7.58618L1.17725 16.0001L9.59118 24.4141L11.0054 22.9999L5.00556 17.0001H31.0832V15.0001Z"
    //         fill="#161616"
    //       />
    //     </svg>
    //     <div className="text-[16px] font-[500] ml-3 text-[#111]">Prev</div>
    //   </div>
    //   {/* mob end */}

    //   <div className="absolute  -bottom-14 gap-7 left-4  sm:left-14 items-center hidden sm:flex">
    //     <div className="flex items-center gap-4" onClick={prevSlide}>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="40"
    //         height="40"
    //         viewBox="0 0 40 40"
    //         fill="none"
    //       >
    //         <path
    //           d="M38.8542 18.75H6.25723L13.7568 11.2504L11.9891 9.48267L1.47168 20.0001L11.9891 30.5175L13.7568 28.7498L6.25707 21.25H38.8542V18.75Z"
    //           fill="#161616"
    //         />
    //       </svg>
    //       <div className="text-[18px] font-[500] text-[#111]">Prev</div>
    //     </div>

    //     <div className="flex  items-center gap-4" onClick={nextSlide}>
    //       <div className="text-[18px] font-[500] text-[#111]">Next</div>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="40"
    //         height="40"
    //         viewBox="0 0 40 40"
    //         fill="none"
    //       >
    //         <path
    //           d="M1.14582 18.75H33.7428L26.2432 11.2504L28.0109 9.48267L38.5283 20.0001L28.0109 30.5175L26.2432 28.7498L33.7429 21.25H1.14582V18.75Z"
    //           fill="#161616"
    //         />
    //       </svg>
    //     </div>
    //   </div>

    //   <div className="absolute sm:text-[16px] text-[13px] bottom-5 sm:-bottom-11 text-[#FFF] sm:text-[#161616] right-5 sm:right-14  flex">
    //     {currentIndex + 1} / {sectionData?.length}
    //   </div>
    // </div>
  );
}

export default Carousel;
