import React from "react";

function SupporTeam({ data }) {
  return (
    <div>
      <p className="mt-[30px]  lg:mt-[60px] text-[24px] sm:text-[32px] font-[600]">
        {" "}
        Meet the team{" "}
      </p>
      <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 mt-[20px] gap-[20px]">
        {data?.map((item, index) => (
          <div key={index} className="mx-auto">
            <div className="h-[200px] w-[170px] lg:h-[300px] lg:w-[260px] ">
              <img
                className="w-full h-full object-cover"
                src={item?.image}
                alt=""
                srcset=""
              />
            </div>
            <p className="text-[16px] sm:text-[18px] font-[600] mt-[9px] whitespace-nowrap">
              {" "}
              {item?.name}
            </p>
            <p className="lg:text-[13px] text-[12px] font-[400] mt-[5px]">
              {" "}
              {item?.job}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SupporTeam;
