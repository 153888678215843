import React, { useEffect, useState } from "react";
import Header from "../Components/header/Header";
import Banner from "../Components/MagazineTemplate/components/howitworks/Banner";
import axios from "axios";
import AccordianSection from "../Components/MagazineTemplate/components/howitworks/AccordianSection";
import Footer from "../Components/footer/Footer";
import Back from "../Components/back/Back";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingCircle from "../Components/LoadingCircle/LoadingCircle";
import useMediaQuery from "../hooks/UseMediaQuery";

function HowItWorks() {
  const [howitworks, setHowitWorks] = useState();
  const [scrolling, setScrolling] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width:640px)")

  const getHowItWorks = async () => {
    try {
      let config = {
        method: "get",
        // url: `/howitworks/data.json`,
        url: `https://storage.googleapis.com/goviral_magazine_data/howitworks/data.json`,
      };
      let result = await axios(config);
      setHowitWorks(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHowItWorks();
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };

  const handleScroll = () => {
    if (window.scrollY >= 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  window.addEventListener("scroll", handleScroll);

  return (
    <div className="fonts-for-Sathosi-howitWorks min-h-[80vh]">
      <div
        onClick={handleNavigateToPrevLocation}
        className={`fonts-for-Sathosi-homepage py-4 sticky top-0  w-[100%] ${
          scrolling && "py-4"
        } bg-[white]   items-center z-20   `}
      >
        <div className="ml-4">
          <Back />
        </div>
      </div>

      <div className="max-w-[1440px] mx-auto">
        {howitworks?.steps ? (
          <>
            <Banner data={howitworks} />

            <AccordianSection data={howitworks?.steps} />
          </>
        ) : (
          <div className=" mx-auto mt-20">
            <LoadingCircle />
          </div>
        )}
      </div>

      {howitworks?.steps && isDesktop && <Footer />}
    </div>
  );
}

export default HowItWorks;
