import React from "react";

function Line({ lineColor }) {
  const color = {
    background: lineColor || "rgba(255, 255, 255, 0.20)",
  };

  return (
    <div className="justify-center items-center flex mt-[50px] sm:mt-[60px]">
      <div
        style={{ backgroundColor: color?.background }}
        className=" w-[88%] h-[1px]"
      ></div>
    </div>
  );
}

export default Line;
